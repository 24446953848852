import { Button, TextField, Typography } from "@suraasa/placebo-ui"
import api from "api"
import { Note } from "api/resources/teacher/types"
import { format } from "date-fns"
import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { createUseStyles } from "react-jss"
import { useParams } from "react-router-dom"
import { handleErrors } from "utils/helpers"
import toast from "utils/toast"

const useStyles = createUseStyles({
  note: {
    whiteSpace: "pre-wrap",
  },
})

const Notes = ({ manageHiringProcess }: { manageHiringProcess: boolean }) => {
  const { jobApplicantId, schoolId } = useParams()

  const [notes, setNotes] = useState<Note[]>([])

  const classes = useStyles()

  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<{ notes: string }>()

  const onSubmit = handleSubmit(async formData => {
    if (!jobApplicantId) return

    const res = await api.teacher.notes.create({
      urlParams: { jobApplicantId },
      data: formData,
      headers: {
        "School-Id": schoolId,
      },
    })

    if (res.isSuccessful) {
      toast.success("Note saved")
      // eslint-disable-next-line @typescript-eslint/no-shadow
      setNotes(notes => [...notes, res.data])
      reset({ notes: "" })
    } else {
      handleErrors(setError, res.errors)
    }
  })

  useEffect(() => {
    const listNotes = async () => {
      if (!jobApplicantId) return

      const res = await api.teacher.notes.list({
        urlParams: {
          jobApplicantId,
        },
        headers: {
          "School-Id": schoolId,
        },
      })

      if (res.isSuccessful) {
        setNotes(res.data)
      }
    }

    listNotes()
  }, [jobApplicantId])

  return (
    <>
      <div>
        <div className="flex flex-col gap-3">
          {notes.length > 0 ? (
            notes.map(({ notes: note, id, dateCreated, addedBy, isSelf }) => (
              <div className="flex flex-col gap-0.5" key={id}>
                <Typography color="onSurface.800" variant="strongSmallBody">
                  {addedBy.fullName} {isSelf ? "(You)" : ""}
                </Typography>
                <Typography color="onSurface.500" variant="smallBody">
                  {format(new Date(dateCreated), "do MMMM yyyy, h:mm a")}
                </Typography>
                <Typography
                  className={classes.note}
                  color="onSurface.800"
                  variant="smallBody"
                >
                  {note}
                </Typography>
              </div>
            ))
          ) : (
            <Typography color="onSurface.500" variant="strongSmallBody">
              You have not added any notes yet.
            </Typography>
          )}
        </div>
      </div>
      <div className="mt-3">
        <form className="flex flex-col gap-1" onSubmit={onSubmit}>
          {/* @ts-expect-error placebo-issue */}
          <TextField
            // placebo-issue cannot get charCount to reset after submit
            // charLimit={300}
            disabled={isSubmitting}
            error={Boolean(errors.notes)}
            helperText={errors.notes?.message}
            placeholder="Write your notes here"
            rows={3}
            fullWidth
            multiLine
            {...register("notes", {
              required: { value: true, message: "Required" },
            })}
          />

          <Button
            className="self-end"
            disabled={!manageHiringProcess}
            loading={isSubmitting}
            type="submit"
          >
            Add note
          </Button>
        </form>
      </div>
    </>
  )
}

export default Notes
