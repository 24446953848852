import React from "react"
import { createUseStyles } from "react-jss"

const DEFAULT_SIZE = 32

const useStyles = createUseStyles({
  "@keyframes ripple": {
    "0%": {
      opacity: 1,
      transform: "scale(0)",
    },
    "100%": {
      opacity: 0,
      transform: "scale(1.1)",
    },
  },

  root: {
    position: "relative",
    zIndex: 1,
    flex: 1,
    borderRadius: "50%",

    "&:before": {
      content: `""`,
      position: "absolute",
      width: "100%",
      height: "100%",
      backgroundColor: "inherit",
      borderRadius: "50%",
      zIndex: "2",
      animation: "$ripple 2000ms ease-out infinite",
    },
  },
})

type Props = {
  children?: React.ReactElement | null
  /**
   * Color of the ripple
   */
  color?: string
}
const RippleAnimation = ({ children, color = "white" }: Props) => {
  const classes = useStyles()
  const childRef = React.useRef<HTMLElement>(null)
  if (!children) return null
  return (
    <span
      className={classes.root}
      style={{
        background: color,
        height:
          childRef.current?.getBoundingClientRect().height || DEFAULT_SIZE,
        width: childRef.current?.getBoundingClientRect().width || DEFAULT_SIZE,
      }}
    >
      {React.cloneElement(children, {
        ref: childRef,
      })}
    </span>
  )
}

export default RippleAnimation
