import { UserType } from "api/resources/recruiter/types"
import { GlobalContext } from "components/GlobalState"
import { useContext } from "react"

const useUserType = () => {
  const { userType } = useContext(GlobalContext)
  const isLeadRecruiter = userType?.includes(UserType.LEAD_RECRUITER)
  const isMemberRecruiter = userType?.includes(UserType.MEMBER_RECRUITER)
  const isRecruiter = isLeadRecruiter || isMemberRecruiter

  const isLeadPartner = userType?.includes(UserType.LEAD_PARTNER)
  const isMemberPartner = userType?.includes(UserType.MEMBER_PARTNER)
  const isPartner = isLeadPartner || isMemberPartner

  const isSchoolAdmin = userType?.includes(UserType.SCHOOL_ADMIN)
  const isLeadAdmin = userType?.includes(UserType.LEAD_ADMIN)

  return {
    userType,

    isLeadRecruiter,
    isMemberRecruiter,
    isRecruiter,

    isLeadPartner,
    isMemberPartner,
    isPartner,

    isSchoolAdmin,
    isLeadAdmin,
  }
}

export default useUserType
