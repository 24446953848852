import axios from "api/axios"
import {
  APIResponse,
  APIResponseOld,
  Config,
  ConfigWithURLParams,
  NoContentType,
  PaginatedAPIResponse,
  PaginatedAPIResponseOld,
} from "api/types"
import {
  formatErrorResponse,
  formatPaginatedResponse,
  formatSuccessResponse,
  formatSuccessResponseOld,
} from "api/utils"

import {
  AccessibleSchoolType,
  AddRecruiterType,
  CheckRecruiter,
  CreateSchool,
  LearnerType,
  RecruiterType,
  UserTypesActions,
} from "./types"
import { urls } from "./urls"

export default {
  retrieveUserType: async ({
    params,
  }: Config): Promise<APIResponse<UserTypesActions>> => {
    try {
      const res = await axios.get(urls.actions(), {
        params,
      })
      return formatSuccessResponse(res)
    } catch (e) {
      throw formatErrorResponse(e)
    }
  },
  getTableActions: async ({
    params,
  }: Config): Promise<APIResponse<UserTypesActions>> => {
    try {
      const res = await axios.get(urls.actions(), {
        params,
      })
      return formatSuccessResponse(res)
    } catch (e) {
      throw formatErrorResponse(e)
    }
  },
  recruiterList: async ({
    params,
    headers,
  }: Config): Promise<PaginatedAPIResponseOld<RecruiterType[]>> => {
    try {
      const res = await axios.get(urls.recruiterList(), {
        params,
        headers,
      })
      return formatPaginatedResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  learnerList: async ({
    params,
    headers,
  }: Config): Promise<PaginatedAPIResponse<LearnerType[]>> => {
    try {
      const res = await axios.get(urls.learnerList(), {
        params,
        headers,
      })
      return formatSuccessResponse(res, { paginatedResponse: true })
    } catch (e) {
      throw formatErrorResponse(e)
    }
  },
  markAsHired: async ({ data, headers }: Config): Promise<NoContentType> => {
    try {
      const res = await axios.post(urls.markAsHired(), data, {
        headers,
      })
      return formatSuccessResponse(res)
    } catch (e) {
      throw formatErrorResponse(e)
    }
  },
  accessibleSchoolList: async ({
    params,
  }: Config): Promise<PaginatedAPIResponseOld<AccessibleSchoolType[]>> => {
    try {
      const res = await axios.get(urls.accessibleSchoolList(), {
        params,
      })
      return formatPaginatedResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  assignRecruiter: async ({
    data,
    headers,
  }: Config): Promise<APIResponseOld<NoContentType>> => {
    try {
      const res = await axios.post(urls.assignRecruiter(), data, { headers })
      return formatSuccessResponseOld(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  reAssignRecruiter: async ({
    data,
    headers,
  }: Config): Promise<APIResponseOld<NoContentType>> => {
    try {
      const res = await axios.put(urls.assignRecruiter(), data, { headers })
      return formatSuccessResponseOld(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  createNewSchool: async ({
    data,
    headers,
  }: Config): Promise<APIResponseOld<CreateSchool>> => {
    try {
      const res = await axios.post(urls.createSchool(), data, { headers })
      return formatSuccessResponseOld(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  addRecruiter: async ({
    data,
  }: Config): Promise<APIResponseOld<AddRecruiterType>> => {
    try {
      const res = await axios.post(urls.addRecruiter(), data)
      return formatSuccessResponseOld(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  checkRecruiter: async ({
    data,
  }: Config): Promise<APIResponseOld<CheckRecruiter>> => {
    try {
      const res = await axios.post(urls.checkRecruiter(), data)
      return formatSuccessResponseOld(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
}
