import { Typography } from "@suraasa/placebo-ui"
import { UserType } from "api/resources/recruiter/types"
import { Representative } from "api/resources/representatives/types"
import Tabs from "components/jobs/Tabs"
import BackButton from "components/shared/BackButton"
import { useEffect, useState } from "react"
import useTabs from "utils/hooks/useTabs"
import SchoolAssignedTab from "views/common/TeamTab/TeamDetailSheet/SchoolAssignedTab"
import { joinRoles } from "views/common/utils"

import { Sheet, SheetBody, SheetContent, SheetHeader } from "@/components/Sheet"

import TeamTab from "../../../Home/Sheet/TeamTab"

type Props = {
  onClose: () => void
  open: boolean
  row: Representative | null
}

const Team = {
  name: "Team",
  content: TeamTab,
}
const SchoolAssigned = {
  name: "School Assigned",
  content: SchoolAssignedTab,
}

const TeamDetailSheet = ({ open, onClose, row }: Props) => {
  const [tabs, setTabs] = useState<any[]>([])

  const hasLeadRole =
    row &&
    row.roles.some(r =>
      [
        UserType.LEAD_ADMIN,
        UserType.LEAD_PARTNER,
        UserType.LEAD_RECRUITER,
      ].includes(r as UserType)
    )

  const [activeTab, setActiveTab] = useTabs({
    tabs,
    initialTab: tabs[0] ? tabs[0].name : "",
    useSearchParams: false,
  })

  useEffect(() => {
    if (hasLeadRole) {
      setTabs([Team, SchoolAssigned])
    } else {
      setTabs([SchoolAssigned])
    }
  }, [hasLeadRole])

  if (!row) {
    return (
      <Sheet open={open} onOpenChange={() => onClose()}>
        <SheetContent
          side="right"
          height={90}
          className="min-w-[1000px] sm:!max-w-5xl"
        >
          <SheetHeader hideClose>
            <BackButton onBack={() => onClose()} />
          </SheetHeader>
          <SheetBody>
            <Typography>No data available</Typography>
          </SheetBody>
        </SheetContent>
      </Sheet>
    )
  }

  const closeSheet = () => {
    setActiveTab("")
    setTabs([])
    onClose()
  }

  return (
    <>
      <Sheet open={open} onOpenChange={closeSheet}>
        <SheetContent
          side="right"
          height={90}
          className="min-w-[1000px] sm:!max-w-5xl"
        >
          <SheetHeader hideClose>
            <BackButton onBack={closeSheet} />
          </SheetHeader>
          <SheetBody>
            <div className="flex justify-between">
              <div className="flex flex-col gap-1">
                <Typography variant="title3" color="onSurface.800">
                  {row.fullName}
                </Typography>
                <Typography variant="smallBody" color="onSurface.500">
                  {joinRoles(row.roles)}
                </Typography>
              </div>
              <div className="flex flex-col gap-1">
                {row.leadRecruiter && (
                  <div className="flex">
                    <Typography variant="smallBody" color="onSurface.400">
                      Recruiter Team Lead - &nbsp;
                    </Typography>
                    <Typography variant="strongSmallBody" color="onSurface.800">
                      {row.leadRecruiter?.fullName}
                    </Typography>
                  </div>
                )}
                {row.leadPartner && (
                  <div className="flex">
                    <Typography variant="smallBody" color="onSurface.400">
                      Partner Team Lead - &nbsp;
                    </Typography>
                    <Typography variant="strongSmallBody" color="onSurface.800">
                      {row.leadPartner?.fullName}
                    </Typography>
                  </div>
                )}
              </div>
            </div>
            <div className="mt-2">
              <Tabs
                color="black"
                activeTab={activeTab}
                tabs={tabs}
                onChange={setActiveTab}
              />
            </div>

            {activeTab && (
              <div className="mt-2">
                <activeTab.content row={row} search="" isActive />
              </div>
            )}
          </SheetBody>
        </SheetContent>
      </Sheet>
    </>
  )
}

export default TeamDetailSheet
