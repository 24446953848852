import { Typography } from "@suraasa/placebo-ui"
import clsx from "clsx"
import React from "react"

type Props = { className?: string }

const NoAccess = ({ className }: Props) => {
  return (
    <Typography
      variant="title3"
      className={clsx("mt-5", className)}
      textAlign="center"
    >
      You don't have access to view this page
    </Typography>
  )
}

export default NoAccess
