import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogTitle,
  TextField,
  theme,
  Typography,
  useMediaQuery,
} from "@suraasa/placebo-ui"
import api from "api"
import { User } from "api/resources/settings/types"
import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { mapErrors } from "utils/helpers"
import { Captcha, useGRecaptcha } from "utils/hooks/useGRecaptcha"
import useToggle from "utils/hooks/useToggle"
import toast from "utils/toast"

import OtpGenDialog from "./OtpGenDialog"

enum Steps {
  email,
  password,
  otp,
}

function ChangeEmailIdDialog({
  open,
  close,
  currentEmailId,
  onUpdate,
}: {
  open: boolean
  close: () => void
  onUpdate: (emailId: User["email"]) => void
  currentEmailId: string | null
}) {
  const [loading, setLoading] = useState(false)
  const [newEmailID, setNewEmailID] = useState<string | null>(null)
  const [otpToken, setOtpToken] = useState<any | null>(null)

  const [step, setStep] = useState<Steps>(Steps.email)

  const [openOTP, toggleOpenOTP] = useToggle(false)

  const smDown = useMediaQuery(theme.breakpoints.down("sm"))

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm<{
    emailId: string
    password: string
  }>()
  const { submitWithCaptcha } = useGRecaptcha({
    action: "auth",
    checkboxContainer: "#email-checkbox-captcha",
  })

  const resetAndClose = () => {
    setStep(Steps.email)
    close()
  }

  const getCaptcha = (): Promise<Captcha> =>
    new Promise(rs =>
      submitWithCaptcha(async result => {
        rs(result)
      })()
    )

  const generateOtp = async (email: string, password: string) => {
    let captcha: Captcha | null = null
    await getCaptcha().then(result => (captcha = result))

    const otpFunc = async () => {
      if (!captcha) {
        toast.error("Please verify the captcha")
        return false
      }
      const res = await api.settings.generateOTP({
        data: { email, password, captcha },
      })
      if (res.isSuccessful) {
        setOtpToken(res.data.token)
        return true
      }
      mapErrors(setError, res.errors, [["email", "emailId"], ["password"]])

      return false
    }

    return otpFunc()
  }

  const onNewEmailIDSubmit = handleSubmit(data => {
    clearErrors()
    setNewEmailID(data.emailId)
    setStep(Steps.password)
  })

  const onCheckPasswordSubmit = handleSubmit(async data => {
    clearErrors()
    let hasError = false
    if (data.emailId.length > 0) {
      if (data.password.length === 0) {
        hasError = true
        setError("password", { message: "Required" })
      }
    }
    if (hasError) return null

    setLoading(true)
    await generateOtp(data.emailId, data.password)
      .then(isOtpSuccess => {
        if (isOtpSuccess) {
          toggleOpenOTP(true)
          close()
          setStep(Steps.otp)
        }
      })
      .finally(() => {
        setLoading(false)
      })
  })

  const afterOTPSubmit = () => {
    if (newEmailID) onUpdate(newEmailID)
    setStep(Steps.email)
  }

  const PasswordForm = () => (
    <div>
      <form onSubmit={e => e.preventDefault()}>
        <div className="mb-3.5 mt-2.75">
          <div>
            <div className="flex items-center gap-0.5">
              <Typography variant="strongSmallBody">
                Changing Email ID to
              </Typography>
              <Typography variant="strongSmallBody">{newEmailID}</Typography>
            </div>
            {Boolean(errors.emailId) && (
              <Typography color="critical.500" variant="smallBody">
                {errors.emailId?.message}
              </Typography>
            )}
          </div>
          <Button
            className="mt-0.5"
            style={{ padding: "0px" }}
            variant="link"
            onClick={() => {
              setNewEmailID(null)
              setValue("password", "")
              setStep(Steps.email)
            }}
          >
            Change
          </Button>
        </div>

        <div className="mb-4.5 gap-1 md:flex-row md:items-start md:gap-1.75">
          <TextField
            error={Boolean(errors.password)}
            helperText={errors.password?.message}
            label="Password"
            type="password"
            fullWidth
            {...register("password")}
          />
          <Typography
            className="mt-0.5"
            color="onSurface.500"
            variant="smallBody"
          >
            Please enter your password for security reasons
          </Typography>
          <div id="email-checkbox-captcha" className="my-2" />
        </div>
      </form>
    </div>
  )

  const EmailForm = () => (
    <div>
      <form onSubmit={e => e.preventDefault()}>
        <div className="mb-3.5 mt-2.75 flex items-center gap-0.5">
          <Typography variant="strongSmallBody">Current Email ID</Typography>
          <Typography color="onSurface.500" variant="smallBody">
            {currentEmailId}
          </Typography>
        </div>

        <div className="mb-4.5 flex flex-col  gap-1 md:flex-row md:items-start md:gap-1.75">
          <TextField
            error={Boolean(errors.emailId)}
            helperText={errors.emailId?.message}
            label="Email ID"
            placeholder="Enter new Email ID here"
            type="email"
            fullWidth
            {...register("emailId", {
              required: { value: true, message: "Required" },
              validate: value =>
                value !== currentEmailId || "Plese enter new Email ID",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "Invalid email address",
              },
            })}
          />
        </div>
      </form>
    </div>
  )

  return (
    <>
      <Dialog
        fullScreen={smDown}
        open={open}
        width={518}
        onAfterClose={reset}
        onRequestClose={resetAndClose}
      >
        <DialogTitle>Change Your Email ID</DialogTitle>
        <DialogContent>
          {step === Steps.email && EmailForm()}
          {step === Steps.password && PasswordForm()}
        </DialogContent>

        <DialogFooter
          actions={{
            primary: {
              label: "Continue",
              onClick:
                step === Steps.email
                  ? onNewEmailIDSubmit
                  : onCheckPasswordSubmit,
              loading,
            },
            secondary: {
              label: "Cancel",
              onClick: resetAndClose,
            },
          }}
        />
      </Dialog>
      <OtpGenDialog
        afterSubmit={afterOTPSubmit}
        details={{ email: newEmailID }}
        open={openOTP && step === Steps.otp}
        otpToken={otpToken}
        toggle={() => {
          toggleOpenOTP()
          resetAndClose()
        }}
      />
    </>
  )
}

export default ChangeEmailIdDialog
