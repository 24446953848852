import { Typography } from "@suraasa/placebo-ui"
import clsx from "clsx"
import { NavArrowLeft, NavArrowRight } from "iconoir-react"
import React, { useCallback, useEffect, useRef, useState } from "react"

import { pdfjsLib } from "./pdfjsLib"

interface PDFViewerProps {
  url: string
  disabled?: React.ReactNode
}

const PDFViewer: React.FC<PDFViewerProps> = ({ url, disabled }) => {
  const [pageNum, setPageNum] = useState<number>(1)
  const [numPages, setNumPages] = useState<number>(0)
  const [pdfDoc, setPdfDoc] = useState<any>(null)
  const [loading, setLoading] = useState(false)

  const canvasRef = useRef<HTMLCanvasElement>(null)

  const renderPage = useCallback(
    (num: number, pdfDoc: pdfjsLib.PDFDocumentProxy) => {
      if (!pdfDoc || !canvasRef.current) return

      const canvas = canvasRef.current
      const ctx = canvas.getContext("2d")

      if (!ctx) return

      pdfDoc.getPage(num).then((page: any) => {
        const viewport = page.getViewport({ scale: 1 })

        canvas.height = viewport.height //actual size
        canvas.width = viewport.width

        // PDFs were rendering blurry on larger screens. This is the fix.
        if (window.innerWidth > 600) {
          canvas.style.height = viewport.height + "px"
          canvas.style.width = viewport.width + "px"
        }

        const renderContext = {
          canvasContext: ctx,
          viewport,
        }
        const renderTask = page.render(renderContext)

        renderTask.promise.then(() => {
          setPageNum(num)
        })
      })
    },
    []
  )

  useEffect(() => {
    async function loadPdf() {
      setLoading(true)
      try {
        const pdfDoc = await pdfjsLib.getDocument(url).promise
        setPdfDoc(pdfDoc)
        setNumPages(pdfDoc.numPages)

        renderPage(1, pdfDoc)
      } catch (error) {
        console.error("Error loading PDF:", error)
      }
      setLoading(false)
    }
    loadPdf()
  }, [url])

  const onPrevPage = () => {
    if (pageNum > 1) {
      renderPage(pageNum - 1, pdfDoc)
    }
  }

  const onNextPage = () => {
    if (pageNum < numPages) {
      renderPage(pageNum + 1, pdfDoc)
    }
  }

  return (
    <div className="relative bg-slate-50">
      <a
        href={url.concat(`?#page=${pageNum}`)}
        target="_blank"
        rel="noreferrer"
        className={clsx("flex justify-center", {
          "blur-sm": disabled,
        })}
      >
        <canvas className="w-full rounded" ref={canvasRef}></canvas>
      </a>
      {disabled && (
        <div className="absolute left-0 top-0 flex size-full items-center justify-center rounded-lg bg-black/50 text-white">
          {disabled}
        </div>
      )}
      {loading && (
        <div className="absolute left-0 top-0 flex size-full items-center justify-center rounded-lg bg-black/50 text-white">
          Loading.....
        </div>
      )}
      {!disabled && (
        <div className="absolute bottom-1 right-1 flex items-center space-x-0.5 rounded-lg bg-onSurface-800 p-1.5">
          <button
            onClick={onPrevPage}
            className={clsx("p-0.5", {
              "text-onSurface-500": pageNum === 1,
              "text-white": pageNum !== 1,
            })}
          >
            <NavArrowLeft className="size-2.5" />
          </button>
          <Typography variant="strongSmallBody" className="!text-white">
            {pageNum}/{numPages}
          </Typography>
          <button
            onClick={onNextPage}
            className={clsx("p-0.5", {
              "text-onSurface-500": pageNum === numPages,
              "text-white": pageNum !== numPages,
            })}
          >
            <NavArrowRight className="size-2.5" />
          </button>
        </div>
      )}
    </div>
  )
}

export default PDFViewer
