import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogProps,
  DialogTitle,
  Typography,
} from "@suraasa/placebo-ui"
import api from "api"
import { Assessment } from "api/resources/assessments/types"
import {
  AssessmentDetailsStep,
  JobApplicantStepStatus,
  StepType,
} from "api/resources/jobs/step.types"
import JobItem from "components/JobItem"
import { Plus } from "iconoir-react"
import React, { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { handleErrors } from "utils/helpers"
import useToggle, { ToggleValue } from "utils/hooks/useToggle"
import { routes } from "utils/routes"

import EditAssessmentDueDate from "./EditAssessmentDueDate"

type Props = {
  toggle: ToggleValue
  jobApplicant: string | number
  afterSubmit?: (data: AssessmentDetailsStep) => void
  schoolId: string
} & Pick<DialogProps, "open">

const AddAssessmentDialog = ({
  open,
  toggle,
  jobApplicant,

  afterSubmit,
  schoolId,
}: Props) => {
  const navigate = useNavigate()

  const [selectedAssessment, setSelectedAssessment] =
    useState<Assessment["id"]>()
  const [assessmentDueDateDialog, toggleAssessmentDueDateDialog] =
    useToggle(false)

  const [assessments, setAssessments] = useState<Assessment[] | null>()
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      setError("")
      const res = await api.assessments.list({
        params: {
          is_active: true,
          page: "all",
        },
        headers: {
          "School-Id": schoolId,
        },
      })
      if (res.isSuccessful) {
        setAssessments(res.data.data)
        setLoading(false)
        setError("")
      } else {
        setAssessments(null)
        setLoading(false)
        setError(res.errors.message ?? "Something went wrong")
      }
    }
    fetchData()
  }, [])

  const onSubmit = async (dueDate: string | null) => {
    const res = await api.jobs.jobApplicantStep.create({
      urlParams: {
        jobApplicantId: jobApplicant,
      },
      data: {
        assessment: selectedAssessment,
        dueDate,
      },
      headers: {
        "School-Id": schoolId,
      },
    })

    if (res.isSuccessful) {
      if (afterSubmit) {
        afterSubmit({
          ...res.data,
          dateCreated: new Date().toISOString(),
          stepId: res.data.id,
          stepType: StepType.ASSESSMENT,
          status: JobApplicantStepStatus.NOT_STARTED,
        })
      }
      toggleAssessmentDueDateDialog()
      toggle()
    } else {
      handleErrors(setError, res.errors)
    }
  }

  return (
    <>
      {assessmentDueDateDialog && selectedAssessment && (
        <EditAssessmentDueDate
          afterSubmit={onSubmit}
          data={{ id: selectedAssessment, schoolId }}
          isEditing={false}
          open={assessmentDueDateDialog}
          toggle={toggleAssessmentDueDateDialog}
        />
      )}

      <Dialog open={open} width="sm" onRequestClose={() => toggle()}>
        <DialogTitle>Add Assessment</DialogTitle>
        <DialogContent className="flex flex-col gap-1">
          <Button
            component={Link}
            startAdornment={<Plus />}
            to={routes.assessment.create}
            variant="text"
          >
            Create New Assessment
          </Button>
          {(loading || error) && (
            <div className="flex items-center justify-center p-2">
              {loading && <CircularProgress />}
              {error && <Typography>{error}</Typography>}
            </div>
          )}

          {assessments &&
            assessments.map(assessment => (
              <JobItem
                data={{
                  id: assessment.id,
                  title: assessment.title,
                  subtitle: `${Math.floor(assessment.duration / 60)} minutes`,
                }}
                key={assessment.id}
                selected={assessment.id === selectedAssessment}
                onClick={() => setSelectedAssessment(assessment.id)}
                onEdit={() =>
                  navigate(
                    `${routes.assessment.edit.replace(
                      ":assessmentId",
                      String(assessment.id)
                    )}/`
                  )
                }
              />
            ))}
        </DialogContent>
        <DialogFooter
          actions={{
            primary: {
              disabled: !selectedAssessment,
              onClick: () => toggleAssessmentDueDateDialog(),
            },
          }}
        />
      </Dialog>
    </>
  )
}

export default AddAssessmentDialog
