import { Typography } from "@suraasa/placebo-ui"
import clsx from "clsx"
import TabItem from "components/Tab/TabItem"
import React from "react"
import { createUseStyles } from "react-jss"
import { Tab, Tabs as TabsType } from "utils/hooks/useTabs"

const useStyles = createUseStyles({
  tabContainer: {
    display: "flex",
    overflowX: "scroll",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
})

type Props = {
  tabs: TabsType
  activeTab: Tab
  onChange: (newTab: string) => void
  className?: string
  color?: "primary" | "black"
}

const Tabs = ({
  tabs,
  activeTab,
  onChange,
  color = "primary",
  className,
}: Props) => {
  const classes = useStyles()
  const test = true

  const getTabTitle = (tab: Tab) => {
    return (
      <Typography
        variant={tab.name === activeTab.name ? "strong" : "body"}
        display="initial"
        className="flex items-center gap-0.5"
      >
        {tab.name} {Boolean(tab.count) && <span>({tab.count})</span>}
      </Typography>
    )
  }

  if (test) {
    return (
      <div className={clsx(classes.tabContainer, className)}>
        {tabs.map(tab => (
          <TabItem
            color={color}
            isActive={tab.name === activeTab.name}
            key={tab.name}
            onClick={() => onChange(tab.name)}
          >
            {tab.startAdornment ? (
              <div className="flex items-center gap-1">
                <>
                  {tab.startAdornment}
                  {getTabTitle(tab)}
                </>
              </div>
            ) : (
              getTabTitle(tab)
            )}
          </TabItem>
        ))}
      </div>
    )
  }

  return (
    <div className={classes.tabContainer}>
      {tabs.map(tab => (
        <TabItem
          isActive={tab.name === activeTab.name}
          key={tab.name}
          onClick={() => onChange(tab.name)}
        >
          {tab.startAdornment ? (
            <div className="flex items-center gap-1">
              <>
                {tab.startAdornment} {tab.name}
              </>
            </div>
          ) : (
            <>{tab.name}</>
          )}
        </TabItem>
      ))}
    </div>
  )
}

export default Tabs
