import { CircularProgress, Typography } from "@suraasa/placebo-ui"
import api from "api"
import { Submission as SubmissionType } from "api/resources/assignments/types"
import { GlobalContext } from "components/GlobalState"
import { InfoCircle } from "iconoir-react"
import React, { useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { getAuthInfo } from "utils/auth"
import { useCopyDisable } from "utils/hooks/useCopyDisable"
import { routes } from "utils/routes"
import useUserType from "views/auth/useUserType"

import Submission from "./Submission"
import SubmissionPreview from "./Submission/SubmissionPreview"

const EvidenceDetails = ({ firstName }: { firstName: string }) => {
  const { submissionId, username } = useParams() as {
    submissionId: string
    username: string
  }
  const {
    profile: { isVerified },
  } = useContext(GlobalContext)
  const { isSchoolAdmin } = useUserType()
  const navigate = useNavigate()

  useCopyDisable()

  const [submissionData, setSubmissionData] = useState<SubmissionType>()

  const isCompletelyVerified = !isSchoolAdmin ? true : isVerified

  const isAllowedToSeeSubmission =
    Boolean(getAuthInfo()) && isCompletelyVerified

  useEffect(() => {
    const init = async () => {
      const res = await api.assignments.retrieveSubmission({
        urlParams: { submissionId },
        headers: {
          Authorization: null,
        },
      })
      if (res.isSuccessful) {
        setSubmissionData(res.data)
      }
    }

    if (submissionId) init()
  }, [submissionId])

  if (!submissionData) {
    return (
      <div className="flex h-full justify-center p-5">
        <CircularProgress />
      </div>
    )
  }

  return (
    <div className="mt-2.5 px-3 pb-4">
      <Typography className="mb-2" color="onSurface.800">
        Following content represents {firstName}’s original work on{" "}
        <strong>{submissionData?.assignment.title}</strong> which has undergone
        multiple levels of quality assurance and plagiarism checks by Suraasa.
      </Typography>
      <button
        className="mb-3 flex w-full items-center rounded-xl border border-highlight-100 bg-highlight-50 px-2 py-1.5"
        onClick={() =>
          navigate(
            routes.publicSkillProfile.verificationProcess.replace(
              ":username",
              username
            )
          )
        }
      >
        <InfoCircle
          className="me-2 hidden shrink-0 text-highlight-500 sm:block"
          height={20}
          width={20}
        />
        <Typography
          color="highlight.900"
          textAlign="left"
          variant="strongSmallBody"
        >
          Learn how Suraasa verifies every single skill evidence (assignment)
        </Typography>
      </button>

      <Typography className="mb-2" color="onSurface.800" variant="title3">
        Submission
      </Typography>

      <div className="relative select-none">
        {isAllowedToSeeSubmission ? (
          <Submission
            attachments={submissionData.attachments}
            content={submissionData.submission}
            references={submissionData.references}
            citations={submissionData.citations}
          />
        ) : (
          <SubmissionPreview
            content={`
            <p style="font-size: 1em; font-weight: bold; margin-bottom: 24px;">Assignment Submission</p>
            <p style="font-size: 1.5em; font-weight: bold;">Submitted By: ${firstName}</p>
            <p style="font-size: 2em; font-weight: bold;">${submissionData?.assignment.title}</p>
            <p style="font-size: 1em; margin-top: 32px;">${submissionData?.assignment.title} refers to...</p>
            `}
            isCompletelyVerified={isCompletelyVerified}
          />
        )}
      </div>
    </div>
  )
}

export default EvidenceDetails
