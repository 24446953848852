import { TextField } from "@suraasa/placebo-ui"
import clsx from "clsx"
import { Search } from "iconoir-react"
import debounce from "lodash/debounce"
import React, { useCallback, useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"

import styles from "./SearchField.module.css"

type Props = {
  value: string | undefined
  placeholder: string
  onSearch: (word: string) => void
  fullWidth?: boolean
  className?: string
  error?: boolean
}

const SearchField = ({
  value,
  placeholder,
  onSearch,
  fullWidth,
  className,
  error,
}: Props) => {
  const [searchText, setSearchText] = useState(value || "")
  const [searchParams] = useSearchParams()
  const tab = searchParams.get("tab")

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce(criteria => {
      onSearch(criteria)
    }, 750),
    [tab, onSearch]
  )

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(decodeURI(e.target.value))
    debouncedSearch(encodeURI(e.target.value))
  }

  useEffect(() => {
    setSearchText(decodeURI(value || ""))
  }, [value])

  return (
    <TextField
      className={clsx("!text-onSurface-500", styles.searchInput, className)}
      name="search"
      placeholder={placeholder}
      error={error}
      startIcon={<Search />}
      fullWidth={fullWidth}
      value={searchText}
      onChange={handleSearch}
    />
  )
}

export default SearchField
