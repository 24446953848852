import {
  AssessmentDetailsStep,
  InterviewDetailsStep,
  LiveDemoDetailsStep,
  Step,
  StepType,
} from "api/resources/jobs/step.types"
import { MarkAsCompleteDialogData } from "components/teacher/Dialogs/MarkAsCompleteDialog"
import { createContext } from "react"
import { UseArray } from "utils/hooks/useArray"

type Item<T> = {
  data: UseArray<T>["array"]
  add: UseArray<T>["unshift"]
  remove: UseArray<T>["removeByKey"]
  update: UseArray<T>["updateByKey"]
  push?: UseArray<T>["push"]
}

type TeacherTimelineContextType = {
  timeline: Item<Step>
  interviews: Item<InterviewDetailsStep>
  liveDemos: Item<LiveDemoDetailsStep>
  assessments: Item<AssessmentDetailsStep>
  profile: any
  dialogs: {
    openFeedback: {
      toggle: (id: number | null, type: StepType) => void
    }
    markAsComplete: {
      toggle: (data: MarkAsCompleteDialogData | null) => void
    }
  }
  jobId?: number
  isRejected: boolean
  actionsAllowed: boolean
}

const defaultItem = {
  data: [],
  add: () => {},
  remove: () => {},
  update: () => {},
}

const TeacherTimelineContext = createContext<TeacherTimelineContextType>({
  timeline: defaultItem,
  assessments: defaultItem,
  interviews: defaultItem,
  liveDemos: defaultItem,
  profile: {},
  dialogs: {
    openFeedback: {
      toggle: () => {},
    },
    markAsComplete: {
      toggle: () => {},
    },
  },
  isRejected: false,
  actionsAllowed: true,
})

export default TeacherTimelineContext
