import { Step } from "api/resources/jobs/step.types"
import clsx from "clsx"
import React, { PropsWithChildren } from "react"
import { createUseStyles } from "react-jss"
import { PropsWithClassName } from "types"

const useStyles = createUseStyles({
  root: {
    "& .TimelineContent-root:last-child .TimelineContent-connector": {
      display: "none",
    },
    "& .TimelineContent-root:last-child": {
      marginBottom: 0,
    },
  },
})

interface Props {
  steps: Step[] | null
}

const Timeline = ({
  steps,
  children,
  className,
}: PropsWithClassName<PropsWithChildren<Props>>) => {
  const classes = useStyles()

  return steps === null ? null : (
    <div className={clsx(classes.root, className)}>{children}</div>
  )
}

export default Timeline
