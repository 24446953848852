import { UserType } from "api/resources/recruiter/types"
import { getRoleName } from "views/Home/utils"

export const joinRoles = (roles: string[]) => {
  return roles.map(r => getRoleName(r as UserType)).join(" - ")
}

export const checkHasRecruiterRole = (roles: string[]) => {
  return roles.some(r =>
    [UserType.LEAD_RECRUITER, UserType.MEMBER_RECRUITER].includes(r as UserType)
  )
}

export const checkHasPartnerRole = (roles: string[]) => {
  return roles.some(r =>
    [UserType.LEAD_PARTNER, UserType.MEMBER_PARTNER].includes(r as UserType)
  )
}

export const checkIsLead = (roles: string[]) => {
  return roles.some(
    r => r === UserType.LEAD_RECRUITER || r === UserType.LEAD_PARTNER
  )
}
