import { NotificationList } from "@suraasa/notifications"
import api from "api"
import { RepresentativeType } from "api/resources/partner/types"
import { Profile } from "api/resources/profile/types"
import {
  RecruiterType,
  UserType,
  UserTypesActions,
} from "api/resources/recruiter/types"
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react"
import { defaultProfile, NotificationAction } from "utils/constants"
import { DepartmentType } from "views/common/SchoolTab/useSchoolList"

export type Context = {
  notifications: NotificationList<NotificationAction>
  setNotifications: React.Dispatch<
    React.SetStateAction<NotificationList<NotificationAction>>
  >
  profile: Profile
  setProfile: (profile: Partial<Profile>) => void
  userType: UserType[] | null
  userActions: UserTypesActions["actions"]
  schoolActions: UserTypesActions["schoolActions"]
  setUserData: React.Dispatch<
    React.SetStateAction<UserTypesActions | undefined>
  >
  recruiterList: RecruiterType[]
  setRecruiterList: React.Dispatch<React.SetStateAction<RecruiterType[]>>
  refetchRecruiters: () => void
  partnerList: RepresentativeType[]
  setPartnerList: React.Dispatch<React.SetStateAction<RepresentativeType[]>>
  refetchPartners: () => void
}

export const GlobalContext = createContext<Context>({
  notifications: { data: null, unreadCount: 0, totalCount: 0 },
  setNotifications: () => {},
  setProfile: () => {},
  profile: defaultProfile,
  userType: null,
  userActions: undefined,
  schoolActions: undefined,
  setUserData: () => {},
  recruiterList: [],
  setRecruiterList: () => {},
  refetchRecruiters: () => {},
  partnerList: [],
  setPartnerList: () => {},
  refetchPartners: () => {},
})
export const useGlobalContext = () => useContext(GlobalContext)

const GlobalState = ({ children }: { children: React.ReactNode }) => {
  const [profile, setProfile] = useState<Context["profile"]>(defaultProfile)
  const [notifications, setNotifications] = useState<
    NotificationList<NotificationAction>
  >({ data: null, unreadCount: 0, totalCount: 0 })
  const [userData, setUserData] = useState<UserTypesActions>()

  const userType = userData?.userType || null
  const isSchoolAdmin = userType?.includes(UserType.SCHOOL_ADMIN)

  const userActions = userData?.actions
  const schoolActions = userData?.schoolActions

  const [recruiterList, setRecruiterList] = useState<RecruiterType[]>([])
  const [partnerList, setPartnerList] = useState<RepresentativeType[]>([])

  const fetchRecruiters = useCallback(async () => {
    const res = await api.recruiter.recruiterList({
      params: {
        all_representatives: true,
        related_department: DepartmentType.RECRUITER,
      },
    })

    if (res.isSuccessful) {
      setRecruiterList(res.data.data)
    }
  }, [])

  const fetchPartners = useCallback(async () => {
    const res = await api.partner.representativeList({
      params: {
        all_representatives: true,
        related_department: DepartmentType.PARTNER,
      },
    })

    if (res.isSuccessful) {
      setPartnerList(res.data.data)
    }
  }, [])

  useEffect(() => {
    if (userType && !isSchoolAdmin) {
      fetchRecruiters()
      fetchPartners()
    }
  }, [fetchRecruiters, userType, fetchPartners, isSchoolAdmin])

  return (
    <GlobalContext.Provider
      value={{
        notifications,
        setNotifications,
        userType,
        userActions,
        schoolActions,
        setUserData,
        profile,
        recruiterList,
        setRecruiterList,
        partnerList,
        setPartnerList,
        refetchPartners: () => {
          fetchPartners()
        },
        refetchRecruiters: () => {
          fetchRecruiters()
        },
        setProfile: (newState: Partial<Profile>) => {
          setProfile(prevState => ({ ...prevState, ...newState }))
        },
      }}
    >
      {children}
    </GlobalContext.Provider>
  )
}

export default GlobalState
