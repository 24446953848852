import { useCallback } from "react"
export interface CustomWindow extends Window {
  grecaptcha?: {
    enterprise: {
      ready: (callback: () => void) => void
      reset: (widgetId?: number) => void
      execute: (widgetId?: number) => Promise<number>
      render: (
        elementId: string | HTMLElement,
        options: {
          sitekey: string
          callback: (token: string) => void
          "error-callback": () => void
          "expired-callback": () => void
          action: string
        }
      ) => number
    }
  }
}

declare let window: CustomWindow

export type Captcha = {
  value: string
  type: CaptchaType
}

export enum CaptchaType {
  checkbox = "checkbox",
  invisible = "invisible",
}

export type UseGRecaptchaProps = {
  action: string
  // A valid selector for the checkbox element to portal inside
  checkboxContainer: string
}

const generateRandomId = () => {
  return Math.random().toString(36).substring(2, 15)
}

export const useGRecaptcha = ({
  action,
  checkboxContainer,
}: UseGRecaptchaProps) => {
  const submitWithCaptcha =
    (onValid: (captcha: Captcha) => void) => async (e?: any) => {
      e?.preventDefault()
      runInvisibleCaptcha(onValid)
    }

  const runCheckboxCaptcha = (onValid: any) => {
    if (!window.grecaptcha) {
      throw new Error("grecaptcha is not loaded")
    }

    const id = `checkbox-captcha-${generateRandomId()}`

    const container = document.querySelector(checkboxContainer) as HTMLElement
    const div = document.createElement("div")
    div.setAttribute("id", id)
    container.appendChild(div)

    window.grecaptcha.enterprise.ready(() => {
      try {
        window.grecaptcha?.enterprise.render(id, {
          sitekey: import.meta.env.VITE_CHECKBOX_RECAPTCHA_KEY,
          callback: function (token: string) {
            onValid({ value: token, type: CaptchaType.checkbox })
          },
          action: action,
          "expired-callback": () => {
            console.log("Running expiredCallback")
          },
          "error-callback": () => {
            resetCaptcha()
            console.log("Running errorCallback")
          },
        })
      } catch (e) {
        console.error(e)
      }
    })
  }

  const runInvisibleCaptcha = (onValid: any) => {
    if (!window.grecaptcha) {
      throw new Error("grecaptcha is not loaded")
    }

    const id = `invisible-captcha-${generateRandomId()}`

    const div = document.createElement("div")
    div.setAttribute("id", id)
    div.setAttribute("data-size", "invisible")
    document.body.appendChild(div)

    window.grecaptcha.enterprise.ready(() => {
      try {
        window.grecaptcha?.enterprise.render(id, {
          sitekey: import.meta.env.VITE_INVISIBLE_RECAPTCHA_KEY,
          callback: function (token: string) {
            onValid({ value: token, type: CaptchaType.invisible })
            document.body.removeChild(div)
          },
          action: action,
          "expired-callback": () => {
            console.log("Running expiredCallback")
          },
          "error-callback": () => {
            console.log("Running errorCallback")
            runCheckboxCaptcha(onValid)
          },
        })
        window.grecaptcha?.enterprise.execute()
      } catch (e) {
        console.error(e)
      }
    })
  }

  const resetCaptcha = useCallback(() => {
    if (!window.grecaptcha) {
      throw new Error("grecaptcha is not loaded")
    }

    const div = document.querySelector(checkboxContainer) as HTMLElement
    if (div) div.innerHTML = ""

    window.grecaptcha?.enterprise.reset()
  }, [checkboxContainer])

  return {
    submitWithCaptcha,
    resetCaptcha,
  }
}
