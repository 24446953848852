import React from "react"

import {
  Sheet,
  SheetBody,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from "@/components/Sheet"

type Props = {
  openSheet: boolean
  videoURL: string
  setOpenSheet: React.Dispatch<React.SetStateAction<boolean>>
}

const VideoPortfolio = ({ openSheet, videoURL, setOpenSheet }: Props) => {
  return (
    <>
      <Sheet open={openSheet} onOpenChange={setOpenSheet}>
        <SheetContent side="bottom" height={90}>
          <SheetHeader>
            <SheetTitle>Video Portfolio</SheetTitle>
          </SheetHeader>
          <SheetBody>
            <div className="flex justify-center">
              {/*  eslint-disable-next-line jsx-a11y/media-has-caption */}
              <video
                controls
                playsInline
                className="max-h-[80vh] w-full rounded-xl"
              >
                <source src={videoURL} type="video/mp4" />
              </video>
            </div>
          </SheetBody>
        </SheetContent>
      </Sheet>
    </>
  )
}

export default VideoPortfolio
