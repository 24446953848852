import { captureException } from "@sentry/react"
import {
  APIResponse,
  ErrorResponse,
  PaginatedResponse,
  SuccessResponse,
} from "api/types"
import { AxiosError, AxiosResponse, AxiosTransformer } from "axios"
import camelCase from "lodash/camelCase"
import snakeCase from "lodash/snakeCase"
import { ServicePrefix } from "utils/constants"

/**
 * @deprecated use `formatSuccessResponse` instead
 */
const formatSuccessResponseOld = <T>(
  res: AxiosResponse
): SuccessResponse<T> => ({
  isSuccessful: true,
  data: res.data.data,
  __data: res,
})

type Options = {
  keepOriginalResponse?: boolean
  paginatedResponse?: boolean
}
const formatSuccessResponse = <T>(
  res: AxiosResponse,
  options?: Options
): APIResponse<T> => {
  if (options?.keepOriginalResponse) {
    return { ...res.data, __data: res }
  }
  if (options?.paginatedResponse) {
    return { ...res.data, __data: res }
  }
  return res.data.data
}

const formatPaginatedResponse = <T>(
  res: AxiosResponse
): PaginatedResponse<T> => ({
  isSuccessful: true,
  data: res.data,
  __data: res,
})

const isAxiosError = (error: unknown): error is AxiosError => {
  if (typeof error === "object" && error !== null) {
    if ("isAxiosError" in error) {
      // If the above check passes then we can be sure that the object is AxiosError
      return (error as AxiosError).isAxiosError
    }
  }
  return false
}

/**
 *
 * @param error This argument is `unknown` because it could be any JavaScript error.
 * We cannot be certain that it will be an `AxiosError` every time.
 */
const formatErrorResponse = (error: unknown): ErrorResponse => {
  if (!isAxiosError(error)) {
    throw error
  }

  const is500 = error.response?.status === 500
  const isNetworkFailure = error.response === undefined
  const isEmpty404 =
    error.response?.status === 404 &&
    error.response?.headers["content-length"] === "0"
  const statusCode = error.response?.status
  // eslint-disable-next-line no-warning-comments
  // TODO: remove this when backend has fully migrated to new structure
  let detail = null

  let message = null
  let fieldErrors = null

  if (!is500 && !isNetworkFailure && !isEmpty404) {
    detail = error.response?.data.errors?.detail ?? null
    if (!detail) {
      message = error.response?.data.errors?.message ?? null
      fieldErrors = message ? null : error.response?.data.errors
    }
  }

  return {
    __error: error,
    isSuccessful: false,
    is500,
    isNetworkFailure,
    statusCode,
    errors: {
      fieldErrors,
      message,
      detail,
    },
  }
}

function isUUIDString(str: string) {
  const UUIDv4Regex =
    /^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i
  return UUIDv4Regex.test(str)
}

/**
 * Converts object keys to a specific format specified by the transformer function recursively
 */
const transformKeys = (obj: any, transformer: (arg: string) => string): any => {
  if (Array.isArray(obj)) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return obj.map(v => transformKeys(v, transformer))
  }
  if (obj != null && obj.constructor === Object) {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        /**
         * There are cases when object keys are UUIDs
         * In our previous setup they were getting converted into
         * camelCase, which is definitely not desirable
         * So, we skip transformation if keys are uuids
         */
        [isUUIDString(key) ? key : transformer(key)]: transformKeys(
          obj[key],
          transformer
        ),
      }),
      {}
    )
  }
  return obj
}

/**
 * Override axios's default implementation of serializing query params
 * we allow the following:
 * ?status=0&status=1
 */
// eslint-disable-next-line @typescript-eslint/ban-types
function paramsSerializer(params: object | URLSearchParams = {}): string {
  let entries

  if ("entries" in params) {
    entries = Array.from(params.entries())
  } else entries = Object.entries(params)

  const arr = []
  for (const [key, value] of entries) {
    if (value === null) continue

    if (Array.isArray(value)) {
      value.forEach(v => arr.push(`${key}=${v as string}`))
    } else {
      arr.push(`${key}=${value as string}`)
    }
  }
  return arr.join("&")
}

/**
 * __noTransform is used to bypass snakeCase conversion. Reason for doing this is:
 * newPassword1 gets converted to new_password_1
 * But, the backend expects new_password1
 * However, the proper snake case implementation is new_password_1
 *
 * This work-around will be removed when backend has fixed their APIs
 */
const transformRequest: AxiosTransformer = data => {
  if (data?.__noTransform) {
    delete data.__noTransform
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return data
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return transformKeys(data, snakeCase)
}

const transformResponse: AxiosTransformer = data =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  transformKeys(data, camelCase)

export const getServiceURL = (base: ServicePrefix) => (url: string) =>
  `${base}${url}`

/**
 * This function DOES NOT log for `statusCode = 401`
 */
const logAPIError = (
  errors: ErrorResponse | AxiosError,
  options?: {
    feature: "talk-to-a-mentor"
  }
) => {
  try {
    let statusCode
    let originalError
    if (isAxiosError(errors)) {
      statusCode = errors.response?.status
      originalError = errors
    } else {
      statusCode = errors.statusCode
      originalError = errors.__error
    }

    if (statusCode === 401) return

    const { config } = originalError

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore we can delete this key now
    delete errors.__error

    const fullURL = originalError.request.responseURL
    const shortURL = config.url

    const data = {
      response: errors,
      rawResponse: originalError.request.response,
      request: {
        url: fullURL,
        method: config.method,
        headers: config.headers,
        data: config.data,
      },
    }
    // Debugging purpose
    // console.log(JSON.stringify(data, null, 2))
    // console.log(`Logging API ERROR: API Error (${statusCode}) at: ${shortURL}`)
    captureException(
      new Error(`API Error (${statusCode}) at: ${shortURL}`),
      scope => {
        if (options?.feature) scope.setTag("feature", options.feature)
        scope.setExtras({
          request: JSON.stringify(data.request, null, 2),
          response: JSON.stringify(data.response, null, 2),
          rawResponse: data.rawResponse,
        })
        return scope
      }
    )
  } catch (e) {
    captureException(new Error(`Logger Error`), scope => {
      if (options?.feature) scope.setTag("feature", options.feature)
      scope.setExtras({
        error: JSON.stringify(e),
      })
      return scope
    })
  }
}

export {
  formatErrorResponse,
  formatPaginatedResponse,
  formatSuccessResponse,
  formatSuccessResponseOld,
  logAPIError,
  paramsSerializer,
  transformKeys,
  transformRequest,
  transformResponse,
}
