import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogProps,
  DialogTitle,
  Typography,
} from "@suraasa/placebo-ui"
import api from "api"
import { StepType } from "api/resources/jobs/step.types"
import { Feedback } from "api/resources/teacher/types"
import React, { useEffect, useState } from "react"
import { useParams, useSearchParams } from "react-router-dom"
import ReactStars from "react-stars"
import { ToggleValue } from "utils/hooks/useToggle"

type Props = {
  id: number
  type: StepType
  open: DialogProps["open"]
  toggle?: ToggleValue
}

const ViewFeedbackDialog = ({ id, open, toggle, type }: Props) => {
  const [data, setData] = useState<Feedback | null>(null)
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)

  const [searchParams] = useSearchParams()
  const params = useParams()

  useEffect(() => {
    const fetchFeedback = async () => {
      const schoolId = params.schoolId || searchParams.get("schoolId")

      setLoading(true)
      setData(null)
      setError("")
      const res =
        type === StepType.INTERVIEW
          ? await api.teacher.interview.feedback.retrieve({
              urlParams: { interviewId: id },
              headers: {
                "School-Id": schoolId,
              },
            })
          : await api.teacher.liveDemo.feedback.retrieve({
              urlParams: { liveDemoId: id },
              headers: {
                "School-Id": schoolId,
              },
            })
      if (res.isSuccessful) {
        setData(res.data.feedback)
        setLoading(false)
        setError("")
      } else {
        setData(null)
        setLoading(false)
        setError(
          res.statusCode === 404
            ? `No feedback was added for this ${
                type === StepType.INTERVIEW ? "interview" : "live demo"
              }`
            : res.errors.message ??
                "We're having trouble processing your request"
        )
      }
    }

    if (id) fetchFeedback()
  }, [id, type])

  return (
    <Dialog
      open={open}
      onRequestClose={() => {
        if (toggle) toggle()
      }}
    >
      <DialogTitle>Feedback</DialogTitle>
      <DialogContent>
        {(loading || error) && (
          <div className="flex items-center justify-center p-2">
            {loading && <CircularProgress />}
            {error && <Typography>{error}</Typography>}
          </div>
        )}
        {data && (
          <div className="my-3 flex flex-col gap-3 px-1">
            {data.map(({ comment, name, rating }) => (
              <div key={name}>
                <Typography variant="strong">{name}</Typography>
                <ReactStars edit={false} size={24} value={rating} />
                <Typography
                  style={{
                    whiteSpace: "pre-line",
                  }}
                  variant="smallBody"
                >
                  {comment || "No comments"}
                </Typography>
              </div>
            ))}
          </div>
        )}
      </DialogContent>
      <DialogFooter
        actions={{
          primary: null,
          secondary: {
            label: "Close",
          },
        }}
      />
    </Dialog>
  )
}

export default ViewFeedbackDialog
