import { Button, DialogProps, Tag, Typography } from "@suraasa/placebo-ui"
import {
  JobInterestedUser,
  JobInterestedUserStatus,
} from "api/resources/jobs/types"
import UserDetailsCard from "components/home/UserDetailsCard"
import SlidingSheet from "components/SlidingSheet"
import { Xmark } from "iconoir-react"
import { pluralize } from "utils/helpers"

type Props = {
  handleClose: () => void
  loading?: boolean
  onClickPrimary: () => void
  profiles: JobInterestedUser[]
  jobId: number | null
} & DialogProps

const PushProfileSheet = ({
  open,
  handleClose,
  loading = false,
  onClickPrimary,
  profiles,
  jobId,
  ...props
}: Props) => (
  <SlidingSheet
    open={open}
    from="end"
    width={"md"}
    onClose={handleClose}
    {...props}
    className="flex flex-col px-3 sm:min-w-[420px]"
  >
    <div>
      <Button
        variant="text"
        color="black"
        onClick={handleClose}
        className="-ml-1 mt-1"
      >
        <Xmark /> Close
      </Button>
    </div>
    <div className="grow">
      {profiles.length > 0 ? (
        <>
          <div className="mb-3 mt-2 space-y-0.5">
            <Typography variant="title3">Confirm Profiles</Typography>
            <Typography variant="subtitle2">
              You are about to share {pluralize("profile", profiles.length)}{" "}
              with the school.
            </Typography>
          </div>
          <div className="flex grow flex-col gap-2">
            {profiles.map((item, index) => {
              const bottomTags = (
                <div className="flex space-x-1">
                  {item.isShortlisted && (
                    <Tag color="four" label="Shortlisted" />
                  )}
                  {item.status === JobInterestedUserStatus.ACCEPTED && (
                    <Tag className="inline" color="one" label={"Shared"} />
                  )}
                </div>
              )
              return (
                <UserDetailsCard
                  action={undefined}
                  jobId={jobId}
                  key={index}
                  user={{
                    ...item.user,
                    fullName: item.user.fullName,
                    profile: {
                      picture: item.user.profile.picture || "",
                      country: item.user.profile.country,
                      isVerified: item.user.profile.isVerified,
                      state: item.user.profile.state,
                    },
                  }}
                  bottomTags={bottomTags}
                />
              )
            })}
          </div>
        </>
      ) : (
        <Typography variant="strong" textAlign="center" className="mt-2">
          0 Profiles Selected
        </Typography>
      )}
    </div>
    <div>
      <Button
        variant="filled"
        color="primary"
        onClick={onClickPrimary}
        fullWidth
        className="my-1"
        loading={loading}
        disabled={profiles.length === 0}
      >
        Push Profiles
      </Button>
    </div>
  </SlidingSheet>
)

export default PushProfileSheet
