import { validateUrls } from "api/types"
import { getServiceURL } from "api/utils"
import { ServicePrefix } from "utils/constants"

const getGravityURL = getServiceURL(ServicePrefix.gravity)

export const urls = validateUrls({
  // These urls do not contain slash at the end due to serverless issues on backend.
  currencies: {
    list: () => getGravityURL("/currencies"),
  },
  curricula: {
    list: () => getGravityURL("/curricula"),
    create: () => getGravityURL("/curricula/create"),
  },
  subjects: {
    list: () => getGravityURL("/subjects"),
    create: () => getGravityURL("/subjects/create"),
  },
  countries: {
    list: () => getGravityURL("/countries"),
    current: () => "/global/countries/current",
  },
  states: {
    list: countryId => getGravityURL(`/countries/${countryId}/states`),
  },
})
