import axios from "api/axios"
import {
  APIResponse,
  APIResponseOld,
  Config,
  ConfigWithURLParams,
  NoContentType,
  PaginatedAPIResponseOld,
} from "api/types"
import {
  formatErrorResponse,
  formatPaginatedResponse,
  formatSuccessResponse,
  formatSuccessResponseOld,
} from "api/utils"

import {
  ActiveJobApplicant,
  ApplicantJobStatus,
  ContactDetails,
  HiredUser,
  Job,
  JobApplicant,
  JobInterestedUser,
  JobInvitedUser,
  JobInvitedUserStatus,
  JobOffer,
  JobOverviewCount,
  NotHiredUser,
  PlacementTracker,
} from "./types"
import { urls } from "./urls"

export default {
  list: async ({
    params,
    headers,
  }: Config): Promise<PaginatedAPIResponseOld<Job[]>> => {
    try {
      const res = await axios.get(urls.jobs.base(), { params, headers })
      return formatPaginatedResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  retrieve: async ({
    urlParams: { jobId },
    headers,
  }: ConfigWithURLParams<"jobId">): Promise<APIResponseOld<Job>> => {
    try {
      const res = await axios.get(urls.jobs.detail(jobId), { headers })
      return formatSuccessResponseOld(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  overviewCount: {
    fn: async ({
      urlParams: { jobId },
      headers,
    }: ConfigWithURLParams<"jobId">): Promise<
      APIResponse<JobOverviewCount>
    > => {
      try {
        const res = await axios.get(urls.jobs.overviewCount(jobId), { headers })
        return formatSuccessResponse(res)
      } catch (e) {
        throw formatErrorResponse(e)
      }
    },
    key: (jobId: string) => ["job-overview", jobId],
  },
  update: async ({
    urlParams: { jobId },
    data,
    headers,
  }: ConfigWithURLParams<"jobId">): Promise<APIResponseOld<Job>> => {
    try {
      const res = await axios.put(urls.jobs.detail(jobId), data, { headers })
      return formatSuccessResponseOld(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  create: async ({ data, headers }: Config): Promise<APIResponseOld<Job>> => {
    try {
      const res = await axios.post(urls.jobs.base(), data, { headers })
      return formatSuccessResponseOld(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  invitedUser: {
    create: async ({
      data,
      headers,
    }: Config): Promise<
      APIResponseOld<{
        id: number
        status: JobInvitedUserStatus
      }>
    > => {
      try {
        const res = await axios.post(urls.invitedUser.base(), data, { headers })
        return formatSuccessResponseOld(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    update: async ({
      urlParams: { invitedUserId },
      data = {},
      headers,
    }: ConfigWithURLParams): Promise<APIResponseOld<NoContentType>> => {
      try {
        const res = await axios.put(
          urls.invitedUser.detail(invitedUserId),
          data,
          { headers }
        )
        return formatSuccessResponseOld(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },

  interestedUser: {
    update: async ({
      urlParams: { jobInterestedUserId },
      data,
      headers,
    }: ConfigWithURLParams): Promise<APIResponseOld<NoContentType>> => {
      if (!headers["School-Id"]) {
        throw new Error("School-Id header is required")
      }
      try {
        const res = await axios.put(
          urls.interestedUser.update(jobInterestedUserId),
          data,
          { headers }
        )
        return formatSuccessResponseOld(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },

  jobApplicant: {
    updatePlacementTracker: async ({
      urlParams: { jobApplicantId },
      data,
      headers,
    }: ConfigWithURLParams): Promise<APIResponseOld<PlacementTracker>> => {
      try {
        const res = await axios.put(
          urls.jobApplicant.updatePlacementTracker(jobApplicantId),
          data,
          { headers }
        )
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    retrieve: async ({
      urlParams,
      params,
      headers,
    }: ConfigWithURLParams): Promise<APIResponseOld<JobApplicant>> => {
      try {
        const res = await axios.get(
          urls.jobApplicant.detail(urlParams.jobApplicantId),
          { params, headers }
        )
        return formatSuccessResponseOld(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    update: async ({
      urlParams: { jobApplicantId },
      data,
      headers,
    }: ConfigWithURLParams): Promise<APIResponseOld<JobApplicant>> => {
      try {
        const res = await axios.put(
          urls.jobApplicant.detail(jobApplicantId),
          data,
          { headers }
        )
        return formatSuccessResponseOld(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    retrieveContactDetails: async ({
      urlParams: { userId },
      headers,
    }: ConfigWithURLParams): Promise<APIResponseOld<ContactDetails>> => {
      try {
        const res = await axios.get(
          urls.jobApplicant.retrieveContactDetails(userId),
          { headers }
        )
        return formatSuccessResponseOld(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },

  applicants: {
    getStatus: async ({
      params,
      data,
      headers,
    }: Config): Promise<APIResponseOld<ApplicantJobStatus[]>> => {
      try {
        const res = await axios.post(urls.applicants.get(), data, {
          params,
          headers,
        })
        return formatSuccessResponseOld(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    pushProfiles: async ({
      data,
      headers,
    }: Config): Promise<APIResponseOld<NoContentType>> => {
      try {
        const res = await axios.put(urls.applicants.pushProfiles(), data, {
          headers,
        })
        return formatSuccessResponseOld(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    listActive: async ({
      urlParams,
      params,
      headers,
    }: ConfigWithURLParams): Promise<
      PaginatedAPIResponseOld<ActiveJobApplicant[]>
    > => {
      try {
        const res = await axios.get(
          urls.applicants.listActive(urlParams.jobId),
          { params, headers }
        )
        return formatPaginatedResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    listApplied: async ({
      urlParams,
      params,
      headers,
    }: ConfigWithURLParams): Promise<
      PaginatedAPIResponseOld<JobInterestedUser[]>
    > => {
      try {
        const res = await axios.get(
          urls.applicants.listApplied(urlParams.jobId),
          { params, headers }
        )
        return formatPaginatedResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    listInvited: async ({
      urlParams,
      params,
      headers,
    }: ConfigWithURLParams): Promise<
      PaginatedAPIResponseOld<JobInvitedUser[]>
    > => {
      try {
        const res = await axios.get(
          urls.applicants.listInvited(urlParams.jobId),
          { params, headers }
        )
        return formatPaginatedResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    listHired: async ({
      urlParams,
      params,
      headers,
    }: ConfigWithURLParams): Promise<PaginatedAPIResponseOld<HiredUser[]>> => {
      try {
        const res = await axios.get(
          urls.applicants.listHired(urlParams.jobId),
          { params, headers }
        )
        return formatPaginatedResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    listNotHired: async ({
      urlParams,
      params,
      headers,
    }: ConfigWithURLParams): Promise<
      PaginatedAPIResponseOld<NotHiredUser[]>
    > => {
      try {
        const res = await axios.get(
          urls.applicants.listNotHired(urlParams.jobId),
          { params, headers }
        )
        return formatPaginatedResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },

  jobApplicantStep: {
    create: async ({
      urlParams: { jobApplicantId },
      data,
    }: ConfigWithURLParams): Promise<APIResponseOld<any>> => {
      try {
        const res = await axios.post(
          urls.jobApplicantStep.create(jobApplicantId),
          data
        )
        return formatSuccessResponseOld(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    update: async ({
      urlParams: { jobApplicantStepId },
      data,
      headers,
    }: ConfigWithURLParams): Promise<APIResponseOld<any>> => {
      try {
        const res = await axios.put(
          urls.jobApplicantStep.update(jobApplicantStepId),
          data,
          { headers }
        )
        return formatSuccessResponseOld(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    delete: async ({
      urlParams: { jobApplicantStepId },
    }: ConfigWithURLParams): Promise<APIResponseOld<NoContentType>> => {
      try {
        const res = await axios.delete(
          urls.jobApplicantStep.update(jobApplicantStepId)
        )
        return formatSuccessResponseOld(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },

  jobOffer: {
    create: async ({
      data,
      headers,
    }: Config): Promise<APIResponseOld<NoContentType>> => {
      try {
        const res = await axios.post(urls.jobOffer.create(), data, { headers })
        return formatSuccessResponseOld(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },

    retrieve: async ({
      urlParams,
      headers,
    }: ConfigWithURLParams): Promise<APIResponseOld<JobOffer>> => {
      try {
        const res = await axios.get(
          urls.jobOffer.retrieve(urlParams.jobOfferId),
          { headers }
        )
        return formatSuccessResponseOld(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },
}
