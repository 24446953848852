import React from "react"
import { Link } from "react-router-dom"

import Card, { Props as CardProps } from "../Card"

type Props = CardProps

const ItemRow = ({
  className = "border-0",
  onClick,
  loading,
  component: Component = "div",
  ...props
}: Props) => {
  if (Component === "a" && !props.href) {
    throw new Error("Component `a` requires `href` prop")
  }
  if (Component === Link && !props.to) {
    throw new Error("Component `Link` requires `to` prop")
  }

  return (
    <Card
      {...props}
      className={className}
      component={Component}
      loading={loading}
      onClick={loading ? undefined : onClick}
    />
  )
}

ItemRow.displayName = "ItemRow"

export default ItemRow
