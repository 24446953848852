import api from "api"
// import { PartnerTypeEnum } from "api/resources/registrations/types"
import { GlobalContext } from "components/GlobalState"
import LoadingOverlay from "components/shared/LoadingOverlay"
import { useContext, useEffect, useState } from "react"
import { Outlet } from "react-router-dom"
import { clearAuthInfo, getAuthInfo } from "utils/auth"

const GetActions = () => {
  const auth = getAuthInfo()

  const [loading, setLoading] = useState(true)

  const { setUserData, setProfile } = useContext(GlobalContext)

  useEffect(() => {
    const getUserType = async () => {
      if (!auth) return
      try {
        const res = await api.recruiter.retrieveUserType({
          params: {
            fields: ["actions", "school_actions"],
          },
        })
        const { userType, schoolId } = res

        if (userType.length === 0) {
          clearAuthInfo()
          window.location.reload()
          return
        }

        if (schoolId) {
          getSchoolProfile(schoolId)
        }

        setUserData(res)
      } catch (e) {}
      setLoading(false)
    }

    getUserType().finally(() => setLoading(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getSchoolProfile = async (schoolId: number) => {
    try {
      const res = await api.profile.retrieve({
        params: {
          fields: "curriculum_board",
        },
        urlParams: {},
        headers: {
          "School-Id": schoolId,
        },
      })
      setProfile(res)
    } catch (e) {}
  }

  if (loading) {
    return <LoadingOverlay showLogo />
  }

  return <Outlet />
}

export default GetActions
