import { Button, Tag, Typography } from "@suraasa/placebo-ui"
import clsx from "clsx"
import { GlobalContext } from "components/GlobalState"
import SchoolLogo from "components/shared/SchoolLogo"
import { formatDistance } from "date-fns"
import React, { useContext } from "react"
import { createUseStyles } from "react-jss"

const useStyles = createUseStyles(theme => ({
  card: {
    background: "white",
    borderRadius: "8px",
    border: `1px solid ${theme.colors.surface[200]}`,
    boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.05)",
    [theme.breakpoints.down("md")]: {
      gap: theme.spacing(2),
    },
  },
  logo: {
    width: "46px",
    height: "46px",
    boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.05)",
  },
}))

type PreviewJobCardProps = {
  currencyCode?: string
  datePublished?: string
  position: string
  salaryOffered: number | null
  salaryNegotiable: boolean
  maximumSalaryOffered: number | null
  subject?: string
}

const PreviewJobCard = ({
  currencyCode = "INR",
  salaryOffered = 0,
  maximumSalaryOffered = 0,
  datePublished,
  salaryNegotiable,
  position,
  subject,
}: PreviewJobCardProps) => {
  const classes = useStyles()

  const { profile } = useContext(GlobalContext)

  const datePublishStatus =
    datePublished &&
    formatDistance(new Date(datePublished), new Date(), {
      addSuffix: true,
    })

  return (
    <div
      className={clsx(
        "flex flex-col justify-between p-3 md:flex-row",
        classes.card
      )}
    >
      <div className="flex flex-col gap-2 md:flex-row">
        <SchoolLogo
          alt="logo"
          className={clsx(classes.logo)}
          src={profile.logo}
        />
        <div className="flex flex-col gap-2">
          <div>
            {(position || subject) && (
              <Typography variant="strong">
                {position ?? ""}
                {subject && `, ${subject}`}
              </Typography>
            )}
            {profile.name && (
              <Typography variant="body">{profile.name}</Typography>
            )}
          </div>
          <div className="flex flex-wrap gap-x-1 gap-y-2">
            {profile.curriculumBoard &&
              profile.curriculumBoard.map((board, index) => (
                <Tag key={index} label={board.name} size="md" />
              ))}
            {profile.city && profile.country && (
              <Tag
                color="onSurface"
                label={`${profile.city}, ${profile.country?.name}`}
                size="md"
              />
            )}
          </div>
          {salaryNegotiable ? (
            <Typography variant="title3">Salary Negotiable</Typography>
          ) : (
            (salaryOffered || currencyCode) && (
              <div className="flex flex-wrap items-center">
                <Typography variant="strong">
                  {currencyCode} {Number(salaryOffered).toLocaleString()}{" "}
                  {maximumSalaryOffered && maximumSalaryOffered > 0 ? (
                    <>- {Number(maximumSalaryOffered).toLocaleString()}</>
                  ) : null}
                </Typography>
                <Typography color="onSurface.500" variant="body">
                  /year
                </Typography>
              </div>
            )
          )}
        </div>
      </div>
      <div className="flex items-end justify-between md:flex-col">
        <Typography color="onSurface.500" variant="smallBody">
          {`Posted ${
            datePublished && datePublishStatus !== "less than a minute ago"
              ? datePublishStatus
              : "recently"
          }`}
        </Typography>
        <Button size="sm" variant="outlined">
          Apply
        </Button>
      </div>
    </div>
  )
}

export default PreviewJobCard
