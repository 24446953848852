import React from "react"

const ArrowSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 20 21"
      fill="none"
    >
      <path
        d="M9.99999 18.8334C14.6024 18.8334 18.3333 15.1025 18.3333 10.5001C18.3333 5.89771 14.6024 2.16675 9.99999 2.16675C5.39762 2.16675 1.66666 5.89771 1.66666 10.5001C1.66666 15.1025 5.39762 18.8334 9.99999 18.8334Z"
        fill="#4666F6"
        stroke="#4666F6"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.66683 10.4998H13.3335M13.3335 10.4998L10.4168 7.58317M13.3335 10.4998L10.4168 13.4165"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ArrowSvg
