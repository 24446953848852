import { IconButton, Typography } from "@suraasa/placebo-ui"
import { Edit } from "iconoir-react"
import { useContext, useState } from "react"
import ProfileContext from "views/Profile/context"

import CheckTile from "../CheckTile"
import AmenitiesDialog from "./AmenitiesDialog"

function Amenities({ canUpdateSchool }: { canUpdateSchool: boolean }) {
  const { amenities, isPublic } = useContext(ProfileContext)
  const [openAmenitiesDialog, setOpenAmenitiesDialog] = useState(false)

  const canEdit = canUpdateSchool

  return (
    <>
      <AmenitiesDialog
        handleClose={() => setOpenAmenitiesDialog(false)}
        open={openAmenitiesDialog}
      />
      <div className="flex items-center justify-between">
        <Typography className="mb-3" color="primary.500" variant="preTitle">
          Amenities
        </Typography>
        {!isPublic && canEdit && (
          <IconButton
            color="primary"
            onClick={() => setOpenAmenitiesDialog(true)}
          >
            <Edit />
          </IconButton>
        )}
      </div>

      {amenities.data.length ? (
        <div className="mb-0.5 flex flex-col flex-wrap sm:flex-row sm:justify-start">
          {amenities.data.map(item => (
            <CheckTile key={item.id} label={item.name} />
          ))}
        </div>
      ) : (
        <Typography
          className="mb-0.5"
          color="onSurface.400"
          variant="smallBody"
        >
          {canEdit ? "Add your school’s amenities here" : "-"}
        </Typography>
      )}
    </>
  )
}

export default Amenities
