import { Button, Container, Typography } from "@suraasa/placebo-ui"
import { Profile, SkillEvidence } from "api/resources/teacher/types"
import clsx from "clsx"
import Overview from "components/teacher/profile/Overview"
import React from "react"
import { useNavigate, useParams } from "react-router-dom"
import { routes } from "utils/routes"

type Props = {
  profile: Profile
  skillEvidences: SkillEvidence[]
}

const SkillOverview = ({ profile, skillEvidences }: Props) => {
  const navigate = useNavigate()
  const { username } = useParams()

  const handleViewEvidence = (courseSlug: string) => {
    navigate(
      routes.publicSkillProfile.evidences
        .replace(":slug", courseSlug)
        .replace(":username", username ?? "")
    )
  }

  return (
    <div>
      <Overview profile={profile} />

      {skillEvidences && (
        <Container className="pb-8">
          <div className="overflow-hidden border border-surface-200 bg-white sm:rounded-2xl">
            <div className="border-b border-surface-200 px-2.5 py-1.5">
              <Typography variant="title3">Skills</Typography>
            </div>
            {skillEvidences.length > 0 ? (
              skillEvidences.map((item, index) => (
                <div
                  className={clsx(
                    "mx-2 flex flex-wrap items-center justify-between gap-1 py-2",
                    {
                      "border-b border-surface-200":
                        index !== skillEvidences.length - 1,
                    }
                  )}
                  key={item.slug}
                >
                  <Typography color="onSurface.800" variant="strong">
                    {item.name}
                  </Typography>
                  <Button
                    variant="text"
                    onClick={() => {
                      handleViewEvidence(item.slug)
                    }}
                  >
                    View Evidences
                  </Button>
                </div>
              ))
            ) : (
              <Typography className="my-4" variant="title3">
                No Skill Evidences Available!
              </Typography>
            )}
          </div>
        </Container>
      )}
    </div>
  )
}

export default SkillOverview
