import { Typography } from "@suraasa/placebo-ui"
import clsx from "clsx"
import React from "react"
import { createUseStyles } from "react-jss"

interface Props {
  message?: string
  className?: string
}

const useStyles = createUseStyles(theme => ({
  root: {
    background: "white",
    border: `1px solid ${theme.colors.surface[200]}`,
    borderRadius: "4px",
  },
}))

const NoDataCard = ({ className, message = "No records found." }: Props) => {
  const classes = useStyles()

  return (
    <div
      className={clsx(
        classes.root,
        "flex items-center justify-center p-3",
        className
      )}
    >
      <Typography>{message}</Typography>
    </div>
  )
}

export default NoDataCard
