import { Typography } from "@suraasa/placebo-ui"
import React, { useContext } from "react"
import { createUseStyles } from "react-jss"
import { pluralize } from "utils/helpers"
import ProfileContext from "views/teacher/profileContext"

import Section from "../Section"
import SectionContent from "../Section/SectionContent"
import SectionHeading from "../Section/SectionHeading"
import SectionTitle from "../Section/SectionTitle"

const useStyles = createUseStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
  locationContainer: {
    display: "flex",
    alignItems: "center",
  },
  flagImg: {
    width: 28,
    height: 20,
    marginRight: theme.spacing(10 / 8),
  },
}))

const WorkLocationInterests = () => {
  const classes = useStyles()

  const {
    workLocationInterest: { data },
  } = useContext(ProfileContext)

  return (
    <Section>
      <SectionHeading
        heading={
          <SectionTitle
            itemCount={data.length}
            title={pluralize("Work Location Interest", data.length, {
              skipCount: true,
            })}
          />
        }
      />

      <SectionContent className={classes.root} xPadding={2}>
        {data.length > 0 ? (
          data.map((item, i) => (
            <React.Fragment key={i}>
              {item.country && item.state && (
                <div className={classes.locationContainer}>
                  <img
                    alt=""
                    className={classes.flagImg}
                    src={`/assets/flags/${item.country.isoCode}.svg`}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null // prevents looping
                      currentTarget.src = "/assets/flags/placeholder.png"
                    }}
                  />
                  <Typography variant="strong">{item.state.name},</Typography>
                  <Typography variant="body">
                    &nbsp;{item.country.name}
                  </Typography>
                </div>
              )}
            </React.Fragment>
          ))
        ) : (
          <Typography color="onSurface.400" variant="smallBody">
            Looks like there's nothing here.
          </Typography>
        )}
      </SectionContent>
    </Section>
  )
}

export default WorkLocationInterests
