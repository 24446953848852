import {
  Button,
  CircularProgress,
  Container,
  Pagination,
  Typography,
} from "@suraasa/placebo-ui"
import api from "api"
import { Job } from "api/resources/jobs/types"
import { SchoolOverview } from "api/resources/school/types"
import clsx from "clsx"
import JobPostingCard from "components/home/JobPostingCard"
import SubjectCards from "components/home/SubjectCards"
import Navbar from "components/shared/Navbar"
import ReactHelmet from "components/shared/ReactHelmet"
import { NavArrowRight, Plus } from "iconoir-react"
import metadata from "metadata.json"
import { useEffect, useState } from "react"
import { createUseStyles } from "react-jss"
import { Link, useNavigate, useParams } from "react-router-dom"
import { useSchoolActions } from "views/auth/useSchoolActions"
import { Actions } from "views/auth/useUserActions"
import useUserType from "views/auth/useUserType"
import NoAccess from "views/common/NoAccess"

import DetailsSheet from "./DetailsSheet"

const useStyles = createUseStyles(({ colors }) => ({
  jobsPlaceholder: {
    border: `1px solid ${colors.surface[200]}`,
    borderRadius: "4px",
  },

  viewMoreButton: {
    "& > span": {
      justifyContent: "flex-end",
    },
  },
}))

const SchoolHome = () => {
  const classes = useStyles()
  const navigate = useNavigate()

  const { schoolId = "" } = useParams()

  const { hasSchoolAction } = useSchoolActions({
    schoolIds: [schoolId],
  })

  const { isSchoolAdmin } = useUserType()

  const canListJobs = hasSchoolAction(Actions.listJobs, schoolId)

  const canViewSchool = hasSchoolAction(Actions.viewSchool, schoolId)

  const canViewJobApplicants = hasSchoolAction(
    Actions.listJobApplicants,
    schoolId
  )

  const canCreateNewJob = hasSchoolAction(Actions.createJob, schoolId)
  const canUpdateJob = hasSchoolAction(Actions.updateJob, schoolId)

  const [loading, setLoading] = useState(true)
  const [showTotalApplicants, setShowTotalApplicants] = useState(false)
  const [showTotalHires, setShowTotalHires] = useState(false)

  const [schoolOverview, setSchoolOverview] = useState<SchoolOverview | null>({
    totalApplicants: 0,
    totalHires: 0,
  })
  const [postings, setPostings] = useState<{
    data: Job[]
    total: number
    page: number
  }>({
    data: [],
    total: 0,
    page: 1,
  })

  const removeJobPostingFromList = (id: number) => {
    setPostings(item => ({
      ...item,
      data: item.data.filter(v => v.id !== id),
    }))
  }

  const getSchoolOverview = async () => {
    const res = await api.school.schoolOverview({
      headers: {
        "School-Id": schoolId,
      },
    })

    if (res.isSuccessful) {
      setSchoolOverview(res.data)
    }
  }
  useEffect(() => {
    if (schoolId) {
      getSchoolOverview()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schoolId])

  useEffect(() => {
    if (!schoolId) {
      return
    }
    const listPostings = async () => {
      setLoading(true)
      const res = await api.jobs.list({
        params: {
          page: postings.page,
          fields: ["school", "number_of_applicants", "date_published"],
        },
        headers: {
          "School-Id": schoolId,
        },
      })

      if (res.isSuccessful) {
        setPostings(prevState => ({
          ...prevState,
          data: res.data.data,
          total: res.data.total,
        }))
        setLoading(false)
      }
    }
    listPostings()
  }, [postings.page, schoolId])

  if (!canViewSchool) {
    return (
      <>
        <ReactHelmet data={metadata.home} />
        <Navbar hideBackButton={isSchoolAdmin} />
        <NoAccess />
      </>
    )
  }

  return (
    <>
      <ReactHelmet data={metadata.home} />
      <Navbar hideBackButton={isSchoolAdmin} />

      <Container className="mb-4">
        <div className="mb-7 flex justify-end">
          {canCreateNewJob && schoolId && (
            <Button
              className="pl-0"
              component={Link}
              startAdornment={<Plus />}
              to="job/create"
            >
              Create New Job Opening
            </Button>
          )}
        </div>
        <DetailsSheet
          onClose={() => {
            setShowTotalApplicants(false)
            setShowTotalHires(false)
          }}
          open={showTotalApplicants || showTotalHires}
          isApplicant={showTotalApplicants}
          schoolId={schoolId || ""}
        />
        {schoolOverview && (
          <div className="mb-2 flex w-full gap-2">
            <div className="flex w-full items-center justify-between rounded-xl border bg-white p-1 px-2">
              <div className="flex flex-col justify-between">
                <Typography variant="preTitle">APPLICANT DATABASE</Typography>
                <Typography variant="title1">
                  {schoolOverview.totalApplicants}
                </Typography>
              </div>
              {canViewJobApplicants && (
                <div>
                  <Button
                    variant="text"
                    onClick={() => setShowTotalApplicants(true)}
                    endAdornment={<NavArrowRight />}
                  >
                    View Details
                  </Button>
                </div>
              )}
            </div>
            <div className="flex w-full items-center justify-between rounded-xl border bg-white p-1 px-2">
              <div className="flex flex-col justify-between">
                <Typography variant="preTitle">TOTAL HIRES</Typography>
                <Typography variant="title1">
                  {schoolOverview.totalHires}
                </Typography>
              </div>
              {canViewJobApplicants && (
                <div>
                  <Button
                    variant="text"
                    onClick={() => setShowTotalHires(true)}
                    endAdornment={<NavArrowRight />}
                  >
                    View Details
                  </Button>
                </div>
              )}
            </div>
          </div>
        )}
        {canListJobs && (
          <>
            <Typography variant="title3" className="mb-2">
              Active Jobs ({postings.data.length})
            </Typography>
            {/* eslint-disable-next-line no-nested-ternary */}
            {loading ? (
              <div className="flex justify-center">
                <CircularProgress />
              </div>
            ) : postings.data.length > 0 ? (
              <div>
                {postings.data.map((item, index) => (
                  <JobPostingCard
                    afterSubmit={() => removeJobPostingFromList(item.id)}
                    job={item}
                    key={index}
                    schoolId={schoolId}
                    canUpdateJob={canUpdateJob}
                    canCloseJob={canUpdateJob}
                  />
                ))}
                {schoolId && (
                  <Button
                    className="mt-2"
                    variant="ghost"
                    onClick={() => {
                      navigate("job/closed")
                    }}
                  >
                    Closed Jobs
                  </Button>
                )}

                {postings.total > 10 && (
                  <Pagination
                    page={postings.page}
                    total={postings.total}
                    onChange={newPage =>
                      setPostings(prevState => ({
                        ...prevState,
                        page: newPage,
                      }))
                    }
                  />
                )}
              </div>
            ) : (
              <>
                <div className={clsx("py-3", classes.jobsPlaceholder)}>
                  <Typography color="onSurface.500" textAlign="center">
                    You haven't created any Job Openings yet. Click on the
                    Create New Job Opening button to start recruiting!
                  </Typography>
                </div>
                <Button
                  className="mt-2"
                  variant="ghost"
                  onClick={() => {
                    navigate("job/closed")
                  }}
                >
                  Closed Jobs
                </Button>
              </>
            )}
          </>
        )}

        <div className="mb-12 mt-5">
          <Typography className="mb-3" variant="title2">
            Hire Teachers
          </Typography>
          <SubjectCards />
        </div>
      </Container>
    </>
  )
}

export default SchoolHome
