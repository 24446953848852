import { LanguageProficiency } from "utils/constants"

export const proficiencyOptions = [
  {
    label: "Elementary",
    value: LanguageProficiency.ELEMENTARY,
  },
  {
    label: "Limited Working",
    value: LanguageProficiency.LIMITED_WORKING,
  },
  {
    label: "Professional Working",
    value: LanguageProficiency.PROFESSIONAL_WORKING,
  },
  {
    label: "Full Professional",
    value: LanguageProficiency.FULL_PROFESSIONAL,
  },
  {
    label: "Native/Bi-lingual",
    value: LanguageProficiency.NATIVE,
  },
]

export const getProficiencyLabel = (value: LanguageProficiency) => {
  return proficiencyOptions.find(option => option.value === value)?.label
}
