import { useQuery } from "@tanstack/react-query"
import api from "api"
import { Representative } from "api/resources/representatives/types"
import Tabs from "components/jobs/Tabs"
import LoadingOverlay from "components/shared/LoadingOverlay"
import { useState } from "react"
import useTabs, { Tabs as TabsType } from "utils/hooks/useTabs"

import TeamMembersTable from "../../common/TeamTab/TeamMembersTable"

type Props = {
  row: Representative | null
}

const TABS = {
  recruiter: "Recruiter Team",
  partner: "Partner Team",
}

enum DepartmentType {
  RECRUITER = "recruiter",
  PARTNER = "partner",
}

const TeamTab = ({ row }: Props) => {
  const [sortBy, setSortBy] = useState<string[]>([""])

  const [tabs, setTabs] = useState<TabsType>([
    {
      name: TABS.recruiter,
      content: TeamMembersTable,
      count: 0,
    },
    {
      name: TABS.partner,
      content: TeamMembersTable,
      count: 0,
    },
  ])

  const [activeTab, setActiveTab] = useTabs({
    tabs,
    initialTab: tabs[0].name,
    useSearchParams: false,
  })
  const { data, isLoading } = useQuery({
    queryFn: () => {
      return api.representatives.representativesList({
        params: {
          fields: ["school_count", "lead_partner_id", "lead_recruiter_id"],
          ordering: sortBy,
          lead_user_id: row?.uuid,
          related_department:
            activeTab.name === TABS.recruiter
              ? DepartmentType.RECRUITER
              : DepartmentType.PARTNER,
        },
      })
    },
    queryKey: [
      "team",
      {
        sortBy,
        activeTab,
        representativeId: row?.uuid,
      },
    ],
  })

  if (isLoading) {
    return <LoadingOverlay />
  }

  const onTabChange = (tab: string) => {
    setActiveTab(tab)
  }

  return (
    <div className="flex w-full flex-col">
      <Tabs
        color="black"
        activeTab={activeTab}
        tabs={tabs}
        onChange={onTabChange}
      />
      {activeTab && (
        <activeTab.content
          key={activeTab.name}
          data={data ?? []}
          setSortBy={setSortBy}
          onCountUpdate={(count: number) => {
            setTabs(prevTabs => {
              return prevTabs.map(x =>
                x.name === activeTab.name ? { ...x, count } : x
              )
            })
          }}
        />
      )}
    </div>
  )
}

export default TeamTab
