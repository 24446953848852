import { CircularProgress, Container, Typography } from "@suraasa/placebo-ui"
import api from "api"
import { User } from "api/resources/settings/types"
import AccountDetails from "components/profile/editProfile/AccountDetails"
import Navbar from "components/shared/Navbar"
import { useEffect, useState } from "react"

function Settings() {
  const [loading, setLoading] = useState<boolean>(false)

  const [accountDetails, setAccountDetails] = useState<User>({
    product: 2,
    email: "",
    firstName: "",
    middleName: "",
    lastName: "",
    fullName: "",
    phoneNumber: null,
  })

  useEffect(() => {
    async function getData() {
      setLoading(true)

      const promises = [api.users.retrieveUser()] as const
      const [accountDetailsRes] = await Promise.all(promises)

      if (accountDetailsRes.isSuccessful) {
        setAccountDetails(accountDetailsRes.data)
      }

      setLoading(false)
    }
    getData()
  }, [])

  return (
    <>
      <Navbar hideBackButton />

      <Container>
        <Typography variant="title1" className="mb-4">
          Account Settings
        </Typography>
        {loading ? (
          <div className="flex flex-col items-center justify-between">
            <CircularProgress />
          </div>
        ) : (
          <AccountDetails
            data={accountDetails}
            updateUserPreferences={newState =>
              setAccountDetails(prevState => ({
                ...prevState,
                ...newState,
              }))
            }
          />
        )}
      </Container>
    </>
  )
}

export default Settings
