import { EmploymentType, OrganisationType } from "api/resources/teacher/types"

export const defaultOrganizationOptions = [
  { label: "School", value: OrganisationType.SCHOOL },
  {
    label: "College/University",
    value: OrganisationType.COLLEGE_OR_UNIVERSITY,
  },
  {
    label: "Tutoring",
    value: OrganisationType.TUTORING,
  },
]

export const employmentTypeOptions = [
  { label: "Full Time", value: EmploymentType.FULL_TIME },
  { label: "Part time", value: EmploymentType.PART_TIME },
  { label: "Fresher", value: EmploymentType.FRESHER },
  { label: "Intern", value: EmploymentType.INTERN },
  { label: "Freelancer", value: EmploymentType.FREELANCE },
  { label: "Self-Employed", value: EmploymentType.SELF_EMPLOYED },
]

export const getEmploymentTypeDisplay = (value: EmploymentType | null) => {
  if (!value) return null
  return employmentTypeOptions.find(option => option.value === value)?.label
}
