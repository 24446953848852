import {
  DialogContent,
  DialogFooter,
  DialogProps,
  DialogTitle,
  Select,
} from "@suraasa/placebo-ui"
import api from "api"
import { Amenity } from "api/resources/profile/types"
import { useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { createUseStyles } from "react-jss"
import { useParams } from "react-router-dom"
import { AMENITIES_CHAR_LIMIT } from "utils/constants"
import { handleErrors } from "utils/helpers"
import useResources from "utils/hooks/useResources"
import toast from "utils/toast"

const useStyles = createUseStyles(theme => ({
  dialogContent: {
    padding: theme.spacing(2.5, 3, 5.5, 3),
    display: "flex",
    gap: 24,
    flexDirection: "column",
  },
}))

type Props = {
  onAdd: (data: Amenity) => void
  handleBack?: () => void
}

const formatAmenities = (amenities: Amenity[]) =>
  amenities.map(item => ({ value: item.id, label: item.name }))

const AddDialog = ({
  handleBack,
  onAdd,
}: Props & Pick<DialogProps, "open" | "onRequestClose">) => {
  const classes = useStyles()

  const { amenities } = useResources(["amenities"])
  const { schoolId } = useParams() as { schoolId: string }

  const [amenitiesOptions, setAmenitiesOptions] = useState(
    formatAmenities(amenities)
  )

  const [loading, setLoading] = useState(false)

  const {
    handleSubmit,
    reset,
    setValue,
    setError,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      amenity: "",
    },
  })

  useEffect(() => {
    reset({})
  }, [reset])

  useEffect(() => {
    setAmenitiesOptions(formatAmenities(amenities))
  }, [amenities])

  const onSubmit = handleSubmit(async FormData => {
    const amenityAdded = FormData.amenity
    setLoading(true)

    if (amenityAdded) {
      const res = await api.profile.amenities.update({
        data:
          typeof amenityAdded === "number"
            ? { amenityId: amenityAdded }
            : { amenityName: amenityAdded },
        headers: { "School-Id": schoolId },
      })

      if (res.isSuccessful) {
        onAdd(res.data.amenity)
        if (handleBack) handleBack()
      } else {
        handleErrors(setError, res.errors)
      }
    }
    setLoading(false)
  })

  return (
    <form onSubmit={onSubmit}>
      <DialogTitle onBack={handleBack}> Add New Amenity</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <div>
          <Controller
            control={control}
            name="amenity"
            render={({ field: { onChange, onBlur, value } }) => (
              <Select
                error={Boolean(errors.amenity)}
                helperText={errors.amenity?.message}
                inputLabelProps={{ required: true }}
                label="amenity"
                options={amenitiesOptions}
                value={
                  value ? amenitiesOptions.find(c => c.value === value) : null
                }
                createable
                fullWidth
                isClearable
                mountOnBody
                onBlur={onBlur}
                onChange={(option, actionMeta) => {
                  onChange(option?.value)

                  if (actionMeta.action === "create-option" && option) {
                    if (option.value.length <= AMENITIES_CHAR_LIMIT) {
                      setAmenitiesOptions(i => [option, ...i])
                      setValue("amenity", String(option.value))
                    } else {
                      toast.error(
                        `Max characters allowed: ${AMENITIES_CHAR_LIMIT}`
                      )
                      setValue(
                        "amenity",
                        String(option.value.slice(0, AMENITIES_CHAR_LIMIT))
                      )
                    }
                  }

                  if (actionMeta.action === "clear") {
                    setValue("amenity", "")
                    setAmenitiesOptions(formatAmenities(amenities))
                  }
                }}
              />
            )}
            rules={{
              required: { value: true, message: "Required" },
            }}
          />
        </div>
      </DialogContent>
      <DialogFooter
        actions={{
          primary: {
            label: "Save",
            variant: "filled",
            color: "primary",
            type: "submit",
            loading,
          },
          secondary: {
            label: "Close",
          },
        }}
      />
    </form>
  )
}

export default AddDialog
