import { Button, IconButton, Typography } from "@suraasa/placebo-ui"
import api from "api"
import {
  JobApplicantStepStatus,
  LiveDemoDetailsStep,
  StepType,
} from "api/resources/jobs/step.types"
import RemoveDialog from "components/RemoveDialog"
import Section from "components/Section"
import SectionContent from "components/Section/SectionContent"
import AddFeedbackDialog from "components/teacher/Dialogs/AddFeedbackDialog"
import ListItem from "components/teacher/ListItem"
import { format } from "date-fns"
import add from "date-fns/add"
import {
  Calendar,
  Clock,
  Copy,
  EditPencil,
  Link,
  MapPin,
  Trash,
} from "iconoir-react"
import React, { useContext, useState } from "react"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { createUseStyles } from "react-jss"
import { useParams } from "react-router-dom"
import { getTag } from "utils/getStepStatusTag"
import useToggle from "utils/hooks/useToggle"
import toast from "utils/toast"
import TeacherTimelineContext from "views/teacher/context"

const useStyles = createUseStyles(theme => ({
  instructions: {
    whiteSpace: "pre-wrap",
    paddingBottom: "8px",
  },
  scheduledFor: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
}))

const Card = ({
  handleLiveDemoToEdit,
  ...props
}: LiveDemoDetailsStep & {
  handleLiveDemoToEdit: () => void
}) => {
  const {
    id,
    dateCreated,
    status,
    step: {
      name,
      scheduledTime,
      duration,
      meetingUrl,
      feedback,
      address,
      isVirtual,
      topic,
      instructions,
    },
  } = props

  const classes = useStyles()
  const {
    isRejected,
    liveDemos,
    dialogs: { openFeedback, markAsComplete },
  } = useContext(TeacherTimelineContext)
  const params = useParams()
  const schoolId = params.schoolId || ""

  const [addFeedbackDialog, toggleAddFeedbackDialog] = useToggle(false)
  const [openRemoveDialog, toggleRemoveDialog] = useToggle(false)
  const [removeLoading, setRemoveLoading] = useState(false)

  const handleRemove = async () => {
    setRemoveLoading(true)
    const res = await api.teacher.jobApplicantStep.delete({
      urlParams: {
        stepId: id,
      },
      headers: {
        "School-Id": schoolId,
      },
    })

    if (res.isSuccessful) {
      liveDemos.remove(id)
      toast.success("Live demo removed successfully")
    } else {
      toast.error("Live demo couldn't be removed")
    }
    setRemoveLoading(false)
  }

  const handleMarkAsComplete = () => {
    if (!feedback) toggleAddFeedbackDialog()
    liveDemos.update(id, {
      ...props,
      status: JobApplicantStepStatus.COMPLETED,
    })
  }

  const PostedDate = () =>
    dateCreated ? (
      <Typography className="mr-auto" color="onSurface.500" variant="smallBody">
        Posted on {format(new Date(dateCreated), "do MMM yy")}
      </Typography>
    ) : null

  const getAction = () => {
    switch (status) {
      case JobApplicantStepStatus.COMPLETED:
        return feedback ? (
          <Button
            size="sm"
            onClick={() =>
              openFeedback.toggle(props.step.id, StepType.LIVE_DEMO)
            }
          >
            Open Feedback
          </Button>
        ) : (
          <Button
            disabled={isRejected}
            size="sm"
            onClick={() => toggleAddFeedbackDialog()}
          >
            Add Feedback
          </Button>
        )

      case JobApplicantStepStatus.NOT_STARTED:
      case JobApplicantStepStatus.IN_PROGRESS:
        return (
          <>
            <Button
              disabled={isRejected}
              variant="text"
              onClick={() => toggleAddFeedbackDialog()}
            >
              Add Feedback
            </Button>
            <Button
              color="success"
              disabled={isRejected}
              size="sm"
              onClick={() =>
                markAsComplete.toggle({
                  id,
                  name,
                  afterSubmit: handleMarkAsComplete,
                  schoolId,
                })
              }
            >
              Mark as complete
            </Button>
          </>
        )

      default:
        break
    }
  }

  const LiveDemoLink = () =>
    meetingUrl ? (
      <div className="flex items-center gap-0.75">
        <a href={meetingUrl} rel="noreferrer" target="_blank">
          <Typography color="interactive.500" variant="smallBody">
            {meetingUrl}
          </Typography>
        </a>
        <CopyToClipboard
          text={meetingUrl}
          onCopy={() => toast.success("Link copied")}
        >
          <IconButton size="xs">
            <Copy />
          </IconButton>
        </CopyToClipboard>
      </div>
    ) : null

  return (
    <>
      <AddFeedbackDialog
        afterSubmit={feedbackData => {
          liveDemos.update(id, {
            ...props,
            step: { ...props.step, feedback: feedbackData },
            status: JobApplicantStepStatus.COMPLETED,
          })
        }}
        schoolId={schoolId}
        id={props.step.id}
        open={addFeedbackDialog}
        toggle={toggleAddFeedbackDialog}
        type={StepType.LIVE_DEMO}
      />

      <RemoveDialog
        handleClose={toggleRemoveDialog}
        loading={removeLoading}
        open={openRemoveDialog}
        title="Delete Live Demo"
        onRemove={handleRemove}
      >
        Are you sure you want to delete this Live Demo? This action can’t be
        reversed.
      </RemoveDialog>

      <Section>
        <SectionContent>
          <div className="flex flex-wrap gap-1">
            <Typography color="onSurface.800" variant="strong">
              {name}
            </Typography>
            <span>•</span>
            <Typography color="onSurface.500" variant="body">
              {meetingUrl ? "Online" : "Offline"}
            </Typography>

            {getTag(props)}

            {[JobApplicantStepStatus.NOT_STARTED].includes(status) && (
              <div className="ml-auto flex gap-1.25">
                <IconButton
                  disabled={isRejected}
                  onClick={handleLiveDemoToEdit}
                >
                  <EditPencil />
                </IconButton>
                <IconButton
                  color="critical"
                  disabled={isRejected}
                  onClick={() => toggleRemoveDialog()}
                >
                  <Trash />
                </IconButton>
              </div>
            )}
          </div>

          {topic && (
            <Typography
              className="mt-2"
              color="onSurface.500"
              variant="strongSmallBody"
            >
              Topic: {topic}
            </Typography>
          )}
          {instructions && (
            <Typography
              className={classes.instructions}
              color="onSurface.500"
              variant="smallBody"
            >
              {instructions}
            </Typography>
          )}

          {(meetingUrl || address) && (
            <ListItem
              className={isVirtual ? "-mb-0.75" : undefined}
              icon={isVirtual ? <Link /> : <MapPin />}
            >
              {isVirtual ? (
                <LiveDemoLink />
              ) : (
                <Typography color="onSurface.800" variant="smallBody">
                  {address}
                </Typography>
              )}
            </ListItem>
          )}

          <ListItem icon={<Calendar />}>
            <Typography
              className={classes.scheduledFor}
              color="onSurface.400"
              display="inline"
              variant="smallBody"
            >
              Scheduled for{" "}
            </Typography>
            <Typography display="inline" variant="strongSmallBody">
              {format(new Date(scheduledTime), "dd MMMM yyyy hh:mm aa")}
            </Typography>
          </ListItem>

          <ListItem icon={<Clock />}>
            <Typography variant="smallBody">
              {[
                format(new Date(scheduledTime), "HH:mm aaa"),
                format(
                  add(new Date(scheduledTime), { minutes: duration }),
                  "HH:mm aaa"
                ),
              ].join(" - ")}
            </Typography>
          </ListItem>

          <div className="mt-2 flex flex-wrap items-center justify-end gap-0.5">
            <PostedDate />
            {getAction()}
          </div>
        </SectionContent>
      </Section>
    </>
  )
}

export default Card
