import { Tag, Typography } from "@suraasa/placebo-ui"
import { useContext } from "react"
import { pluralize } from "utils/helpers"
import ProfileContext from "views/teacher/profileContext"

import Section from "../Section"
import SectionContent from "../Section/SectionContent"
import SectionHeading from "../Section/SectionHeading"
import SectionTitle from "../Section/SectionTitle"

const Skills = () => {
  const {
    skills: { data },
  } = useContext(ProfileContext)

  return (
    <Section>
      <SectionHeading
        heading={
          <SectionTitle
            itemCount={data.length}
            title={pluralize("Skill", data.length, {
              skipCount: true,
            })}
          />
        }
      />
      <SectionContent className={"flex flex-wrap gap-1.5"} xPadding={2}>
        {data.length > 0 ? (
          data.map((item, i) => (
            <Tag key={i} label={item.skillName} size="sm" />
          ))
        ) : (
          <Typography color="onSurface.400" variant="smallBody">
            Looks like there's nothing here.
          </Typography>
        )}
      </SectionContent>
    </Section>
  )
}

export default Skills
