import { Button, Container, IconButton, Typography } from "@suraasa/placebo-ui"
import { Profile } from "api/resources/profile/types"
import clsx from "clsx"
import { Edit } from "iconoir-react"
import { useContext } from "react"
import { createUseStyles } from "react-jss"
import { useNavigate } from "react-router"
import { routes } from "utils/routes"
import useUserType from "views/auth/useUserType"
import ProfileContext from "views/Profile/context"

import ProfileTabs from "./ProfileTabs"

const useStyles = createUseStyles(theme => ({
  cover: ({
    coverImage,
  }: {
    coverImage: Profile["coverImage"]
    isXs: boolean
  }) => ({
    width: "100%",
    backgroundImage: `url(${coverImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
  }),

  backdrop: {
    width: "100%",
    height: "100%",
    opacity: "0.9",
    background: theme.colors.onSurface[800],
    position: "absolute",
    top: "0",
  },
  content: {
    width: "100%",
    height: "auto",
    position: "relative",
    color: theme.colors.common.white[500],
  },

  profileTabs: {
    width: "100%",
  },

  schoolLogo: ({ isXs }: { isXs: boolean }) => ({
    background: theme.colors.common.white[500],
    borderRadius: theme.spacing(1),
    width: isXs ? "75px" : "140px",
    height: isXs ? "75px" : "140px",
    "& > img": {
      height: "inherit",
      width: "inherit",
      objectFit: "cover",
      borderRadius: theme.spacing(1),
    },
  }),

  schoolBoard: {
    background: "rgba(255, 255, 255, 0.16)",
    backdropFilter: "blur(5px)",
    width: "min-content",
    borderRadius: "17.5px",
    whiteSpace: "nowrap",
  },

  fullWidth: {
    width: "100%",
  },

  websiteLink: {
    "& > a": {
      color: theme.colors.common.white[500],
    },
  },
}))

function Cover({
  isXs,
  canEditProfile,
}: {
  isXs: boolean
  canEditProfile: boolean
}) {
  const { profile, isPublic } = useContext(ProfileContext)

  const classes = useStyles({
    coverImage: profile.coverImage,
    isXs,
  })

  const navigate = useNavigate()

  function renderAddressLine2() {
    const data = [
      [profile.city || "", profile.pincode ? profile.pincode.toString() : ""],
      profile.state?.name || "",
      profile.country?.name || "",
    ]

    const isNotEmpty = (v: string | string[]) => v !== ""

    return data
      .map(v => (Array.isArray(v) ? v.filter(isNotEmpty).join(" ") : v))
      .filter(isNotEmpty)
      .join(", ")
  }

  return (
    <div className={classes.cover}>
      <div className={classes.backdrop} />
      <div className={classes.content}>
        <Container>
          <div className="flex items-start gap-3.5 pt-2.75 sm:pt-8">
            <div
              className={clsx(
                classes.schoolLogo,
                "flex items-center justify-center"
              )}
            >
              <img
                alt="schoolLogo"
                src={profile.logo || "/assets/schoolLogo.svg"}
              />
            </div>

            <div
              className={clsx(
                "flex flex-col justify-center gap-1",
                classes.fullWidth
              )}
            >
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-1">
                  <Typography variant="title2">
                    {profile.name}
                    {profile.branch && ` (${profile.branch})`}
                  </Typography>
                </div>

                {!isPublic && canEditProfile && (
                  <div className="hidden sm:block">
                    <Button
                      color="white"
                      startAdornment={<Edit />}
                      variant="text"
                      onClick={() =>
                        navigate(
                          routes.school.editProfile.replace(
                            ":schoolId",
                            profile.id.toString()
                          )
                        )
                      }
                    >
                      Edit Profile
                    </Button>
                  </div>
                )}

                {!isPublic && canEditProfile && (
                  <div className="sm:hidden">
                    <IconButton
                      color="white"
                      variant="plain"
                      onClick={() => navigate(routes.school.editProfile)}
                    >
                      <Edit />
                    </IconButton>
                  </div>
                )}
              </div>

              <Typography
                color="surface.100"
                variant={isXs ? "smallBody" : "subtitle2"}
              >
                {profile.address}
                <Typography
                  className="mt-0.25 sm:mt-0.5"
                  variant={isXs ? "smallBody" : "subtitle2"}
                >
                  {renderAddressLine2()}
                </Typography>
              </Typography>

              {profile.curriculumBoard && (
                <div className="flex items-center gap-0.5">
                  {profile.curriculumBoard.map(item => (
                    <Typography
                      className={clsx(
                        "mr-1.5 px-1.5 py-0.5 sm:mt-1.75",
                        classes.schoolBoard
                      )}
                      key={item.uuid}
                      variant="smallBody"
                    >
                      {item.name}
                    </Typography>
                  ))}
                </div>
              )}
            </div>
          </div>
          {profile.schoolContactPerson && (
            <div>
              <div className="mt-4 flex flex-col gap-0.5">
                <Typography variant="title4" color="secondary.50">
                  Point of Contact Details (POC)
                </Typography>
                <div className="mt-1 flex gap-1">
                  <div className="flex flex-col">
                    <Typography color="secondary.50" variant="strong">
                      {profile.schoolContactPerson.name}
                    </Typography>
                    <Typography variant="smallBody" color="secondary.50">
                      {profile.schoolContactPerson.email}
                    </Typography>
                  </div>
                  <div className="flex items-end">
                    <Typography variant="smallBody" color="secondary.50">
                      {`• +${profile.schoolContactPerson.phoneNumber.countryCode} ${profile.schoolContactPerson.phoneNumber.number}`}
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="my-2 flex flex-col gap-2 sm:mb-2 sm:mt-6 sm:flex-row sm:flex-wrap sm:gap-6">
            <div className="flex flex-col gap-0.5">
              <Typography variant="smallBody">Website</Typography>
              <Typography
                className={classes.websiteLink}
                color="common.white.500"
                variant="strong"
              >
                {profile.website ? (
                  <a href={profile.website} rel="noreferrer" target="_blank">
                    {profile.website}
                  </a>
                ) : (
                  "-"
                )}
              </Typography>
            </div>
            <div className="flex flex-col gap-0.5">
              <Typography variant="smallBody">E-Mail</Typography>
              <Typography variant="strong">{profile.email || "-"}</Typography>
            </div>
            <div className="flex flex-col gap-0.5">
              <Typography variant="smallBody">Phone</Typography>
              <Typography variant="strong">
                {profile.phoneNumber
                  ? `+${profile.phoneNumber.countryCode} ${profile.phoneNumber.number}`
                  : "-"}
              </Typography>
            </div>
            <div className="flex flex-col gap-0.5">
              <Typography variant="smallBody">Established</Typography>
              <Typography variant="strong">
                {profile.dateEstablished
                  ? new Date(profile.dateEstablished).getFullYear()
                  : "-"}
              </Typography>
            </div>
          </div>

          {!isXs && <ProfileTabs />}
        </Container>
        {isXs && <ProfileTabs />}
      </div>
    </div>
  )
}

export default Cover
