import {
  Button,
  CircularProgress,
  Container,
  Divider,
  Select,
  TextField,
  theme,
  Typography,
} from "@suraasa/placebo-ui"
import { useInfiniteQuery } from "@tanstack/react-query"
import api from "api"
import { ExploreTeacher, Job } from "api/resources/jobs/types"
import clsx from "clsx"
import Filters, { getDefaultFilters } from "components/explore/Filters"
import InvitedTeachersBanner from "components/explore/InvitedTeachersBanner"
import InviteToJobDialog from "components/explore/InviteToJobDialog"
import UserDetailsCard from "components/home/UserDetailsCard"
import { OpportunitiesDialog } from "components/jobs/ApplicantsTab"
import NoDataCard from "components/jobs/NoDataCard"
import BackButton from "components/shared/BackButton"
import Navbar from "components/shared/Navbar"
import ReactHelmet from "components/shared/ReactHelmet"
import { Check, InfoCircle, Plus, Search } from "iconoir-react"
import debounce from "lodash/debounce"
import metadata from "metadata.json"
import React, { useEffect, useMemo, useState } from "react"
import { createUseStyles } from "react-jss"
import {
  createSearchParams,
  Link,
  useNavigate,
  useSearchParams,
} from "react-router-dom"
import { getAuthInfo } from "utils/auth"
import { getPlatformURL, getTeacherTimelineRoute } from "utils/helpers"
import usePaginationScroll from "utils/hooks/usePaginationScroll"
import useResources from "utils/hooks/useResources"
import { routes } from "utils/routes"
import toast from "utils/toast"
import { useSchoolActions } from "views/auth/useSchoolActions"
import { Actions } from "views/auth/useUserActions"

const useStyles = createUseStyles(({ colors }) => ({
  filter: {
    width: "306px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  hiringFor: {
    background: colors.common.white[500],
    border: `1px solid ${colors.surface[200]}`,
    borderRadius: "4px",
  },
  topMessageBar: {
    background: theme.colors.interactive[50],
    position: "relative",
  },
  closeTopBarButton: {
    position: "absolute",
    right: 24,
    top: "25%",
  },
  viewMore: {
    width: "100%",
    "& > span": {
      justifyContent: "flex-end",
    },
  },

  dropDown: {
    [theme.breakpoints.down("xs")]: {
      width: "240px",
    },
  },
}))

const createNewJobSelectOptionId = -1
const Explore = () => {
  const classes = useStyles()
  const isLoggedIn = Boolean(getAuthInfo())

  const { subjects, curricula } = useResources(["subjects", "curricula"])

  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()

  const jobPosition = searchParams.get("jobPosition")
  const jobId = searchParams.get("jobId")
  const schoolId = searchParams.get("schoolId") || ""

  const { hasSchoolAction } = useSchoolActions({ schoolIds: [schoolId] })

  const canViewCareerSupport = hasSchoolAction(
    Actions.viewCareerSupport,
    schoolId
  )
  const canListUsers = hasSchoolAction(Actions.listUsers, schoolId)
  const canManageHiringProcess = hasSchoolAction(
    Actions.manageHiringProcess,
    schoolId
  )

  const [showMobileFilters, setShowMobileFilters] = useState(false)

  const [inviteDialogOpen, setInviteDialogOpen] = useState(false)
  const [teacher, setTeacher] = useState<ExploreTeacher>()
  const [jobs, setJobs] = useState<Pick<Job, "id" | "position">[]>([])
  const [selectedJob, setSelectedJob] = useState<Pick<
    Job,
    "id" | "position"
  > | null>(
    jobPosition && jobId
      ? {
          id: Number(jobId),
          position: jobPosition,
        }
      : null
  )
  const [invitedTeacherNames, setInvitedTeacherNames] = useState<string[]>([])
  const [search, setSearch] = useState(searchParams.get("search") || "")

  const debouncedSearch = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
    const params = searchParams

    if (e.target.value.length > 2) {
      params.append("search", e.target.value)
    } else {
      params.delete("search")
    }

    setSearchParams(params, { replace: true })
  }, 750)

  const {
    isFetching: loading,
    hasNextPage,
    fetchNextPage,
    data,
    refetch,
  } = useInfiniteQuery({
    queryKey: ["explore", Object.fromEntries(searchParams.entries())],
    queryFn: x =>
      api.teacher.explore.list({
        params: {
          fields: [
            "job_applicant",
            "job_interested_user",
            "job_invited_user",
            "can_view_contact_details",
            "interviewing_schools",
            "is_externally_placed",
            "opportunities",
            "has_active_career_support",
          ],
          ...Object.fromEntries(searchParams.entries()),
          page: x.pageParam,
        },
        headers: {
          "School-Id": schoolId,
        },
      }),
    initialPageParam: 1,
    getNextPageParam: lastPage => {
      return lastPage.nextPage ?? undefined
    },
  })
  const [showOpportunities, setShowOpportunities] =
    useState<ExploreTeacher | null>(null)
  const teachers = data?.pages.map(page => page.data).flat() || []

  /**
   * This loading is used when teachers
   * have to invited for a specific job
   */
  const [sendInviteLoading, setSendInviteLoading] = useState<
    ExploreTeacher["user"]["uuid"] | null
  >(null)

  const otherFilters = React.useMemo(
    () => ({
      jobPosition: jobPosition ?? "",
      jobId: jobId ?? "",
      search,
      schoolId,
    }),
    [jobPosition, jobId, search, schoolId]
  )

  const filterChoices = useMemo(
    () => [
      ...getDefaultFilters().map(filter => {
        if (filter.type === "multi-select") {
          if (filter.id === "subjects[]") {
            filter.options = subjects.map(({ name, uuid }) => ({
              label: name,
              value: uuid,
            }))
          }
        }

        if (filter.type === "multi") {
          if (filter.id === "curriculum[]") {
            filter.options = curricula.map(({ name, uuid }) => ({
              label: name,
              value: uuid,
            }))
          }
        }
        return filter
      }),
    ],
    [subjects, curricula]
  )

  const sendInvite = async (teacherId: string) => {
    setSendInviteLoading(teacherId)
    const res = await api.jobs.invitedUser.create({
      data: {
        jobId,
        userId: teacherId,
      },
      headers: {
        "School-Id": schoolId,
      },
    })

    if (res.isSuccessful) {
      const invitedTeacher = teachers.find(item => item.user.uuid === teacherId)
        ?.user.fullName

      if (invitedTeacher)
        setInvitedTeacherNames(prevState => [...prevState, invitedTeacher])

      toast.success("Candidate Invited!")
      refetch()
    } else {
      toast.error(res.errors.message)
    }
    setSendInviteLoading(null)
  }

  const getAction = (item: ExploreTeacher) => {
    if (item.jobApplicant && schoolId) {
      return (
        <Button
          className="-ml-0.75"
          variant="text"
          component={Link}
          to={getTeacherTimelineRoute({
            jobId: jobId,
            jobApplicantId: item.jobApplicant.id.toString(),
            username: item.user.username,
            schoolId: schoolId,
          })}
        >
          View Timeline
        </Button>
      )
    }

    if (item.jobInterestedUser) {
      return (
        <div className="flex items-center gap-0.5">
          <Check color={theme.colors.success[500]} />
          <Typography color="success.500">Applied</Typography>
        </div>
      )
    }

    if (item.jobInvitedUser) {
      return (
        <div className="flex items-center gap-0.5">
          <Check color={theme.colors.success[500]} />
          <Typography color="success.500">Invited</Typography>
        </div>
      )
    }

    if (canManageHiringProcess)
      return (
        <Button
          loading={sendInviteLoading === item.user.uuid}
          startAdornment={<Plus />}
          variant="text"
          onClick={() => {
            if (!isLoggedIn)
              window.location.href = getPlatformURL(
                "sso",
                `/?platform=School&redirect-url=${encodeURIComponent(
                  window.location.href
                )}`
              )

            if (jobId) sendInvite(item.user.uuid)
            else {
              setTeacher(item)
              setInviteDialogOpen(true)
            }
          }}
        >
          Invite
        </Button>
      )

    return null
  }

  useEffect(() => {
    const listJobs = async () => {
      const res = await api.jobs.list({
        params: {
          page: "all",
        },
        headers: {
          "School-Id": schoolId,
        },
      })

      if (res.isSuccessful) {
        setJobs([
          ...res.data.data,
          {
            id: createNewJobSelectOptionId,
            position: "Create New Job",
          },
        ])
      }
    }
    if (schoolId && canListUsers) {
      listJobs()
    }
  }, [schoolId, canListUsers])

  const { ref } = usePaginationScroll({
    loading: loading,
    hasNextPage: hasNextPage,
    actionFunc: fetchNextPage,
  })

  return (
    <>
      <ReactHelmet data={metadata.explore} />
      <Navbar
        gutterBottom={!(selectedJob && invitedTeacherNames.length > 0)}
        hideBackButton
      />
      {selectedJob && (
        <InvitedTeachersBanner
          job={selectedJob}
          teachers={invitedTeacherNames}
          onCancel={() => setInvitedTeacherNames([])}
          schoolId={schoolId || ""}
        />
      )}

      <Container className="mb-6">
        <BackButton className="mb-2" />
        <div>
          <div className="mb-1.5 flex flex-wrap items-center justify-between gap-2">
            <Typography component="h1" variant="title2">
              Teachers
            </Typography>

            {isLoggedIn && (
              <div className="flex grow items-center justify-between gap-1 sm:grow-0 sm:justify-start">
                <Typography
                  color="onSurface.500"
                  style={{ whiteSpace: "nowrap" }}
                  variant="smallBody"
                >
                  Hiring for
                </Typography>

                <Select
                  className={classes.dropDown}
                  getOptionLabel={option => option.position}
                  getOptionValue={option => option.id.toString()}
                  name="jobId"
                  options={jobs}
                  placeholder="Select Job"
                  value={selectedJob}
                  isClearable
                  isSearchable
                  mountOnBody
                  onChange={v => {
                    setInvitedTeacherNames([])
                    setSelectedJob(v)
                    if (v) {
                      if (v.id === createNewJobSelectOptionId) {
                        navigate(
                          routes.school.job.create.replace(
                            ":schoolId",
                            schoolId
                          )
                        )
                        return
                      }
                      searchParams.set("jobId", v.id.toString())
                      searchParams.set("jobPosition", v.position)
                      setSearchParams(searchParams, { replace: true })
                    } else {
                      const newSearchParams = createSearchParams()
                      newSearchParams.append("schoolId", schoolId)
                      setSearchParams(newSearchParams, { replace: true })
                    }
                  }}
                />
              </div>
            )}
          </div>
          <div className="mb-1 flex grow justify-end sm:hidden">
            <Button
              variant="text"
              onClick={() => setShowMobileFilters(prevState => !prevState)}
            >
              Filters
            </Button>
          </div>

          <Divider className="mb-3" />
        </div>
        <div className="flex flex-col gap-3 sm:flex-row">
          <div
            className={clsx({
              "hidden sm:block": !showMobileFilters,
            })}
          >
            <Filters
              className={classes.filter}
              disabled={loading}
              filters={filterChoices}
              otherFilters={otherFilters}
            />
          </div>

          <div className="flex grow flex-col gap-2">
            <TextField
              placeholder="Search teacher by name or email"
              className="mt-1.5"
              rounded
              fullWidth
              startIcon={<Search />}
              onChange={debouncedSearch}
              defaultValue={search}
              helperText={
                search.length > 0 && search.length < 3
                  ? "Minimum 3 characters are required"
                  : ""
              }
            />
            {!loading && teachers.length === 0 ? (
              <NoDataCard message="Looks like nothing matches this filter criteria. Try something else." />
            ) : (
              teachers.map((item, i) => (
                <div ref={ref} key={i}>
                  <UserDetailsCard
                    action={getAction(item)}
                    applications={item.interviewingSchools}
                    canViewContactDetails={
                      canListUsers && item.canViewContactDetails
                    }
                    jobId={jobId}
                    key={item.user.uuid}
                    user={item.user}
                    verifiedSkillEvidences={item.verifiedSkillEvidences}
                  />
                  {canViewCareerSupport ? (
                    <div
                      className={clsx(
                        "flex items-center justify-between rounded-b-lg px-2 py-1",
                        item.hasActiveCareerSupport
                          ? "bg-onSurface-900"
                          : "bg-onSurface-200",
                        item.hasActiveCareerSupport
                          ? "text-white"
                          : "text-onSurface-800"
                      )}
                    >
                      <Typography variant="smallBody">
                        {item.hasActiveCareerSupport
                          ? `Active Career Support${
                              item.isExternallyPlaced
                                ? " (Placed Externally)"
                                : ""
                            }`
                          : `No Career Support${
                              item.isExternallyPlaced
                                ? " (Placed Externally)"
                                : ""
                            }`}
                      </Typography>
                      {item.opportunities && item.opportunities.length > 0 && (
                        <InfoCircle
                          height={20}
                          className="cursor-pointer"
                          onClick={() => setShowOpportunities(item)}
                        />
                      )}
                    </div>
                  ) : null}
                </div>
              ))
            )}
            {loading && (
              <div className="flex items-center justify-center">
                <CircularProgress />
              </div>
            )}
          </div>
        </div>
        {showOpportunities?.opportunities && (
          <OpportunitiesDialog
            opportunities={showOpportunities.opportunities}
            onClose={() => setShowOpportunities(null)}
          />
        )}
        {teacher && schoolId && (
          <InviteToJobDialog
            open={inviteDialogOpen}
            teacherId={teacher.user.uuid}
            onRequestClose={() => setInviteDialogOpen(false)}
            schoolId={schoolId}
          />
        )}
      </Container>
    </>
  )
}

export default Explore
