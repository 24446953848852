import { validateUrls } from "api/types"
import { getServiceURL } from "api/utils"
import { ServicePrefix } from "utils/constants"

const getSolisURL = getServiceURL(ServicePrefix.solis)

export const urls = validateUrls({
  generatePlatformToken: () => getSolisURL("/v1/auth/user-tokens/"),
  user: () => getSolisURL("/v1/users/"),
  refreshToken: () => getSolisURL(`/v1/auth/refresh-token/`),
})
