import {
  Accordion,
  AccordionDetails,
  AccordionHeader,
  AccordionSummary,
  Button,
  Divider,
  Typography,
} from "@suraasa/placebo-ui"
import clsx from "clsx"
import Section from "components/teacher/profile/Section"
import SectionHeading from "components/teacher/profile/Section/SectionHeading"
import SectionTitle from "components/teacher/profile/Section/SectionTitle"
import { format } from "date-fns"
import React from "react"
import { createUseStyles } from "react-jss"
import { BaseComponentProps } from "types"
import { pluralize } from "utils/helpers"
import ProfileContext from "views/teacher/profileContext"

const useStyles = createUseStyles(theme => ({
  content: {
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),

    [theme.breakpoints.only("xs")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    "&:not(:last-child)": {
      borderBottom: `1px solid ${theme.colors.onSurface[200]}`,
    },
  },
  accordionSummary: {
    height: "max-content",
    // paddingRight: theme.spacing(3),
  },
  accordionDetails: {
    position: "relative",
    marginTop: theme.spacing(1),
  },

  headerPaddingReset: {
    paddingLeft: 0,
    paddingRight: theme.spacing(0.5),
  },
}))

const Achievements = ({ className }: BaseComponentProps) => {
  const classes = useStyles()

  const {
    achievements: {
      awards: { data: awards },
      projects: { data: projects },
      publications: { data: publications },
      testScores: { data: testScores },
    },
  } = React.useContext(ProfileContext)

  return (
    <Section className={className}>
      <SectionHeading
        heading={<SectionTitle title="Achievements" />}
        xPadding={2}
      />

      {publications.length > 0 && (
        <div className={classes.content}>
          <Accordion className="py-2">
            <AccordionHeader className={classes.headerPaddingReset}>
              <SectionTitle
                itemCount={publications.length}
                title={pluralize("Publication", publications.length, {
                  skipCount: true,
                })}
                typographyProps={{ color: "onSurface.700" }}
              />
            </AccordionHeader>

            <AccordionSummary className={classes.accordionSummary}>
              {publications.map((publication, index) => (
                <Typography display="inline" key={index} variant="smallBody">
                  {publication.title}
                  {index < publications.length - 1 && (
                    <span className="mx-0.5">•</span>
                  )}
                </Typography>
              ))}
            </AccordionSummary>

            <AccordionDetails
              className={clsx("pl-2 pr-0", classes.accordionDetails)}
            >
              {publications.map((publication, index) => (
                <React.Fragment key={index}>
                  <div className="pr-3">
                    <Typography className="mb-0.5" variant="strongSmallBody">
                      {publication.title}
                    </Typography>

                    <Typography
                      className="mb-0.25"
                      color="onSurface.600"
                      variant="smallBody"
                    >
                      {publication.publisher}
                    </Typography>

                    {publication.publishedOn && (
                      <Typography
                        className="mb-1"
                        color="onSurface.600"
                        variant="smallBody"
                      >
                        {format(new Date(publication.publishedOn), "MMMM yyyy")}
                      </Typography>
                    )}

                    <Typography
                      className="mb-1"
                      style={{
                        whiteSpace: "pre-wrap",
                      }}
                      variant="smallBody"
                    >
                      {publication.description}
                    </Typography>

                    {publication.url && (
                      <Button
                        component="a"
                        href={publication.url}
                        nudge="left"
                        target="_blank"
                        variant="link"
                      >
                        View Publication
                      </Button>
                    )}
                  </div>

                  {index < publications.length - 1 && (
                    <div className="my-2">
                      <Divider color="onSurface.200" weight="light" />
                    </div>
                  )}
                </React.Fragment>
              ))}
            </AccordionDetails>
          </Accordion>
        </div>
      )}

      {awards.length > 0 && (
        <div className={classes.content}>
          <Accordion className="py-2">
            <AccordionHeader className={classes.headerPaddingReset}>
              <SectionTitle
                itemCount={awards.length}
                title={`${pluralize("Award", awards.length, {
                  skipCount: true,
                })} and ${pluralize("Honor", awards.length, {
                  skipCount: true,
                })}`}
                typographyProps={{ color: "onSurface.700" }}
              />
            </AccordionHeader>

            <AccordionSummary className={classes.accordionSummary}>
              {awards.map((award, index) => (
                <Typography
                  display="inline"
                  key={award.id || index}
                  variant="smallBody"
                >
                  {award.title}
                  {index < awards.length - 1 && (
                    <span className="mx-0.5">•</span>
                  )}
                </Typography>
              ))}
            </AccordionSummary>

            <AccordionDetails
              className={clsx("pl-2 pr-0", classes.accordionDetails)}
            >
              {awards.map((award, index) => (
                <React.Fragment key={award.id || index}>
                  <div className="pr-3">
                    <Typography variant="strongSmallBody">
                      {award.title}
                    </Typography>

                    <Typography
                      className="mb-0.25"
                      color="onSurface.600"
                      variant="smallBody"
                    >
                      {award.issuer}
                    </Typography>

                    {award.issuedOn && (
                      <Typography
                        className="mb-1"
                        color="onSurface.600"
                        variant="smallBody"
                      >
                        {format(new Date(award.issuedOn), "MMMM yyyy")}
                      </Typography>
                    )}

                    <Typography
                      style={{
                        whiteSpace: "pre-wrap",
                      }}
                      variant="smallBody"
                    >
                      {award.description}
                    </Typography>
                  </div>

                  {index < awards.length - 1 && (
                    <div className="my-2">
                      <Divider color="onSurface.200" weight="light" />
                    </div>
                  )}
                </React.Fragment>
              ))}
            </AccordionDetails>
          </Accordion>
        </div>
      )}

      {testScores.length > 0 && (
        <div className={classes.content}>
          <Accordion className="py-2">
            <AccordionHeader className={classes.headerPaddingReset}>
              <SectionTitle
                itemCount={testScores.length}
                title={pluralize("Test Score", testScores.length, {
                  skipCount: true,
                })}
                typographyProps={{ color: "onSurface.700" }}
              />
            </AccordionHeader>

            <AccordionSummary className={classes.accordionSummary}>
              {testScores.map((score, index) => (
                <Typography display="inline" key={index} variant="smallBody">
                  {score.name}
                  {index < testScores.length - 1 && (
                    <span className="mx-0.5">•</span>
                  )}
                </Typography>
              ))}
            </AccordionSummary>

            <AccordionDetails
              className={clsx("pl-2 pr-0", classes.accordionDetails)}
            >
              {testScores.map((score, index) => (
                <React.Fragment key={index}>
                  <div className="pr-3">
                    <Typography variant="strongSmallBody">
                      {score.name}
                    </Typography>

                    {score.score && (
                      <Typography
                        className="mb-0.25"
                        color="onSurface.600"
                        variant="smallBody"
                      >
                        Score: {score.score}
                      </Typography>
                    )}

                    {score.testDate && (
                      <Typography
                        className="mb-1"
                        color="onSurface.600"
                        variant="smallBody"
                      >
                        {format(new Date(score.testDate), "MMMM d, yyyy")}
                      </Typography>
                    )}

                    <Typography
                      className="mb-1"
                      style={{
                        whiteSpace: "pre-wrap",
                      }}
                      variant="smallBody"
                    >
                      {score.description}
                    </Typography>

                    {(score.evidenceUrl || score.evidenceDocument) && (
                      <Button
                        component="a"
                        href={
                          (score.evidenceUrl as string) ??
                          (score.evidenceDocument as string)
                        }
                        nudge="left"
                        target="_blank"
                        variant="link"
                      >
                        View Result
                      </Button>
                    )}
                  </div>

                  {index < testScores.length - 1 && (
                    <div className="my-2">
                      <Divider color="onSurface.200" weight="light" />
                    </div>
                  )}
                </React.Fragment>
              ))}
            </AccordionDetails>
          </Accordion>
        </div>
      )}

      {projects.length > 0 && (
        <div className={classes.content}>
          <Accordion className="py-2">
            <AccordionHeader className={classes.headerPaddingReset}>
              <SectionTitle
                itemCount={projects.length}
                title={pluralize("Project", projects.length, {
                  skipCount: true,
                })}
                typographyProps={{ color: "onSurface.700" }}
              />
            </AccordionHeader>

            <AccordionSummary className={classes.accordionSummary}>
              {projects.map((project, index) => (
                <Typography display="inline" key={index} variant="smallBody">
                  {project.title}
                  {index < projects.length - 1 && (
                    <span className="mx-0.5">•</span>
                  )}
                </Typography>
              ))}
            </AccordionSummary>

            <AccordionDetails
              className={clsx("pl-2 pr-0", classes.accordionDetails)}
            >
              {projects.map((project, index) => (
                <React.Fragment key={index}>
                  <div className="pr-3">
                    <Typography variant="strongSmallBody">
                      {project.title}
                    </Typography>

                    {project.startDate && (
                      <Typography
                        className="mb-1"
                        color="onSurface.600"
                        variant="smallBody"
                      >
                        {format(new Date(project.startDate), "MMMM yyyy")} -{" "}
                        {project.endDate
                          ? format(new Date(project.endDate), "MMMM yyyy")
                          : "Ongoing"}
                      </Typography>
                    )}

                    <Typography
                      className="mb-1"
                      style={{
                        whiteSpace: "pre-wrap",
                      }}
                      variant="smallBody"
                    >
                      {project.description}
                    </Typography>

                    {project.url && (
                      <Button
                        component="a"
                        href={project.url}
                        nudge="left"
                        target="_blank"
                        variant="link"
                      >
                        View Project
                      </Button>
                    )}
                  </div>

                  {index < projects.length - 1 && (
                    <div className="my-2">
                      <Divider color="onSurface.200" weight="light" />
                    </div>
                  )}
                </React.Fragment>
              ))}
            </AccordionDetails>
          </Accordion>
        </div>
      )}

      {publications.length === 0 &&
        awards.length === 0 &&
        testScores.length === 0 &&
        projects.length === 0 && (
          <Typography
            className="px-3 py-2"
            color="onSurface.400"
            variant="smallBody"
          >
            Looks like there's nothing here.
          </Typography>
        )}
    </Section>
  )
}

export default Achievements
