import {
  Avatar,
  Button,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Tag,
  Typography,
} from "@suraasa/placebo-ui"
import { useInfiniteQuery } from "@tanstack/react-query"
import api from "api"
import {
  Job,
  NotHiredRejectedBy,
  NotHiredUserStatus,
} from "api/resources/jobs/types"
import VerifiedBadge from "assets/home/verifiedBadge.svg"
import AddSalaryDialog from "components/teacher/Dialogs/AddSalaryDialog"
import { MoreVert } from "iconoir-react"
import { useState } from "react"
import { Link } from "react-router-dom"
import { getTeacherProfileRoute } from "utils/helpers"
import usePaginationScroll from "utils/hooks/usePaginationScroll"

const getJobApplicationStatus = (item: NotHiredUserStatus) => {
  switch (item) {
    case NotHiredUserStatus.APPLICATION_CANCELLED:
      return <Tag color="critical" label="Application Cancelled" />
    case NotHiredUserStatus.APPLICATION_WITHDRAWN:
      return <Tag color="critical" label="Application Withdrawn" />
    case NotHiredUserStatus.JOB_OFFER_REJECTED:
      return <Tag color="critical" label="Offer Rejected" />
    case NotHiredUserStatus.APPLICANT_REJECTED:
      return <Tag color="critical" label="Applicant Rejected" />
    case NotHiredUserStatus.APPLICATION_REJECTED:
      return <Tag color="critical" label="Application Rejected" />
    default:
      break
  }
}

const getRejectedBy = (item: NotHiredRejectedBy) => {
  switch (item) {
    case NotHiredRejectedBy.SYSTEM:
      return <Typography>System</Typography>
    case NotHiredRejectedBy.PARTNER:
      return <Typography>Partner</Typography>
    case NotHiredRejectedBy.TEACHER:
      return <Typography>Teacher</Typography>
    case NotHiredRejectedBy.SCHOOL:
      return <Typography>School</Typography>
    case NotHiredRejectedBy.RECRUITER:
      return <Typography>Recruiter</Typography>

    default:
      throw new Error("Invalid rejected by")
  }
}
type Props = {
  job: Pick<Job, "position" | "id" | "dateReopened" | "status">
  schoolId: string | undefined
  canManageHiringProcess: boolean
}

const NotHiredTab = ({ job, schoolId, canManageHiringProcess }: Props) => {
  const [openAddSalaryDialog, setOpenAddSalaryDialog] = useState<{
    id: number
  } | null>(null)

  const {
    isFetching: loading,
    hasNextPage,
    fetchNextPage,
    data,
    refetch,
  } = useInfiniteQuery({
    queryKey: ["notHired", job.id, schoolId],
    queryFn: x =>
      api.jobs.applicants.listNotHired({
        urlParams: {
          jobId: job.id,
        },
        params: {
          page: x.pageParam,
        },
        headers: {
          "School-Id": schoolId,
        },
      }),
    initialPageParam: 1,
    getNextPageParam: lastPage => {
      return lastPage.isSuccessful ? lastPage.data.nextPage : undefined
    },
  })
  const applicants =
    data?.pages.map(page => (page.isSuccessful ? page.data.data : [])).flat() ||
    []

  const { ref } = usePaginationScroll({
    loading: loading,
    hasNextPage: hasNextPage,
    actionFunc: fetchNextPage,
  })

  return (
    <>
      {loading && (
        <div className="mt-5 flex justify-center">
          <CircularProgress />
        </div>
      )}
      <div className="my-3">
        <div className="min-w-[800px] rounded border border-surface-200 bg-white">
          <div className={"grid grid-cols-[70px_1.75fr_1.5fr_1.5fr_50px] py-2"}>
            <div />
            <Typography variant="strongSmallBody">Name</Typography>

            <div className="flex items-center gap-0.5">
              <Typography variant="strongSmallBody">Status</Typography>
            </div>
            <div className="flex items-center gap-0.5">
              <Typography variant="strongSmallBody">Action By</Typography>
            </div>
          </div>
          {applicants.length > 0 ? (
            applicants.map((item, i) => (
              <div
                className={
                  "relative grid grid-cols-[70px_1.75fr_1.5fr_1.5fr_50px] border-t border-surface-200 py-2"
                }
                key={i}
                ref={ref}
              >
                <div className="pl-3">
                  <Avatar
                    name={item.user.fullName}
                    size="xs"
                    src={item.user.profile.picture ?? undefined}
                  />
                </div>
                <div>
                  <div className="flex items-center gap-0.5">
                    <Button
                      className="-ml-0.75"
                      component={Link}
                      to={getTeacherProfileRoute({
                        jobId: job.id,
                        username: item.user.username,
                        schoolId,
                      })}
                      variant="link"
                    >
                      {item.user.fullName}
                    </Button>
                    {item.user.profile.isVerified && (
                      <img src={VerifiedBadge} alt="" />
                    )}
                  </div>

                  <div className="flex items-center">
                    <Typography variant="smallBody" color="onSurface.500">
                      {item.user.email}
                    </Typography>
                  </div>

                  {item.user.profile.phoneNumber && (
                    <Typography variant="smallBody" color="onSurface.500">
                      +{item.user.profile.phoneNumber.code}
                      {item.user.profile.phoneNumber.number}
                    </Typography>
                  )}
                </div>
                <div className="flex items-center">
                  {getJobApplicationStatus(item.status)}
                </div>
                {item.rejectedBy && (
                  <div className="flex items-center">
                    {getRejectedBy(item.rejectedBy)}
                  </div>
                )}
                {!canManageHiringProcess
                  ? null
                  : item.jobApplicant &&
                    item.jobApplicant.id &&
                    item.status === NotHiredUserStatus.JOB_OFFER_REJECTED &&
                    item.placementTracker &&
                    item.placementTracker.salary === null && (
                      <div className="mr-1.25 flex items-center">
                        <Menu
                          menuButton={
                            <IconButton>
                              <MoreVert />
                            </IconButton>
                          }
                          portal
                        >
                          <MenuItem
                            onClick={() =>
                              item.jobApplicant && item.jobApplicant.id
                                ? setOpenAddSalaryDialog({
                                    id: item.jobApplicant.id,
                                  })
                                : {}
                            }
                          >
                            Add Salary
                          </MenuItem>
                        </Menu>
                      </div>
                    )}
              </div>
            ))
          ) : (
            <div>
              <Typography
                className={
                  "rounded-lg border border-onSurface-200 bg-[#f8fafc] py-3 pl-9"
                }
                color="onSurface.500"
                variant="smallBody"
              >
                No data available
              </Typography>
            </div>
          )}
        </div>
      </div>
      {openAddSalaryDialog && schoolId && (
        <AddSalaryDialog
          toggle={() => setOpenAddSalaryDialog(null)}
          open={Boolean(openAddSalaryDialog)}
          data={{
            id: openAddSalaryDialog.id,
            schoolId,
            afterSubmit: () => {
              setOpenAddSalaryDialog(null)
              refetch()
            },
          }}
        />
      )}
    </>
  )
}

export default NotHiredTab
