import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogProps,
  DialogTitle,
  Select,
  TextField,
  Typography,
} from "@suraasa/placebo-ui"
import api from "api"
import { Currency } from "api/resources/global/types"
import { format } from "date-fns"
import { useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { ToggleValue } from "utils/hooks/useToggle"
import toast from "utils/toast"

export type UpdateOfferStatusDialogData = {
  id: number
  afterSubmit?: () => void
  schoolId: string
}

type Props = {
  toggle: ToggleValue
  data: UpdateOfferStatusDialogData
} & Pick<DialogProps, "open">

type FormData = {
  salary: string
  currencyId: string
  dateOfHiring: string
}

const AddSalaryDialog = ({
  open,
  data: { id, afterSubmit, schoolId },
  toggle,
}: Props) => {
  const {
    register,
    handleSubmit: submitSalary,
    control,
    formState: { errors },
  } = useForm<FormData>()
  const [currencyData, setCurrencyData] = useState<Currency[]>([])

  useEffect(() => {
    const getCurrencyData = async () => {
      const res = await api.global.listCurrencies({ params: { page: -1 } })
      if (res.isSuccessful) {
        setCurrencyData(res.data)
      }
    }
    getCurrencyData()
  }, [])

  const onSubmitSalary = submitSalary(async data => {
    const res = await api.jobs.jobApplicant.updatePlacementTracker({
      urlParams: {
        jobApplicantId: id,
      },
      data: {
        salary: data.salary,
        currency_id: data.currencyId,
        ...(data.dateOfHiring
          ? {
              date_of_hiring: format(new Date(data.dateOfHiring), "yyyy-MM-dd"),
            }
          : {}),
      },
      headers: {
        "School-Id": schoolId,
      },
    })
    if (res.isSuccessful) {
      toast.success("Salary updated")
      toggle()
      if (afterSubmit) afterSubmit()
    }
  })

  return (
    <Dialog open={open} width="md" onRequestClose={() => toggle()}>
      <DialogTitle>Add Salary</DialogTitle>
      <DialogContent>
        <div className="space-y-2">
          <Typography variant="smallBody">
            Please enter the salary offered to the learner
          </Typography>
          <div className="flex flex-col gap-2">
            <div className="flex items-end gap-0.5">
              <Controller
                control={control}
                name="currencyId"
                render={({ field: { onChange, onBlur, value } }) => (
                  <Select
                    error={Boolean(errors.currencyId)}
                    getOptionLabel={option => `${option.symbol} ${option.name}`}
                    getOptionValue={option => option.uuid}
                    helperText={errors.currencyId?.message}
                    options={currencyData}
                    placeholder="₹"
                    mountOnBody
                    fullWidth
                    label="Currency"
                    inputLabelProps={{
                      required: true,
                    }}
                    className="max-w-[100px]"
                    value={
                      value ? currencyData.find(i => i.uuid === value) : null
                    }
                    isSearchable
                    onBlur={onBlur}
                    onChange={val => onChange(val?.uuid ? val.uuid : null)}
                  />
                )}
                rules={{
                  required: { value: true, message: "Required" },
                }}
              />
              <TextField
                error={Boolean(errors.salary)}
                helperText={errors.salary?.message}
                autoFocus
                fullWidth
                required
                label="Salary"
                placeholder="Enter Salary Here"
                type="number"
                inputLabelProps={{
                  required: true,
                }}
                {...register("salary", {
                  required: { value: true, message: "Required" },
                })}
              />
            </div>
            <TextField
              error={Boolean(errors.dateOfHiring)}
              helperText={errors.dateOfHiring?.message}
              autoFocus
              fullWidth
              required
              placeholder="Date of hiring"
              type="date"
              label="Date of Hiring"
              {...register("dateOfHiring")}
            />
          </div>
        </div>
      </DialogContent>
      <DialogFooter
        actions={{
          primary: {
            label: "Add Salary",
            onClick: onSubmitSalary,
            color: "primary",
          },
          secondary: {
            label: "No I don't want to add salary",
            onClick: () => toggle(),
          },
        }}
      />
    </Dialog>
  )
}

export default AddSalaryDialog
