import { Container } from "@suraasa/placebo-ui"
import { GlobalContext } from "components/GlobalState"
import Tabs from "components/jobs/Tabs"
import TitleBar from "components/Recruiter/TitleBar"
import Navbar from "components/shared/Navbar"
import { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import useTabs, { Tabs as TabsType } from "utils/hooks/useTabs"
import { routes } from "utils/routes"
import useUserActions from "views/auth/useUserActions"
import useUserType from "views/auth/useUserType"
import SchoolTab from "views/common/SchoolTab"
import { getRoleName } from "views/Home/utils"

import Learners from "../common/LearnersTab"
import TeamTab from "../common/TeamTab"
import TitleBarActions from "./TitleBarActions"

export const AllTabs = {
  team: "Team",
  school: "Schools",
  learner: "Learners",
}
const MainTabs: TabsType = [
  {
    name: AllTabs.team,
    content: TeamTab,
  },
  {
    name: AllTabs.school,
    content: SchoolTab,
  },
  {
    name: AllTabs.learner,
    content: Learners,
  },
]

const Home = () => {
  const { userType } = useUserType()
  const navigate = useNavigate()
  const { profile } = useContext(GlobalContext)
  const { isSchoolAdmin } = useUserType()

  const {
    learnersTab: { canViewLearners },
    schoolTab,
    teamTab: { canViewTeamTab },
    userActions,
  } = useUserActions()

  const [tabsAllowed, setTabsAllowed] = useState<string[]>([])

  const setTabs = () => {
    if (userActions) {
      const t = []
      if (canViewTeamTab) {
        t.push(AllTabs.team)
      }
      if (Object.values(schoolTab).some(t => t)) {
        t.push(AllTabs.school)
      }
      if (canViewLearners) {
        t.push(AllTabs.learner)
      }
      setTabsAllowed(t)
    }
  }

  const tabs = MainTabs.filter(t => tabsAllowed.includes(t.name))

  const [activeTab, setActiveTab] = useTabs({
    tabs,
    initialTab: tabs[0] ? tabs[0].name : "",
  })

  useEffect(() => {
    setTabs()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userActions])

  useEffect(() => {
    if (isSchoolAdmin && profile.id) {
      navigate(routes.school.home.replace(":schoolId", profile.id.toString()), {
        replace: true,
      })
      return
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSchoolAdmin, profile])
  return (
    <div className="h-full bg-white">
      <Navbar hideBackButton gutterBottom={false} />
      <TitleBar
        name={userType ? userType.map(r => getRoleName(r)).join(" - ") : ""}
        bottomSlot={
          <div className="mt-2">
            <Tabs
              color="black"
              activeTab={activeTab}
              tabs={tabs}
              onChange={setActiveTab}
            />
          </div>
        }
        action={<TitleBarActions activeTab={activeTab} />}
      />
      {activeTab && (
        <Container className="mb-3 pb-5">
          <activeTab.content />
        </Container>
      )}
    </div>
  )
}

export default Home
