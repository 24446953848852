import { Divider, Typography } from "@suraasa/placebo-ui"
import { UserAcademicStatus } from "api/resources/teacher/types"
import React, { useContext } from "react"
import ProfileContext from "views/teacher/profileContext"

import Section from "../Section"
import SectionContent from "../Section/SectionContent"
import SectionHeading from "../Section/SectionHeading"
import SectionTitle from "../Section/SectionTitle"
import { ProfileQuickAccessTabs } from "../utils"
import ItemRow from "./ItemRow"

const EducationWithSuraasa = () => {
  const {
    profile: { user },
    academics: { qualifications, certifications },
  } = useContext(ProfileContext)
  const suraasaQualifications = qualifications.data.filter(
    item => item.isVerified
  )
  const suraasaCertifications = certifications.data.filter(
    item => item.isVerified
  )

  return (
    <>
      <Section id={ProfileQuickAccessTabs.educationWithSuraasa}>
        <SectionHeading
          heading={
            <SectionTitle title={ProfileQuickAccessTabs.educationWithSuraasa} />
          }
          xPadding={2}
        />
        <SectionContent xPadding={2}>
          {suraasaQualifications.length === 0 &&
            suraasaCertifications.length === 0 && (
              <Typography variant="smallBody" color="onSurface.400">
                {user.firstName} has not enrolled in any programme with Suraasa.
              </Typography>
            )}
          <div className="flex flex-col gap-1.5">
            {suraasaQualifications.map((item, index) => (
              <React.Fragment key={item.id}>
                <ItemRow
                  isInProgress={item?.status === UserAcademicStatus.IN_PROGRESS}
                  title={item.name || ""}
                  startDate={item.startDate}
                  endDate={item.endDate}
                  type="Qualification"
                />
                {index < suraasaQualifications.length - 1 && (
                  <Divider color="onSurface.200" weight="light" />
                )}
              </React.Fragment>
            ))}
            {suraasaCertifications.map((item, index) => (
              <React.Fragment key={item.id}>
                <ItemRow
                  title={item.name || ""}
                  startDate={item.completionDate}
                  type="Certification"
                />
                {index < suraasaCertifications.length - 1 && (
                  <Divider color="onSurface.200" weight="light" />
                )}
              </React.Fragment>
            ))}
          </div>
        </SectionContent>
      </Section>
    </>
  )
}

export default EducationWithSuraasa
