export const SEND_OFFER_PARAM = "send-offer"

export const routes = {
  // Auth Routes
  login: "/",
  signup: "/signup",
  logout: "/logout",
  resetPassword: "/reset-password/:uuid/:token/:timestamp",
  forgotPassword: "/forgot-password",

  // Home
  home: "/home",

  // Public Routes
  explore: "/explore",
  teacherProfile: "/teacher/profile/:username",

  // School Routes
  school: {
    home: "/school/:schoolId",

    profile: "/school/:schoolId/profile",
    editProfile: "/school/:schoolId/profile/edit",

    job: {
      create: "/school/:schoolId/job/create",
      edit: "/school/:schoolId/job/edit/:jobId",
      details: "/school/:schoolId/job/:jobId",
      closed: "/school/:schoolId/job/closed",
    },

    teacherInterview: "/school/:schoolId/teacher/interview",
    teacherTimeline:
      "/school/:schoolId/teacher/:username/:jobApplicantId/timeline",
    sendOffer: `/school/:schoolId/teacher/:username/:jobApplicantId/timeline?${SEND_OFFER_PARAM}=true`,
    viewOffer: `/school/:schoolId/teacher/:username/offer/:jobOfferId`,
  },

  // Account related
  help: "/help",
  settings: "/settings",

  /**
   * Discuss if we can remove the assessment and interview feature.
   */
  assessment: {
    create: "/school/:schoolId/assessment/create",
    edit: "/school/:schoolId/assessment/edit/:assessmentId",
    details: "/school/:schoolId/assessment/:assessmentId",
    result: "/school/:schoolId/assessment/:jobApplicantStepId/result",
    list: "/assessment/list",
  },

  offerLetterSent: "/teacher/offer-letter-sent",

  publicSkillProfile: {
    root: "/teacher/skill-profile/:username/",
    overview: "/teacher/skill-profile/:username/overview",
    evidences: "/teacher/skill-profile/:username/evidences/:slug",
    evidenceDetails:
      "/teacher/skill-profile/:username/evidence-details/:submissionId",
    verificationProcess:
      "/teacher/skill-profile/:username/verification-process",
  },

  pageNotFound: "/*",
} as const
