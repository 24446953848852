import { validateUrls } from "api/types"
import { getServiceURL } from "api/utils"
import { ServicePrefix } from "utils/constants"

const getNebulaURL = getServiceURL(ServicePrefix.nebula)

export const urls = validateUrls({
  assessment: {
    base: () => getNebulaURL(`/v1/assessments/`),
    detail: assessmentId => getNebulaURL(`/v1/assessments/${assessmentId}/`),
    bulkUpdate: assessmentId =>
      getNebulaURL(`/v1/assessments/${assessmentId}/questions/`),
    listSolutions: jobApplicantStepId =>
      `/jobs/assessments/steps/${jobApplicantStepId}/solutions/`,
  },
  questions: {
    base: () => getNebulaURL(`/v1/assessments/questions/`),
    update: questionId =>
      getNebulaURL(`/v1/assessments/questions/${questionId}/`),
  },
})
