import { Divider, DividerProps } from "@suraasa/placebo-ui"
import React from "react"
import { createUseStyles } from "react-jss"

const useStyles = createUseStyles(theme => ({
  root: {
    marginLeft: theme.spacing(-7.5),
    marginRight: theme.spacing(-2),
  },
}))

const TimelineDivider = (props: DividerProps) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Divider {...props} />
    </div>
  )
}

export default TimelineDivider
