import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogTitle,
  Typography,
} from "@suraasa/placebo-ui"
import { Job } from "api/resources/jobs/types"
import { GlobalContext } from "components/GlobalState"
import { Props as ShareSheetProps } from "components/shared/ShareSheet"
import CopyText from "components/shared/ShareSheet/CopyText"
import SocialIcons from "components/shared/ShareSheet/SocialIcons"
import React, { useContext } from "react"
import { getShareJobContent } from "utils/helpers"

type Props = {
  job: Job
} & Pick<ShareSheetProps, "handleClose" | "open">

const ShareJobDialog = ({ open, job, handleClose }: Props) => {
  const { profile } = useContext(GlobalContext)

  const url = `${import.meta.env.VITE_JOBS_PLATFORM_URL}/school/${
    profile.slug
  }?jobSlug=${job.slug}`
  const contentData = {
    profile,
    job: {
      slug: job.slug,
      position: job.position,
      subject: job.subject.name,
    },
  }

  return (
    <>
      <Dialog
        open={open}
        shouldCloseOnOverlayClick={false}
        onRequestClose={handleClose}
      >
        <DialogTitle>Job Opening Created Successfully!</DialogTitle>
        <DialogContent>
          <div className="mb-1 flex flex-col gap-3">
            <Typography variant="body">
              Your job opening has been successfully posted. <br />
              <br />
              Increase the number of applications on your opening by sharing it
              with your network.
            </Typography>

            <Typography variant="strong">Share on Social Media</Typography>
          </div>

          <SocialIcons
            data={{
              url,
              text: "",
              platformSpecificText: {
                linkedin: getShareJobContent("linkedin", contentData),
                whatsapp: getShareJobContent("whatsapp", contentData),
                facebook: getShareJobContent("facebook", contentData),
                telegram: getShareJobContent("telegram", contentData),
              },
            }}
          />

          <Typography className="mb-1" variant="subtitle2">
            Job Link
          </Typography>

          <CopyText url={url} />
        </DialogContent>
        <DialogFooter
          actions={{
            primary: {
              label: "Done",
              color: "primary",
              type: "button",
              onClick: handleClose,
            },
            secondary: null,
          }}
        />
      </Dialog>
    </>
  )
}

export default ShareJobDialog
