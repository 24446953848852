import axios from "api/axios"
import {
  APIResponseOld,
  Config,
  NoContentType,
  PaginatedAPIResponseOld,
} from "api/types"
import {
  formatErrorResponse,
  formatPaginatedResponse,
  formatSuccessResponseOld,
} from "api/utils"

import { Profile } from "../profile/types"
import {
  ActiveSchoolApplicants,
  HiredSchoolApplicants,
} from "../recruiter/types"
import { School, SchoolCountOverview, SchoolOverview } from "./types"
import { urls } from "./urls"

export default {
  activeSchoolApplicants: async ({
    params,
    headers,
  }: Config): Promise<PaginatedAPIResponseOld<ActiveSchoolApplicants>> => {
    try {
      const res = await axios.get(urls.school.activeSchoolApplicants(), {
        params,
        headers,
      })
      return formatPaginatedResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  hiredSchoolApplicants: async ({
    headers,
    params,
  }: Config): Promise<PaginatedAPIResponseOld<HiredSchoolApplicants>> => {
    try {
      const res = await axios.get(urls.school.hiredSchoolApplicants(), {
        headers,
        params,
      })
      return formatPaginatedResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  schoolOverview: async ({
    headers,
  }: Config): Promise<APIResponseOld<SchoolOverview>> => {
    try {
      const res = await axios.get(urls.school.overview(), { headers })
      return formatSuccessResponseOld(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  listAccessibleSchools: async ({
    headers,
  }: Config): Promise<APIResponseOld<School[]>> => {
    try {
      const res = await axios.get(urls.school.listAccessibleSchools(), {
        headers,
      })
      return formatSuccessResponseOld(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  create: async ({ data }: Config): Promise<APIResponseOld<School>> => {
    try {
      const res = await axios.post(urls.school.list(), data)
      return formatSuccessResponseOld(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  retrieve: async ({
    params,
    headers,
  }: Config): Promise<APIResponseOld<Profile>> => {
    try {
      const res = await axios.get(urls.school.retrieve(), { params, headers })
      return formatSuccessResponseOld(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  update: async ({
    data,
    headers,
  }: Config): Promise<APIResponseOld<Profile>> => {
    try {
      const res = await axios.put(urls.school.list(), data, { headers })
      return formatSuccessResponseOld(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  verify: async ({
    headers,
  }: Config): Promise<APIResponseOld<NoContentType>> => {
    try {
      const res = await axios.put(urls.school.verify(), {}, { headers })
      return formatSuccessResponseOld(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  schoolCountOverview: async (): Promise<
    APIResponseOld<SchoolCountOverview>
  > => {
    try {
      const res = await axios.get(urls.school.countOverview())
      return formatSuccessResponseOld(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
}
