import { Button, Typography } from "@suraasa/placebo-ui"
import { useContext } from "react"
import { Link } from "react-router-dom"
import { getAuthInfo } from "utils/auth"
import { redirectToLogin } from "utils/helpers"
import { routes } from "utils/routes"
import ProfileContext from "views/teacher/profileContext"

import Section from "../Section"
import SectionContent from "../Section/SectionContent"
import SectionHeading from "../Section/SectionHeading"
import SectionTitle from "../Section/SectionTitle"
import { ProfileQuickAccessTabs } from "../utils"
import PDFViewer from "./PDFViewer"

const fallBackPDF =
  "https://assets.suraasa.com/lms/profile-assets/placeholder-resume.pdf"

const Resume = () => {
  const isLoggedIn = Boolean(getAuthInfo())

  const { profile } = useContext(ProfileContext)

  return (
    <Section id={ProfileQuickAccessTabs.resume}>
      <SectionHeading heading={<SectionTitle title="Resume" />} xPadding={2} />
      <SectionContent xPadding={2}>
        <div>
          {!isLoggedIn && (
            <PDFViewer
              url={fallBackPDF}
              disabled={
                isLoggedIn ? undefined : (
                  <Button onClick={redirectToLogin}>
                    Sign in to View Resume
                  </Button>
                )
              }
            />
          )}
          {isLoggedIn && !profile.resume && (
            <Typography color="onSurface.400" variant="smallBody">
              {profile.user.firstName} has not uploaded their resume yet.
            </Typography>
          )}

          {isLoggedIn && profile.resume && <PDFViewer url={profile.resume} />}
        </div>
      </SectionContent>
    </Section>
  )
}

export default Resume
