import { Button, Typography } from "@suraasa/placebo-ui"
import {
  JobApplicantStepStatus,
  LiveDemoDetailsStep,
  StepType,
} from "api/resources/jobs/step.types"
import { format } from "date-fns"
import { Clock } from "iconoir-react"
import React, { useContext } from "react"
import { createUseStyles } from "react-jss"
import { useParams } from "react-router-dom"
import useToggle from "utils/hooks/useToggle"
import TeacherTimelineContext from "views/teacher/context"

import AddFeedbackDialog from "../Dialogs/AddFeedbackDialog"
import EditLiveDemoDialog from "../Dialogs/EditLiveDemoDialog"
import ListItem from "../ListItem"

const useStyles = createUseStyles(theme => ({
  instructions: {
    whiteSpace: "pre-wrap",
    paddingBottom: "8px",
  },

  list: {
    [theme.breakpoints.down("xs")]: {
      "& svg": {
        display: "none",
      },
    },
  },
}))

type Props = {
  tag?: React.ReactElement
} & LiveDemoDetailsStep

const LiveDemoItem = (props: Props) => {
  const {
    id,
    tag,
    dateCreated,
    status,
    step: {
      name,
      scheduledTime,
      feedback,
      topic,
      instructions,
      isVirtual,
      meetingUrl,
      ...step
    },
  } = props
  const classes = useStyles()

  const params = useParams()
  const schoolId = params.schoolId || ""

  const {
    isRejected,
    timeline,
    dialogs: { openFeedback, markAsComplete },
  } = useContext(TeacherTimelineContext)

  const [feedbackDialog, toggleFeedbackDialog] = useToggle(false)
  const [openLiveDemo, toggleOpenLiveDemo] = useToggle(false)

  const handleMarkAsComplete = () => {
    if (!feedback) toggleFeedbackDialog()
    timeline.update(id, {
      ...props,
      status: JobApplicantStepStatus.COMPLETED,
    })
  }

  const getAction = () => {
    switch (status) {
      case JobApplicantStepStatus.COMPLETED:
        return feedback ? (
          <Button
            variant="text"
            onClick={() => openFeedback.toggle(step.id, StepType.LIVE_DEMO)}
          >
            Open Feedback
          </Button>
        ) : (
          <Button
            disabled={isRejected}
            variant="text"
            onClick={() => toggleFeedbackDialog()}
          >
            Add Feedback
          </Button>
        )
      case JobApplicantStepStatus.NOT_STARTED:
        return (
          <>
            <Button
              color="success"
              disabled={isRejected}
              variant="text"
              onClick={() =>
                markAsComplete.toggle({
                  id,
                  name,
                  afterSubmit: handleMarkAsComplete,
                  schoolId,
                })
              }
            >
              Mark as complete
            </Button>
            {meetingUrl ? (
              <Button
                color="primary"
                component="a"
                href={meetingUrl}
                size="sm"
                target="_blank"
              >
                Join Live Demo
              </Button>
            ) : (
              <Button
                disabled={isRejected}
                size="sm"
                onClick={() => toggleOpenLiveDemo()}
              >
                Edit Live Demo
              </Button>
            )}
          </>
        )
      case JobApplicantStepStatus.IN_PROGRESS:
        return (
          <>
            <Button
              disabled={isRejected}
              variant="text"
              onClick={() => toggleFeedbackDialog()}
            >
              Add Feedback
            </Button>
            <Button
              color="success"
              disabled={isRejected}
              size="sm"
              variant={meetingUrl ? "text" : "filled"}
              onClick={() =>
                markAsComplete.toggle({
                  id,
                  name,
                  afterSubmit: handleMarkAsComplete,
                  schoolId,
                })
              }
            >
              Mark as complete
            </Button>
            {meetingUrl && (
              <Button
                color="primary"
                component="a"
                href={meetingUrl}
                size="sm"
                target="_blank"
              >
                Join Live Demo
              </Button>
            )}
          </>
        )
      case JobApplicantStepStatus.SKIPPED:
        return null
      default:
        break
    }
  }

  return (
    <>
      <EditLiveDemoDialog
        data={props}
        open={openLiveDemo}
        toggle={toggleOpenLiveDemo}
        schoolId={schoolId}
      />

      <AddFeedbackDialog
        afterSubmit={feedbackData => {
          timeline.update(id, {
            ...props,
            step: { ...props.step, feedback: feedbackData },
          })
        }}
        schoolId={schoolId}
        id={step.id}
        open={feedbackDialog}
        toggle={toggleFeedbackDialog}
        type={StepType.LIVE_DEMO}
      />

      <div style={{ width: "100%" }}>
        <div className="flex flex-wrap gap-1 sm:gap-1.5">
          <Typography color="onSurface.800" variant="strong">
            {name}
          </Typography>
          {tag}
          <Typography
            className="shrink-0 sm:ml-auto"
            color="onSurface.500"
            variant="smallBody"
          >
            Posted on {format(new Date(dateCreated), "do MMM yy")}
          </Typography>
        </div>
        <Typography
          className="mt-0.5"
          color="onSurface.400"
          variant="smallBody"
        >
          Live Demo {isVirtual ? "(Online)" : "(Offline)"}
        </Typography>
        {topic && (
          <Typography
            className="mt-2"
            color="onSurface.500"
            variant="strongSmallBody"
          >
            Topic: {topic}
          </Typography>
        )}
        {instructions && (
          <Typography
            className={classes.instructions}
            color="onSurface.500"
            variant="smallBody"
          >
            {instructions}
          </Typography>
        )}

        <ListItem className={classes.list} icon={<Clock />}>
          <div className="flex flex-col sm:block">
            <Typography
              color="onSurface.400"
              display="inline"
              variant="smallBody"
            >
              Scheduled for{" "}
            </Typography>
            <Typography display="inline" variant="strongSmallBody">
              {format(new Date(scheduledTime), "dd MMMM yyyy hh:mm aa")}
            </Typography>
          </div>
        </ListItem>
        <div className="mt-3 flex flex-wrap justify-end gap-1">
          {getAction()}
        </div>
      </div>
    </>
  )
}

export default LiveDemoItem
