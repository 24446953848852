import ShareSheet from "components/shared/ShareSheet"
import { useState } from "react"
import { createUseStyles } from "react-jss"
import { getShareJobContent } from "utils/helpers"
import { useCurrentSchool } from "views/School/context"

type Props = {
  jobSlug: string
  position: string
  subject: string
  activator: (handleOpen: () => void) => void
}

const useStyles = createUseStyles(() => ({
  root: {
    width: "100%",
  },
}))

const ShareJobButton = ({ activator, jobSlug, position, subject }: Props) => {
  const { school: profile } = useCurrentSchool()
  const classes = useStyles()

  const [open, setOpen] = useState(false)

  if (!profile) return null

  const shareURL = `${import.meta.env.VITE_JOBS_PLATFORM_URL}/school/${
    profile?.slug ?? ""
  }?jobSlug=${jobSlug}`

  const contentData = {
    profile,
    job: {
      slug: jobSlug,
      position,
      subject,
    },
  }
  return (
    <div className={classes.root}>
      <>{activator(() => setOpen(true))}</>
      <ShareSheet
        data={{
          url: shareURL,
          text: "",
          platformSpecificText: {
            linkedin: getShareJobContent("linkedin", contentData),
            whatsapp: getShareJobContent("whatsapp", contentData),
            facebook: getShareJobContent("facebook", contentData),
            telegram: getShareJobContent("telegram", contentData),
          },
        }}
        handleClose={() => setOpen(false)}
        open={open}
      />
    </div>
  )
}

export default ShareJobButton
