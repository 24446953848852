import {
  Country,
  Currency,
  Language,
  State,
  Subject,
} from "api/resources/global/types"
import { LanguageProficiency } from "utils/constants"
import { ValueOf } from "utils/helpers"

/**
 * jobApplicant is needed only
 * in the case where we need to add interview
 * Note: This can be either string or number
 * @if it comes from backend --> number
 * @if it comes from url params --> string
 */

export enum EmploymentType {
  FULL_TIME = "full_time",
  PART_TIME = "part_time",
  FRESHER = "fresher",
  INTERN = "intern",
  FREELANCE = "freelance",
  SELF_EMPLOYED = "self_employed",
}

/**
 * Using object literal instead of enum to use null value.
 * Organisation type can either be null or an integer in the range of 1-3
 */
export const OrganisationType = {
  SCHOOL: "school",
  COLLEGE_OR_UNIVERSITY: "college_or_university",
  TUTORING: "tutoring",
  OTHERS: null,
} as const

export type WorkExperienceType = {
  id: string
  title?: string
  employmentType?: EmploymentType
  description?: string | null
  organisationName?: string
  organisationType?: ValueOf<typeof OrganisationType>
  otherOrganisationType?: string | null
  countryId?: string | null
  country?: Country | null
  stateId?: string | null
  state?: State | null
  currentlyWorking?: boolean
  startDate?: string // Date
  endDate?: string | null // Date
  salary?: number | null
  currencyId?: string | null
  curriculumId?: string | null
  teachingLevelId?: string | null
  teachingRoleId?: string | null
  otherCurriculum?: string | null
  otherTeachingLevel?: string | null
  otherTeachingRole?: string | null

  subjects?: {
    subjectId?: string
    subject: {
      id: number
      uuid: string
      name: string
      addedById?: string | null
      isApproved?: boolean
    }
  }[]

  currency?: Currency | null
  curriculum?: {
    uuid: string
    name: string
    isDefault?: true | null
  } | null
  teachingLevel?: {
    uuid: string
    name: string
    curriculumId?: string
  } | null
  teachingRole?: {
    uuid: string
    name: string
    curriculumId?: string
    teachingLevelId?: string
  } | null
}

export enum CoverStyle {
  CANDY = "candy",
  DAWN = "dawn",
  AURORA = "aurora",
  OCEAN = "ocean",
  TERRA = "terra",
  DUSK = "dusk",
  OPAL = "opal",
}

export type Profile = {
  id: string
  totalWorkingExperience: number // in years
  coverStyle?: CoverStyle
  resume?: string | null
  careerAspiration: string | null
  countriesWorkedIn: { name: string }[]
  currentWorkingExperience?:
    | (Pick<WorkExperienceType, "title" | "organisationName"> & {
        subjects?: string[]
        teachingLevel?: string
      })
    | null
  country: Country | null
  dateOfBirth: string | null
  gender: Gender | null
  isVerified: boolean
  lookingForJobs: boolean
  nationality: Country | null
  state: State | null
  phoneNumber: { code: string; number: string } | null
  picture: string | null
  user: {
    email: string
    firstName: string
    lastName: string | null
    middleName: string
    username: string
    uuid: string
  }
}

export enum Gender {
  MALE = "male",
  FEMALE = "female",
  PREFER_NOT_TO_SAY = "prefer_not_to_say",
}

export type Feedback = [
  {
    name: "Interpersonal Skills"
    comment: string
    rating: number
  },
  {
    name: "Communication Skills"
    comment: string
    rating: number
  },
  {
    name: "Attention To Detail"
    comment: string
    rating: number
  },
  {
    name: "Pedagogy Skills"
    comment: string
    rating: number
  },
  {
    name: "Overall Comments"
    comment: string
    rating: number
  }
]

export type Note = {
  id: number
  job: number
  user: string
  addedBy: {
    fullName: string
    uuid: string
  }
  dateCreated: string
  isSelf: boolean
  notes: string
}

export enum EvidenceType {
  URL = 1,
  File,
}

type BaseEvidence = {
  id: string
}

export type Evidence =
  | (BaseEvidence & {
      evidenceType: EvidenceType.URL
      url: string
    })
  | (BaseEvidence & {
      evidenceType: EvidenceType.File
      file: string
    })

export type QualificationLevel = {
  id: number
  name: string
}

export type Qualification = {
  id: number
  organisationName?: string | null
  name?: string
  qualificationLevelId?: number
  qualificationFieldId?: string
  qualificationLevel?: QualificationLevel
  startDate?: string // Date
  endDate?: string // Date
  grade?: string
  isVerified?: boolean
  status?: UserAcademicStatus
}

export type Certification = {
  id: number
  name?: string
  organisationName?: string | null
  willExpire?: boolean
  completionDate?: string // Date
  expirationDate?: string | null // Date
  isVerified?: boolean
  evidences?: Evidence[]
}

export type LanguageResponse = {
  proficiency?: LanguageProficiency
  languageId: string
  id: number
  language: Language
}

export type WorkLocationInterest = {
  countryId: string
  stateId: string
  id: number
  country: Country
  state: State
}

export type Interest = {
  id: string
  interest: string
}

export type Publication = {
  id: string
  title?: string
  publisher?: string
  publishedOn?: string | null // ISODate
  description?: string | null
  url?: string | null
}

export type TestScore = {
  id: string
  name?: string
  score?: string
  testDate?: string | null // ISODate
  description?: string | null
  evidenceDocument?: string | null
  evidenceUrl?: string | null // url validations apply
}

export type Award = {
  id: string
  title?: string
  issuer?: string
  issuedOn?: string | null
  description?: string | null
}

export type Project = {
  id: string
  title?: string
  currentlyWorking?: boolean
  startDate?: string // ISODate
  endDate?: string | null // ISODate
  url?: string | null
  description?: string | null
}

export type SubjectResponse = {
  subjectId: string
  id: number
  subject: Subject
}

export type PersonalDetails = {
  firstName: string
  lastName?: string
  picture: string | null
  dateOfBirth?: string
  gender: Gender | null
  nationality?: string
  country?: string
  state?: string
  lookingForJobs?: boolean
}

export type SkillEvidencesOverview = {
  advancedSkillCount: number
  beginnerSkillCount: number
  intermediateSkillCount: number
  professional: number
}

export type SkillEvidence = {
  name: string
  slug: string
  evidences: {
    submission: string
    title: string
    uuid: string
    visibility?: boolean
  }[]
}

export type Skill = {
  id: string
  skillName: string
  sequence: number
}

export enum UserAcademicStatus {
  IN_PROGRESS = 1,
  COMPLETED = 2,
  SUSPENDED = 3,
}
