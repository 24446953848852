import { validateUrls } from "api/types"
import { getServiceURL } from "api/utils"
import { ServicePrefix } from "utils/constants"

const getNebulaURL = getServiceURL(ServicePrefix.nebula)
const getSolisURL = getServiceURL(ServicePrefix.solis)

export const urls = validateUrls({
  jobs: {
    base: () => getNebulaURL("/v1/jobs/"),
    detail: jobId => getNebulaURL(`/v1/jobs/${jobId}/`),
    overview: () => getSolisURL(`/v1/jobs/overview/`),
    overviewCount: jobId => getNebulaURL(`/v1/jobs/overview/${jobId}/`),
  },
  invitedUser: {
    base: () => getNebulaURL(`/v1/jobs/invited-user/`),
    detail: invitedUserId =>
      getNebulaURL(`/v1/jobs/invited-user/${invitedUserId}/`),
  },
  interestedUser: {
    update: jobInterestedUserId =>
      getNebulaURL(`/v1/jobs/interested-user/${jobInterestedUserId}/`),
  },
  jobApplicant: {
    updatePlacementTracker: jobApplicantId =>
      getNebulaURL(`/v1/placements/trackers/applicants/${jobApplicantId}/`),
    detail: jobApplicantId =>
      getNebulaURL(`/v1/jobs/applicants/${jobApplicantId}/`),

    retrieveContactDetails: userId => `/jobs/users/${userId}/`,
  },
  applicants: {
    get: () => getNebulaURL("/v1/users/"),
    pushProfiles: () => getNebulaURL(`/v1/jobs/push-profiles/`),
    listInvited: jobId => `/v1/jobs/${jobId}/applicants/invited/`,
    listApplied: jobId => `/v1/jobs/${jobId}/applicants/interested/`,
    listActive: jobId => `/v1/jobs/${jobId}/applicants/active/`,
    listHired: jobId => `/v1/jobs/${jobId}/applicants/hired/`,
    listNotHired: jobId => `/v1/jobs/${jobId}/applicants/not-hired/`,
  },
  jobApplicantStep: {
    update: jobApplicantStepId =>
      getNebulaURL(`/v1/hiring/steps/${jobApplicantStepId}/`),
    create: jobApplicantId =>
      getNebulaURL(`/v1/hiring/applicants/${jobApplicantId}/steps/`),
  },
  jobOffer: {
    list: () => "/jobs/offers/",
    create: () => getNebulaURL(`/v1/hiring/offers/`),
    retrieve: jobOfferId => getNebulaURL(`/v1/hiring/offers/${jobOfferId}/`),
  },
})
