import { Button, Container, Divider, Typography } from "@suraasa/placebo-ui"
import api from "api"
import { Job } from "api/resources/jobs/types"
import clsx from "clsx"
import { GlobalContext } from "components/GlobalState"
import CreateJobForm, { CreateJobType } from "components/jobs/CreateJobForm"
import PreviewJob from "components/jobs/PreviewTab"
import ShareJobDialog from "components/jobs/ShareJobDialog"
import Navbar from "components/shared/Navbar"
import { EditPencil, Eye } from "iconoir-react"
import React, { useContext, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { createUseStyles } from "react-jss"
import { useNavigate, useParams } from "react-router-dom"
import { handleErrors } from "utils/helpers"
import useResources from "utils/hooks/useResources"
import { routes } from "utils/routes"
import toast from "utils/toast"

const useStyles = createUseStyles(theme => ({
  cardContent: {
    borderBottomLeftRadius: theme.spacing(1),
    borderBottomRightRadius: theme.spacing(1),
    padding: theme.spacing(3, 3, 4),
  },
  cardHeader: {
    padding: theme.spacing(1.5, 3, 1),
    borderTopLeftRadius: theme.spacing(1),
    borderTopRightRadius: theme.spacing(1),
  },
  card: {
    background: "white",
    borderRadius: theme.spacing(1),
    border: `1px solid ${theme.colors.surface[200]}`,
  },
  createPreviewDivider: {
    width: "64px",
  },
  progressChip: {
    borderRadius: "100px",
    height: "48px",
    padding: "14px 16px",
    border: `1px solid ${theme.colors.surface[300]}`,
    background: theme.colors.surface[200],
    color: theme.colors.primary[500],
    "&:hover": {
      background: theme.colors.surface[200],
    },
  },
  progressChipActive: {
    background: theme.colors.primary[500],
    color: "white",
    "&:hover": {
      background: theme.colors.primary[500],
    },
  },
}))

const Create = () => {
  const classes = useStyles()
  const {
    profile: { isVerified },
  } = useContext(GlobalContext)
  const {
    control,
    register,
    handleSubmit,
    setError,
    reset,
    clearErrors,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<CreateJobType>()

  const { jobId, schoolId } = useParams() as { jobId: string; schoolId: string }
  const navigate = useNavigate()

  const { subjects, currencies } = useResources(["subjects", "currencies"])

  const [subjectOptions, setSubjectOptions] = useState(subjects)
  const [shareDialogOpen, setShareDialogOpen] = useState(false)

  const [loading, setLoading] = useState(Boolean(jobId))

  useEffect(() => {
    setSubjectOptions(subjects)
  }, [subjects])

  const [job, setJob] = useState<Job>()

  const [isPreviewMode, setIsPreviewMode] = useState(false)

  useEffect(() => {
    const fetchJob = async () => {
      if (!jobId || currencies.length === 0) {
        return
      }

      setLoading(true)

      const res = await api.jobs.retrieve({
        urlParams: { jobId },
        headers: {
          "School-Id": schoolId,
        },
      })
      if (res.isSuccessful) {
        const {
          position,
          currency,
          salaryOffered,
          maximumSalaryOffered,
          subject,
          salaryNegotiable,
          description,
          eligibilityCriterion,
        } = res.data

        setJob(res.data)
        reset({
          makeSalaryRange: Boolean(maximumSalaryOffered),
          position,
          currency,
          salaryNegotiable,
          salaryOffered,
          maximumSalaryOffered,
          subject,
          eligibilityCriterion,
          description: description ?? "",
        })
        setLoading(false)
      } else {
        handleErrors(setError, res.errors)
      }
    }
    fetchJob()
  }, [jobId, reset, setError, currencies, schoolId])

  const onSubmit = handleSubmit(async data => {
    // depending if we have a job id present in the url or not, we will either
    // update the existing job or create a new one.

    const isNegotiable = data.salaryNegotiable === true

    if (jobId) {
      const res = await api.jobs.update({
        urlParams: { jobId },
        data: {
          salaryOffered: isNegotiable ? null : data.salaryOffered,
          maximumSalaryOffered: isNegotiable ? null : data.maximumSalaryOffered,
          description: data.description,
          eligibilityCriterion: data.eligibilityCriterion,
          currencyId: isNegotiable ? null : data.currency?.uuid,
          salaryNegotiable: data.salaryNegotiable,
        },
        headers: {
          "School-Id": schoolId,
        },
      })

      if (res.isSuccessful) {
        navigate(
          routes.school.job.details
            .replace(":schoolId", schoolId)
            .replace(":jobId", res.data.id.toString())
        )
      } else {
        handleErrors(setError, res.errors)
      }
    } else {
      const res = await api.jobs.create({
        data: {
          position: data.position,
          eligibilityCriterion: data.eligibilityCriterion,
          salaryOffered: isNegotiable ? null : data.salaryOffered,
          maximumSalaryOffered: isNegotiable ? null : data.maximumSalaryOffered,
          currencyId: isNegotiable ? null : data.currency?.uuid,
          description: data.description,
          salaryNegotiable: data.salaryNegotiable,
          subjectId: data.subject?.uuid,
        },
        headers: {
          "School-Id": schoolId,
        },
      })

      if (res.isSuccessful) {
        setJob(res.data)
        toast.success("Job Published successfully")
        if (isVerified) setShareDialogOpen(true)
        else if (schoolId) {
          navigate(
            `${routes.explore}?jobId=${res.data.id}&schoolId=${schoolId}&jobPosition=${res.data.position}`
          )
        }
      } else {
        handleErrors(setError, res.errors)
        setIsPreviewMode(false)
      }
    }
  })

  const checkAndProceedToPreview = handleSubmit(data => {
    let hasError = false
    clearErrors()

    if (!data.salaryNegotiable && data.salaryOffered) {
      if (data.makeSalaryRange === true && data.maximumSalaryOffered) {
        if (data.salaryOffered === data.maximumSalaryOffered) {
          hasError = true
          setError("maximumSalaryOffered", {
            message: "Base salary and Maximum salary can't be same",
          })
        }
        if (+data.salaryOffered > +data.maximumSalaryOffered) {
          hasError = true
          setError("maximumSalaryOffered", {
            message: "Base salary can't be greater than maximum salary",
          })
        }
      }
    }

    if (hasError) return
    setIsPreviewMode(true)
  })

  const watchPosition = watch("position")
  const watchSalaryOffered = watch("salaryOffered")
  const watchMaximumSalaryOffered = watch("maximumSalaryOffered")
  const watchSalaryNegotiable = watch("salaryNegotiable")
  const watchCurrency = watch("currency")
  const watchSubject = watch("subject")
  const watchMakeSalaryRange = watch("makeSalaryRange")

  const handleShareDialogClose = () => {
    setShareDialogOpen(false)
    if (job)
      navigate(
        `${routes.explore}?jobId=${job.id}&jobPosition=${job.position}&schoolId=${schoolId}`
      )
  }

  return (
    <>
      <Navbar gutterBottom={false} />

      {shareDialogOpen && job && (
        <ShareJobDialog
          handleClose={handleShareDialogClose}
          job={job}
          open={shareDialogOpen}
        />
      )}

      <Container>
        <div className="grid grid-cols-12 gap-3 pb-4 sm:pt-4">
          <div className="col-span-12 col-start-1 col-end-12 flex flex-wrap items-center justify-between gap-y-1 sm:col-start-2">
            <Typography
              className="sm:col-span-10 sm:col-start-2"
              variant="title2"
            >
              {jobId ? "Edit Job Posting" : "Create New Job Posting"}
            </Typography>
            <div className="col-end-11 flex items-center">
              <Button
                className={clsx(classes.progressChip, {
                  [classes.progressChipActive]: !isPreviewMode,
                })}
                startAdornment={<EditPencil />}
                variant="filled"
                onClick={() => setIsPreviewMode(false)}
              >
                {jobId ? "Edit" : "Create"}
              </Button>

              <Divider className={classes.createPreviewDivider} />
              <Button
                className={clsx(classes.progressChip, {
                  [classes.progressChipActive]: isPreviewMode,
                })}
                disabled={
                  !(
                    Boolean(watchPosition) &&
                    Boolean(watchSalaryOffered) &&
                    Boolean(watchCurrency) &&
                    Boolean(watchSubject)
                  )
                }
                startAdornment={<Eye />}
                variant="filled"
                onClick={() => setIsPreviewMode(true)}
              >
                Preview
              </Button>
            </div>
          </div>
          <div
            className={clsx(
              "col-span-12 col-start-1 sm:col-span-10 sm:col-start-2",
              classes.card
            )}
          >
            <div className="col-span-12 col-start-1">
              <div className={classes.cardHeader}>
                <Typography variant="title3">
                  {/* eslint-disable-next-line no-nested-ternary */}
                  {isPreviewMode ? "Preview" : jobId ? "Edit" : "Create"}
                </Typography>
              </div>
              <Divider weight="light" />
              <div className={classes.cardContent}>
                <form
                  onSubmit={isPreviewMode ? onSubmit : checkAndProceedToPreview}
                >
                  {!isPreviewMode ? (
                    <CreateJobForm
                      control={control}
                      currencies={currencies}
                      currencySymbol={watchCurrency?.symbol}
                      errors={errors}
                      job={job}
                      loading={loading}
                      makeSalaryRange={watchMakeSalaryRange}
                      salaryNegotiable={watchSalaryNegotiable}
                      register={register}
                      setSubjectOptions={setSubjectOptions}
                      setValue={setValue}
                      subjects={subjectOptions}
                    />
                  ) : (
                    <PreviewJob
                      currencyCode={watchCurrency?.code}
                      maximumSalaryOffered={watchMaximumSalaryOffered}
                      position={watchPosition}
                      salaryNegotiable={watchSalaryNegotiable}
                      salaryOffered={watchSalaryOffered}
                      subject={watchSubject?.name}
                    />
                  )}
                </form>
              </div>
            </div>
          </div>
          <div className="col-span-12 flex items-center justify-between sm:col-span-10 sm:col-start-2 md:col-span-6 md:col-start-6 md:justify-end">
            {isPreviewMode && (
              <Button variant="text" onClick={() => setIsPreviewMode(false)}>
                Edit Job Details
              </Button>
            )}

            {isPreviewMode ? (
              <Button
                className="sm:ml-5"
                loading={isSubmitting}
                type="submit"
                onClick={onSubmit}
              >
                Publish Job Posting
              </Button>
            ) : (
              <Button
                className="sm:ml-5"
                loading={isSubmitting}
                type="submit"
                onClick={checkAndProceedToPreview}
              >
                Preview & Publish
              </Button>
            )}
          </div>
        </div>
      </Container>
    </>
  )
}

export default Create
