import { validateUrls } from "api/types"
import { getServiceURL } from "api/utils"
import { ServicePrefix } from "utils/constants"

const getNebulaURL = getServiceURL(ServicePrefix.nebula)

export const urls = validateUrls({
  school: {
    activeSchoolApplicants: () => `/v1/jobs/school-applicants/active/`,
    hiredSchoolApplicants: () => `/v1/jobs/school-applicants/hired/`,
    retrieve: () => getNebulaURL(`/v1/schools/profile/`),
    list: () => getNebulaURL("/v1/schools/"),
    listAccessibleSchools: () => getNebulaURL("/v1/schools/access/"),
    overview: () => getNebulaURL("/v2/schools/school-overview/"),
    verify: () => getNebulaURL("/v1/schools/verify/"),
    countOverview: () => getNebulaURL("/v1/schools/overview/"),
  },
})
