import { CircularProgress, Container, Typography } from "@suraasa/placebo-ui"
import api from "api"
import { JobOffer } from "api/resources/jobs/types"
import { Profile as ProfileType } from "api/resources/teacher/types"
import Section from "components/Section"
import SectionContent from "components/Section/SectionContent"
import Navbar from "components/shared/Navbar"
import OfferDetails from "components/teacher/OfferDetails"
// import Overview from "components/teacher/Overview"
import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"

const TeacherTimeline = () => {
  const { jobOfferId, username, schoolId } = useParams()

  const [offer, setOffer] = useState<JobOffer>()

  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const [profile, setProfile] = useState<ProfileType | null>(null)

  useEffect(() => {
    const getOffer = async () => {
      if (!jobOfferId) return
      setLoading(true)

      const res = await api.jobs.jobOffer.retrieve({
        urlParams: { jobOfferId },
        headers: {
          "School-Id": schoolId,
        },
      })

      if (res.isSuccessful) {
        setOffer(res.data)
      } else if (res.errors.message) setError(res.errors.message)
      else setError("An unknown error occurred")
      setLoading(false)
    }
    getOffer()
  }, [jobOfferId])

  useEffect(() => {
    const retrieveProfile = async () => {
      setLoading(true)
      if (!username) return

      const res = await api.teacher.profile.retrieve({
        urlParams: {
          username,
        },
      })

      if (res.isSuccessful) {
        setProfile(res.data)
        setLoading(false)
      } else {
        setError("Something went wrong")
      }
    }

    retrieveProfile()
  }, [username])

  if (!profile || loading)
    return (
      <>
        <Navbar />
        <div className="flex items-center justify-center p-3">
          <CircularProgress />
        </div>
      </>
    )

  if (error)
    return (
      <>
        <Navbar />
        <Container>
          <Section>
            <SectionContent className="flex items-center justify-center p-3">
              <Typography>{error}</Typography>
            </SectionContent>
          </Section>
        </Container>
      </>
    )

  return (
    <>
      <Navbar />
      <Container>
        <div className="mb-3">
          {/* <Overview {...profile} /> */}

          {offer && <OfferDetails details={offer} />}
        </div>
      </Container>
    </>
  )
}

export default TeacherTimeline
