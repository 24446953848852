import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogProps,
  DialogTitle,
  Tag,
  Typography,
} from "@suraasa/placebo-ui"
import { Job } from "api/resources/jobs/types"
import React from "react"
import { createUseStyles } from "react-jss"
import { ToggleValue } from "utils/hooks/useToggle"

type Props = {
  open: DialogProps["open"]
  toggle: ToggleValue
  data: Pick<
    Job,
    | "currency"
    | "description"
    | "position"
    | "salaryOffered"
    | "subject"
    | "maximumSalaryOffered"
    | "schoolContactPerson"
  >
}

const useStyles = createUseStyles(() => ({
  description: {
    whiteSpace: "pre-line",
  },
}))

const JobOpeningDetailsDialog = ({ open, toggle, data }: Props) => {
  const classes = useStyles()

  return (
    <Dialog open={open} onRequestClose={() => toggle()}>
      <DialogTitle>Job Opening Details</DialogTitle>
      <DialogContent>
        {data.schoolContactPerson && (
          <div className="rounded-lg bg-interactive-100">
            <Typography variant="title4">
              Point of Contact Details (POC)
            </Typography>
            <div>
              <Typography variant="strong" color="secondary.900">
                {data.schoolContactPerson.name}
              </Typography>
              <Typography variant="smallBody">
                {data.schoolContactPerson.email} •{"  "}+
                {data.schoolContactPerson.phoneNumber?.code}{" "}
                {data.schoolContactPerson.phoneNumber?.number}
              </Typography>
            </div>
          </div>
        )}
        <div>
          <Typography variant="title3">{data.position}</Typography>
          <Tag className="mt-1" color="onSurface" label={data.subject.name} />
        </div>

        <div className="flex flex-wrap items-center">
          <Typography className="mb-2 mt-2.5" variant="strong">
            {data.currency?.code} {Number(data.salaryOffered).toLocaleString()}{" "}
            {data.maximumSalaryOffered && (
              <>- {Number(data.maximumSalaryOffered).toLocaleString()}</>
            )}
          </Typography>
          <Typography color="onSurface.500" variant="body">
            /year
          </Typography>
        </div>
        <Typography className={classes.description}>
          {data.description}
        </Typography>
      </DialogContent>
      <DialogFooter
        actions={{
          primary: null,
          secondary: {
            label: "Close",
          },
        }}
      />
    </Dialog>
  )
}

export default JobOpeningDetailsDialog
