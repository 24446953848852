import { Button } from "@suraasa/placebo-ui"
import api from "api"
import { JobInterestedUserAction } from "api/resources/jobs/types"
import RejectApplicationDialog from "components/teacher/Dialogs/RejectApplicationDialog"
import { useState } from "react"
import toast from "utils/toast"

import AcceptApplicationDialog from "./AcceptApplicationDialog"

type Props = {
  jobApplicantId?: number
  jobInterestedUserId: number
  onUpdate: (status: string) => void
  isShared: boolean
  position?: string
  fullName: string
  schoolId: string
}

const AcceptRejectApplicantActions = ({
  jobApplicantId,
  jobInterestedUserId,
  onUpdate,
  isShared,
  position = "this job",
  fullName,
  schoolId,
}: Props) => {
  const [dialogLoading, setDialogLoading] = useState(false)

  const [dialog, setDialog] = useState<"accept" | "reject" | null>(null)

  const handleSharedProfileAction = async (
    status: JobInterestedUserAction,
    reasons?: any
  ) => {
    const isAccepted = status === JobInterestedUserAction.ACCEPTED
    setDialogLoading(true)

    const res = await api.jobs.jobApplicant.update({
      urlParams: {
        jobApplicantId: jobApplicantId || "",
      },
      data: {
        status: isAccepted ? "in_progress" : JobInterestedUserAction.REJECTED,
        ...(reasons ? { rejectionReason: reasons } : {}),
      },
      headers: {
        "School-Id": schoolId,
      },
    })
    if (res.isSuccessful) {
      if (status === JobInterestedUserAction.ACCEPTED) {
        toast.success("Application accepted")
        onUpdate("accepted")
      } else {
        toast.success("Application rejected")
        onUpdate("rejected")
      }

      setDialog(null)
    } else {
      toast.error(res.errors.message)
    }
    setDialogLoading(false)
  }

  const handleAction = async (
    status: JobInterestedUserAction,
    reasons?: any
  ) => {
    if (isShared) {
      return await handleSharedProfileAction(status, reasons)
    }

    setDialogLoading(true)

    const res = await api.jobs.interestedUser.update({
      urlParams: {
        jobInterestedUserId,
      },
      data: {
        status: status,
        ...(reasons ? { rejectionReason: reasons } : {}),
      },
      headers: {
        "School-Id": schoolId,
      },
    })
    if (res.isSuccessful) {
      if (status === JobInterestedUserAction.ACCEPTED) {
        toast.success("Application accepted")
        onUpdate("accepted")
      } else {
        toast.success("Application rejected")
        onUpdate("rejected")
      }
      setDialog(null)
    } else {
      toast.error(res.errors.message)
    }
    setDialogLoading(false)
  }

  const handleClose = () => {
    setDialog(null)
  }

  return (
    <div className="flex gap-1">
      {dialog === "reject" && (
        <RejectApplicationDialog
          submitHandler={reasons =>
            handleAction(JobInterestedUserAction.REJECTED, reasons)
          }
          resourceId={jobInterestedUserId}
          jobPosition={position || ""}
          mode="application"
          open={dialog === "reject"}
          toggle={handleClose}
          userName={fullName}
          schoolId={schoolId}
        />
      )}

      {dialog === "accept" && (
        <AcceptApplicationDialog
          handleClose={handleClose}
          loading={dialogLoading}
          open={dialog === "accept"}
          onAccept={() => {
            handleAction(JobInterestedUserAction.ACCEPTED)
          }}
        >
          Accept <b>{fullName}</b>’s application for&nbsp;
          <b>{position}</b>?
        </AcceptApplicationDialog>
      )}

      <Button
        color="critical"
        variant="text"
        onClick={() => {
          setDialog("reject")
        }}
      >
        Reject
      </Button>

      <Button
        size="sm"
        variant="outlined"
        onClick={() => {
          setDialog("accept")
        }}
      >
        Accept
      </Button>
    </div>
  )
}

export default AcceptRejectApplicantActions
