import { Button, Typography } from "@suraasa/placebo-ui"
import { User } from "api/resources/settings/types"
import { useState } from "react"
import { getAuthInfo, saveAuthInfo } from "utils/auth"

import ChangeEmailIdDialog from "./ChangeEmailIdDialog"
import ChangePasswordDialog from "./ChangePasswordDialog"
import ChangePhoneNumberDialog from "./ChangePhoneNumberDialog"

function AccountDetails({
  data: { email, phoneNumber },
  updateUserPreferences,
}: {
  data: User
  updateUserPreferences: (data: Partial<User>) => void
}) {
  const [openChangePassword, setOpenChangePassword] = useState(false)
  const [openChangePhone, setOpenChangePhone] = useState(false)
  const [openChangeEmail, setOpenChangeEmail] = useState(false)

  return (
    <>
      <ChangePasswordDialog
        close={() => setOpenChangePassword(false)}
        open={openChangePassword}
      />
      <ChangePhoneNumberDialog
        close={() => setOpenChangePhone(false)}
        currentPhone={phoneNumber}
        open={openChangePhone}
        onUpdate={(phone: User["phoneNumber"]) =>
          updateUserPreferences({ phoneNumber: phone })
        }
      />
      <ChangeEmailIdDialog
        close={() => setOpenChangeEmail(false)}
        currentEmailId={email}
        open={openChangeEmail}
        onUpdate={(emailId: User["email"]) => {
          updateUserPreferences({ email: emailId })
          const authInfo = getAuthInfo()
          if (authInfo)
            saveAuthInfo({
              ...authInfo,
              user: { ...authInfo.user, email: emailId },
            })
        }}
      />

      <div className="flex items-start justify-between">
        <div className="mb-3 flex flex-col gap-0.5">
          <Typography color="common.black.700" variant="strong">
            Account Phone Number
          </Typography>
          <Typography color="onSurface.500" variant="strong">
            +{phoneNumber?.code} {phoneNumber?.number}
          </Typography>
        </div>
        <Button
          color="primary"
          variant="text"
          onClick={() => setOpenChangePhone(true)}
        >
          Edit
        </Button>
      </div>
      <div className="flex items-center justify-between">
        <div className="mb-3 flex flex-col gap-0.5">
          <Typography color="common.black.700" variant="strong">
            Account Email ID
          </Typography>
          <Typography color="onSurface.500" variant="strong">
            {email || "-"}
          </Typography>
        </div>
        <Button
          color="primary"
          variant="text"
          onClick={() => {
            setOpenChangeEmail(true)
          }}
        >
          Edit
        </Button>
      </div>
      <div className="flex items-center justify-between">
        <div className="mb-3 flex flex-col gap-0.5">
          <Typography color="common.black.700" variant="strong">
            Account Password
          </Typography>
          <Typography color="onSurface.500" variant="strong">
            ••••••••
          </Typography>
        </div>
        <Button
          color="primary"
          variant="text"
          onClick={() => setOpenChangePassword(true)}
        >
          Change Password
        </Button>
      </div>
    </>
  )
}

export default AccountDetails
