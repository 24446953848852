import {
  CircularProgress,
  Container,
  theme,
  Typography,
  useMediaQuery,
} from "@suraasa/placebo-ui"
import api from "api"
import {
  AssessmentDetailsStep,
  InterviewDetailsStep,
  LiveDemoDetailsStep,
  Step,
  StepType,
} from "api/resources/jobs/step.types"
import { JobApplicant, JobApplicantStatus } from "api/resources/jobs/types"
import { Profile as ProfileType } from "api/resources/teacher/types"
import Section from "components/Section"
import SectionContent from "components/Section/SectionContent"
import SectionHeading from "components/Section/SectionHeading"
import Navbar from "components/shared/Navbar"
import TabItem from "components/Tab/TabItem"
// import Assessments from "components/teacher/Assessments"
import MarkAsCompleteDialog, {
  MarkAsCompleteDialogData,
} from "components/teacher/Dialogs/MarkAsCompleteDialog"
import RejectApplicationDialog from "components/teacher/Dialogs/RejectApplicationDialog"
import ViewFeedbackDialog from "components/teacher/Dialogs/ViewFeedbackDialog"
import Interviews from "components/teacher/Interviews"
import LiveDemos from "components/teacher/LiveDemos"
// import LiveDemos from "components/teacher/LiveDemos"
import Notes from "components/teacher/Notes"
import TeacherProfile from "components/teacher/profile"
import Overview from "components/teacher/profile/Overview"
import SendOffer from "components/teacher/SendOffer"
import Timeline from "components/teacher/Timeline"
import { useEffect, useState } from "react"
import { createUseStyles } from "react-jss"
import { useParams, useSearchParams } from "react-router-dom"
import useArray from "utils/hooks/useArray"
import useTabs, { Tabs } from "utils/hooks/useTabs"
import useToggle from "utils/hooks/useToggle"
import { SEND_OFFER_PARAM } from "utils/routes"
import { useSchoolActions } from "views/auth/useSchoolActions"
import { Actions } from "views/auth/useUserActions"
import NoAccess from "views/common/NoAccess"

import TeacherTimelineContext from "./context"

const useStyles = createUseStyles({
  tab: {
    overflowX: "auto",
  },
})

const tabs: Tabs = [
  {
    name: "Timeline",
    content: Timeline,
  },
  {
    name: "Interviews",
    content: Interviews,
  },
  {
    name: "Live Demos",
    content: LiveDemos,
  },
  // {
  //   name: "Assessments",
  //   content: Assessments,
  // },
  {
    name: "Profile",
    // eslint-disable-next-line react/display-name
    content: () => <TeacherProfile skipOverview skipContainer />,
  },
  {
    name: "Notes",
    content: Notes,
  },
]

const TeacherTimeline = () => {
  const classes = useStyles()
  const xsDown = useMediaQuery(theme.breakpoints.down("xs"))
  const { jobApplicantId, username, schoolId = "" } = useParams()

  const [searchParams] = useSearchParams()
  const [activeTab, setActiveTab] = useTabs({
    initialTab: tabs[0].id,
    tabs,
  })

  const { hasSchoolAction } = useSchoolActions({
    schoolIds: [schoolId],
  })
  const canViewHiringProcess = hasSchoolAction(
    Actions.viewHiringProcess,
    schoolId
  )
  const manageHiringProcess = hasSchoolAction(
    Actions.manageHiringProcess,
    schoolId
  )
  const canSendJobOffer =
    hasSchoolAction(Actions.manageHiringProcess, schoolId) &&
    hasSchoolAction(Actions.sendJobOffer, schoolId)

  const [jobApplicant, setJobApplicant] = useState<JobApplicant | null>(null)

  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)

  const [openFeedback, setOpenFeedback] = useState<number | null>(null)
  const [openFeedBackType, setOpenFeedBackType] = useState<StepType>(
    StepType.INTERVIEW
  )
  const [markAsCompleteDialog, setMarkAsCompleteDialog] =
    useState<MarkAsCompleteDialogData | null>(null)
  const [openRejectDialog, toggleRejectDialog] = useToggle(false)

  const [profile, setProfile] = useState<ProfileType | null>(null)

  const timeline = useArray<Step>([])

  useEffect(() => {
    const retrieveJobApplicant = async () => {
      if (!jobApplicantId) return

      const res = await api.jobs.jobApplicant.retrieve({
        urlParams: {
          jobApplicantId,
        },
        params: {
          fields: "job_offer",
        },
        headers: {
          "School-Id": schoolId,
        },
      })

      if (res.isSuccessful) {
        setJobApplicant(res.data)
      } else setError("Something went wrong")
    }

    retrieveJobApplicant()
    // Cannot add navigate to deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobApplicantId, username])

  useEffect(() => {
    const retrieveProfile = async () => {
      setLoading(true)
      if (!username) return

      const res = await api.teacher.profile.retrieve({
        urlParams: {
          username,
        },
        headers: {
          "School-Id": schoolId,
        },
      })

      if (res.isSuccessful) {
        setProfile(res.data)
        setLoading(false)
      } else {
        setError("Something went wrong")
      }
    }

    retrieveProfile()
  }, [username])

  useEffect(() => {
    const retrieveTimeline = async () => {
      if (!jobApplicantId) return

      const res = await api.teacher.jobApplicantStep.list({
        urlParams: {
          jobApplicantId,
        },
        params: {
          page: "all",
        },
        headers: {
          "School-Id": schoolId,
        },
      })

      if (res.isSuccessful) {
        timeline.set(res.data)
      } else {
        setError("Something went wrong")
      }
    }

    retrieveTimeline()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobApplicantId])

  const isSendOffer = searchParams.get(SEND_OFFER_PARAM)

  if (!profile || loading)
    return (
      <>
        <Navbar />
        <div className="flex items-center justify-center p-3">
          <CircularProgress />
        </div>
      </>
    )

  if (error)
    return (
      <>
        <Navbar />
        <Container>
          <Section>
            <SectionContent className="flex items-center justify-center p-3">
              <Typography>{error}</Typography>
            </SectionContent>
          </Section>
        </Container>
      </>
    )

  const handleOpenFeedback = (id: number | null, type: StepType) => {
    setOpenFeedBackType(type)
    setOpenFeedback(id)
  }

  const areActionsAllowed = () => {
    if (!jobApplicant?.status) {
      return true
    }
    switch (jobApplicant.status) {
      case JobApplicantStatus.REJECTED:
      case JobApplicantStatus.JOB_OFFER_SENT:
      case JobApplicantStatus.JOB_OFFER_ACCEPTED:
      case JobApplicantStatus.JOB_OFFER_REJECTED:
      case JobApplicantStatus.WITHDRAWN:
        return false
      default:
        return true
    }
  }
  if (!canViewHiringProcess) {
    return (
      <>
        <Navbar hideBackButton gutterBottom={false} />
        <NoAccess />
      </>
    )
  }

  return (
    <>
      <Navbar hideBackButton gutterBottom={false} />
      <Overview profile={profile} />
      <Container className="mb-3" fullWidth={xsDown}>
        <>
          {isSendOffer && canSendJobOffer && (
            <div className="mb-3">
              <Typography className="mb-3" variant="title2">
                Send Job Offer
              </Typography>
            </div>
          )}

          {isSendOffer && canSendJobOffer ? (
            <SendOffer />
          ) : (
            <Section>
              <SectionHeading
                className={classes.tab}
                heading={
                  <div className="flex justify-between gap-2">
                    {tabs.map(tab => (
                      <TabItem
                        isActive={tab.name === activeTab?.name}
                        key={tab.name}
                        onClick={() => setActiveTab(tab.name)}
                      >
                        {tab.name}
                      </TabItem>
                    ))}
                  </div>
                }
              />

              <TeacherTimelineContext.Provider
                value={{
                  profile,
                  dialogs: {
                    openFeedback: {
                      toggle: handleOpenFeedback,
                    },
                    markAsComplete: {
                      toggle: setMarkAsCompleteDialog,
                    },
                  },
                  timeline: {
                    data: timeline.array,
                    update: timeline.updateByKey,
                    remove: timeline.removeByKey,
                    add: timeline.unshift,
                  },
                  assessments: {
                    data: timeline.array.filter(
                      i => i.stepType === StepType.ASSESSMENT
                    ) as AssessmentDetailsStep[],
                    update: timeline.updateByKey,
                    remove: timeline.removeByKey,
                    add: timeline.unshift,
                  },
                  interviews: {
                    data: timeline.array.filter(
                      i => i.stepType === StepType.INTERVIEW
                    ) as InterviewDetailsStep[],
                    update: timeline.updateByKey,
                    remove: timeline.removeByKey,
                    add: timeline.unshift,
                  },
                  liveDemos: {
                    data: timeline.array.filter(
                      i => i.stepType === StepType.LIVE_DEMO
                    ) as LiveDemoDetailsStep[],
                    update: timeline.updateByKey,
                    remove: timeline.removeByKey,
                    add: timeline.unshift,
                  },
                  jobId: jobApplicant?.job,
                  actionsAllowed: areActionsAllowed(),
                  isRejected:
                    jobApplicant?.status === JobApplicantStatus.REJECTED,
                }}
              >
                <SectionContent xPadding={2}>
                  <activeTab.content
                    manageHiringProcess={manageHiringProcess}
                  />
                </SectionContent>
              </TeacherTimelineContext.Provider>

              {openFeedback && (
                <ViewFeedbackDialog
                  id={openFeedback}
                  open={Boolean(openFeedback)}
                  toggle={() => setOpenFeedback(null)}
                  type={openFeedBackType}
                />
              )}

              {markAsCompleteDialog && (
                <MarkAsCompleteDialog
                  data={markAsCompleteDialog}
                  open={Boolean(markAsCompleteDialog)}
                  toggle={() => setMarkAsCompleteDialog(null)}
                />
              )}

              {jobApplicant && (
                <RejectApplicationDialog
                  schoolId={schoolId || ""}
                  resourceId={jobApplicant.id}
                  jobPosition="this job"
                  mode="activeApplication"
                  afterSubmit={() => window.location.reload()}
                  open={openRejectDialog}
                  toggle={toggleRejectDialog}
                  userName={profile.user.firstName}
                />
              )}
            </Section>
          )}
        </>
      </Container>
    </>
  )
}

export default TeacherTimeline
