import { Button, Typography } from "@suraasa/placebo-ui"
import {
  InterviewDetailsStep,
  JobApplicantStepStatus,
  StepType,
} from "api/resources/jobs/step.types"
import { format } from "date-fns"
import { Clock } from "iconoir-react"
import React, { useContext } from "react"
import { createUseStyles } from "react-jss"
import { Link, useParams } from "react-router-dom"
import { isMissed } from "utils/getStepStatusTag"
import { generateHuddleURL } from "utils/helpers"
// import TimelineDivider from "components/Timeline/TimelineDivider"
import useToggle from "utils/hooks/useToggle"
import TeacherTimelineContext from "views/teacher/context"

import AddFeedbackDialog from "../Dialogs/AddFeedbackDialog"
import EditInterviewDialog from "../Dialogs/EditInterviewDialog"
import ListItem from "../ListItem"

const useStyles = createUseStyles(theme => ({
  list: {
    [theme.breakpoints.down("xs")]: {
      "& svg": {
        display: "none",
      },
    },
  },
}))

type Props = {
  tag?: React.ReactElement
} & InterviewDetailsStep

const InterviewItem = ({ tag, ...props }: Props) => {
  const {
    id,
    status,
    dateCreated,
    step: { name, scheduledTime, feedback, url, ...step },
  } = props

  const classes = useStyles()

  const {
    isRejected,
    timeline,
    dialogs: { openFeedback, markAsComplete },
  } = useContext(TeacherTimelineContext)

  const params = useParams()
  const schoolId = params.schoolId || ""

  const [feedbackDialog, toggleFeedbackDialog] = useToggle(false)
  const [openInterviewDialog, toggleOpenInterviewDialog] = useToggle(false)

  const handleMarkAsComplete = () => {
    if (!feedback) toggleFeedbackDialog()
    timeline.update(id, {
      ...props,
      status: JobApplicantStepStatus.COMPLETED,
    })
  }

  const getAction = () => {
    switch (status) {
      case JobApplicantStepStatus.COMPLETED:
        return feedback ? (
          <Button
            variant="text"
            onClick={() => openFeedback.toggle(step.id, StepType.INTERVIEW)}
          >
            Open Feedback
          </Button>
        ) : (
          <Button
            disabled={isRejected}
            variant="text"
            onClick={() => toggleFeedbackDialog()}
          >
            Add Feedback
          </Button>
        )

      case JobApplicantStepStatus.NOT_STARTED:
        return url ? (
          <>
            <Button
              color="success"
              disabled={isRejected}
              variant="text"
              onClick={() =>
                markAsComplete.toggle({
                  id,
                  name,
                  afterSubmit: handleMarkAsComplete,
                  schoolId,
                })
              }
            >
              Mark As Complete
            </Button>
            <Button
              color="primary"
              component={url ? Link : undefined}
              disabled={isRejected || !url}
              rel="opener"
              size="sm"
              target="_blank"
              to={generateHuddleURL({
                meetingUrl: url,
                interviewId: step.id,
              })}
            >
              Join Interview
            </Button>
          </>
        ) : (
          <>
            {feedback ? (
              <Button
                variant="text"
                onClick={() => openFeedback.toggle(step.id, StepType.INTERVIEW)}
              >
                Open Feedback
              </Button>
            ) : (
              <Button
                disabled={isRejected}
                variant="text"
                onClick={() => toggleFeedbackDialog()}
              >
                Add Feedback
              </Button>
            )}
            {!isMissed(props) && (
              <Button
                color="success"
                disabled={isRejected}
                size="sm"
                onClick={() =>
                  markAsComplete.toggle({
                    id,
                    name,
                    afterSubmit: handleMarkAsComplete,
                    schoolId,
                  })
                }
              >
                Mark as complete
              </Button>
            )}
          </>
        )
      case JobApplicantStepStatus.IN_PROGRESS:
        return (
          <>
            <Button
              color="success"
              disabled={isRejected}
              variant="text"
              onClick={() =>
                markAsComplete.toggle({
                  id,
                  name,
                  afterSubmit: handleMarkAsComplete,
                  schoolId,
                })
              }
            >
              Mark as complete
            </Button>

            {url && (
              <Button
                color="primary"
                component={url ? Link : undefined}
                disabled={isRejected || !url}
                rel="opener"
                size="sm"
                target="_blank"
                to={generateHuddleURL({
                  meetingUrl: url,
                  interviewId: step.id,
                })}
              >
                Join Interview
              </Button>
            )}
          </>
        )
      case JobApplicantStepStatus.SKIPPED:
        return null
      default:
        break
    }
  }
  return (
    <>
      <EditInterviewDialog
        afterSubmit={data => timeline.update(id, data)}
        data={props}
        open={openInterviewDialog}
        toggle={toggleOpenInterviewDialog}
        schoolId={schoolId}
      />

      <AddFeedbackDialog
        afterSubmit={feedbackData => {
          timeline.update(id, {
            ...props,
            step: { ...props.step, feedback: feedbackData },
          })
        }}
        schoolId={schoolId}
        id={step.id}
        open={feedbackDialog}
        toggle={toggleFeedbackDialog}
        type={StepType.INTERVIEW}
      />

      <div style={{ width: "100%" }}>
        <div className="flex flex-wrap gap-1 sm:gap-1.5">
          <Typography color="onSurface.800" variant="strong">
            {name}
          </Typography>

          {tag}
          <Typography
            className="shrink-0 sm:ml-auto"
            color="onSurface.500"
            variant="smallBody"
          >
            Posted on {format(new Date(dateCreated), "do MMM yy")}
          </Typography>
        </div>
        <Typography color="onSurface.400" variant="smallBody">
          Interview
        </Typography>

        <ListItem className={classes.list} icon={<Clock />}>
          <div className="flex flex-col sm:block">
            <Typography
              color="onSurface.400"
              display="inline"
              variant="smallBody"
            >
              Scheduled for{" "}
            </Typography>
            <Typography display="inline" variant="strongSmallBody">
              {format(new Date(scheduledTime), "dd MMMM yyyy hh:mm aa")}
            </Typography>
          </div>
        </ListItem>
        <div className="mt-3 flex flex-wrap justify-end gap-1">
          {getAction()}
        </div>
      </div>
    </>
  )
}

export default InterviewItem
