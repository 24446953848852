import { Divider, Typography } from "@suraasa/placebo-ui"

function EditHeadings({ text }: { text: string }) {
  return (
    <div className="flex flex-row items-center">
      <Typography color="onSurface.500" variant="smallBody">
        {text}
      </Typography>
      <div className="grow pl-2">
        <Divider weight="light" />
      </div>
    </div>
  )
}

export default EditHeadings
