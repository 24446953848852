import { Divider, Typography } from "@suraasa/placebo-ui"
import { SubmissionAttachment } from "api/resources/assignments/types"
import { countWords } from "utils/countWords"

import Attachment from "./Attachment"
import styles from "./Submission.module.css"

export const Paper = (props: any) => (
  <div className="-mx-2 overflow-x-auto rounded-none border-x-0 border-surface-200 bg-common-white-500 p-2 sm:mx-0 sm:rounded-xl sm:border">
    {/* eslint-disable-next-line react/destructuring-assignment */}
    {props.children}
  </div>
)

type Props = {
  content: string
  references?: string | null
  citations?: string | null
  attachments: SubmissionAttachment[]
}
const Submission = ({ references, content, attachments, citations }: Props) => {
  const contentWordCount = countWords(content)
  const referencesWordCount = countWords(references || "")
  const citationsWordCount = countWords(citations || "")

  const noContent = contentWordCount === 0 && attachments.length === 0

  return (
    <Paper>
      {noContent && (
        <Typography>No content was posted in this submission</Typography>
      )}

      {contentWordCount > 0 && (
        <Typography variant="body">
          <div
            className={styles.contentDiv}
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </Typography>
      )}

      {referencesWordCount > 0 && (
        <div className="mt-4">
          <Typography className="mb-1" variant="title3">
            References
          </Typography>
          <Typography variant="body">
            <div
              className={styles.contentDiv}
              dangerouslySetInnerHTML={{ __html: references || "" }}
            />
          </Typography>
        </div>
      )}

      {citationsWordCount > 0 && (
        <div className="mt-4">
          <Typography className="mb-1" variant="title3">
            Citations
          </Typography>
          <Typography variant="body">
            <div
              className={styles.contentDiv}
              dangerouslySetInnerHTML={{ __html: citations || "" }}
            />
          </Typography>
        </div>
      )}

      {attachments.length > 0 && (
        <>
          <Divider className="my-2.75" color="onSurface.200" weight="light" />
          <div className="flex flex-wrap items-center justify-between gap-1 sm:justify-start">
            {attachments.map((attachment, index) => (
              <Attachment file={attachment} key={index} />
            ))}
          </div>
        </>
      )}
    </Paper>
  )
}

export default Submission
