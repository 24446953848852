import api from "api"
import { UserTypesActions } from "api/resources/recruiter/types"
import { useEffect, useRef, useState } from "react"
import { ValueOf } from "utils/helpers"

import useUserActions, { Actions } from "./useUserActions"

const useSchoolActions = ({
  schoolIds,
}: {
  schoolIds: string[] | number[]
}) => {
  const { hasAction } = useUserActions()

  const [loading, setLoading] = useState(false)

  const [schoolActions, setSchoolActions] = useState<
    UserTypesActions["schoolActions"]
  >([])

  const prevSchoolIdsRef = useRef<string[] | number[]>([])

  const hasSchoolAction = (
    action: ValueOf<typeof Actions>,
    schoolId: string | number
  ): boolean => {
    if (!schoolActions) {
      return false
    }
    const rowActions = schoolActions.find(
      s => s.schoolId.toString() === schoolId.toString()
    )

    if (rowActions && hasAction(action, rowActions.actions)) {
      return true
    }

    return false
  }

  useEffect(() => {
    const getDataActions = async () => {
      setLoading(true)
      try {
        const res = await api.recruiter.getTableActions({
          params: {
            fields: ["school_actions"],
            school_ids: schoolIds.map(id => id.toString()),
          },
        })
        if (res.schoolActions && res.schoolActions.length > 0) {
          setSchoolActions(res.schoolActions)
        }
      } catch (e) {}
      setLoading(false)
    }
    if (
      schoolIds.length &&
      JSON.stringify(schoolIds) !== JSON.stringify(prevSchoolIdsRef.current)
    ) {
      getDataActions()
      prevSchoolIdsRef.current = schoolIds
    }
  }, [schoolIds])

  return {
    schoolActions,
    hasSchoolAction,
    loading,
  }
}

export { useSchoolActions }
