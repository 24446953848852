import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogProps,
  DialogTitle,
  IconButton,
  Typography,
} from "@suraasa/placebo-ui"
import api from "api"
import clsx from "clsx"
import RemoveDialog from "components/RemoveDialog"
import { Plus, Trash } from "iconoir-react"
import capitalize from "lodash/capitalize"
import { useContext, useEffect, useState } from "react"
import { createUseStyles } from "react-jss"
import { useParams } from "react-router-dom"
import toast from "utils/toast"
import ProfileContext from "views/Profile/context"

import AddDialog from "./AddDialog"

const useStyles = createUseStyles(theme => ({
  dialogContent: {
    padding: 0,
    display: "flex",
    flexDirection: "column",
  },
  addAmenities: {
    borderBottom: `1px solid ${theme.colors.onSurface[200]}`,
  },
  amenitiesContainer: {
    display: "flex",
    padding: theme.spacing(1.5, 3, 1.5, 0),
    alignItems: "center",
    justifyContent: "space-between",
    color: theme.colors.critical[500],
    borderBottom: `1px solid ${theme.colors.onSurface[200]}`,
    "&:last-child": {
      border: "none",
    },
  },
}))

const AmenitiesDialog = ({
  open,
  handleClose,
}: { handleClose: () => void } & Pick<DialogProps, "open">) => {
  const classes = useStyles()

  const {
    amenities: { data, add, remove },
  } = useContext(ProfileContext)

  const { schoolId } = useParams() as { schoolId: string }

  const hasNoItems = data.length === 0

  const [openAddAmenitiesDialog, setOpenAddAmenitiesDialog] =
    useState(hasNoItems)
  const [amenityToDelete, setAmenityToDelete] = useState<string | null>(null)

  const [removeDialogLoading, setRemoveDialogLoading] = useState(false)

  const handleRemove = async () => {
    if (!amenityToDelete) return

    setRemoveDialogLoading(true)

    const res = await api.profile.amenities.delete({
      urlParams: { id: amenityToDelete },
      headers: { "School-Id": schoolId },
    })
    if (res.isSuccessful) {
      remove(amenityToDelete)
      setAmenityToDelete(null)
      toast.success("Removed successfully.")
    } else {
      toast.error(res.errors.message || "Something went wrong")
    }
    setRemoveDialogLoading(false)
  }

  useEffect(() => {
    if (open) setOpenAddAmenitiesDialog(hasNoItems)
  }, [open, hasNoItems])

  return (
    <Dialog
      open={open}
      width={518}
      onAfterClose={() => setOpenAddAmenitiesDialog(false)}
      onRequestClose={() => handleClose()}
    >
      {openAddAmenitiesDialog ? (
        <AddDialog
          handleBack={
            hasNoItems ? undefined : () => setOpenAddAmenitiesDialog(false)
          }
          open={openAddAmenitiesDialog}
          onAdd={add}
          onRequestClose={() => handleClose()}
        />
      ) : (
        <>
          <RemoveDialog
            handleClose={() => setAmenityToDelete(null)}
            loading={removeDialogLoading}
            open={Boolean(amenityToDelete)}
            title="Remove Amenity"
            keepScrollLocked
            onRemove={handleRemove}
          >
            <Typography variant="smallBody">
              Are you sure you want to remove{" "}
              <b>
                {capitalize(
                  data.find(item => item.id === amenityToDelete)?.name
                )}
              </b>{" "}
              from the amenities?
            </Typography>
          </RemoveDialog>
          <DialogTitle>Amenities</DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <div className={clsx(classes.addAmenities, "px-1.5 py-2")}>
              <Button
                startAdornment={<Plus />}
                variant="text"
                onClick={() => setOpenAddAmenitiesDialog(true)}
              >
                Add New Amenity
              </Button>
            </div>
            <div className="flex flex-col gap-1.5 py-1.5 pl-3 pr-0">
              {data.map(item => (
                <div className={classes.amenitiesContainer} key={item.id}>
                  <div>
                    <Typography
                      className="pb-0.5"
                      color="onSurface.800"
                      variant="strong"
                    >
                      {capitalize(item.name)}
                    </Typography>
                  </div>
                  <IconButton
                    color="critical"
                    onClick={() => {
                      setAmenityToDelete(item.id)
                    }}
                  >
                    <Trash />
                  </IconButton>
                </div>
              ))}
            </div>
          </DialogContent>
          <DialogFooter
            actions={{
              primary: null,
              secondary: {
                variant: "outlined",
                color: "secondary",
                label: "Close",
              },
            }}
          />
        </>
      )}
    </Dialog>
  )
}

export default AmenitiesDialog
