import {
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  Radio,
  RangeSlider,
  Select,
  TextField,
  Typography,
} from "@suraasa/placebo-ui"
import VerifiedBadge from "assets/home/verifiedBadge.svg"
import clsx from "clsx"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { createUseStyles } from "react-jss"
import { useSearchParams } from "react-router-dom"
import { buildParams } from "utils/helpers"

import LocationFilter from "./LocationFilter"

export const getDefaultFilters: () => Filter[] = () => [
  {
    id: "is_verified",
    type: "multi",
    options: [{ label: "Suraasa Verified", value: "true" }],
  },
  {
    displayName: "Subject",
    id: "subjects[]",
    type: "multi-select",
    options: [],
  },
  {
    displayName: "Curriculum",
    id: "curriculum[]",
    type: "multi",
    options: [],
  },
  {
    displayName: "Current Residence",
    id: "location",
    type: "location",
  },
  {
    displayName: "Experience (Years)",
    id: "Years",
    type: "range",
    min: 0,
    max: 10,
    maxValueLabel: "10+",
  },
]

export type Filter = {
  displayName?: string
  id: string
} & (
  | { type: "text" | "location" }
  | {
      type: "single" | "multi" | "multi-select"
      options: { label: string; value: string }[]
    }
  | {
      type: "range"
      min: number
      max: number
      maxValueLabel?: string
      minValueLabel?: string
    }
)

const useStyles = createUseStyles(theme => ({
  root: {
    background: "white",
    border: `1px solid ${theme.colors.surface[200]}`,
    borderRadius: "4px",
    height: "max-content",
  },
  userName: {
    "&:hover": {
      textDecorationColor: "black",
    },
  },
  imageContainer: {
    position: "relative",
    height: "max-content",
  },
  image: {
    width: "48px",
    height: "48px",
  },
  imageBadge: {
    position: "absolute",
    bottom: "0",
    right: "-3px",
  },
  range: {
    padding: theme.spacing(0, 1),
  },
  separator: {
    color: theme.colors.primary[300],
  },
  optionsContainer: {
    paddingTop: theme.spacing(0.5),
    paddingLeft: theme.spacing(0.5),
    maxHeight: 300,
    overflowY: "auto",
    overflowX: "hidden",
    minHeight: "30px",
  },
  rangeContainer: {
    overflow: "visible",
  },
}))

type Props = {
  disabled?: boolean
  className?: string
  filters: Filter[]
  otherFilters?: Record<string, string>
  onChange?: (params: URLSearchParams) => void
}

type SelectedFilters = {
  [key: string]: string | string[]
}

const getInitialState = (filters: Filter[], searchParams: URLSearchParams) => {
  const initialState: SelectedFilters = {}

  filters.forEach(filter => {
    switch (filter.type) {
      case "multi":
      case "multi-select": {
        initialState[filter.id] = searchParams.getAll(filter.id) || []
        break
      }
      case "single": {
        initialState[filter.id] = searchParams.get(filter.id) || ""
        break
      }

      case "text": {
        initialState[filter.id] = searchParams.get(filter.id) || ""
        break
      }
      case "range": {
        initialState[`min${filter.id}`] =
          searchParams.get(`min${filter.id}`) || ""
        initialState[`max${filter.id}`] =
          searchParams.get(`max${filter.id}`) || ""
        break
      }

      case "location": {
        initialState.country = searchParams.get("country") || ""
        initialState.state = searchParams.get("state") || ""
        break
      }
      default:
        break
    }
  })

  return initialState
}

const Filters = React.memo(
  ({
    className,
    filters,
    onChange,
    otherFilters = {},
    disabled = false,
  }: Props) => {
    const classes = useStyles()

    const [searchParams, setSearchParams] = useSearchParams()
    const [selectedFilters, setFilters] = useState<SelectedFilters>(
      getInitialState(filters, searchParams)
    )

    useEffect(() => {
      const params = buildParams({ ...selectedFilters, ...otherFilters })
      // console.log(selectedFilters, params.toString())
      if (onChange) onChange(params)

      // Update query params in the browser URL
      setSearchParams(params, { replace: true })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFilters, otherFilters])

    const toggleValue = (array: string[], value: string) => {
      if (array.includes(value)) {
        return array.filter((v: any) => v !== value)
      }
      return [...array, value]
    }

    const handleFilterChange = useCallback(
      (
        filter: Filter,
        value:
          | string
          | { country: string; state: string | null }
          | string[]
          | { min: number; max: number }
      ) => {
        if (typeof value !== "string" && "country" in value) {
          return setFilters(prevState => ({
            ...prevState,
            country: value.country,
            state: value.state || "",
          }))
        }

        if (typeof value !== "string" && "min" in value) {
          setFilters(prevState => ({
            ...prevState,
            [`min${filter.id}`]:
              "min" in filter && filter.min === value.min
                ? ""
                : value.min.toString() || "",
            [`max${filter.id}`]:
              "max" in filter && filter.max === value.max
                ? ""
                : value.max.toString() || "",
          }))
        }

        if (typeof value === "string") {
          switch (filter.type) {
            case "single":
              setFilters(prevState => {
                const newFilters = {
                  ...prevState,
                  [filter.id]: prevState[filter.id] === value ? "" : value,
                }
                return newFilters
              })
              break
            case "multi": {
              setFilters(prevState => {
                const newFilters = {
                  ...prevState,
                  // @ts-expect-error This will be fixed when we streamline the type of SelectedFilters
                  [filter.id]: toggleValue(prevState[filter.id], value),
                }
                return newFilters
              })
              break
            }

            case "text": {
              setFilters(prevState => ({ ...prevState, [filter.id]: value }))
              break
            }

            default:
              break
          }
        } else if (Array.isArray(value)) {
          setFilters(prevState => ({ ...prevState, [filter.id]: value }))
        }
      },
      []
    )

    const onRangeChange = useCallback(
      (filter: Filter, value: { min: number; max: number }) => {
        handleFilterChange(filter, value)
      },
      [handleFilterChange]
    )

    const handleClearFilters = () => {
      const clearedFilters: SelectedFilters = {}
      Object.keys(selectedFilters).forEach(filter => {
        clearedFilters[filter] = ""
      })

      setFilters(clearedFilters)
    }

    const enableFiltersButton = useMemo(
      () =>
        Object.values(selectedFilters).some(item => {
          if (Array.isArray(item)) return item.length > 0

          if (item !== "") return true

          return false
        }),
      [selectedFilters]
    )

    return (
      <div>
        <div className="flex h-9 justify-end">
          <Button
            disabled={!enableFiltersButton || disabled}
            variant="text"
            onClick={handleClearFilters}
          >
            Clear All Filters
          </Button>
        </div>
        <div
          className={clsx("flex flex-col gap-3 p-2", classes.root, className)}
        >
          {filters.map((filter, index) => (
            <div key={index}>
              {filter.displayName && (
                <>
                  <div className="mb-1 flex items-center justify-between">
                    <Typography variant="strong">
                      {filter.displayName}
                    </Typography>
                    {(filter.type === "multi" || filter.type === "single") && (
                      <Button
                        disabled={disabled}
                        variant="text"
                        onClick={() => {
                          setFilters(prevState => ({
                            ...prevState,
                            [filter.id]: [],
                          }))
                        }}
                      >
                        Clear
                      </Button>
                    )}
                  </div>
                  <Divider
                    className={clsx({ "mb-2.5": filter.type !== "range" })}
                  />
                </>
              )}

              <div
                className={clsx(classes.optionsContainer, {
                  [classes.rangeContainer]: filter.type === "range",
                })}
              >
                {"options" in filter && filter.options.length === 0 ? (
                  <div className="flex justify-center">
                    <CircularProgress />
                  </div>
                ) : (
                  <>
                    {filter.type === "multi" &&
                      (filter.id === "is_verified" ? (
                        <div className="flex items-center gap-1">
                          {filter.options.map(option => (
                            <div key={option.value}>
                              <Checkbox
                                checked={selectedFilters[filter.id].includes(
                                  option.value
                                )}
                                defaultChecked={undefined}
                                disabled={disabled}
                                label={option.label}
                                value={option.value}
                                onChange={() => {
                                  handleFilterChange(filter, option.value)
                                }}
                              />
                            </div>
                          ))}
                          <img src={VerifiedBadge} alt="" />
                        </div>
                      ) : (
                        filter.options.map(option => (
                          <div key={option.value}>
                            <Checkbox
                              checked={selectedFilters[filter.id].includes(
                                option.value
                              )}
                              className="mb-1.5"
                              defaultChecked={undefined}
                              disabled={disabled}
                              label={option.label}
                              value={option.value}
                              onChange={() => {
                                handleFilterChange(filter, option.value)
                              }}
                            />
                          </div>
                        ))
                      ))}

                    {filter.type === "single" &&
                      filter.options.map(option => (
                        <div key={option.value}>
                          <Radio
                            checked={
                              selectedFilters[filter.id] === option.value
                            }
                            className="mb-1.5"
                            disabled={disabled}
                            label={option.label}
                            value={option.value}
                            onChange={() => {
                              handleFilterChange(filter, option.value)
                            }}
                          />
                        </div>
                      ))}

                    {filter.type === "range" && (
                      <RangeSlider
                        className={classes.range}
                        max={filter.max}
                        maxValueLabel={filter.maxValueLabel}
                        min={filter.min}
                        minValueLabel={filter.minValueLabel}
                        value={{
                          min:
                            Number(selectedFilters[`min${filter.id}`]) ||
                            filter.min,
                          max:
                            Number(selectedFilters[`max${filter.id}`]) ||
                            filter.max,
                        }}
                        onChange={value => {
                          if (disabled) return
                          onRangeChange(filter, value)
                        }}
                      />
                    )}

                    {filter.type === "text" && (
                      <TextField
                        disabled={disabled}
                        placeholder={filter.displayName}
                        value={selectedFilters[filter.id]}
                        fullWidth
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handleFilterChange(filter, e.target.value)
                        }
                      />
                    )}

                    {filter.type === "location" && (
                      <LocationFilter
                        disabled={disabled}
                        value={{
                          country: selectedFilters.country as string,
                          state: selectedFilters.state as string,
                        }}
                        onChange={val => {
                          handleFilterChange(
                            { id: "location", type: "location" },
                            val
                          )
                        }}
                      />
                    )}

                    {filter.type === "multi-select" && (
                      <Select
                        className="p-0.5"
                        getOptionLabel={option => option.label}
                        getOptionValue={option => option.value}
                        isDisabled={disabled}
                        options={filter.options}
                        placeholder={
                          filter.options.length > 0 &&
                          `Ex: ${filter.options[0].label}`
                        }
                        value={filter.options.filter(option =>
                          selectedFilters[filter.id].includes(option.value)
                        )}
                        fullWidth
                        isClearable
                        isMulti
                        isSearchable
                        mountOnBody
                        onChange={v => {
                          handleFilterChange(
                            filter,
                            v.map(va => va.value)
                          )
                        }}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    )
  }
)

Filters.displayName = "Filters"
export default Filters
