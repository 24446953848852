import { PhoneNumber } from "../users/types"

export enum UserType {
  SCHOOL_ADMIN = "school_admin",
  MEMBER_RECRUITER = "member_recruiter",
  LEAD_RECRUITER = "lead_recruiter",
  LEAD_PARTNER = "lead_partner",
  MEMBER_PARTNER = "member_partner",
  LEAD_ADMIN = "lead_admin",
}

export type UserTypesActions = {
  userType: UserType[]
  schoolId: number | null
  actions?: string[]
  schoolActions?: {
    schoolId: number
    actions: string[]
  }[]
}

export type AccessibleSchoolType = {
  id: number
  name: string
  slug: string
  totalJobCount: number
  activeJobCount: number
  address: string | null
  assignedRecruiter?: {
    uuid: string
    email: string
    fullName: string
  }
  assignedPartner?: {
    uuid: string
    email: string
    fullName: string
  }
  fullName?: string | null
  recruiterEmail?: string
}

export type LearnerType = {
  product: "learning"
  username: string
  email: string
  fullName: string
  uuid: string
  profile: {
    picture: string | null
    isVerified: boolean | null
    phoneNumber: PhoneNumber | null
  }
  schoolCount: number
  placementRecords: {
    schoolName: string
    jobTitle: string
    dateOfHiring: string
  }[]
}

export type RecruiterType = {
  email: string
  fullName: string
  product: string
  uuid: string
  schoolCount: number
  representativeId: number
  roles: string[]
}

export type CreateSchool = {
  id: number
  name: string
  slug: string
}

export type AddRecruiterType = {
  email: string
  firstName: string
  lastName: string
  phoneNumber: string
}

export type CheckRecruiter = {
  email: string
  firstName: string
  lastName: string
  phoneNumber: PhoneNumber
}

export type ActiveSchoolApplicants = {
  user: {
    product: "learning"
    username: string
    email: string
    fullName: string
    uuid: string
    profile: {
      picture: string | null
      isVerified: boolean | null
      phoneNumber: PhoneNumber | null
    }
  }
  activeApplicationsCount: number
  previousApplicationsCount: number
  activeApplications: {
    jobApplicantId: number
    jobTitle: string
  }[]
  previousApplications: {
    jobApplicantId: number
    jobTitle: string
  }[]
}

export type HiredSchoolApplicants = {
  id: number
  status: "job_offer_accepted"
  jobOffer: {
    id: number
    status: string
    dateCreated: string
  } | null
  user: {
    product: "learning"
    username: string
    email: string
    profile: {
      picture: string | null
      isVerified: boolean | null
      phoneNumber?: PhoneNumber | null
    }
    fullName: string
    uuid: string
  }
}
