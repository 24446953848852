import { Typography } from "@suraasa/placebo-ui"
import Section from "components/teacher/profile/Section"
import SectionContent from "components/teacher/profile/Section/SectionContent"
import SectionHeading from "components/teacher/profile/Section/SectionHeading"
import SectionTitle from "components/teacher/profile/Section/SectionTitle"
import React, { useContext } from "react"
import { createUseStyles } from "react-jss"
import ProfileContext from "views/teacher/profileContext"

import { ProfileQuickAccessTabs } from "../utils"

const useStyles = createUseStyles({
  sectionContainer: {
    whiteSpace: "pre-wrap",
    wordBreak: "break-word",
  },
})

const About = () => {
  const classes = useStyles()

  const {
    profile: { careerAspiration },
  } = useContext(ProfileContext)

  return (
    <Section id={ProfileQuickAccessTabs.about}>
      <SectionHeading heading={<SectionTitle title="About" />} xPadding={2} />
      <SectionContent className={classes.sectionContainer} xPadding={2}>
        <Typography
          color={careerAspiration ? "onSurface.800" : "onSurface.400"}
          variant="smallBody"
        >
          {careerAspiration || "-"}
        </Typography>
      </SectionContent>
    </Section>
  )
}

export default About
