import { IconButton, Table, TableFilter, Typography } from "@suraasa/placebo-ui"
import { Representative } from "api/resources/representatives/types"
import clsx from "clsx"
import NoDataCard from "components/jobs/NoDataCard"
import TableWrapper from "components/TableWrapper"
import { useEffect, useState } from "react"
import { SetState } from "utils/helpers"
import useUserActions from "views/auth/useUserActions"
import ArrowSvg from "views/common/SchoolTable/ArrowSvg"

import NoAccess from "../NoAccess"
import { checkIsLead, joinRoles } from "../utils"
import TeamDetailSheet from "./TeamDetailSheet"

type Props = {
  data: Representative[]
  isLoading: boolean
  onCountUpdate?: (count: number) => void
  setSortBy: SetState<string[]>
  setFilters?: SetState<TableFilter[]>
}

const TeamMembersTable = ({
  data,
  onCountUpdate,
  setSortBy,
  isLoading,
  setFilters,
}: Props) => {
  const { teamTab } = useUserActions()
  const { canViewNameEmailRole } = teamTab

  const [details, setDetails] = useState<Representative | null>(null)

  useEffect(() => {
    onCountUpdate?.(data.length)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  if (!canViewNameEmailRole) {
    return <NoAccess />
  }

  return (
    <>
      <TeamDetailSheet
        open={Boolean(details)}
        onClose={() => setDetails(null)}
        row={details}
      />
      <TableWrapper
        content={className => (
          <>
            <Table
              key="team-table"
              className={className}
              data={data}
              loading={isLoading}
              rowComponent={row => {
                return (
                  <tr
                    key={`${row.uuid} - ${row.email}`}
                    className={clsx(
                      checkIsLead(row.roles) &&
                        "bg-gradient-to-r from-[#DBEAFE] to-[#DBEAFE00]"
                    )}
                  >
                    <td>
                      <Typography
                        color="onSurface.800"
                        variant="strongSmallBody"
                      >
                        {row.fullName}
                      </Typography>
                    </td>

                    <td className="max-w-xs break-words">
                      <Typography color="onSurface.800" variant="smallBody">
                        {row.email}
                      </Typography>
                    </td>

                    <td>
                      <Typography
                        color="onSurface.500"
                        variant={
                          checkIsLead(row.roles) ? "strongSmallBody" : "body"
                        }
                      >
                        {joinRoles(row.roles)}
                      </Typography>
                    </td>
                    <td>
                      <Typography color="onSurface.500" variant="body">
                        {row.schoolCount}
                      </Typography>
                    </td>

                    <td>
                      <IconButton
                        onClick={() => {
                          setDetails(row)
                        }}
                      >
                        <ArrowSvg />
                      </IconButton>
                    </td>
                  </tr>
                )
              }}
              headers={[
                {
                  title: "Name",
                  id: "name",
                  sortable: true,
                },
                {
                  title: "Email",
                  id: "email",
                },
                {
                  title: "Role",
                  id: "role",
                  filters: [
                    {
                      displayName: "Recruiter",
                      value: "member_recruiter",
                    },
                    {
                      displayName: "Partner",
                      value: "member_partner",
                    },
                    {
                      displayName: "Lead Recruiter",
                      value: "lead_recruiter",
                    },
                    {
                      displayName: "Lead Partner",
                      value: "lead_partner",
                    },
                  ],
                },
                {
                  title: "School Assigned",
                  id: "school_assigned",
                },
              ]}
              onFilter={filters => {
                setFilters?.(filters)
              }}
              multiSort={false}
              onSort={cols => {
                if (cols.length > 0) {
                  setSortBy(cols)
                } else {
                  setSortBy([""])
                }
              }}
            />
          </>
        )}
      />

      {data.length === 0 && <NoDataCard />}
    </>
  )
}

export default TeamMembersTable
