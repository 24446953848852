import { IconButton, Typography } from "@suraasa/placebo-ui"
import clsx from "clsx"
import { Edit } from "iconoir-react"
import React from "react"
import { createUseStyles } from "react-jss"

const useStyles = createUseStyles(theme => ({
  listing: {
    width: "100%",
    textAlign: "left",
    cursor: "pointer",
    padding: theme.spacing(1.5, 2),
    borderRadius: "4px",
    transition: "all 0.3s",
    "&:hover": {
      background: theme.colors.surface[100],
    },

    "&:active": {
      background: theme.colors.surface[100],
      outline: `1px solid ${theme.colors.surface[300]}`,
    },

    "&:focus-visible": {
      background: theme.colors.surface[100],
      outline: `1px solid ${theme.colors.surface[300]}`,
    },
  },
  selected: {
    background: theme.colors.primary[50],
  },
}))

type Props = {
  className?: string
  selected?: boolean
  data: {
    id: number
    title: string
    subtitle?: string
  }
  onEdit: () => void
  onClick: () => void
}

const JobItem = ({ data, onEdit, onClick, className, selected }: Props) => {
  const classes = useStyles()

  return (
    <div
      aria-pressed="false"
      className={clsx(
        classes.listing,
        {
          [classes.selected]: selected,
        },
        "flex justify-between",
        className
      )}
      role="button"
      tabIndex={0}
      onClick={onClick}
      onKeyDown={e => {
        if (e.key === "Enter" || e.key === "") {
          onClick()
        }
      }}
    >
      <div>
        <Typography
          color={selected ? "primary.700" : "onSurface.800"}
          variant="strong"
        >
          {data.title}
        </Typography>
        {data.subtitle && (
          <Typography
            color={selected ? "primary.600" : "onSurface.500"}
            variant="smallBody"
          >
            {data.subtitle}
          </Typography>
        )}
      </div>
      <IconButton
        onClick={(e: any) => {
          e.stopPropagation()
          // handle edit
          onEdit()
        }}
      >
        <Edit />
      </IconButton>
    </div>
  )
}
export default JobItem
