import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogProps,
  DialogTitle,
  Select,
  TextField,
  Typography,
} from "@suraasa/placebo-ui"
import api from "api"
import { Currency } from "api/resources/global/types"
import { JobApplicantStatus } from "api/resources/jobs/types"
import { useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { ToggleValue } from "utils/hooks/useToggle"
import toast from "utils/toast"

export type UpdateOfferStatusDialogData = {
  id: number
  name: string
  status: JobApplicantStatus
  schoolId: string
  afterSubmit?: () => void
}

type Props = {
  toggle: ToggleValue
  data: UpdateOfferStatusDialogData
} & Pick<DialogProps, "open">

type FormData = {
  salary: string
  currencyId: string
}

const UpdateOfferStatusDialog = ({
  open,
  data: { id, afterSubmit, name, status, schoolId },
  toggle,
}: Props) => {
  const {
    register,
    handleSubmit: submitSalary,
    control,
    formState: { errors },
  } = useForm<FormData>()
  const [showAddSalary, setShowAddSalary] = useState(false)
  const [currencyData, setCurrencyData] = useState<Currency[]>([])

  useEffect(() => {
    const getCurrencyData = async () => {
      const res = await api.global.listCurrencies({ params: { page: -1 } })
      if (res.isSuccessful) {
        setCurrencyData(res.data)
      }
    }
    getCurrencyData()
  }, [])
  const onSubmitSalary = submitSalary(async data => {
    const res = await api.jobs.jobApplicant.updatePlacementTracker({
      urlParams: {
        jobApplicantId: id,
      },
      data: {
        salary: data.salary,
        currency_id: data.currencyId,
      },
      headers: {
        "School-Id": schoolId,
      },
    })
    if (res.isSuccessful) {
      toast.success("Salary updated")
      toggle()
      if (afterSubmit) afterSubmit()
    }
  })

  const handleSubmit = async () => {
    const res = await api.jobs.jobApplicant.update({
      urlParams: {
        jobApplicantId: id,
      },
      data: {
        status,
      },
      headers: {
        "School-Id": schoolId,
      },
    })
    if (res.isSuccessful) {
      toast.success("Status updated")
      if (status === JobApplicantStatus.JOB_OFFER_SENT) {
        toggle()
        if (afterSubmit) afterSubmit()
      } else {
        setShowAddSalary(true)
      }
    }
  }

  const text = (() => {
    switch (status) {
      case JobApplicantStatus.JOB_OFFER_ACCEPTED:
        return {
          button: "Offer Accepted",
          message: "Job Offer Accepted",
          color: "success",
        } as const
      case JobApplicantStatus.JOB_OFFER_REJECTED:
        return {
          button: "Offer Rejected",
          message: "Job Offer Rejected",
          color: "critical",
        } as const
      case JobApplicantStatus.JOB_OFFER_SENT: {
        return {
          button: "Offer Sent",
          message: "Job Offer Sent",
          color: "primary",
        } as const
      }

      default:
        throw new Error("Invalid status")
    }
  })()

  return (
    <Dialog open={open} width="md" onRequestClose={() => toggle()}>
      <DialogTitle>{text.message}</DialogTitle>
      <DialogContent>
        {showAddSalary ? (
          <div className="space-y-2">
            <Typography variant="smallBody">
              Please enter the salary offered to the learner
            </Typography>
            <div className="grid grid-cols-[100px_1fr]">
              <div>
                <Controller
                  control={control}
                  name="currencyId"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Select
                      error={Boolean(errors.currencyId)}
                      getOptionLabel={option =>
                        `${option.symbol} ${option.name}`
                      }
                      getOptionValue={option => option.uuid}
                      helperText={errors.currencyId?.message}
                      options={currencyData}
                      placeholder="₹"
                      mountOnBody
                      fullWidth
                      value={
                        value ? currencyData.find(i => i.uuid === value) : null
                      }
                      isSearchable
                      onBlur={onBlur}
                      onChange={val => onChange(val?.uuid ? val.uuid : null)}
                    />
                  )}
                  rules={{
                    required: { value: true, message: "Required" },
                  }}
                />
              </div>

              <TextField
                error={Boolean(errors.salary)}
                helperText={errors.salary?.message}
                autoFocus
                fullWidth
                placeholder="Enter Salary Here"
                type="number"
                {...register("salary", {
                  required: { value: true, message: "Required" },
                })}
              />
            </div>
          </div>
        ) : (
          <Typography>
            Are you sure you want to mark <b>{name}</b> as {text.message}
          </Typography>
        )}
      </DialogContent>
      <DialogFooter
        actions={{
          primary: showAddSalary
            ? {
                label: "Continue",
                onClick: onSubmitSalary,
                color: "primary",
              }
            : {
                label: text.button,
                onClick: handleSubmit,
                color: text.color,
              },
        }}
      />
    </Dialog>
  )
}

export default UpdateOfferStatusDialog
