import { TextField } from "@suraasa/placebo-ui"
import clsx from "clsx"
import styles from "components/Recruiter/SearchField/SearchField.module.css"
import { Search } from "iconoir-react"
import debounce from "lodash/debounce"
import React, { useState } from "react"

type Props = {
  className?: string
}

const useSearchField = (props: Props) => {
  const [search, setSearch] = useState("")

  const handleSearch = debounce(function (value: string) {
    setSearch(value)
  }, 750)

  const SearchField = (
    <TextField
      className={clsx(
        props.className,
        "!text-onSurface-500",
        styles.searchInput
      )}
      name="search"
      placeholder="Search by name"
      startIcon={<Search />}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
        handleSearch(e.target.value)
      }
    />
  )

  return { SearchField, searchText: search }
}

export default useSearchField
