import { GlobalContext } from "components/GlobalState"
import { useContext } from "react"
import { ValueOf } from "utils/helpers"

// TODO: Sync all names with backend action names
export const Actions = {
  // For School Tab
  canAddSchool: "SCH001",
  canViewUnassignedSchool: "SCH005",
  canViewAssignedSchool: "SCH006",
  canViewUnpublishedSchool: ["SCH016", "SCH021"],
  canAssignSchoolPartner: "SCH012",
  canAssignSchoolRecruiter: "SCH013",
  canPublishSchool: "SCH008",

  updateSchool: "SCH011",

  canViewTeamTab: ["SCH007", "SCH019"],
  canAddMemberPartner: "SCH014",
  canAddMemberRecruiter: "SCH015",
  canViewNameEmailRole: "SCH017",

  canViewLearners: "PLC001",
  canMarkAsPlaced: "PLC002",

  listJobs: "JOB001",

  viewJob: "JOB002",

  viewSchool: "SCH009",
  createJob: "JOB009",

  updateJob: "JOB010",

  manageHiringProcess: "JOB005",
  viewHiringProcess: "SCH018",

  listJobApplicants: "JOB004",

  sendJobOffer: "JOB007",
  updateOfferStatus: "SCH022",

  pushProfilesToSchool: "JOB008",

  viewCareerSupport: "SCH020",

  listUsers: "USR003",
}

const useUserActions = () => {
  const { userActions } = useContext(GlobalContext)

  const hasAction = (
    action: ValueOf<typeof Actions>,
    allActions?: string[]
  ) => {
    if (!allActions) allActions = userActions

    if (Array.isArray(action)) {
      return allActions?.some(a => action.includes(a)) || false
    } else {
      return allActions?.includes(action) || false
    }
  }

  return {
    hasAction,
    userActions,
    teamTab: {
      canViewTeamTab: hasAction(Actions.canViewTeamTab),
      canViewNameEmailRole: hasAction(Actions.canViewNameEmailRole),
    },
    schoolTab: {
      canViewAssignedSchool: hasAction(Actions.canViewAssignedSchool),
      canViewUnassignedSchool: hasAction(Actions.canViewUnassignedSchool),
      canViewUnpublishedSchool: hasAction(Actions.canViewUnpublishedSchool),
      canAddSchool: hasAction(Actions.canAddSchool),
    },
    learnersTab: {
      canViewLearners: hasAction(Actions.canViewLearners),
      canMarkAsPlaced: hasAction(Actions.canMarkAsPlaced),
    },

    addTeamMember: {
      canAddPartner: hasAction(Actions.canAddMemberPartner),
      canAddRecruiter: hasAction(Actions.canAddMemberRecruiter),
    },
    listJobs: hasAction(Actions.listJobs),
    viewSchool: hasAction(Actions.viewSchool),
    canCreateNewJob: hasAction(Actions.createJob),

    manageHiringProcess: hasAction(Actions.manageHiringProcess),
    viewHiringProcess: hasAction(Actions.viewHiringProcess),
  }
}

export default useUserActions
