import { Button, Typography } from "@suraasa/placebo-ui"
import clsx from "clsx"
import { ArrowUpCircle } from "iconoir-react"
import React, { forwardRef, useCallback, useEffect, useState } from "react"
import { createUseStyles } from "react-jss"

import PreviewFile from "./PreviewFile"

const useStyles = createUseStyles(theme => ({
  root: {
    "& .box__input": {
      width: 200,
      height: 200,
      transition: "border-color .15s linear",

      border: `2px dashed ${theme.colors.onSurface[300]}`,
      borderRadius: theme.spacing(0.5),

      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",

      "&.is-dragover": {
        borderColor: theme.colors.primary[500],
        backgroundColor: theme.colors.common.white,
      },
    },

    "& label": {
      cursor: "pointer",
    },

    "& .box__file": {
      display: "none",
    },

    "& .box__button": {
      fontWeight: theme.typography.fontWeightBold,
      padding: theme.spacing(1, 4),
      textTransform: "uppercase",
      letterSpacing: 1.8,
      marginTop: theme.spacing(3),
    },
  },
  inputLabel: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& *": {
      textAlign: "center",
    },

    "& svg": {
      marginBottom: theme.spacing(2),
    },

    "& p:nth-child(2)": {
      fontWeight: theme.typography.fontWeightMedium,
    },

    "& p:nth-child(3)": {
      color: theme.colors.onSurface[400],
    },
  },
  instructions: {
    marginTop: theme.spacing(1),
    listStylePosition: "inside",
    color: theme.colors.onSurface[500],
  },
  errors: {
    color: theme.colors.critical[500],
  },

  browseButton: {
    color: theme.colors.primary[500],
    "&:hover": {
      color: theme.colors.primary[600],
    },
  },
}))

type FileType = FileList | null | string

type Props = {
  /**
   * Example: [".pdf", ".xlsx", ".docx"]
   */
  allowedExtensions?: string[]
  /**
   * In bytes
   * Default: 1024 (1KB)
   */
  maxSize?: number
  /**
   * Any information that the user should know before uploading files
   * TODO: Implement auto generated instructions for file size and format
   */
  instructions?: string[]
  onChange?: (files: FileType) => void
  fieldError?: string
  // onClick?: () => void
  value: FileType
}

const FileUpload = forwardRef(
  (
    {
      instructions = [],
      allowedExtensions = [],
      maxSize = 1024,
      fieldError = "",
      value,
      onChange,
    }: Props,
    ref: React.ForwardedRef<HTMLDivElement>
  ) => {
    const classes = useStyles()

    const [files, setFiles] = useState<FileType>(value)
    const [errors, setErrors] = useState<string[]>([])

    const validateFiles = useCallback(
      // eslint-disable-next-line @typescript-eslint/no-shadow
      (files: FileType) => {
        if (!files || typeof files === "string") return

        if (files.length === 0) return

        setErrors([])

        const fileExt = files[0].name.split(".").pop()
        const fileSize = files[0].size

        if (
          allowedExtensions.length > 0 &&
          !allowedExtensions.includes(`.${fileExt}`)
        ) {
          setErrors(e => [...e, "Invalid file format"])
          return
        }

        if (fileSize > maxSize) {
          setErrors(e => [...e, `File size is too large`])
          return
        }

        setFiles(files)
      },
      [allowedExtensions, maxSize]
    )

    useEffect(() => {
      if (typeof onChange === "function") onChange(files)
    }, [files, onChange])

    const clearInput = () => {
      setFiles(null)
    }

    useEffect(() => {
      if (typeof value === "string") setFiles(value)
    }, [value])

    useEffect(() => {
      const root = document.querySelector("#__fileUploadRoot")
      const box = document.querySelector("#__fileUploadRoot .box__input")

      if (!root) return
      if (!box) return

      const allDragEvents = [
        "drag",
        "dragstart",
        "dragend",
        "dragover",
        "dragenter",
        "dragleave",
        "drop",
      ]

      function preventDefault(e: Event) {
        e.preventDefault()
        e.stopPropagation()
      }
      allDragEvents.forEach(event => {
        root.addEventListener(event, preventDefault, false)
      })

      const dragOverEvents = ["dragover", "dragenter"]
      function handleDragOver() {
        box?.classList.add("is-dragover")
      }
      dragOverEvents.forEach(event => {
        box.addEventListener(event, handleDragOver, false)
      })

      const dragLeaveEvents = ["dragleave", "dragend", "drop"]
      function handleDragLeave() {
        box?.classList.remove("is-dragover")
      }
      dragLeaveEvents.forEach(event => {
        box.addEventListener(event, handleDragLeave, false)
      })

      function handleFileDrop(e: Event) {
        if ("dataTransfer" in e) {
          const droppedFiles = (e as DragEvent).dataTransfer?.files
          validateFiles(droppedFiles ?? null)
        }
      }
      root.addEventListener("drop", handleFileDrop, false)

      return () => {
        allDragEvents.forEach(event => {
          root.removeEventListener(event, preventDefault)
        })
        dragOverEvents.forEach(event => {
          box.removeEventListener(event, handleDragOver)
        })
        dragLeaveEvents.forEach(event => {
          box.removeEventListener(event, handleDragLeave)
        })
        root.removeEventListener("drop", handleFileDrop)
      }
      /**
       * Files is added to the dependency because when files are added,
       * the "box__input" element unmounts, clearing the event listeners.
       * And the listeners don't re-apply if user removes the file and tries to upload again..
       */
    }, [validateFiles, files])

    return (
      <div className={classes.root} id="__fileUploadRoot" ref={ref}>
        {files && files.length > 0 ? (
          <>
            <PreviewFile data={typeof files === "string" ? files : files[0]} />
            <Button
              className="mt-1"
              color="critical"
              variant="text"
              onClick={clearInput}
            >
              Remove
            </Button>
          </>
        ) : (
          <>
            <div className="box__input">
              <input
                accept={
                  allowedExtensions.length > 0
                    ? allowedExtensions.join()
                    : undefined
                }
                className="box__file"
                id="file-input"
                type="file"
                onChange={e => validateFiles(e.target.files)}
              />

              <label className={classes.inputLabel} htmlFor="file-input">
                <ArrowUpCircle />

                <Typography variant="body">
                  Drag and Drop file to upload
                </Typography>
                <Typography variant="body">or</Typography>

                <Typography
                  className={classes.browseButton}
                  color="primary.500"
                  variant="button"
                >
                  Browse
                </Typography>
              </label>
              {errors.length === 0 ? (
                <div className={clsx(classes.errors, "mt-2")}>
                  <Typography variant="smallBody">{fieldError}</Typography>
                </div>
              ) : (
                errors.map((error, i) => (
                  <Typography
                    className={clsx(classes.errors, "mt-2")}
                    key={i}
                    variant="smallBody"
                  >
                    {error}
                  </Typography>
                ))
              )}
            </div>
            <ul className={classes.instructions}>
              {instructions.map((point, i) => (
                <Typography color="onSurface.400" key={i} variant="smallBody">
                  <li>{point}</li>
                </Typography>
              ))}
            </ul>
          </>
        )}
      </div>
    )
  }
)

FileUpload.displayName = "FileUpload"

export default FileUpload
