import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogTitle,
} from "@suraasa/placebo-ui"

type Props = {
  open: boolean
  toggle: () => void
  onSubmit: () => void
  position: string
  subjectName: string
}
const ReopenJobConfirmationDialog = ({
  open,
  toggle,
  onSubmit,
  position,
  subjectName,
}: Props) => (
  <Dialog open={open} width="sm" onRequestClose={toggle}>
    <DialogTitle>Reopen Job</DialogTitle>
    <DialogContent>
      Are you sure you want to Reopen Job{" "}
      <strong>
        {position}, {subjectName}?
      </strong>
    </DialogContent>
    <DialogFooter
      actions={{
        primary: {
          label: "Reopen Job",
          onClick: onSubmit,
          color: "primary",
        },
      }}
    />
  </Dialog>
)

export default ReopenJobConfirmationDialog
