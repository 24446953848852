import { CircularProgress, Container } from "@suraasa/placebo-ui"
import api from "api"
import { Profile } from "api/resources/profile/types"
import PublicDetails from "components/profile/editProfile/PublicDetails"
import Navbar from "components/shared/Navbar"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"

function EditProfile() {
  const [loading, setLoading] = useState<boolean>(false)
  const { schoolId = "" } = useParams()
  const [publicDetails, setPublicDetails] = useState<Profile>({
    id: 1,
    curriculumBoard: [],
    name: "",
    website: "",
    email: "",
    branch: null,
    phoneNumber: null,
    address: "",
    description: "",
    logo: null,
    city: null,
    pincode: null,
    coverImage: null,
    galleryCount: 0,
    dateEstablished: null,
    workingDays: null,
    dayStartTime: "00:00",
    dayEndTime: "00:00",
    teachingMode: null,
    isVerified: true,
    slug: "",
    country: null,
    state: null,
  })

  useEffect(() => {
    async function getData() {
      setLoading(true)

      const promises = [
        api.school.retrieve({
          params: {
            fields: "curriculum_board",
          },
          headers: {
            "School-Id": schoolId,
          },
        }),
      ] as const

      const [publicDetailsRes] = await Promise.all(promises)

      if (publicDetailsRes.isSuccessful) {
        setPublicDetails(publicDetailsRes.data)
      }

      setLoading(false)
    }
    getData()
  }, [])

  return (
    <>
      <Navbar />

      <Container>
        {loading ? (
          <div className="flex flex-col items-center justify-between">
            <CircularProgress />
          </div>
        ) : (
          <PublicDetails
            data={publicDetails}
            onUpdate={newState =>
              setPublicDetails(prevState => ({
                ...prevState,
                ...newState,
              }))
            }
            schoolId={schoolId}
          />
        )}
      </Container>
    </>
  )
}

export default EditProfile
