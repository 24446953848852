import { TableFilter } from "@suraasa/placebo-ui"
import { useQuery } from "@tanstack/react-query"
import api from "api"
import NoDataCard from "components/jobs/NoDataCard"
import SearchField from "components/Recruiter/SearchField"
import { useState } from "react"
import useUserActions from "views/auth/useUserActions"

import TeamMembersTable from "./TeamMembersTable"

const TeamTab = () => {
  const { teamTab } = useUserActions()
  const { canViewTeamTab } = teamTab

  const [search, setSearch] = useState("")

  const [sortBy, setSortBy] = useState<string[]>([""])
  const [filters, setFilters] = useState<TableFilter[]>([])

  const { data, isLoading } = useQuery({
    queryFn: () => {
      return api.representatives.representativesList({
        params: {
          fields: ["school_count", "lead_partner_id", "lead_recruiter_id"],
          ordering: sortBy,
          ...(search.length > 2 ? { search } : {}),
          related_roles: filters
            .filter(({ id }) => id === "role")
            .map(({ value }) => value),
        },
      })
    },
    queryKey: [
      "team",
      {
        filters,
        sortBy,
        search,
      },
    ],
  })

  if (!canViewTeamTab) {
    return null
  }

  return (
    <>
      <SearchField
        placeholder="Search by Name"
        value={search}
        onSearch={setSearch}
        fullWidth
        className="mb-3"
      />
      <div>
        <TeamMembersTable
          isLoading={isLoading}
          setFilters={setFilters}
          data={data ?? []}
          setSortBy={setSortBy}
        />

        {!isLoading && data && data.length === 0 && <NoDataCard />}
      </div>
    </>
  )
}

export default TeamTab
