import { Error, ErrorType } from "@suraasa/error-pages"
import Navbar from "components/shared/Navbar"
import React from "react"
import { useNavigate } from "react-router-dom"
import { routes } from "utils/routes"

const ErrorPage = ({ errorCode }: Pick<ErrorType, "errorCode">) => {
  const router = useNavigate()

  switch (errorCode) {
    case 401:
      return (
        <>
          <title>Suraasa | 401</title>

          <Error
            errorCode={401}
            navbar={<Navbar hideBackButton />}
            navigate={router}
            options={{
              onLogin: () => router(routes.login),
            }}
          />
        </>
      )
    case 403:
      return (
        <>
          <title>Suraasa | 403</title>

          <Error
            errorCode={403}
            navbar={<Navbar hideBackButton />}
            navigate={router}
            options={{
              onHomepage: () => router("/"),
              onHelp: () => router(routes.help),
            }}
          />
        </>
      )
    case 404:
      return (
        <>
          <title>Suraasa | 404</title>
          <Error
            errorCode={404}
            navbar={<Navbar hideBackButton />}
            navigate={router}
            options={{
              onHomepage: () => router("/"),
              onHelp: () => router(routes.help),
            }}
          />
        </>
      )
    case 500:
      return (
        <>
          <title>Suraasa | 500</title>
          <Error
            errorCode={500}
            navbar={<Navbar hideBackButton />}
            navigate={router}
            options={{
              onHelp: () => router(routes.help),
            }}
          />
        </>
      )
    default:
      return null
  }
}

export default ErrorPage
