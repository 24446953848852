import { Button, Typography } from "@suraasa/placebo-ui"
import { SkillEvidence } from "api/resources/teacher/types"
import clsx from "clsx"
import { Link, useParams } from "react-router-dom"
import { routes } from "utils/routes"

const ItemRow = ({
  className,
  data,
}: {
  className?: string
  data: SkillEvidence
}) => {
  const { username } = useParams()

  return (
    <div
      className={clsx(
        className,
        "flex flex-wrap items-center justify-between gap-1"
      )}
    >
      <Typography variant="strong" color="onSurface.800">
        {data.name}
      </Typography>
      <Button
        component={Link}
        to={routes.publicSkillProfile.evidences
          .replace(":username", username ?? "")
          .replace(":slug", data.slug)}
        variant="text"
      >
        View Evidences
      </Button>
    </div>
  )
}

export default ItemRow
