import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  Tag,
  TextField,
  theme,
  Typography,
} from "@suraasa/placebo-ui"
import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query"
import api from "api"
import {
  Job,
  JobInterestedUser,
  JobInterestedUserStatus,
  LastActivity,
  Opportunity,
} from "api/resources/jobs/types"
import clsx from "clsx"
import AsyncBuilder from "components/AsyncBuilder"
import Filters, { Filter, getDefaultFilters } from "components/explore/Filters"
import UserDetailsCard from "components/home/UserDetailsCard"
import format from "date-fns/format"
import { Check, InfoCircle, Search } from "iconoir-react"
import debounce from "lodash/debounce"
import startCase from "lodash/startCase"
import uniqBy from "lodash/uniqBy"
import React, { useMemo, useState } from "react"
import { createUseStyles } from "react-jss"
import { useSearchParams } from "react-router-dom"
import usePaginationScroll from "utils/hooks/usePaginationScroll"
import useResources from "utils/hooks/useResources"
import toast from "utils/toast"
import { useSchoolActions } from "views/auth/useSchoolActions"
import { Actions } from "views/auth/useUserActions"

import AcceptRejectApplicantActions from "./AcceptRejectApplicantActions"
import NoDataCard from "./NoDataCard"
import PushProfileSheet from "./PushProfileSheet"

const useStyles = createUseStyles({
  filter: {
    width: "306px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
})

type Props = {
  job: Pick<Job, "position" | "id">
  isClosed?: boolean
  showPushProfileSheet: boolean
  onHidePushProfileSheet: (success?: boolean) => void
  showSelectProfiles: boolean
  setProfilesToPush: (id: number[]) => void
  profilesToPush: number[]
  showShared: boolean
  schoolId: string
  canManageHiringProcess: boolean
}

const ApplicantsTab = ({
  job,
  isClosed,
  showPushProfileSheet,
  onHidePushProfileSheet,
  showSelectProfiles,
  showShared,
  schoolId,
  canManageHiringProcess,
}: Props) => {
  const classes = useStyles()

  const { hasSchoolAction } = useSchoolActions({
    schoolIds: [schoolId],
  })
  const canViewCareerSupport = hasSchoolAction(
    Actions.viewCareerSupport,
    schoolId
  )

  const { subjects, curricula } = useResources(["subjects", "curricula"])

  const [searchParams, setSearchParams] = useSearchParams()

  const [showMobileFilters, setShowMobileFilters] = useState(false)

  const {
    isFetching: loading,
    hasNextPage,
    fetchNextPage,
    data,
    refetch,
  } = useInfiniteQuery({
    enabled: !!job.id,
    queryKey: [
      "applicants",
      job.id,
      Object.fromEntries(searchParams.entries()),
      schoolId,
    ],
    queryFn: x =>
      api.jobs.applicants.listApplied({
        urlParams: {
          jobId: job.id,
        },
        params: {
          ...Object.fromEntries(searchParams.entries()),
          page: x.pageParam,
          fields: [
            "is_externally_placed",
            "opportunities",
            "has_active_career_support",
          ],
        },
        headers: {
          "School-Id": schoolId,
        },
      }),

    initialPageParam: 1,
    getNextPageParam: lastPage => {
      return lastPage.isSuccessful ? lastPage.data.nextPage : undefined
    },
  })
  const applicants =
    data?.pages.map(page => (page.isSuccessful ? page.data.data : [])).flat() ||
    []

  const { ref } = usePaginationScroll({
    loading: loading,
    hasNextPage: hasNextPage,
    actionFunc: fetchNextPage,
  })

  const [profilesToPush, setProfilesToPush] = useState<JobInterestedUser[]>([])

  const [search, setSearch] = useState("")

  const [showOpportunities, setShowOpportunities] =
    useState<JobInterestedUser | null>(null)

  const debouncedSearch = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)

    if (e.target.value.length > 2) {
      searchParams.append("search", e.target.value)
    } else {
      searchParams.delete("search")
    }

    setSearchParams(searchParams, { replace: true })
  }, 750)

  const handleShortlist = async (
    row: JobInterestedUser,
    shortlist: boolean
  ) => {
    const res = await api.jobs.interestedUser.update({
      urlParams: {
        jobInterestedUserId: row.id,
      },
      data: {
        isShortlisted: shortlist,
      },
      headers: {
        "School-Id": schoolId,
      },
    })
    if (res.isSuccessful) {
      if (shortlist) {
        toast.success("Application shortlisted")
      }
      refetch()
      // setApplicants(a => ({
      //   ...a,
      //   data: a.data.map(v =>
      //     v.id === row.id ? { ...v, isShortlisted: shortlist } : v
      //   ),
      // }))
    } else {
      toast.error(res.errors.message)
    }
  }

  const queryClient = useQueryClient()

  const getAction = (item: JobInterestedUser) => {
    if (!canManageHiringProcess) {
      return null
    }
    return (
      <div className="flex gap-1">
        <AcceptRejectApplicantActions
          isShared={item.status === JobInterestedUserStatus.ACCEPTED}
          jobInterestedUserId={item.id}
          position={job.position}
          fullName={item.user.fullName}
          jobApplicantId={item.jobApplicant?.id}
          onUpdate={() => {
            queryClient.invalidateQueries({
              queryKey: api.jobs.overviewCount.key(job.id.toString()),
            })
            refetch()
          }}
          schoolId={schoolId}
        />

        {item.isShortlisted ? (
          <AsyncBuilder
            handler={async () => {
              await handleShortlist(item, false)
            }}
            render={props => (
              <Button className="mr-1" variant="text" {...props}>
                <>
                  <Typography color="success.500">Shortlisted</Typography>
                  <Check color={theme.colors.success[500]} />
                </>
              </Button>
            )}
          />
        ) : (
          <AsyncBuilder
            handler={async () => {
              await handleShortlist(item, true)
            }}
            render={props => (
              <Button
                className="mr-2"
                color="primary"
                variant="text"
                {...props}
              >
                Shortlist
              </Button>
            )}
          />
        )}
      </div>
    )
  }
  const otherFilters = React.useMemo(
    () => ({ tab: "Applicants", search }),
    [search]
  )
  const ShortlistedFilter: Filter = {
    id: "applicant_profile_type",
    type: "single",
    options: [
      { label: "All Teachers", value: "" },
      { label: "Shortlisted Profiles", value: "shortlisted" },
      { label: "Shared Profiles", value: "shared" },
      {
        label: "Shortlisted and Shared Profiles",
        value: "shortlisted_and_shared",
      },
      {
        label: "Shortlisted but not Shared Profiles",
        value: "shortlisted_but_not_shared",
      },
    ],
  }

  const noCareerSupportFilter: Filter[] = canViewCareerSupport
    ? [
        {
          id: "active_career_support",
          type: "single",
          options: [{ label: "Active Career Support", value: "true" }],
        },
      ]
    : []

  const filterChoices = useMemo(
    () =>
      [ShortlistedFilter, ...noCareerSupportFilter, ...getDefaultFilters()].map(
        filter => {
          if (filter.type === "multi-select") {
            if (filter.id === "subjects[]") {
              filter.options = subjects.map(({ name, uuid }) => ({
                label: name,
                value: uuid,
              }))
            }
          }
          if (filter.type === "multi") {
            if (filter.id === "curriculum[]") {
              filter.options = curricula.map(({ name, uuid }) => ({
                label: name,
                value: uuid,
              }))
            }
          }
          return filter
        }
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [subjects, curricula]
  )

  const onSubmitPushProfiles = async () => {
    const res = await api.jobs.applicants.pushProfiles({
      data: {
        ids: profilesToPush.map(v => v.id),
      },
      headers: {
        "School-Id": schoolId,
      },
    })
    if (res.isSuccessful) {
      setProfilesToPush([])
      onHidePushProfileSheet(true)
      refetch()
      toast.success("Profiles pushed")
    } else {
      toast.error(res.errors.message)
    }
  }

  return (
    <div className="mt-3 flex flex-col gap-3 sm:flex-row">
      <div className="mb-1 flex grow justify-end sm:hidden">
        <Button
          variant="text"
          onClick={() => setShowMobileFilters(prevState => !prevState)}
        >
          Filters
        </Button>
      </div>
      <div
        className={clsx({
          "hidden sm:block": !showMobileFilters,
        })}
      >
        <Filters
          className={classes.filter}
          disabled={loading}
          filters={filterChoices}
          otherFilters={otherFilters}
        />
      </div>
      <div className="flex w-full flex-col">
        <div
          className={clsx(
            "flex h-9 w-full items-center",
            showSelectProfiles && applicants.length > 0
              ? "justify-between"
              : "justify-end"
          )}
        >
          {showSelectProfiles && applicants.length > 0 && (
            <div className="flex items-center">
              <Checkbox
                checked={
                  profilesToPush.length === applicants.length &&
                  profilesToPush.every(
                    p => p.id === applicants.find(a => a.id == p.id)?.id
                  )
                }
                onChange={e => {
                  e.target.checked
                    ? setProfilesToPush(
                        uniqBy([...profilesToPush, ...applicants], "id")
                      )
                    : setProfilesToPush([])
                }}
              />
              <Typography variant="button" className="ms-1">
                Select All
              </Typography>
            </div>
          )}

          <TextField
            placeholder="Search by name"
            rounded
            startIcon={<Search />}
            onChange={debouncedSearch}
            helperText={
              search.length > 0 && search.length < 3
                ? "Minimum 3 characters are required"
                : ""
            }
          />
        </div>

        <div className="flex grow flex-col gap-2">
          {applicants.length === 0 ? (
            <NoDataCard message="There are no applicants. Tip: Having a detailed school profile greatly increases the chances of getting more applications." />
          ) : (
            <>
              {applicants.map((item, index) => {
                const action = getAction(item)
                const bottomTags = (
                  <div className="flex space-x-1">
                    {item.isShortlisted && (
                      <Tag color="four" label="Shortlisted" />
                    )}
                    {item.status === JobInterestedUserStatus.ACCEPTED &&
                      showShared && (
                        <Tag className="inline" color="one" label={"Shared"} />
                      )}
                  </div>
                )

                return (
                  <div
                    className="flex items-center space-x-1"
                    key={index}
                    ref={ref}
                  >
                    {showSelectProfiles && (
                      <Checkbox
                        checked={profilesToPush.some(p => p.id === item.id)}
                        onChange={e => {
                          e.target.checked
                            ? setProfilesToPush([...profilesToPush, item])
                            : setProfilesToPush(
                                profilesToPush.filter(p => p.id !== item.id)
                              )
                        }}
                      />
                    )}
                    <div className="w-full">
                      <UserDetailsCard
                        action={
                          isClosed || showSelectProfiles ? undefined : action
                        }
                        applications={item.applications}
                        jobId={job.id}
                        key={index}
                        user={{
                          ...item.user,
                          fullName: item.user.fullName,
                          profile: {
                            picture: item.user.profile.picture || "",
                            country: item.user.profile.country,
                            isVerified: item.user.profile.isVerified,
                            state: item.user.profile.state,
                          },
                        }}
                        canViewContactDetails={!showSelectProfiles}
                        bottomTags={showSelectProfiles ? bottomTags : null}
                        showShared={showShared}
                        isShared={
                          item.status === JobInterestedUserStatus.ACCEPTED
                        }
                        className="rounded-b-none"
                      />
                      {canViewCareerSupport ? (
                        <div
                          className={clsx(
                            "flex items-center justify-between rounded-b-lg px-2 py-1",
                            item.hasActiveCareerSupport
                              ? "bg-onSurface-900"
                              : "bg-onSurface-200",
                            item.hasActiveCareerSupport
                              ? "text-white"
                              : "text-onSurface-800"
                          )}
                        >
                          <Typography variant="smallBody">
                            {item.hasActiveCareerSupport
                              ? `Active Career Support${
                                  item.isExternallyPlaced
                                    ? " (Placed Externally)"
                                    : ""
                                }`
                              : `No Career Support${
                                  item.isExternallyPlaced
                                    ? " (Placed Externally)"
                                    : ""
                                }`}
                          </Typography>
                          {item.opportunities &&
                            item.opportunities.length > 0 && (
                              <InfoCircle
                                height={20}
                                className="cursor-pointer"
                                onClick={() => setShowOpportunities(item)}
                              />
                            )}
                        </div>
                      ) : null}
                    </div>
                  </div>
                )
              })}
            </>
          )}
        </div>
        {loading && (
          <div className="mt-2 flex grow justify-center">
            <CircularProgress />
          </div>
        )}
        {showPushProfileSheet && (
          <PushProfileSheet
            jobId={job.id}
            open={showPushProfileSheet}
            onClickPrimary={onSubmitPushProfiles}
            handleClose={onHidePushProfileSheet}
            profiles={profilesToPush}
          />
        )}
        {showOpportunities?.opportunities && (
          <OpportunitiesDialog
            opportunities={showOpportunities.opportunities}
            onClose={() => setShowOpportunities(null)}
          />
        )}
      </div>
    </div>
  )
}

export default ApplicantsTab

export const OpportunitiesDialog = ({
  opportunities,
  onClose,
}: {
  opportunities: Opportunity[]
  onClose: () => void
}) => {
  return (
    <Dialog open={true} width="md" onRequestClose={() => onClose()}>
      <DialogContent>
        <div className="grid grid-cols-3">
          <Typography variant="strongSmallBody">Opportunities</Typography>
          <Typography variant="strongSmallBody">Last Activity</Typography>
          <Typography variant="strongSmallBody">Date</Typography>
        </div>

        <div className="mt-1 flex flex-col gap-1">
          {opportunities &&
            opportunities.map((item, key) => (
              <div className="grid grid-cols-3" key={key}>
                <Typography variant="smallBody">{item.schoolName}</Typography>
                <Typography
                  variant="smallBody"
                  color={
                    item.lastActivity === LastActivity.NOT_HIRED
                      ? "critical.500"
                      : undefined
                  }
                >
                  {startCase(item.lastActivity)}
                </Typography>
                <Typography variant="smallBody">
                  {format(new Date(item.date), "dd MMM yyyy")}
                </Typography>
              </div>
            ))}
        </div>
      </DialogContent>
    </Dialog>
  )
}
