import {
  Avatar,
  Button,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Tag,
  Typography,
} from "@suraasa/placebo-ui"
import { useInfiniteQuery } from "@tanstack/react-query"
import api from "api"
import { HiredUser, Job } from "api/resources/jobs/types"
import VerifiedBadge from "assets/home/verifiedBadge.svg"
import AddSalaryDialog from "components/teacher/Dialogs/AddSalaryDialog"
import { format } from "date-fns"
import { ArrowDown, ArrowUp, MoreVert } from "iconoir-react"
import { useState } from "react"
import { Link } from "react-router-dom"
import { getTeacherProfileRoute } from "utils/helpers"
import usePaginationScroll from "utils/hooks/usePaginationScroll"

type Props = {
  job: Pick<Job, "position" | "id" | "dateReopened" | "status">
  schoolId: string | undefined
  canManageHiringProcess: boolean
}

const HiredTab = ({ job, schoolId, canManageHiringProcess }: Props) => {
  const [offerOrderingRecent, setOfferOrderingRecent] = useState(true)
  const [openAddSalaryDialog, setOpenAddSalaryDialog] = useState<{
    user: HiredUser
  } | null>(null)

  const {
    isFetching: loading,
    hasNextPage,
    fetchNextPage,
    data,
    refetch,
  } = useInfiniteQuery({
    queryKey: ["hired", job.id, offerOrderingRecent, schoolId],
    queryFn: x =>
      api.jobs.applicants.listHired({
        urlParams: {
          jobId: job.id,
        },
        params: {
          page: x.pageParam,
          ordering: `${offerOrderingRecent ? "" : "-"}job_offer__date_created`,
          fields: ["placement_tracker", "job_offer"],
        },
        headers: {
          "School-Id": schoolId,
        },
      }),
    initialPageParam: 1,
    getNextPageParam: lastPage => {
      return lastPage.isSuccessful ? lastPage.data.nextPage : undefined
    },
  })
  const applicants =
    data?.pages.map(page => (page.isSuccessful ? page.data.data : [])).flat() ||
    []

  const { ref } = usePaginationScroll({
    loading: loading,
    hasNextPage: hasNextPage,
    actionFunc: fetchNextPage,
  })

  return (
    <>
      {loading && (
        <div className="mt-5 flex justify-center">
          <CircularProgress />
        </div>
      )}
      <div className="my-3">
        <div className="min-w-[800px] rounded border border-surface-200 bg-white">
          <div className={"grid grid-cols-[70px_1.75fr_1.5fr_1.5fr_50px] py-2"}>
            <div />
            <Typography variant="strongSmallBody">Name</Typography>

            <div className="flex items-center gap-0.5">
              <Typography variant="strongSmallBody">Status</Typography>
            </div>
            <div className="flex items-center gap-0.5">
              <Typography variant="strongSmallBody">Offer Sent Date</Typography>
              <IconButton onClick={() => setOfferOrderingRecent(v => !v)}>
                {offerOrderingRecent ? (
                  <ArrowDown className="size-2 text-onSurface-500" />
                ) : (
                  <ArrowUp className="size-2 text-onSurface-500" />
                )}
              </IconButton>
            </div>
          </div>
          {applicants.length > 0 ? (
            applicants.map((item, i) => (
              <div
                className={
                  "relative grid grid-cols-[70px_1.75fr_1.5fr_1.5fr_50px] border-t border-surface-200 py-2"
                }
                key={i}
                ref={ref}
              >
                <div className="pl-3">
                  <Avatar
                    name={item.user.fullName}
                    size="xs"
                    src={item.user.profile.picture ?? undefined}
                  />
                </div>
                <div>
                  <div className="flex items-center gap-0.5">
                    <Button
                      className="-ml-0.75"
                      component={Link}
                      to={getTeacherProfileRoute({
                        jobId: job.id,
                        username: item.user.username,
                        schoolId,
                      })}
                      variant="link"
                    >
                      {item.user.fullName}
                    </Button>
                    {item.user.profile.isVerified && (
                      <img src={VerifiedBadge} alt="" />
                    )}
                  </div>

                  <div className="flex items-center">
                    <Typography variant="smallBody" color="onSurface.500">
                      {item.user.email}
                    </Typography>
                  </div>

                  {item.user.profile.phoneNumber && (
                    <Typography variant="smallBody" color="onSurface.500">
                      +{item.user.profile.phoneNumber.code}
                      {item.user.profile.phoneNumber.number}
                    </Typography>
                  )}
                </div>
                <div className="flex items-center">
                  <Tag color="success" label="Accepted" />
                </div>
                <div className="flex items-center">
                  {item.jobOffer?.dateCreated
                    ? format(
                        new Date(item.jobOffer.dateCreated),
                        "dd MMMM yyyy"
                      )
                    : " - "}
                </div>
                {!canManageHiringProcess ? null : item.placementTracker &&
                  item.placementTracker.salary === null ? (
                  <div className="mr-1.25 flex items-center">
                    <Menu
                      menuButton={
                        <IconButton>
                          <MoreVert />
                        </IconButton>
                      }
                      portal
                    >
                      <MenuItem
                        onClick={() =>
                          setOpenAddSalaryDialog({
                            user: item,
                          })
                        }
                      >
                        Add Salary
                      </MenuItem>
                    </Menu>
                  </div>
                ) : null}
              </div>
            ))
          ) : (
            <div>
              <Typography
                className={
                  "rounded-lg border border-onSurface-200 bg-[#f8fafc] py-3 pl-9"
                }
                color="onSurface.500"
                variant="smallBody"
              >
                No data available
              </Typography>
            </div>
          )}
        </div>
      </div>
      {openAddSalaryDialog && schoolId && (
        <AddSalaryDialog
          toggle={() => setOpenAddSalaryDialog(null)}
          open={Boolean(openAddSalaryDialog)}
          data={{
            schoolId,
            id: openAddSalaryDialog.user.id,
            afterSubmit: () => {
              refetch()
              setOpenAddSalaryDialog(null)
            },
          }}
        />
      )}
    </>
  )
}

export default HiredTab
