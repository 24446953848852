import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogProps,
  DialogTitle,
  IconButton,
  TextField,
} from "@suraasa/placebo-ui"
import api from "api"
import { ManagementMessage } from "api/resources/profile/types"
import FileInput from "components/shared/FileInput"
import { Camera } from "iconoir-react"
import { useContext, useEffect, useRef, useState } from "react"
import { useForm } from "react-hook-form"
import { createUseStyles } from "react-jss"
import { useParams } from "react-router-dom"
import { acceptedImageTypes } from "utils/constants"
import { handleErrors, validateImageUpload } from "utils/helpers"
import toast from "utils/toast"
import ProfileContext from "views/Profile/context"

const useStyles = createUseStyles(theme => ({
  imgContainer: {
    borderRadius: "50%",
    overflow: "hidden",
    height: theme.spacing(12),
    width: theme.spacing(12),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  displayPictureMobile: {
    width: "inherit",
    objectFit: "cover",
    objectPosition: "top",
    height: "inherit",
  },
  imgOverlay: {
    position: "absolute",
    height: theme.spacing(12),
    borderRadius: "100px",
    width: theme.spacing(12),
    backgroundColor: theme.colors.onSurface[800],
    opacity: 0.5,
  },
  cameraIcon: {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}))

type FormProps = {
  message: string
  name: string
  position: string
}

type Props = {
  onUpdate: (newData: ManagementMessage | null) => void
  handleClose: () => void
} & Pick<DialogProps, "open">

const EditManagementMsgDialog = ({ open, handleClose, onUpdate }: Props) => {
  const classes = useStyles()

  const { schoolId } = useParams() as { schoolId: string }

  const fileInputRef = useRef<HTMLInputElement>(null)
  const [loading, setLoading] = useState(false)
  const { profile } = useContext(ProfileContext)
  const [image, setImage] = useState(profile.managementMessage?.image)
  const [profileImage, setProfileImage] = useState<File | null>(null)
  const [removeLoading, setRemoveLoading] = useState(false)
  const [removePictureLoading, setRemovePictureLoading] = useState(false)

  const {
    register,
    handleSubmit,
    reset,
    setError,
    formState: { errors },
  } = useForm<FormProps>()

  useEffect(() => {
    reset({
      message: profile.managementMessage?.message,
      name: profile.managementMessage?.name,
      position: profile.managementMessage?.position,
    })
    setImage(profile.managementMessage?.image)
  }, [profile.managementMessage, reset])

  const onSubmit = handleSubmit(async formValues => {
    setLoading(true)
    let formData

    if (profileImage) {
      if (validateImageUpload(profileImage, 5)) {
        formData = new FormData()
        formData.append("image", profileImage)
        formData.append("name", formValues.name)
        formData.append("position", formValues.position)
        formData.append("message", formValues.message)
      } else {
        setLoading(false)
        return
      }
    }

    const res = await api.profile.managementMessage.update({
      data: formData || formValues,
      headers: formData
        ? {
            "Content-Type": "multipart/form-data",
            "School-Id": schoolId,
          }
        : {
            "School-Id": schoolId,
          },
    })

    if (res.isSuccessful) {
      onUpdate(res.data)
      handleClose()
    } else handleErrors(setError, res.errors)

    setLoading(false)
  })

  const onRemove = async () => {
    setRemoveLoading(true)
    const res = await api.profile.managementMessage.delete({
      headers: { "School-Id": schoolId },
    })

    if (res.isSuccessful) {
      if (fileInputRef.current) fileInputRef.current.value = ""
      onUpdate(null)
      setImage(null)
      reset({})
      handleClose()
    } else {
      toast.error(res.errors.message || "Failed to Remove")
    }
    setRemoveLoading(false)
  }

  const removePicture = async () => {
    setRemovePictureLoading(true)
    if (profile.managementMessage?.image) {
      const res = await api.profile.managementMessage.update({
        data: { image: null },
        headers: {
          "Content-Type": "application/json",
          "School-Id": schoolId,
        },
      })

      if (res.isSuccessful) {
        if (fileInputRef.current) fileInputRef.current.value = ""
        onUpdate({ ...profile.managementMessage, image: null })
        setImage(null)
        setProfileImage(null)
      } else toast.error(res.errors.message || "Unable to remove image")
    }
    setRemovePictureLoading(false)
  }

  const uploadPicture = (fileArray: File[]) => {
    setProfileImage(fileArray[0])
    setImage(URL.createObjectURL(fileArray[0]))
  }

  return (
    <Dialog
      open={open}
      width={636}
      onAfterClose={reset}
      onRequestClose={() => {
        if (fileInputRef.current) fileInputRef.current.value = ""
        setImage(null)
        handleClose()
      }}
    >
      <form onSubmit={onSubmit}>
        <DialogTitle>Management’s Message</DialogTitle>

        <DialogContent>
          <div className="flex items-stretch justify-start gap-6">
            <FileInput
              accept={acceptedImageTypes.toString()}
              name="management-message-image"
              ref={fileInputRef}
              onChange={files => uploadPicture(files)}
            />

            <div className="flex flex-col items-center">
              <div className={classes.imgContainer}>
                <img
                  alt="&#32;"
                  className={classes.displayPictureMobile}
                  src={image || ""}
                />

                <div className={classes.imgOverlay} />
                <div className={classes.cameraIcon}>
                  <IconButton
                    color="white"
                    size="sm"
                    type="button"
                    onClick={() => fileInputRef.current?.click()}
                  >
                    <Camera height="36px" width="36px" />
                  </IconButton>
                </div>
              </div>

              {profile.managementMessage?.image && (
                <Button
                  className="mt-2"
                  color="primary"
                  loading={removePictureLoading}
                  type="button"
                  variant="text"
                  onClick={() => removePicture()}
                >
                  Remove
                </Button>
              )}
            </div>

            <div className="mb-3 flex grow flex-col gap-2">
              <TextField
                error={Boolean(errors.name)}
                helperText={errors.name?.message}
                label="Name"
                autoFocus
                fullWidth
                {...register("name", {
                  required: { value: true, message: "Required" },
                })}
              />
              <TextField
                error={Boolean(errors.position)}
                helperText={errors.position?.message}
                label="Position"
                autoFocus
                fullWidth
                {...register("position", {
                  required: { value: true, message: "Required" },
                })}
              />
            </div>
          </div>

          {/* @ts-expect-error placebo-issue */}
          <TextField
            charLimit={1500}
            error={Boolean(errors.message)}
            helperText={errors.message?.message}
            maxRows={20}
            rows={8}
            autoFocus
            fullWidth
            multiLine
            {...register("message", {
              required: { value: true, message: "Required" },
              maxLength: {
                value: 1500,
                message: "Try to keep it short",
              },
            })}
          />
        </DialogContent>
        <DialogFooter
          actions={{
            primary: {
              label: "Save",
              type: "submit",
              loading,
            },
            tertiary: {
              label: "Remove",
              type: "button",
              variant: "text",
              loading: removeLoading,
              onClick: onRemove,
              color: "critical",
            },
          }}
        />
      </form>
    </Dialog>
  )
}

export default EditManagementMsgDialog
