import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogProps,
  DialogTitle,
  Typography,
} from "@suraasa/placebo-ui"
import React from "react"

type Props = {
  handleClose: () => void
  loading?: boolean
  children: React.ReactNode
  onAccept: () => void
} & DialogProps

const AcceptApplicationDialog = ({
  open,
  handleClose,
  loading = false,
  children,
  onAccept,
  ...props
}: Props) => (
  <Dialog open={open} width={358} onRequestClose={handleClose} {...props}>
    <DialogTitle>Accept Application</DialogTitle>
    <DialogContent>
      <Typography variant="smallBody">{children}</Typography>
    </DialogContent>
    <DialogFooter
      actions={{
        primary: {
          label: "Accept",
          type: "submit",
          loading,
          onClick: onAccept,
        },
      }}
    />
  </Dialog>
)

export default AcceptApplicationDialog
