import { Button, Container, theme, Typography } from "@suraasa/placebo-ui"
import clsx from "clsx"
import React from "react"
import { createUseStyles } from "react-jss"

const useStyles = createUseStyles({
  root: {
    position: "fixed",
    width: "100%",
    left: 0,
    zIndex: "10",
    transition: "bottom 500ms",
    bottom: -100,
  },

  content: {
    background: "white",
    boxShadow: "1px 1px 5px rgba(0, 0, 0, 0.1)",
    border: `1px solid ${theme.colors.surface[100]}`,
    borderRadius: theme.spacing(0.5),
  },

  animate: {
    bottom: 20,
  },
})

function FloatingSaveBanner({
  open = false,
  onSave,
  loading,
}: {
  onSave: () => void
  loading: boolean
  open?: boolean
}) {
  const classes = useStyles()

  return (
    <div
      className={clsx(classes.root, "hidden sm:block", {
        [classes.animate]: open,
      })}
    >
      <Container>
        <div
          className={clsx(
            "flex items-center justify-between p-2",
            classes.content
          )}
        >
          <Typography variant="body">
            Looks like you have made some changes.
          </Typography>
          <Button
            color="primary"
            loading={loading}
            size="sm"
            type="submit"
            variant="filled"
            onClick={onSave}
          >
            Save
          </Button>
        </div>
      </Container>
    </div>
  )
}

export default FloatingSaveBanner
