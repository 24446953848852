import axios from "api/axios"
import { APIResponseOld, Config } from "api/types"
import { formatErrorResponse, formatSuccessResponseOld } from "api/utils"

import { User } from "../settings/types"
import { AuthData } from "./types"
import { urls } from "./urls"

// School/Recruiter user
export default {
  generatePlatformToken: async ({
    data,
  }: Config): Promise<APIResponseOld<AuthData>> => {
    try {
      const res = await axios.post(urls.generatePlatformToken(), data)
      return formatSuccessResponseOld(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  retrieveUser: async (): Promise<APIResponseOld<User>> => {
    try {
      const res = await axios.get(urls.user())
      return formatSuccessResponseOld(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
}
