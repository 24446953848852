import axios from "api/axios"
import { APIResponseOld, Config, ConfigWithURLParams } from "api/types"
import { formatErrorResponse, formatSuccessResponseOld } from "api/utils"

import { Country, Currency, Curriculum, State, Subject } from "./types"
import { urls } from "./urls"

export default {
  listCurrencies: async ({
    params,
  }: Config): Promise<APIResponseOld<Currency[]>> => {
    try {
      const res = await axios.get(urls.currencies.list(), {
        params,
      })
      return formatSuccessResponseOld(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  subjects: {
    // This is bulk-create
    create: async ({ data }: Config): Promise<APIResponseOld<Subject[]>> => {
      try {
        const res = await axios.post(urls.subjects.create(), data)
        return formatSuccessResponseOld(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    list: async ({ params }: Config): Promise<APIResponseOld<Subject[]>> => {
      try {
        const res = await axios.get(urls.subjects.list(), {
          params,
        })
        return formatSuccessResponseOld(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },

  listCountries: async ({
    params,
  }: Config): Promise<APIResponseOld<Country[]>> => {
    try {
      const res = await axios.get(urls.countries.list(), {
        params,
      })
      return formatSuccessResponseOld(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  listStates: async ({
    params,
    urlParams,
  }: ConfigWithURLParams<"countryId">): Promise<APIResponseOld<State[]>> => {
    try {
      const res = await axios.get(urls.states.list(urlParams.countryId), {
        params,
      })
      return formatSuccessResponseOld(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  curricula: {
    create: async ({ data }: Config): Promise<APIResponseOld<Curriculum[]>> => {
      try {
        const res = await axios.post(urls.curricula.create(), data)
        return formatSuccessResponseOld(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    list: async ({ params }: Config): Promise<APIResponseOld<Curriculum[]>> => {
      try {
        const res = await axios.get(urls.curricula.list(), {
          params,
        })
        return formatSuccessResponseOld(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },
  detectCountry: async (): Promise<APIResponseOld<Country>> => {
    try {
      const res = await axios.get(urls.countries.current())
      return formatSuccessResponseOld(res)
    } catch (e) {
      const errorResponse = formatErrorResponse(e)
      return errorResponse
    }
  },
}
