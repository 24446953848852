import {
  Button,
  CircularProgress,
  Container,
  theme,
  useMediaQuery,
} from "@suraasa/placebo-ui"
import api from "api"
import {
  Amenity,
  Gallery as GalleryType,
  Perk,
  Profile as ProfileType,
} from "api/resources/profile/types"
import Cover from "components/profile/Cover"
import Gallery from "components/profile/Gallery"
import JobOpenings from "components/profile/JobOpenings"
import Overview from "components/profile/Overview"
import PerksAndAmenities from "components/profile/PerksAndAmenities"
import Navbar from "components/shared/Navbar"
import { useEffect, useState } from "react"
import { createUseStyles } from "react-jss"
import { useParams, useSearchParams } from "react-router-dom"
import { getAuthInfo } from "utils/auth"
import { defaultProfile } from "utils/constants"
import useArray from "utils/hooks/useArray"
import toast from "utils/toast"
import { useSchoolActions } from "views/auth/useSchoolActions"
import useUserActions, { Actions } from "views/auth/useUserActions"
import useUserType from "views/auth/useUserType"
import PublishDialog from "views/common/SchoolTab/components/PublishDialog"

import ProfileContext from "./context"

const useStyles = createUseStyles({
  container: () => ({
    position: "relative",
    paddingBottom: theme.spacing(2),
  }),
})

const Profile = ({
  hideNavbar = false,
}: {
  hideNavbar?: boolean
  bypassVerificationCheck?: boolean
}) => {
  const [searchParams] = useSearchParams()

  const { schoolId } = useParams() as { schoolId: string }

  const { hasAction } = useUserActions()

  const { hasSchoolAction } = useSchoolActions({
    schoolIds: [schoolId],
  })

  const canUpdateSchool = hasSchoolAction(Actions.updateSchool, schoolId)

  const [isVerified, setIsVerified] = useState<ProfileType["isVerified"]>(false)
  const [schoolName, setSchoolName] = useState<ProfileType["name"] | null>(null)
  const [profile, setProfile] = useState<ProfileType>(defaultProfile)
  const [loading, setLoading] = useState(true)
  const [btnLoading, setBtnLoading] = useState(false)
  const gallery = useArray<GalleryType>([])
  const amenities = useArray<Amenity>([])
  const perks = useArray<Perk>([])

  const classes = useStyles()
  const isXs = useMediaQuery(theme.breakpoints.only("xs"))

  const publishProfile = async () => {
    setBtnLoading(true)

    const res = await api.school.verify({
      headers: {
        "School-Id": schoolId,
      },
    })
    if (res.isSuccessful) {
      toast.success("Profile published successfully")
      setIsVerified(true)
      setBtnLoading(false)
    } else {
      toast.error(res.errors.message || "Something went wrong")
      setBtnLoading(false)
    }
    setSchoolName(null)
  }

  useEffect(() => {
    if (!schoolId) {
      return
    }
    async function getProfile() {
      setLoading(true)

      try {
        const res = await api.profile.retrieve({
          params: {
            fields: [
              "gallery",
              "curriculum_board",
              "amenities",
              "perks",
              "management_message",
              "gallery_count",
              "is_assigned",
            ],
            photos: 20,
          },
          urlParams: {
            schoolSlug: searchParams.get("schoolSlug"),
          },
          headers: {
            "School-Id": schoolId,
          },
        })

        setProfile(res)
        setIsVerified(res.isVerified)
        if (res.gallery) {
          gallery.set(res.gallery)
        }
        if (res.amenities) {
          amenities.set(res.amenities)
        }
        if (res.perks) {
          perks.set(res.perks)
        }
        setLoading(false)
      } catch (e) {}
    }

    getProfile()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schoolId])
  const canPublish = hasAction(Actions.canPublishSchool)

  return (
    <>
      <PublishDialog
        open={Boolean(schoolName)}
        loading={btnLoading}
        schoolName={schoolName || ""}
        onClose={() => setSchoolName(null)}
        onSubmit={() => {
          publishProfile()
        }}
      />
      <ProfileContext.Provider
        value={{
          isPublic: !getAuthInfo(),
          profile,
          updateProfile: newState => {
            setProfile(prevState => ({
              ...prevState,
              ...newState,
            }))
          },
          gallery: {
            data: gallery.array,
            update: gallery.updateByKey,
            remove: gallery.removeByKey,
            add: gallery.push,
            set: gallery.set,
          },
          perks: {
            data: perks.array,
            update: perks.updateByKey,
            remove: perks.removeByKey,
            add: perks.push,
            set: perks.set,
          },
          amenities: {
            data: amenities.array,
            update: amenities.updateByKey,
            remove: amenities.removeByKey,
            add: amenities.push,
            set: amenities.set,
          },
        }}
      >
        <div>
          {!hideNavbar && (
            <Navbar
              gutterBottom={false}
              bottomEndSlot={
                isVerified ? undefined : canPublish ? (
                  <Button
                    onClick={() => {
                      setSchoolName(profile.name)
                    }}
                  >
                    Publish
                  </Button>
                ) : undefined
              }
            />
          )}

          {loading ? (
            <div className="my-20 flex justify-center">
              <CircularProgress />
            </div>
          ) : (
            <>
              <Cover isXs={isXs} canEditProfile={canUpdateSchool} />
              {isXs ? (
                <div className={classes.container}>
                  <Overview canUpdateSchool={canUpdateSchool} />
                  <Gallery canUpdateSchool={canUpdateSchool} />
                  <JobOpenings schoolId={schoolId} />
                  <PerksAndAmenities canUpdateSchool={canUpdateSchool} />
                </div>
              ) : (
                <Container className={classes.container}>
                  <Overview canUpdateSchool={canUpdateSchool} />
                  <Gallery canUpdateSchool={canUpdateSchool} />
                  <JobOpenings schoolId={schoolId} />
                  <PerksAndAmenities canUpdateSchool={canUpdateSchool} />
                </Container>
              )}
            </>
          )}
        </div>
      </ProfileContext.Provider>
    </>
  )
}

export default Profile
