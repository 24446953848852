import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@suraasa/placebo-ui"
import api from "api"
import clsx from "clsx"
import RemoveDialog from "components/RemoveDialog"
import { Xmark } from "iconoir-react"
import React, { useContext, useState } from "react"
import { createUseStyles } from "react-jss"
import { useParams } from "react-router-dom"
import { DEFAULT_GALLERY_PIC } from "utils/constants"
import toast from "utils/toast"
import ProfileContext from "views/Profile/context"

import AddGalleryImage from "./AddGalleryImage"

const useStyles = createUseStyles(theme => ({
  galleryDialogContent: {
    minHeight: "147px",
  },

  imageContainer: {
    width: "100%",
    height: "147px",
    position: "relative",
  },

  image: {
    width: "inherit",
    height: "inherit",
    objectFit: "cover",
    zIndex: 10,
  },

  deleteButton: {
    position: "absolute",
    right: "4px",
    top: "4px",
    width: "20px",
    height: "20px",
    zIndex: 11,
    borderRadius: "100px",
    background: theme.colors.critical[400],
    color: "white",

    "&:hover": {
      background: theme.colors.critical[500],
    },
  },

  addGalleryButton: {
    minHeight: "147px",
  },

  dialogTitle: {
    "& .DialogTitle-container > div": {
      width: "100%",

      "& > p": {
        width: "99%",
      },
    },
  },
}))

function EditGalleryDialog({
  open,
  handleClose,
}: {
  open: boolean
  handleClose: () => void
}) {
  const classes = useStyles()
  const { gallery, isPublic, updateProfile, profile } =
    useContext(ProfileContext)

  const [imageIdToDelete, setImageIdToDelete] = useState<number | null>(null)
  const [loading, setLoading] = useState(false)

  const { schoolId } = useParams() as { schoolId: string }

  const handleRemove = async (id: number | null) => {
    if (id === null) {
      return
    }

    setLoading(true)

    const res = await api.profile.gallery.delete({
      urlParams: { id },
      headers: { "School-Id": schoolId },
    })
    if (res.isSuccessful) {
      gallery.remove(id)
      updateProfile({ galleryCount: profile.galleryCount - 1 })
    } else toast.error("Remove Image Failed")

    setImageIdToDelete(null)
    setLoading(false)
  }

  return (
    <Dialog
      className={clsx(classes.dialogTitle)}
      open={open}
      width="min(95%,800px)"
    >
      <RemoveDialog
        handleClose={!loading ? () => setImageIdToDelete(null) : () => null}
        loading={loading}
        open={imageIdToDelete !== null}
        title="Delete image"
        onRemove={() => handleRemove(imageIdToDelete)}
      >
        Are you sure you want to delete this image?
      </RemoveDialog>

      <DialogTitle>
        <div
          className={clsx(
            classes.dialogTitle,
            "flex items-center justify-between"
          )}
        >
          Gallery
          <Button
            variant="text"
            onClick={() => {
              handleClose()
            }}
          >
            Done
          </Button>
        </div>
      </DialogTitle>
      <DialogContent>
        <div
          className={clsx(
            "grid gap-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5",
            classes.galleryDialogContent
          )}
        >
          {gallery.data.length < 20 && !isPublic && (
            <div className={classes.addGalleryButton}>
              <AddGalleryImage />
            </div>
          )}

          {gallery.data.map(item => (
            <div className={classes.imageContainer} key={item.id}>
              <img
                alt="gallery"
                className={classes.image}
                src={item.photo}
                onError={e => (e.currentTarget.src = DEFAULT_GALLERY_PIC)}
              />
              <IconButton
                className={classes.deleteButton}
                onClick={() => setImageIdToDelete(item.id)}
              >
                <Xmark height="16px" width="16px" />
              </IconButton>
            </div>
          ))}
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default EditGalleryDialog
