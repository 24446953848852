import { Tag, Typography } from "@suraasa/placebo-ui"
import Section from "components/teacher/profile/Section"
import SectionContent from "components/teacher/profile/Section/SectionContent"
import SectionHeading from "components/teacher/profile/Section/SectionHeading"
import SectionTitle from "components/teacher/profile/Section/SectionTitle"
import React, { useContext } from "react"
import { createUseStyles } from "react-jss"
import { pluralize } from "utils/helpers"
import ProfileContext from "views/teacher/profileContext"

const useStyles = createUseStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(1.5),
  },
}))

const Interests = () => {
  const classes = useStyles()

  const {
    interests: { data },
  } = useContext(ProfileContext)

  return (
    <>
      <Section>
        <SectionHeading
          heading={
            <SectionTitle
              itemCount={data.length}
              title={`${pluralize("Interest", data.length, {
                skipCount: true,
              })} & ${pluralize("Hobby", data.length, {
                plural: "Hobbies",
                skipCount: true,
              })}`}
            />
          }
        />

        <SectionContent className={classes.root} xPadding={2}>
          {data.length > 0 ? (
            data.map((item, i) => (
              <Tag key={i} label={item.interest} size="sm" />
            ))
          ) : (
            <Typography color="onSurface.400" variant="smallBody">
              Looks like there's nothing here.
            </Typography>
          )}
        </SectionContent>
      </Section>
    </>
  )
}

export default Interests
