import {
  Button,
  IconButton,
  Pagination,
  Table,
  TableFilter,
  Tooltip,
  Typography,
} from "@suraasa/placebo-ui"
import { AccessibleSchoolType } from "api/resources/recruiter/types"
import { GlobalContext } from "components/GlobalState"
import NoDataCard from "components/jobs/NoDataCard"
import TableWrapper from "components/TableWrapper"
import { Edit } from "iconoir-react"
import uniqBy from "lodash/uniqBy"
import { useContext } from "react"
import { useNavigate } from "react-router-dom"
import { SetState } from "utils/helpers"
import useFirstRender from "utils/hooks/useFirstRender"
import { routes } from "utils/routes"
import { useSchoolActions } from "views/auth/useSchoolActions"
import useUserActions, { Actions } from "views/auth/useUserActions"

import { DepartmentType } from "../SchoolTab/useSchoolList"
import { SchoolTabs } from "../SchoolTab/utils"
import ArrowSvg from "./ArrowSvg"

type Props = {
  schoolList: {
    data: AccessibleSchoolType[]
    total: number
    nextPage: null | number
    previousPage: null | number
  }
  page: number
  setPage: SetState<number>
  loading: boolean
  setFilters?: (x: TableFilter[]) => void
  setSortBy: (x: string[]) => void
  showRecruiter?: boolean
  showPartner?: boolean
  onEditRepresentative?: (
    row: AccessibleSchoolType,
    department: DepartmentType
  ) => void
  activeTab: string
  onPublishSchool?: (row: AccessibleSchoolType) => void
}

const SchoolTable = ({
  schoolList,
  page,
  setPage,
  loading,
  setFilters,
  setSortBy,
  showRecruiter,
  showPartner,
  onEditRepresentative,
  activeTab,
  onPublishSchool,
}: Props) => {
  const isFirstRender = useFirstRender()
  const { hasAction } = useUserActions()

  const { hasSchoolAction } = useSchoolActions({
    schoolIds: schoolList.data.map(s => s.id),
  })

  const { recruiterList, partnerList } = useContext(GlobalContext)
  const navigate = useNavigate()

  const partnerFilters = uniqBy(
    partnerList.map(item => {
      if (!item.uuid) {
        return {
          displayName: "",
          value: "",
        }
      }
      return {
        displayName: item.fullName,
        value: String(item.uuid),
      }
    }),
    "value"
  )

  const recruiterFilters = [
    {
      id: "no_recruiter_assigned",
      displayName: "No Recruiters assigned",
      value: "no_recruiter_assigned",
    },
    ...uniqBy(
      recruiterList.map(item => {
        if (!item.uuid) {
          return {
            displayName: "",
            value: "",
          }
        }
        return {
          displayName: item.fullName,
          value: String(item.uuid),
        }
      }),
      "value"
    ),
  ]

  const isUnassignedTab = activeTab === SchoolTabs.unassigned
  const isUnpublishedTab = activeTab === SchoolTabs.unpublished
  const otherSchools = activeTab === SchoolTabs.otherSchools

  const showEditPartner = (row: AccessibleSchoolType) => {
    if (otherSchools) return false

    if (isUnassignedTab) {
      if (hasSchoolAction(Actions.canAssignSchoolPartner, row.id)) {
        return true
      }
      if (row.assignedPartner) {
        return false
      }
      if (hasAction(Actions.canAssignSchoolPartner)) {
        return true
      }
    }
    if (isUnpublishedTab) {
      if (
        !!row.assignedPartner &&
        hasSchoolAction(Actions.canAssignSchoolPartner, row.id)
      ) {
        return true
      }
    }
    if (hasAction(Actions.canAssignSchoolPartner)) {
      return true
    }

    return false
  }

  const showEditRecruiter = (row: AccessibleSchoolType) => {
    if (otherSchools) return false

    if (
      isUnassignedTab &&
      hasAction(Actions.canAssignSchoolRecruiter) &&
      !!row.assignedPartner
    ) {
      return true
    }

    if (hasSchoolAction(Actions.canAssignSchoolRecruiter, row.id)) {
      return true
    }

    return false
  }
  const canPublish = (row: AccessibleSchoolType) => {
    if (hasAction(Actions.canPublishSchool)) {
      return true
    }
    if (hasSchoolAction(Actions.canPublishSchool, row.id)) {
      return true
    }
    return false
  }
  return (
    <div>
      <TableWrapper
        content={className => (
          <Table
            className={className}
            data={schoolList.data}
            loading={loading}
            headers={[
              {
                title: "School Name",
                id: "name",
                sortable: true,
                columnComponent: ({ row }) => (
                  <td className="flex flex-col justify-center">
                    <button
                      className="text-start hover:underline"
                      onClick={() => {
                        navigate(
                          routes.school.profile.replace(
                            ":schoolId",
                            row.id.toString()
                          )
                        )
                      }}
                    >
                      <Typography
                        color="onSurface.800"
                        variant="strongSmallBody"
                      >
                        {row.name}
                      </Typography>
                    </button>
                    {row.address && (
                      <Typography color="onSurface.800" variant="smallBody">
                        {row.address}
                      </Typography>
                    )}
                  </td>
                ),
              },
              ...(showRecruiter
                ? [
                    {
                      id: "recruiters",
                      title: "Recruiter",
                      filters: recruiterFilters,
                      columnComponent: ({
                        row,
                      }: {
                        row: AccessibleSchoolType
                      }) => (
                        <td className="cursor-default">
                          <div className="flex items-center">
                            <Typography
                              color="onSurface.800"
                              variant="strongSmallBody"
                            >
                              {row.assignedRecruiter
                                ? row.assignedRecruiter.fullName
                                : `-`}
                            </Typography>
                            {showEditRecruiter(row) && onEditRepresentative && (
                              <div className="group-hover:block">
                                <IconButton
                                  onClick={() => {
                                    onEditRepresentative(
                                      row,
                                      DepartmentType.RECRUITER
                                    )
                                  }}
                                >
                                  <Edit />
                                </IconButton>
                              </div>
                            )}
                          </div>
                        </td>
                      ),
                    },
                  ]
                : []),
              ...(showPartner
                ? [
                    {
                      title: "Partner",
                      id: "partners",
                      filters: partnerFilters,
                      columnComponent: ({
                        row,
                      }: {
                        row: AccessibleSchoolType
                      }) => (
                        <td className="cursor-default">
                          <div className="flex items-center">
                            <Typography
                              color="onSurface.800"
                              variant="strongSmallBody"
                            >
                              {row.assignedPartner
                                ? row.assignedPartner.fullName
                                : `-`}
                            </Typography>
                            {showEditPartner(row) && onEditRepresentative && (
                              <div className="group-hover:block">
                                <IconButton
                                  onClick={() => {
                                    onEditRepresentative(
                                      row,
                                      DepartmentType.PARTNER
                                    )
                                  }}
                                >
                                  <Edit />
                                </IconButton>
                              </div>
                            )}
                          </div>
                        </td>
                      ),
                    },
                  ]
                : []),

              {
                title: "Active Jobs",
                id: "active_job_count",
                sortable: true,
                columnComponent: ({ row }) => (
                  <td>
                    <Typography>{row.activeJobCount || `-`}</Typography>
                  </td>
                ),
              },
              {
                title: "",
                id: "",
                columnComponent: ({ row }) => (
                  <td>
                    <div className="flex items-center justify-end gap-1">
                      {isUnpublishedTab && canPublish(row) && onPublishSchool && (
                        <Tooltip
                          disabled={row.totalJobCount !== 0}
                          title="Please add at least one active job to publish this school."
                        >
                          <span>
                            <Button
                              variant="text"
                              disabled={row.totalJobCount === 0}
                              onClick={() => {
                                onPublishSchool(row)
                              }}
                            >
                              Publish
                            </Button>
                          </span>
                        </Tooltip>
                      )}
                      <IconButton
                        onClick={() => {
                          navigate(
                            routes.school.home.replace(
                              ":schoolId",
                              row.id.toString()
                            )
                          )
                        }}
                      >
                        <ArrowSvg />
                      </IconButton>
                    </div>
                  </td>
                ),
              },
            ]}
            multiSort={false}
            onFilter={filters => {
              if (isFirstRender) {
                return
              }
              setFilters?.(filters)
            }}
            onSort={cols => {
              if (cols.length > 0) {
                setSortBy(cols)
              } else {
                setSortBy(["-id"])
              }
            }}
          />
        )}
      />
      {!loading && schoolList.data.length === 0 && <NoDataCard />}

      {schoolList.total > 10 && (
        <Pagination
          className="paginated-svg"
          page={page}
          total={schoolList.total}
          onChange={setPage}
        />
      )}
    </div>
  )
}

export default SchoolTable
