import {
  Button,
  Container,
  Divider,
  IconButton,
  Select,
  TextField,
  theme,
  Typography,
} from "@suraasa/placebo-ui"
import api from "api"
import cover from "assets/school-background.png"
import clsx from "clsx"
import SlidingSheet from "components/SlidingSheet"
import { Xmark } from "iconoir-react"
import { useEffect } from "react"
import { Controller, useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import countryCodes from "utils/countryCodes"
import { mapErrors } from "utils/helpers"
import useDetectCountry from "utils/hooks/useDetectCountry"
import { routes } from "utils/routes"
import toast from "utils/toast"
import isURL from "validator/es/lib/isURL"

type Props = {
  open: boolean
  handleClose: () => void
}

type Form = {
  school: {
    name: string
    website: string
  }
  user: {
    email: string
    password: string
    confirmPassword: string
    firstName: string
    lastName: string
    phoneNumber: string | null
    countryCode: string | null
  }
}

const CreateNewSchool = ({ open, handleClose }: Props) => {
  const navigate = useNavigate()
  const currentCountry = useDetectCountry()
  const currentPhoneNumberCode = countryCodes.find(
    item => item.code === currentCountry?.isoCode
  )?.dialCode
  const {
    register,
    handleSubmit,
    setError,
    control,
    formState: { errors },
    reset,
  } = useForm<Form>({
    defaultValues: {
      user: {
        phoneNumber: "",
        countryCode: "",
      },
    },
  })

  useEffect(() => {
    reset({
      user: {
        countryCode: currentPhoneNumberCode,
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPhoneNumberCode])

  const onSubmit = handleSubmit(async data => {
    if (data.user.password !== data.user.confirmPassword) {
      setError("user.confirmPassword", { message: "Passwords do not match" })
      return
    }

    const res = await api.recruiter.createNewSchool({
      data: {
        email: data.user.email,
        password: data.user.password,
        firstName: data.user.firstName,
        lastName: data.user.lastName,
        phoneNumber: `${data.user.countryCode}${data.user.phoneNumber}`,
        name: data.school.name,
        website: data.school.website,
      },
    })

    if (res.isSuccessful) {
      toast.success("School created successfully")
      navigate(
        routes.school.profile.replace(":schoolId", res.data.id.toString())
      )
      handleClose()
    } else {
      mapErrors(setError, res.errors, [
        ["website", "school.website"],
        ["name", "school.name"],
        ["email", "user.email"],
        ["admin", "user.email"],
        ["password1", "user.password"],
        ["firstName", "user.firstName"],
        ["lastName", "user.lastName"],
        ["phoneNumber", "user.phoneNumber"],
      ])
    }
  })
  return (
    <SlidingSheet from="bottom" open={open} onClose={handleClose} fullScreen>
      <Container>
        <span className="flex items-center justify-between py-1.5">
          <IconButton
            className="flex size-[27px] items-center justify-center !rounded-full border-2 border-solid border-onSurface-500"
            onClick={handleClose}
          >
            <Xmark color={theme.colors.onSurface[500]} />
          </IconButton>
        </span>
      </Container>
      <div className="relative">
        <img alt="" className="min-h-[100px] w-full" src={cover} />

        <Typography
          className="absolute left-2/4 top-2/4 -translate-x-2/4 -translate-y-2/4"
          color="common.white.500"
          textAlign="center"
          variant="title1"
        >
          Create New School
        </Typography>
      </div>
      <Container className="mb-4  mt-8">
        <form onSubmit={onSubmit}>
          <div className="flex flex-col items-center gap-3">
            <div
              className={clsx("w-full max-w-[360px]", "flex flex-col gap-3")}
            >
              <TextField
                error={Boolean(errors.school?.name)}
                helperText={errors.school?.name?.message}
                label="School Name"
                fullWidth
                inputLabelProps={{
                  required: true,
                }}
                {...register("school.name", {
                  required: { value: true, message: "Required" },
                })}
              />
              <TextField
                error={Boolean(errors.school?.website)}
                helperText={errors.school?.website?.message}
                label="School Website"
                fullWidth
                inputLabelProps={{
                  required: true,
                }}
                {...register("school.website", {
                  validate: value =>
                    isURL(value, {
                      protocols: ["https", "http"],
                    }) || "Invalid URL",
                  required: { value: true, message: "Required" },
                })}
              />
            </div>

            <Divider className="mx-3 w-full max-w-[636px]" />

            <div
              className={clsx("w-full max-w-[360px]", "flex flex-col gap-3")}
            >
              <TextField
                error={Boolean(errors.user?.firstName)}
                helperText={errors.user?.firstName?.message}
                label="First Name"
                inputLabelProps={{
                  required: true,
                }}
                fullWidth
                {...register("user.firstName", {
                  required: { value: true, message: "Required" },
                })}
              />

              <TextField
                error={Boolean(errors.user?.lastName)}
                helperText={errors.user?.lastName?.message}
                label="Last Name"
                fullWidth
                {...register("user.lastName", {
                  required: { value: false, message: "Required" },
                })}
              />

              <div>
                <div className="flex gap-2">
                  <Controller
                    control={control}
                    name="user.countryCode"
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Select
                        className="max-w-15"
                        error={Boolean(errors.user?.countryCode)}
                        getOptionLabel={({ dialCode }) => dialCode}
                        getOptionValue={({ dialCode }) => dialCode}
                        helperText={errors.user?.countryCode?.message}
                        label="Code"
                        options={countryCodes}
                        placeholder="+91"
                        value={
                          value
                            ? countryCodes.find(item => item.dialCode === value)
                            : null
                        }
                        fullWidth
                        onBlur={onBlur}
                        onChange={val => {
                          onChange(val?.dialCode)
                        }}
                        inputLabelProps={{
                          required: true,
                        }}
                      />
                    )}
                    rules={{
                      required: { value: true, message: "Required" },
                    }}
                  />

                  <TextField
                    className="w-full max-w-[300px]"
                    error={Boolean(errors.user?.phoneNumber)}
                    helperText={errors.user?.phoneNumber?.message}
                    label="Phone Number"
                    placeholder="9845XX XXXXX"
                    inputMode="numeric"
                    fullWidth
                    inputLabelProps={{
                      required: true,
                    }}
                    {...register("user.phoneNumber", {
                      required: { value: true, message: "Required" },
                      maxLength: {
                        value: 12,
                        message: "Length cannot be greater than 12",
                      },
                      minLength: {
                        value: 8,
                        message: "Length cannot be less than 8",
                      },
                    })}
                  />
                </div>

                <Typography
                  className="mt-1"
                  color="onSurface.500"
                  variant="smallBody"
                >
                  This phone number will be used to verify school’s identity.
                  Make sure they have access to this number.
                </Typography>
              </div>
            </div>

            <Divider className="mx-3 w-full max-w-[636px]" />

            <div
              className={clsx("w-full max-w-[360px]", "flex flex-col gap-3")}
            >
              <Typography color="onSurface.500" variant="smallBody">
                This email and password will be used to login in to their
                Suraasa Account.
              </Typography>

              <TextField
                error={Boolean(errors.user?.email)}
                helperText={errors.user?.email?.message}
                label="Email Address"
                placeholder="johndoe@example.com"
                type="email"
                fullWidth
                inputLabelProps={{
                  required: true,
                }}
                {...register("user.email", {
                  required: { value: true, message: "Required" },
                })}
              />

              <TextField
                error={Boolean(errors.user?.password)}
                helperText={errors.user?.password?.message}
                label="Password"
                type="password"
                fullWidth
                inputLabelProps={{
                  required: true,
                }}
                {...register("user.password", {
                  required: { value: true, message: "Required" },
                })}
              />

              <TextField
                error={Boolean(errors.user?.confirmPassword)}
                helperText={errors.user?.confirmPassword?.message}
                label="Confirm Password"
                type="password"
                fullWidth
                inputLabelProps={{
                  required: true,
                }}
                {...register("user.confirmPassword", {
                  required: { value: true, message: "Required" },
                })}
              />

              <Button type="submit" fullWidth>
                Create New School
              </Button>
            </div>
          </div>
        </form>
      </Container>
    </SlidingSheet>
  )
}

export default CreateNewSchool
