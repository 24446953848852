import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogTitle,
  Typography,
} from "@suraasa/placebo-ui"

const PublishDialog = ({
  open,
  schoolName,
  onSubmit,
  onClose,
  loading,
}: {
  open: boolean
  loading: boolean
  schoolName: string
  onSubmit: () => void
  onClose: () => void
}) => {
  return (
    <Dialog open={open} onRequestClose={onClose}>
      <DialogTitle>Publish School</DialogTitle>
      <DialogContent>
        <Typography>
          Do you want to publish{" "}
          <Typography variant="strong" display="inline">
            {schoolName}
          </Typography>
          ?
          <br />
          <br />
          This will mark the school as 'Verified' and the school will start
          showing up on the jobs platform.
        </Typography>
      </DialogContent>
      <DialogFooter
        actions={{
          primary: {
            label: "Publish",
            type: "button",
            loading,
            onClick: onSubmit,
          },
        }}
      />
    </Dialog>
  )
}

export default PublishDialog
