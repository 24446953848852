import { Typography } from "@suraasa/placebo-ui"
import { SkillEvidence } from "api/resources/teacher/types"
import React from "react"
import { useParams } from "react-router-dom"

import EvidenceList from "./EvidenceList"

const Evidences = ({ skillEvidences }: { skillEvidences: SkillEvidence[] }) => {
  const { slug } = useParams() as { slug: string }

  const course = skillEvidences.find(item => item.slug === slug)

  return (
    <div className="mt-2.5 px-3 pb-4">
      <Typography className="mb-2" variant="title3">
        Evidences
      </Typography>

      <EvidenceList data={course?.evidences ?? []} canView />
    </div>
  )
}

export default Evidences
