import clsx from "clsx"
import React, { PropsWithChildren } from "react"
import { createUseStyles } from "react-jss"
import { BaseComponentProps } from "types"

const useStyles = createUseStyles(theme => ({
  root: {
    background: "#fff",
    border: `1px solid ${theme.colors.onSurface[200]}`,
    borderRadius: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      borderRadius: 0,
    },
  },
}))

const Section = ({
  children,
  className,
  ...props
}: PropsWithChildren<BaseComponentProps>) => {
  const classes = useStyles()
  return (
    <section
      className={clsx(classes.root, className)}
      {...props}
      style={{
        scrollMarginTop: "54px",
      }}
    >
      {children}
    </section>
  )
}

export default Section
