import axios from "api/axios"
import { Config, PaginatedAPIResponseOld } from "api/types"
import { formatErrorResponse, formatPaginatedResponse } from "api/utils"

import { RepresentativeType } from "./types"
import { urls } from "./urls"

export default {
  representativeList: async ({
    params,
    headers,
  }: Config): Promise<PaginatedAPIResponseOld<RepresentativeType[]>> => {
    try {
      const res = await axios.get(urls.representativeList(), {
        params,
        headers,
      })
      return formatPaginatedResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
}
