// import { TableFilter } from "@suraasa/placebo-ui"
import { TableFilter } from "@suraasa/placebo-ui"
import { useQuery } from "@tanstack/react-query"
import api from "api"
import { AccessibleSchoolType } from "api/resources/recruiter/types"
import { PaginatedResponse } from "api/types"
import { useEffect, useState } from "react"

export enum MemberTabs {
  yourSchools = "Your Schools",
  otherSchools = "Other Schools",
}

export enum DepartmentType {
  RECRUITER = "recruiter",
  PARTNER = "partner",
}
type Props = {
  size?: number
  search?: string
  getAssignedRecruiter?: boolean
  getAssignedPartner?: boolean
  isAssigned?: null | boolean
  isAssignedToMe?: null | boolean
  isVerified?: null | boolean
  relatedDepartment?: DepartmentType
  recruiters?: string[]
  partners?: string[]
}

type useSchoolListType = {
  setSortBy: React.Dispatch<React.SetStateAction<string[]>>
  page: number
  setPage: React.Dispatch<React.SetStateAction<number>>
  loading: boolean
  data: PaginatedResponse<AccessibleSchoolType[]>["data"]
  setData: React.Dispatch<
    React.SetStateAction<{
      data: AccessibleSchoolType[]
      total: number
      nextPage: number | null
      previousPage: number | null
    }>
  >
  refetchData: () => void
  filters: TableFilter[]
  setFilters: React.Dispatch<React.SetStateAction<TableFilter[]>>
}

const useSchoolList = ({
  search,
  size = 10,
  getAssignedRecruiter = false,
  getAssignedPartner = false,
  isAssigned = null,
  isAssignedToMe = null,
  isVerified = null,
  relatedDepartment,
  recruiters,
  partners,
}: Props): useSchoolListType => {
  const [sortBy, setSortBy] = useState<string[]>(["-id"])
  const [page, setPage] = useState(1)
  const [filters, setFilters] = useState<TableFilter[]>([])
  const [schoolList, setSchoolList] = useState<
    PaginatedResponse<AccessibleSchoolType[]>["data"]
  >({
    data: [],
    nextPage: null,
    previousPage: null,
    total: 0,
  })

  const fields: string[] = []
  if (getAssignedRecruiter) {
    fields.push("assigned_recruiter_id")
  }
  if (getAssignedPartner) {
    fields.push("assigned_partner_id")
  }

  const { data, isLoading, refetch } = useQuery({
    queryFn: () => {
      const noRecruiterFilter = filters.some(
        f => f.id === "recruiters" && f.value === "no_recruiter_assigned"
      )
      const recruiterFilters =
        recruiters ??
        filters
          .filter(
            f => f.id === "recruiters" && f.value !== "no_recruiter_assigned"
          )
          .map(({ value }) => value)

      return api.representatives.school({
        params: {
          size: size,
          page,
          ordering: sortBy,
          search: search || null,
          ...(relatedDepartment
            ? { related_department: relatedDepartment }
            : {}),
          ...(isAssigned !== null ? { is_assigned: isAssigned } : {}),
          ...(isVerified !== null ? { is_verified: isVerified } : {}),
          ...(isAssignedToMe !== null
            ? { is_assigned_to_self: isAssignedToMe }
            : {}),

          ...(noRecruiterFilter ? { no_recruiter_assigned: true } : {}),

          recruiters: recruiterFilters,

          ...(partners
            ? {
                partners,
              }
            : {
                partners: filters
                  .filter(({ id }) => id === "partners")
                  .map(({ value }) => value),
              }),
          fields,
        },
      })
    },
    queryKey: [
      "school-list",
      recruiters,
      partners,
      page,
      sortBy,
      search,
      filters,
      relatedDepartment,
      isAssigned,
      isVerified,
      isAssignedToMe,
      fields,
    ],
  })

  const refetchData = () => {
    refetch()
  }

  useEffect(() => {
    if (data) {
      setSchoolList(data)
    }
  }, [data])

  return {
    setSortBy,
    data: schoolList,
    setData: setSchoolList,
    loading: isLoading,
    page,
    setPage,
    refetchData,
    filters,
    setFilters,
  }
}

export default useSchoolList
