import clsx from "clsx"
import React, { PropsWithChildren } from "react"
import { createUseStyles } from "react-jss"
import { PropsWithClassName } from "types"

const useStyles = createUseStyles(theme => ({
  root: {
    background: "#fff",
    position: "relative",
    border: `1px solid ${theme.colors.onSurface[200]}`,
    borderRadius: theme.spacing(1),
  },
  stepConnector: {
    position: "absolute",
    height: 32,
    width: 2,
    bottom: -32,
    left: 31,
    background: theme.colors.onSurface[200],
  },
}))

type Props = {
  icon?: React.ReactElement
}

const TimelineContent = ({
  icon: Icon,
  children,
  className,
}: PropsWithChildren<PropsWithClassName<Props>>) => {
  const classes = useStyles()

  return (
    <div
      className={clsx(
        classes.root,
        "TimelineContent-root",
        "my-4 px-2 pb-2 pt-3",
        className
      )}
    >
      <div className="flex gap-1.5">
        <span className="TimelineContent-icon flex">{Icon}</span>
        {children}
      </div>
      <div
        className={clsx("TimelineContent-connector", classes.stepConnector)}
      />
    </div>
  )
}

export default TimelineContent
