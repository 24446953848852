import { UserType } from "api/resources/recruiter/types"

export const getRoleName = (role: UserType) => {
  switch (role) {
    case UserType.LEAD_ADMIN:
      return "Admin"
    case UserType.LEAD_RECRUITER:
      return "Lead Recruiter"
    case UserType.LEAD_PARTNER:
      return "Lead Partner"
    case UserType.MEMBER_PARTNER:
      return "Partner"
    case UserType.MEMBER_RECRUITER:
      return "Recruiter"
    case UserType.SCHOOL_ADMIN:
      return "School Admin"
  }
}
