import clsx from "clsx"
import TabItem from "components/Tab/TabItem"
import React, { useState } from "react"
import { createUseStyles } from "react-jss"
import { tabsProfile } from "utils/constants"

const useStyles = createUseStyles(theme => ({
  containerTabs: {
    background: theme.colors.common.white[500],
    borderRadius: theme.spacing(3, 3, 0, 0),
    [theme.breakpoints.down("xs")]: {
      borderRadius: 0,
      overflowX: "auto",
      "& > div > a": {
        whiteSpace: "nowrap",
      },

      "&::-webkit-scrollbar": {
        display: "none",
      },
    },
  },
}))

function ProfileTabs() {
  const classes = useStyles()
  const [activeTab, setActiveTab] = useState(tabsProfile.overview)
  return (
    <div className={clsx(classes.containerTabs, "px-2 pt-3 sm:px-3 sm:pb-2")}>
      <div className="flex items-center">
        {Object.entries(tabsProfile).map(([key, value]) => (
          <a href={`#${value}`} key={key}>
            <TabItem
              isActive={activeTab === value}
              onClick={() => setActiveTab(value)}
            >
              {value}
            </TabItem>
          </a>
        ))}
      </div>
    </div>
  )
}

export default ProfileTabs
