import {
  Checkbox,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@suraasa/placebo-ui"
import api from "api"
import { Reason } from "components/teacher/Dialogs/RejectApplicationDialog"
import { ArrowLeft } from "iconoir-react"
import { useState } from "react"
import { useForm } from "react-hook-form"
import toast from "utils/toast"

const closingReasons = [
  { key: "0", display: "Hired from Suraasa" },
  { key: "1", display: "Hired from external source" },
  { key: "2", display: "Job no longer available" },
  { key: "3", display: "School not a partner anymore" },
  { key: "4", display: "Other Reasons" },
] as const

type Form = {
  "0": boolean
  "1": boolean
  "2": boolean
  "3": boolean
  "4": boolean
  reason: string
}

type Props = {
  open: boolean
  handleClose: () => void
  position: string
  subjectName: string
  jobId: number
  onSuccess?: () => void
  schoolId: string
}
const CloseJobDialog = ({
  open,
  handleClose,
  onSuccess,
  position,
  subjectName,
  jobId,
  schoolId,
}: Props) => {
  const [showReasonForm, setShowReasonForm] = useState(false)

  const {
    register,
    handleSubmit,
    watch,
    formState: { isSubmitting },
  } = useForm<Form>()

  const otherReasons = watch("4")

  const onSubmit = handleSubmit(async formData => {
    const reasons = []

    for (const [key, value] of Object.entries(formData)) {
      if (value) {
        if (key === "4") continue

        if (key === "reason") {
          if (otherReasons)
            reasons.push({ reason: formData.reason, isOther: true })
        } else
          reasons.push({
            reason: closingReasons.find(r => r.key === key)?.display,
            isOther: false,
          })
      }
    }

    closeJobAPI(reasons)
  })

  const closeJobAPI = async (reasons: Reason[]) => {
    if (!jobId) return

    const res = await api.jobs.update({
      urlParams: {
        jobId,
      },
      data: {
        status: "closed",
        closingReason: reasons,
      },
      headers: {
        "School-Id": schoolId,
      },
    })

    if (res.isSuccessful) {
      toast.success("Job closed.")
      handleClose()
      if (onSuccess) onSuccess()
    } else {
      toast.error(res.errors.message)
    }
  }

  return (
    <Dialog open={open} width="md" onRequestClose={handleClose}>
      <DialogTitle>
        <div className="flex items-center space-x-1">
          {showReasonForm && (
            <IconButton color="black" onClick={() => setShowReasonForm(false)}>
              <ArrowLeft />
            </IconButton>
          )}
          <span>
            Close {position}, {subjectName}?
          </span>
        </div>
      </DialogTitle>
      <DialogContent>
        <div>
          {showReasonForm ? (
            <div className="py-1">
              <Typography className="mb-2.5" variant="strong">
                Please tell us the reason for closing this Job Application
              </Typography>
              <form className="flex flex-col gap-2" onSubmit={onSubmit}>
                {closingReasons.map(({ key, display }) => (
                  <Checkbox {...register(key)} key={key} label={display} />
                ))}
                {otherReasons && (
                  <>
                    {/* @ts-expect-error placebo-issue */}
                    <TextField
                      className="mb-1.5"
                      disabled={!otherReasons}
                      rows={2}
                      fullWidth
                      multiLine
                      {...register("reason")}
                      placeholder="Tell us why you are rejecting this application"
                    />
                  </>
                )}
              </form>
            </div>
          ) : (
            <div>
              <Typography className="mb-1" variant="body">
                Closing this job will automatically perform these actions:
              </Typography>
              <ul
                style={{
                  listStyle: "disc",
                  paddingLeft: "1.25rem",
                }}
              >
                <li>
                  All the active applications will be cancelled and will be
                  shifted to previous applications.
                </li>
                <li>All your invites sent will be expired.</li>
                <li>
                  We’ll inform all the teachers who were part of it or applied
                  or were invited for this job about its closure.
                </li>
              </ul>
            </div>
          )}
        </div>
      </DialogContent>
      <DialogFooter
        actions={{
          primary: {
            label: `Close ${showReasonForm ? "Job" : ""} Opening`,
            onClick: showReasonForm ? onSubmit : () => setShowReasonForm(true),
            color: "critical",
            loading: isSubmitting,
          },
        }}
      />
    </Dialog>
  )
}

export default CloseJobDialog
