import { Representative } from "api/resources/representatives/types"
import Tabs from "components/jobs/Tabs"
import LoadingOverlay from "components/shared/LoadingOverlay"
import { useEffect, useState } from "react"
import { SetState } from "utils/helpers"
import useTabs, { Tabs as TabsType } from "utils/hooks/useTabs"
import useSchoolList, {
  DepartmentType,
} from "views/common/SchoolTab/useSchoolList"
import SchoolTable from "views/common/SchoolTable"
import { checkHasPartnerRole, checkHasRecruiterRole } from "views/common/utils"

type Props = {
  row: Representative | null
}

const TABS = {
  recruiter: "As Recruiter",
  partner: "As Partner",
}

const RecruiterTab = {
  name: TABS.recruiter,
  count: 0,
}

const PartnerTab = {
  name: TABS.partner,
  count: 0,
}

const SchoolAssigned = ({
  row,
  tabs,
  setTabs,
}: Props & { tabs: TabsType; setTabs: SetState<TabsType> }) => {
  const hasRecruiterRole = checkHasRecruiterRole(row?.roles || [])
  const hasPartnerRole = checkHasPartnerRole(row?.roles || [])

  const [activeTab, setActiveTab] = useTabs({
    tabs,
    initialTab: tabs[0] ? tabs[0].name : "",
    useSearchParams: false,
  })

  const {
    data: schoolList,
    loading,
    page,
    setPage,
    setSortBy,
    setFilters,
  } = useSchoolList({
    search: "",
    getAssignedPartner: true,
    getAssignedRecruiter: true,
    relatedDepartment:
      activeTab.name === TABS.recruiter
        ? DepartmentType.RECRUITER
        : DepartmentType.PARTNER,
    recruiters:
      activeTab.name === TABS.recruiter && row?.uuid ? [row.uuid] : undefined,
    partners:
      activeTab.name === TABS.partner && row?.uuid ? [row.uuid] : undefined,
  })

  const onTabChange = (tab: string) => {
    setActiveTab(tab)
  }

  useEffect(() => {
    const t: any = []

    if (hasRecruiterRole) {
      t.push(RecruiterTab)
    }
    if (hasPartnerRole) {
      t.push(PartnerTab)
    }
    setTabs(t)
  }, [hasRecruiterRole, hasPartnerRole])

  useEffect(() => {
    if (!schoolList) {
      return
    }
    setTabs(prevTabs => {
      return prevTabs.map(x =>
        x.name === activeTab.name ? { ...x, count: schoolList.data.length } : x
      )
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schoolList])

  return (
    <div className="flex w-full flex-col">
      <Tabs
        color="black"
        activeTab={activeTab}
        tabs={tabs}
        onChange={onTabChange}
      />
      <SchoolTable
        loading={loading}
        schoolList={schoolList}
        setFilters={x => {
          console.log(x)
          setFilters(x)
        }}
        setSortBy={setSortBy}
        showPartner={activeTab.name === TABS.recruiter}
        showRecruiter={activeTab.name === TABS.partner}
        page={page}
        setPage={setPage}
        activeTab={activeTab ? activeTab.name : ""}
      />
    </div>
  )
}

const SchoolAssignedTab = ({ ...props }: Props) => {
  const { row } = props
  const hasRecruiterRole = checkHasRecruiterRole(row?.roles || [])
  const hasPartnerRole = checkHasPartnerRole(row?.roles || [])
  const [tabs, setTabs] = useState<TabsType>([])

  useEffect(() => {
    const t: any = []

    if (hasRecruiterRole) {
      t.push(RecruiterTab)
    }
    if (hasPartnerRole) {
      t.push(PartnerTab)
    }
    setTabs(t)
  }, [hasRecruiterRole, hasPartnerRole])

  if (tabs.length === 0) {
    return null
  }

  return <SchoolAssigned {...props} tabs={tabs} setTabs={setTabs} />
}

export default SchoolAssignedTab
