import { Avatar, Button, Chip, Tag, Typography } from "@suraasa/placebo-ui"
import { Country, State } from "api/resources/global/types"
import VerifiedBadge from "assets/home/verifiedBadge.svg"
import clsx from "clsx"
import { useState } from "react"
import { createUseStyles } from "react-jss"
import { Link, useParams, useSearchParams } from "react-router-dom"
import {
  formatWorkDuration,
  getTeacherProfileRoute,
  pluralize,
} from "utils/helpers"

import ContactDetailsDialog from "./ContactDetailsDialog"

const useStyles = createUseStyles(theme => ({
  userName: {
    "&:hover": {
      textDecorationColor: "black",
    },
  },
  imageContainer: {
    position: "relative",
    height: "max-content",
    width: "48px",
  },

  imageBadge: {
    position: "absolute",
    bottom: "0",
    right: "-3px",
  },
  separator: {
    color: theme.colors.primary[300],
  },
  userWorkExperiences: {
    height: "100%",
  },
}))

type JobUser = {
  uuid: string
  fullName: string
  username: string
  workExperiences: {
    subjectInterests?: string[]
    daysOfExperience: null | number
    curricula: string[]
    subjects: string[]
  }
  profile: {
    picture?: null | string
    isVerified: boolean
    country?: Country | null
    state?: State | null
  }
}

type Props = {
  applications?: number
  user: JobUser
  canViewContactDetails?: boolean
  action?: JSX.Element | null
  bottomTags?: JSX.Element | null
  verifiedSkillEvidences?: number
  className?: string
  jobId?: number | string | null
  showShared?: boolean
  isShared?: boolean
}

const UserDetailsCard = ({
  applications,
  canViewContactDetails,
  verifiedSkillEvidences,
  user,
  action,
  className,
  jobId,
  bottomTags,
  showShared,
  isShared,
}: Props) => {
  const [contactDialogOpen, setContactDialogOpen] = useState(false)

  const classes = useStyles()
  const [searchParams] = useSearchParams()

  const { schoolId } = useParams() as { schoolId: string }

  const teacherProfileRoute = getTeacherProfileRoute({
    jobId,
    schoolId: schoolId || searchParams.get("schoolId"),
    username: user.username,
  })
  return (
    <div
      className={clsx(
        "flex flex-col gap-1 rounded-lg border border-surface-200 bg-white p-2 sm:flex-row sm:gap-2",
        className
      )}
    >
      <div className="flex gap-1.5">
        <div className={classes.imageContainer}>
          <Avatar
            name={user.fullName}
            size="md"
            src={user.profile.picture ?? undefined}
          />
          {user.profile.isVerified && (
            <img src={VerifiedBadge} className={classes.imageBadge} alt="" />
          )}
        </div>

        <div className="flex flex-col gap-0.5 sm:hidden">
          <Button
            className={classes.userName}
            color="black"
            component={Link}
            nudge="left"
            to={teacherProfileRoute}
            variant="link"
          >
            <Typography display="inline" variant="strong">
              {user.fullName}
            </Typography>
          </Button>
          {verifiedSkillEvidences !== undefined &&
            verifiedSkillEvidences > 0 && (
              <Tag
                className="inline"
                color="success"
                label={pluralize("Verified skill", verifiedSkillEvidences)}
              />
            )}
          {isShared && !bottomTags && showShared && (
            <Tag className="inline" color="one" label="Shared" />
          )}
        </div>
      </div>

      <div className="grow">
        <div className="mb-1 flex flex-col justify-between gap-1 sm:mb-2 sm:flex-row sm:gap-0">
          <div className="grow">
            <div className="hidden sm:block">
              <Button
                className={classes.userName}
                color="black"
                component={Link}
                nudge="left"
                to={teacherProfileRoute}
                variant="link"
              >
                <Typography display="inline" variant="strong">
                  {user.fullName}
                </Typography>
              </Button>
              {verifiedSkillEvidences !== undefined &&
                verifiedSkillEvidences > 0 && (
                  <Tag
                    className="ml-1 inline"
                    color="success"
                    label={pluralize("Verified skill", verifiedSkillEvidences)}
                  />
                )}
              {isShared && !bottomTags && showShared && (
                <Tag className="inline" color="one" label="Shared" />
              )}
            </div>
            <div className="flex flex-col flex-wrap sm:flex-row sm:items-center sm:gap-0.5">
              {Boolean(
                user.workExperiences.subjectInterests &&
                  user.workExperiences.subjectInterests.length > 0
              ) && (
                <Typography className="mt-0.5" variant="smallBody">
                  Interested in teaching{" "}
                  <b>{user.workExperiences.subjectInterests?.join(", ")}</b>
                </Typography>
              )}

              {Boolean(
                user.workExperiences.subjectInterests &&
                  user.workExperiences.subjectInterests.length > 0 &&
                  user.workExperiences.daysOfExperience
              ) && (
                <Typography
                  className="hidden md:block"
                  color="primary.300"
                  display="initial"
                >
                  {" • "}
                </Typography>
              )}

              {user.workExperiences.daysOfExperience ? (
                <Typography className="mt-0.5" variant="smallBody">
                  {formatWorkDuration(user.workExperiences.daysOfExperience)} of
                  experience
                </Typography>
              ) : null}
            </div>
            {user.workExperiences.subjects.length > 0 && (
              <Typography className="mt-0.5" variant="smallBody">
                Teaches <b>{user.workExperiences.subjects.join(", ")}</b>
              </Typography>
            )}

            {user.profile.state?.name && user.profile.country?.name && (
              <Typography className="mt-0.5" variant="smallBody">
                Lives in{" "}
                <b>
                  {user.profile.state.name}, {user.profile.country.name}
                </b>
              </Typography>
            )}

            {applications !== undefined && applications > 0 && (
              <Typography className="mt-0.5" variant="smallBody">
                Interviewing with <b>{applications}</b> other{" "}
                {pluralize("school", applications, { skipCount: true })}
              </Typography>
            )}
          </div>

          {user.workExperiences.curricula.length > 0 && (
            <div
              className={clsx(
                classes.userWorkExperiences,
                "flex flex-wrap gap-0.5"
              )}
            >
              {user.workExperiences.curricula.map((board, index) => (
                <Chip
                  color="secondary"
                  key={index}
                  label={board}
                  size="sm"
                  variant="outlined"
                />
              ))}
            </div>
          )}
        </div>

        <div className="flex flex-col justify-between sm:flex-row sm:items-center">
          {!bottomTags && (
            <Button
              className="-ml-1"
              variant="text"
              onClick={() => {
                setContactDialogOpen(true)
              }}
            >
              View Contact Details
            </Button>
          )}
          {bottomTags}
          <div className="flex items-center justify-end">{action}</div>
        </div>
      </div>

      {contactDialogOpen && (
        <ContactDetailsDialog
          canViewContactDetails={canViewContactDetails}
          handleClose={() => setContactDialogOpen(false)}
          open={contactDialogOpen}
          teacher={{
            name: user.fullName,
            uuid: user.uuid,
          }}
        />
      )}
    </div>
  )
}

export default UserDetailsCard
