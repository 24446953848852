import axios from "api/axios"
import { APIResponse, Config, PaginatedAPIResponse } from "api/types"
import { formatErrorResponse, formatSuccessResponse } from "api/utils"

import { AccessibleSchoolType } from "../recruiter/types"
import { Representative } from "./types"
import { urls } from "./urls"

export default {
  representativesList: async ({
    params,
  }: Config): Promise<APIResponse<Representative[]>> => {
    try {
      const res = await axios.get(urls.representativesList(), {
        params,
      })
      return formatSuccessResponse(res, {})
    } catch (e) {
      throw formatErrorResponse(e)
    }
  },
  school: async ({
    params,
  }: Config): Promise<PaginatedAPIResponse<AccessibleSchoolType[]>> => {
    try {
      const res = await axios.get(urls.accessibleSchool(), {
        params,
      })
      return formatSuccessResponse(res, { paginatedResponse: true })
    } catch (e) {
      throw formatErrorResponse(e)
    }
  },
}
