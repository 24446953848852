"use client"

import * as AvatarPrimitive from "@radix-ui/react-avatar"
import { User } from "iconoir-react"
import * as React from "react"

import { cn } from "@/lib/utils"

const AvatarRoot = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Root>
>(({ className, ...props }, ref) => (
  <AvatarPrimitive.Root
    ref={ref}
    className={cn(
      "block size-10 shrink-0 rounded-full overflow-hidden border-white border-2",
      className
    )}
    {...props}
  />
))
AvatarRoot.displayName = "AvatarRoot"

const AvatarImage = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Image>,
  React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Image>
>(({ className, ...props }, ref) => (
  <AvatarPrimitive.Image
    ref={ref}
    className={cn("avatar-image", "size-full object-cover", className)}
    {...props}
  />
))
AvatarImage.displayName = AvatarPrimitive.Image.displayName

const AvatarFallback = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Fallback>,
  React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Fallback> & {
    name: string | undefined
  }
>(({ className, name, ...props }, ref) => {
  const getInitials = (name: string | undefined) => {
    if (!name) return ""
    const chunks = name.match(/\b(\w)/g)
    if (!chunks) return ""

    if (chunks.length === 1) return chunks[0].toUpperCase()
    return [chunks[0], chunks[chunks.length - 1]].join("").toUpperCase()
  }

  const initials = getInitials(name)

  return (
    <AvatarPrimitive.Fallback
      ref={ref}
      className={cn(
        "avatar-fallback",
        "font-medium flex size-full items-center justify-center bg-decorative-one-100 text-decorative-one-200",
        className
      )}
      {...props}
    >
      {initials ? initials : <User />}
    </AvatarPrimitive.Fallback>
  )
})
AvatarFallback.displayName = AvatarPrimitive.Fallback.displayName

export type AvatarProps = React.ComponentPropsWithoutRef<
  typeof AvatarPrimitive.Root
> & {
  name?: string | null
  src?: string | null
}

const Avatar = ({ name, src, className }: AvatarProps) => {
  if (!name && !src) {
    console.warn("Avatar is used with no name and no src")
  }

  const ref = React.useRef<HTMLSpanElement>(null)

  const [fontSize, setFontSize] = React.useState<string>("auto")

  React.useEffect(() => {
    if (!ref.current) return

    const width = ref.current.clientWidth
    const fontSize = `${width / 3}px`

    setFontSize(fontSize)
  }, [ref, className])

  return (
    <AvatarRoot className={className} ref={ref}>
      <AvatarImage src={src ?? undefined} />
      <AvatarFallback name={name ?? undefined} style={{ fontSize }} />
    </AvatarRoot>
  )
}

Avatar.displayName = "Avatar"

export { Avatar }
