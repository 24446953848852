import {
  Country,
  Currency,
  Curriculum,
  State,
  Subject,
} from "api/resources/global/types"

import { PhoneNumber } from "../users/types"
import {
  ActiveJobApplicantStepStatus,
  ActiveJobApplicantStepType,
} from "./step.types"

export enum JobStatus {
  UNPUBLISHED = "unpublished",
  PUBLISHED = "published",
  CLOSED = "closed",
}

export enum JobInterestedUserAction {
  REJECTED = "rejected",
  ACCEPTED = "accepted",
}

export enum NotHiredUserStatus {
  APPLICANT_REJECTED = "applicant_rejected",
  JOB_OFFER_REJECTED = "job_offer_rejected",
  APPLICATION_REJECTED = "application_rejected",
  APPLICATION_CANCELLED = "application_cancelled",
  APPLICATION_WITHDRAWN = "application_withdrawn",
}

export enum NotHiredRejectedBy {
  PARTNER = "partner",
  TEACHER = "teacher",
  SCHOOL = "school",
  RECRUITER = "recruiter",
  SYSTEM = "system",
}

export enum JobInterestedUserStatus {
  PENDING = "pending",
  ACCEPTED = "accepted",
  REJECTED = "rejected",
  WITHDRAWN = "withdrawn",
}

export enum JobInvitedUserStatus {
  NOT_SENT = "not_sent",
  PENDING = "pending",
  ACCEPTED = "accepted",
  REJECTED = "rejected",
  REVOKED = "revoked",
  CANCELLED = "cancelled",
}
export enum JobApplicantStatus {
  PENDING = "pending",
  IN_PROGRESS = "in_progress",
  REJECTED = "rejected",
  JOB_OFFER_SENT = "job_offer_sent",
  JOB_OFFER_ACCEPTED = "job_offer_accepted",
  JOB_OFFER_REJECTED = "job_offer_rejected",
  WITHDRAWN = "withdrawn",
  CANCELLED = "cancelled",
}

export enum JobOfferResourceStatus {
  SENT = "sent",
  ACCEPTED = "accepted",
  REJECTED = "rejected",
}

type JobEligibilityCriterion = "all" | "qualification"

export type JobOverviewCount = {
  activeApplications: number
  applicants: number
  invited: number
  hired: number
  notHired: number
}

export type Job = {
  id: number
  position: string
  slug: string
  subject: Subject
  currency: Currency | null
  description: string | null
  maximumSalaryOffered: number | null
  salaryOffered: number | null
  salaryNegotiable: boolean
  datePublished?: string
  dateReopened?: string | null
  dateClosed?: string | null
  status: JobStatus
  curriculumBoard?: {
    curriculumId: string
  }[]
  school?: {
    name: string
    slug: string
    logo: string | null
    country: Country | null
    state: State | null
    curriculumBoard: Curriculum
    branch: string | null
    city: string | null
  }
  numberOfApplicants?: number | null
  eligibilityCriterion: JobEligibilityCriterion
  schoolContactPerson: {
    id: number
    phoneNumber: PhoneNumber | null
    name: string
    email: string
  } | null
  // If we're viewing the job for a particular user, we may request these fields from the backend
  jobApplicant?: {
    id: number
    status: JobApplicantStatus
  } | null
  jobInterestedUser?: {
    dateCreated: string
    status: JobInterestedUserStatus
  } | null
  jobInvitedUser?: {
    invitationSentOn: string
    status: JobInvitedUserStatus
  } | null
}

export type JobApplicant = {
  id: number
  status: JobApplicantStatus
  job: number
  user: string
  jobOffer?: {
    id: number
    status: JobOfferResourceStatus
  }
  jobInterestedUser?: {
    id: number
    status: JobInterestedUserStatus
  } | null
  jobInvitedUser?: {
    id: number
    status: JobInvitedUserStatus
  } | null
}

export type ContactDetails = {
  email: string
  profile: {
    phoneNumber: PhoneNumber | null
  }
}

export type ActiveJobApplicant = {
  id: number
  status: JobApplicantStatus
  dateCreated: string
  jobOffer: {
    id: number
    status: JobOfferResourceStatus
    dateCreated: string
  } | null
  currentStep: {
    id: number
    stepType: ActiveJobApplicantStepType
    step: {
      id: number
      name: string
      scheduledTime: string
      duration: number
      isTelephonic: boolean
      videoMeetingId: string | null
      user: string
      job: number
      jobApplicant: number
      url: string | null
    }
    status: ActiveJobApplicantStepStatus
    score: number | null
    dueDate: string
    startTime: string | null
    endTime: string | null
  }
  user: {
    fullName: string
    username: string
    uuid: string
    email: string
    profile: {
      phoneNumber: PhoneNumber | null
      picture: string | null
      isVerified: boolean
      country: Country | null
      state: State | null
    }
  }
}

export type JobInvitedUser = {
  id: number
  applications: number
  invitationSentOn: string | null
  invitationExpiresAt: string | null
  status: JobInvitedUserStatus
  user: {
    username: string
    fullName: string
    uuid: string
    workExperiences: {
      daysOfExperience: null | number
      noOfJobs: number
      salaryDrawn: null | number
      currency: Currency | null
    }
    profile: {
      picture: string | null
      isVerified: boolean
    }
  }
}

export enum LastActivity {
  IN_PROGRESS = "in_progress",
  JOB_OFFER_SENT = "job_offer_sent",
  INTERVIEW = "interview",
  ASSESSMENT = "assessment",
  LIVE_DEMO = "live_demo",
  NOT_HIRED = "not_hired",
}

export type Opportunity = {
  schoolName: string
  lastActivity: LastActivity
  date: string
}

export type JobInterestedUser = {
  id: number
  status: JobInterestedUserStatus
  jobApplicant: {
    id: number
    status: JobApplicantStatus
  } | null
  applications: number
  dateCreated: string
  isShortlisted: boolean
  hasActiveCareerSupport?: boolean
  isExternallyPlaced?: boolean
  opportunities?: Opportunity[]

  user: {
    uuid: string
    fullName: string
    username: string
    workExperiences: {
      daysOfExperience: null | number
      curricula: string[]
      subjects: string[]
    }
    profile: {
      picture: string | null
      isVerified: boolean
      country: Country | null
      state: State | null
    }
  }
}

export type NotHiredUser = {
  // If job applicant exists then it will come
  jobApplicant?: { id: number }
  status: NotHiredUserStatus
  rejectedBy: NotHiredRejectedBy | undefined
  placementTracker: {
    salary: string | null
  } | null
  user: {
    uuid: string
    fullName: string
    username: string
    email: string
    profile: {
      phoneNumber: PhoneNumber | null
      picture: string | null
      isVerified: boolean
      country: Country | null
      state: State | null
    }
  }
}

export type HiredUser = {
  id: number
  status: JobOfferResourceStatus
  jobOffer: {
    id: number
    status: JobOfferResourceStatus
    dateCreated: string
  } | null
  placementTracker: {
    salary: string | null
  } | null
  user: {
    uuid: string
    fullName: string
    username: string
    email: string
    profile: {
      phoneNumber: PhoneNumber | null
      picture: string | null
      isVerified: boolean
      country: Country | null
      state: State | null
    }
  }
}

export type ApplicantJobStatus = {
  canViewContactDetails?: boolean
  jobApplicant?: {
    id: number
    status: JobApplicantStatus
  } | null
  jobInterestedUser?: {
    id: number
    status: JobInterestedUserStatus
  } | null
  jobInvitedUser?: {
    id: number
    status: JobInvitedUserStatus
  } | null
}

export type ExploreTeacher = {
  interviewingSchools: number
  canViewContactDetails?: boolean
  hasActiveCareerSupport?: boolean
  isExternallyPlaced?: boolean
  opportunities?: {
    schoolName: string
    lastActivity: LastActivity
    date: string
  }[]
  jobApplicant: {
    id: number
    status: JobApplicantStatus
  } | null
  jobInterestedUser: {
    id: number
    status: JobInterestedUserStatus
  } | null
  jobInvitedUser: {
    id: number
    status: JobInvitedUserStatus
  } | null

  user: {
    uuid: string
    username: string
    workExperiences: {
      subjectInterests: string[]
      subjects: string[]
      curricula: string[]
      daysOfExperience: number | null
    }
    profile: {
      picture: null | string
      isVerified: boolean
      country?: Country | null
      state?: State | null
    }
    fullName: string
  }
  verifiedSkillEvidences: number
}

export type JobOffer = {
  description: string
  finalSalary: number
  currency: Currency
  joiningDate: string
  offerLetter: string
  offerLetterName: string
  status: JobOfferResourceStatus
  job: Job
  user: string
}

export type PlacementTracker = {
  id: number
  schoolName: string
  jobTitle: string
  salary: number | null
  dateOfHiring: string
  currencyId: Currency | null
  user: string
  jobApplicant: number | null
}
