import axios from "api/axios"
import { APIResponseOld, Config, NoContentType } from "api/types"
import { formatErrorResponse, formatSuccessResponseOld } from "api/utils"

import { OTPToken, User } from "./types"
import { urls } from "./urls"

export default {
  changePassword: async ({
    data,
  }: Config): Promise<APIResponseOld<NoContentType>> => {
    try {
      const res = await axios.post(urls.changePassword(), data)
      return formatSuccessResponseOld(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  changePhoneNumber: async ({
    data,
  }: Config): Promise<APIResponseOld<User>> => {
    try {
      const res = await axios.put(urls.user(), data)
      return formatSuccessResponseOld(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  generateOTP: async ({ data }: Config): Promise<APIResponseOld<OTPToken>> => {
    try {
      const res = await axios.post(urls.generateOTP(), data)
      return formatSuccessResponseOld(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  verifyOTP: async ({ data }: Config): Promise<APIResponseOld<User>> => {
    try {
      const res = await axios.put(urls.verifyOTP(), data)
      return formatSuccessResponseOld(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
}
