import {
  Button,
  Chip,
  Container,
  IconButton,
  Tag,
  theme,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@suraasa/placebo-ui"
import api from "api"
import {
  ApplicantJobStatus,
  Job,
  JobApplicantStatus,
  JobInterestedUserStatus,
} from "api/resources/jobs/types"
import {
  CoverStyle,
  Profile,
  WorkExperienceType,
} from "api/resources/teacher/types"
import clsx from "clsx"
import InviteToJobDialog from "components/explore/InviteToJobDialog"
import AcceptRejectApplicantActions from "components/jobs/AcceptRejectApplicantActions"
import BackButton from "components/shared/BackButton"
import ShareSheet from "components/shared/ShareSheet"
import TabBar from "components/TabBar"
import useTabs from "components/TabBar/hooks/useTabs"
import {
  BadgeCheck,
  Mail,
  MapPin,
  Phone,
  Plus,
  ShareAndroid,
} from "iconoir-react"
import { useEffect, useState } from "react"
import { Link, useParams, useSearchParams } from "react-router-dom"
import { getAuthInfo } from "utils/auth"
import {
  buildUserName,
  getTeacherTimelineRoute,
  pluralize,
  redirectToLogin,
} from "utils/helpers"
import { useSchoolActions } from "views/auth/useSchoolActions"
import { Actions } from "views/auth/useUserActions"

import { Avatar } from "@/components/Avatar"

import { ProfileQuickAccessTabs } from "../utils"
import VideoPortfolio from "../VideoPortfolio"

const getImageUrl = (imageName?: CoverStyle, isSmDown?: boolean) => {
  if (!imageName) {
    return undefined
  }

  let baseUrl = "https://assets.suraasa.com/lms/profile-assets/cover-images/"

  if (isSmDown) {
    baseUrl += "mobile/"
  }

  return `${baseUrl}${imageName}.webp`
}
export const formatWorkDuration = (days: number) => {
  if (days < 365) {
    const months = Math.floor(days / 30)

    if (months === 0) return "Less than a month"
    return pluralize("month", months)
  }

  const eightMonthsInYears = 8 / 12

  const years = days / 365
  const threshold = Math.trunc(years) + eightMonthsInYears

  if (years > threshold) return `${Math.trunc(years)}+ years`

  return `${pluralize("year", Math.floor(years))}`
}

const initialTabs = [
  {
    content: () => <div />,
    name: ProfileQuickAccessTabs.about,
  },
  {
    content: () => <div />,
    name: ProfileQuickAccessTabs.resume,
  },
  {
    content: () => <div />,
    name: ProfileQuickAccessTabs.educationWithSuraasa,
  },
  {
    content: () => <div />,
    name: ProfileQuickAccessTabs.skillPortfolio,
  },
  {
    content: () => <div />,
    name: ProfileQuickAccessTabs.topSkillEvidences,
  },
  {
    content: () => <div />,
    name: ProfileQuickAccessTabs.otherDetails,
  },
]
const scrollTo = (id: string) => {
  const element = document.getElementById(id)
  if (!element) return

  element.scrollIntoView({
    behavior: "smooth",
    block: "start",
    inline: "nearest",
  })
}

type Props = {
  profile: Profile
  workExperiences?: Partial<WorkExperienceType>[]
  showBackButton?: boolean
  showQuickAccessTabs?: boolean
  hideTimelineAction?: boolean
}

const Overview = ({
  profile,
  workExperiences,
  showBackButton = true,
  showQuickAccessTabs = false,
  hideTimelineAction = false,
}: Props) => {
  const isXsDown = useMediaQuery(theme.breakpoints.down("xs"))
  const isLoggedIn = getAuthInfo()
  const [openShare, setOpenShare] = useState(false)

  const { username, ...params } = useParams()
  const [searchParams] = useSearchParams()
  const jobId = searchParams.get("jobId")

  const schoolId = params.schoolId || searchParams.get("schoolId") || ""

  const [activeTab, setActiveTab] = useTabs({
    tabs: initialTabs,
    initialTab: initialTabs[0].name,
    useSearchParams: false,
  })

  const [inviteDialogOpen, setInviteDialogOpen] = useState(false)
  const [videoPortfolioURL, setVideoPortfolioURL] = useState<string | null>(
    null
  )
  const [videoPortfolioDialogOpen, setVideoPortfolioDialogOpen] =
    useState(false)

  const [job, setJob] = useState<Job | null>(null)
  const [applicantJobInfo, setApplicantJobInfo] =
    useState<ApplicantJobStatus | null>(null)

  useEffect(() => {
    api.teacher.videoPortfolio
      .retrieve({
        urlParams: { username: username ?? "" },
      })
      .then(res => {
        if (res.isSuccessful) {
          setVideoPortfolioURL(res.data.videoPortfolio)
        }
      })
  }, [])

  const { hasSchoolAction } = useSchoolActions({ schoolIds: [schoolId] })
  const manageHiringProcess = hasSchoolAction(
    Actions.manageHiringProcess,
    schoolId
  )

  const {
    coverStyle,
    totalWorkingExperience: experienceInYears,
    user,
    isVerified,
    picture,
    phoneNumber,
    state,
    country,
    currentWorkingExperience,
  } = profile

  useEffect(() => {
    async function getApplicationStatus() {
      const res = await api.jobs.applicants.getStatus({
        params: {
          fields: [
            "can_view_contact_details",
            "job_interested_user",
            "job_applicant",
            "job_invited_user",
          ],
          job_id: jobId,
        },
        data: {
          users: [user.uuid],
        },
        headers: {
          "School-Id": schoolId,
        },
      })
      if (res.isSuccessful) {
        if (res.data.length > 0) {
          setApplicantJobInfo(res.data[0])
        }
      }
    }
    getApplicationStatus()
  }, [user.uuid, jobId, schoolId])

  useEffect(() => {
    async function getJob() {
      if (!jobId) return

      const res = await api.jobs.retrieve({
        urlParams: {
          jobId: jobId,
        },
        headers: {
          "School-Id": schoolId,
        },
      })
      if (res.isSuccessful) {
        setJob(res.data)
      }
    }
    getJob()
  }, [user.uuid, jobId, schoolId])

  const url = `${window.location.origin}/profile/${user.username}`
  const fullName = buildUserName(user)
  const teaches = currentWorkingExperience?.subjects

  const curriculumTags = workExperiences
    ? [
        ...new Set(
          workExperiences.map(item => item.curriculum?.name).filter(Boolean)
        ),
      ]
    : []

  const formattedExperience = !experienceInYears
    ? undefined
    : formatWorkDuration(experienceInYears * 365)

  const getActions = () => {
    // const jobApplicant = applicantJobInfo?.jobApplicant
    const { jobApplicant, jobInterestedUser, jobInvitedUser } =
      applicantJobInfo || {}

    if (jobApplicant) {
      switch (jobApplicant.status) {
        case JobApplicantStatus.JOB_OFFER_SENT: {
          // TODO: Don't have job offer id here
          // if (jobApplicant.jobOffer)
          //   return (
          //     <div className="flex flex-col items-center gap-1">
          //       <Button
          //         component={Link}
          //         to={routes.viewOffer
          //           .replace(":username", user.username)
          //           .replace(":jobOfferId", String(jobApplicant.jobOffer?.id))}
          //         variant="filled"
          //       >
          //         View offer
          //       </Button>
          //     </div>
          //   )

          return <Tag color="primary" label="Job Offer Sent" />
        }
        case JobApplicantStatus.JOB_OFFER_ACCEPTED: {
          return <Tag color="success" label="Hired" />
        }
        case JobApplicantStatus.JOB_OFFER_REJECTED: {
          return <Tag color="critical" label="Offer Rejected" />
        }

        case JobApplicantStatus.PENDING: {
          break
        }

        case JobApplicantStatus.IN_PROGRESS:
          if (hideTimelineAction) return null
          return (
            <Button
              className="-ml-0.75"
              variant="text"
              component={Link}
              to={getTeacherTimelineRoute({
                jobId: jobId,
                jobApplicantId: jobApplicant.id.toString(),
                username: user.username,
                schoolId: schoolId,
              })}
            >
              View Timeline
            </Button>
          )
      }
    }

    if (jobInterestedUser) {
      return (
        <AcceptRejectApplicantActions
          position={job?.position}
          jobApplicantId={jobApplicant?.id}
          jobInterestedUserId={jobInterestedUser.id}
          fullName={user.firstName}
          schoolId={schoolId}
          isShared={
            jobInterestedUser.status === JobInterestedUserStatus.ACCEPTED
          }
          onUpdate={() => {
            window.location.reload()
          }}
        />
      )
    }

    if (jobInvitedUser) {
      return <Tag color="success" label="Invited" />
    }

    return (
      <Button
        startAdornment={<Plus />}
        variant="text"
        disabled={!manageHiringProcess}
        onClick={() => {
          if (!isLoggedIn) {
            redirectToLogin()
            return
          }

          setInviteDialogOpen(true)
        }}
      >
        Invite
      </Button>
    )
  }

  return (
    <>
      {!isLoggedIn && (
        <div className="bg-interactive-500 text-white">
          <Container>
            <div className="flex flex-col items-center justify-between gap-1.5 py-1 sm:flex-row">
              <Typography variant="subtitle2">
                Sign in to view full profile
              </Typography>
              <Button
                rounded
                color="white"
                size="sm"
                className="!w-full !text-primary-500 sm:!w-auto"
                onClick={redirectToLogin}
              >
                Sign In
              </Button>
            </div>
          </Container>
        </div>
      )}
      <div className="mb-3 border-b-2 border-solid border-b-onSurface-200 bg-white shadow-[0px_6px_15px_0px_#0000000D]">
        <div
          style={{
            backgroundImage: `url(${getImageUrl(coverStyle, isXsDown)})`,
          }}
          className={clsx("relative h-16 bg-cover text-black")}
        >
          {showBackButton && (
            <Container className="h-full">
              <div className="flex h-full flex-col justify-between">
                <BackButton className="mt-2 !bg-black/30" color="white" />

                <div className="mb-1 self-end [&_a]:!bg-black/30 [&_a]:!text-white [&_button]:!border-black/30 [&_button]:!bg-black/30 [&_button]:!text-white">
                  {getActions()}
                </div>
              </div>
            </Container>
          )}
        </div>
        <Container className="pt-2.5">
          <div className="relative">
            <div className="absolute -top-9">
              <Avatar
                src={picture}
                name={fullName}
                className="size-15 bg-white"
              />
            </div>
            <div className="mb-1 flex items-center justify-end space-x-1">
              <Tooltip
                disabled={Boolean(videoPortfolioURL)}
                title={`${user.firstName} has not added a video portfolio yet.`}
              >
                <span>
                  <Button
                    size="sm"
                    rounded
                    disabled={!videoPortfolioURL}
                    onClick={() => {
                      setVideoPortfolioDialogOpen(true)
                    }}
                  >
                    Video Portfolio
                  </Button>

                  {videoPortfolioURL && (
                    <VideoPortfolio
                      openSheet={videoPortfolioDialogOpen}
                      setOpenSheet={setVideoPortfolioDialogOpen}
                      videoURL={videoPortfolioURL}
                    />
                  )}
                </span>
              </Tooltip>

              <IconButton
                onClick={() => {
                  setOpenShare(true)
                }}
                variant="filled"
                className="!hidden !rounded-full sm:!grid"
                size="sm"
              >
                <ShareAndroid fontSize={13} />
              </IconButton>
            </div>
            <div className="mt-3 flex flex-wrap items-center space-x-0.75">
              <Typography component="h1" display="inline" variant="title3">
                {fullName}
              </Typography>
              {isVerified && (
                <BadgeCheck
                  color="white"
                  height="22"
                  width="22"
                  className="fill-primary-500"
                />
              )}
              {curriculumTags.map(name => (
                <Chip
                  key={name}
                  color="secondary"
                  label={name}
                  size="sm"
                  variant="outlined"
                />
              ))}
            </div>

            {((teaches && teaches.length > 0) || formattedExperience) && (
              <div className="mt-1 flex flex-col gap-0.5">
                {teaches && teaches.length > 0 && (
                  <Typography>
                    Teaches{" "}
                    <Typography display="inline" variant="subtitle2">
                      {teaches.join(", ")}
                    </Typography>
                  </Typography>
                )}

                {formattedExperience && (
                  <Typography>
                    Experience of{" "}
                    <Typography display="inline" variant="subtitle2">
                      {formattedExperience}
                    </Typography>
                  </Typography>
                )}
              </div>
            )}
            {applicantJobInfo?.canViewContactDetails && (
              <div className="mb-2 mt-1.5 flex flex-wrap items-center justify-start space-y-1.25 text-onSurface-500 sm:space-y-0 sm:divide-x">
                {user.email && (
                  <div className="flex items-center space-x-0.5 sm:pr-1">
                    <Mail className="size-2.5" />
                    <Typography variant="smallBody">{user.email}</Typography>
                  </div>
                )}
                {phoneNumber && (
                  <div className="flex items-center space-x-0.5 sm:px-1">
                    <Phone className="size-2.5" />
                    <Typography variant="smallBody">
                      +{phoneNumber.code} {phoneNumber.number}
                    </Typography>
                  </div>
                )}
                {state && country && (
                  <div className="flex items-center space-x-0.5 sm:pl-1">
                    <MapPin className="size-2.5" />
                    <Typography variant="smallBody">
                      {[state.name, country.name].join(", ")}
                    </Typography>
                  </div>
                )}
              </div>
            )}

            {showQuickAccessTabs && (
              <TabBar
                className="-mb-0.25 mt-2.5"
                activeTab={activeTab}
                tabs={initialTabs}
                onChange={tab => {
                  scrollTo(tab)
                  setActiveTab(tab)
                }}
              />
            )}
          </div>
        </Container>
      </div>

      <ShareSheet
        data={{
          url,
          text: `Check out ${fullName}'s profile on Suraasa, the career app for teachers: ${url}`,
        }}
        open={openShare}
        handleClose={() => setOpenShare(false)}
      />
      {inviteDialogOpen && schoolId && (
        <InviteToJobDialog
          open={inviteDialogOpen}
          teacherId={user.uuid}
          onRequestClose={() => setInviteDialogOpen(false)}
          schoolId={schoolId}
        />
      )}
    </>
  )
}

export default Overview
