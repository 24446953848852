import {
  Accordion,
  AccordionDetails,
  AccordionHeader,
  Avatar,
  Button,
  CircularProgress,
  Tag,
  TagColors,
  Typography,
} from "@suraasa/placebo-ui"
import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query"
import api from "api"
import {
  Job,
  JobInvitedUser,
  JobInvitedUserStatus,
} from "api/resources/jobs/types"
import VerifiedBadge from "assets/home/verifiedBadge.svg"
import clsx from "clsx"
import RejectApplicationDialog from "components/teacher/Dialogs/RejectApplicationDialog"
import format from "date-fns/format"
import isPast from "date-fns/isPast"
import React, { useState } from "react"
import { createUseStyles } from "react-jss"
import { Link } from "react-router-dom"
import {
  formatWorkDuration,
  getTeacherProfileRoute,
  pluralize,
} from "utils/helpers"
import usePaginationScroll from "utils/hooks/usePaginationScroll"
import toast from "utils/toast"

import NoDataCard from "./NoDataCard"

const useStyles = createUseStyles(theme => ({
  root: {
    overflowX: "auto",
  },

  container: {
    background: "white",
    borderRadius: "4px",
    border: `1px solid ${theme.colors.surface[200]}`,
    minWidth: "800px",
  },

  tableHeaderGrid: {
    display: "grid",
    gridTemplateColumns: "94px 1.25fr 1fr 1fr 0.7fr 1fr",
  },
  tableContentGrid: {
    display: "grid",
    gridTemplateColumns: "48px 1.25fr 1fr 1fr 0.7fr 1fr",
  },

  tableItem: {
    position: "relative",
  },

  accordionHeader: {
    padding: 0,
    borderTop: `1px solid ${theme.colors.surface[200]}`,

    "& > .AccordionHeader-icon": {
      padding: theme.spacing(0, 1.5),
    },
    "& > .AccordionHeader-content": {
      flexGrow: 1,
    },
  },

  accordionDetails: {
    padding: theme.spacing(3, 0),
    borderTop: `1px solid ${theme.colors.surface[200]}`,
  },

  image: {
    width: "32px",
    height: "32px",
  },

  textSmall: {
    fontSize: "12px",
    lineHeight: "15.6px",
  },
}))

type Props = {
  job: Pick<Job, "position" | "id">
  isClosed?: boolean
  isPreviousApplications?: boolean
  schoolId: string | undefined
  canManageHiringProcess: boolean
}

const getTag = (
  status: JobInvitedUserStatus | null,
  isExpired: boolean
): { color: TagColors; text: string } => {
  if (isExpired) {
    return { color: "onSurface", text: "Expired" }
  }
  switch (status) {
    case JobInvitedUserStatus.CANCELLED:
      return { color: "critical", text: "Cancelled" }
    case JobInvitedUserStatus.PENDING:
      return { color: "warning", text: "Pending" }
    case JobInvitedUserStatus.REJECTED:
      return { color: "critical", text: "Not Interested" }
    case JobInvitedUserStatus.REVOKED:
      return { color: "critical", text: "Revoked" }
    case JobInvitedUserStatus.NOT_SENT:
      return { color: "critical", text: "Not Sent" }
    default:
      throw new Error("Invalid JobInvitedUserStatus")
  }
}

const isExpired = (user: JobInvitedUser) => {
  if (!user.invitationExpiresAt) {
    return false
  }
  return isPast(new Date(user.invitationExpiresAt))
}

const InvitedTab = ({
  job,
  isClosed,
  isPreviousApplications,
  schoolId,
  canManageHiringProcess,
}: Props) => {
  const {
    isFetching: loading,
    hasNextPage,
    fetchNextPage,
    data,
    refetch,
  } = useInfiniteQuery({
    queryKey: ["invited", job.id, schoolId],
    queryFn: x =>
      api.jobs.applicants.listInvited({
        urlParams: {
          jobId: job.id,
        },
        params: {
          page: x.pageParam,
          ...(isPreviousApplications
            ? {
                status: "previous",
              }
            : {}),
        },
        headers: {
          "School-Id": schoolId,
        },
      }),
    initialPageParam: 1,
    getNextPageParam: lastPage => {
      return lastPage.isSuccessful ? lastPage.data.nextPage : undefined
    },
  })
  const { ref } = usePaginationScroll({
    loading: loading,
    hasNextPage: hasNextPage,
    actionFunc: fetchNextPage,
  })

  const applicants =
    data?.pages.map(page => (page.isSuccessful ? page.data.data : [])).flat() ||
    []

  const [selectedRow, setSelectedRow] = useState<JobInvitedUser | null>(null)

  const reInvite = async (item: JobInvitedUser | null) => {
    const res = await api.jobs.invitedUser.create({
      data: {
        userId: item?.user.uuid,
        jobId: job.id,
      },
      headers: {
        "School-Id": schoolId,
      },
    })

    if (res.isSuccessful) {
      refetch()
      toast.success("Candidate Re-invited!")
    } else {
      toast.error(res.errors.message)
    }
  }

  const classes = useStyles()

  const queryClient = useQueryClient()

  const getInvitationAction = (user: JobInvitedUser) => {
    if (!canManageHiringProcess) {
      return null
    }
    if (
      user.status === JobInvitedUserStatus.REJECTED ||
      user.status === JobInvitedUserStatus.CANCELLED ||
      user.status === JobInvitedUserStatus.REVOKED
    ) {
      return (
        <Button
          variant="text"
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation()
            reInvite(user)
          }}
        >
          Re-invite
        </Button>
      )
    }
    if (user.status === JobInvitedUserStatus.PENDING) {
      return (
        <Button
          color="critical"
          variant="text"
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation()
            setSelectedRow(user)
          }}
        >
          Revoke
        </Button>
      )
    }

    return null
  }

  return (
    <>
      {applicants.length === 0 && !loading && (
        <NoDataCard
          className="mt-3"
          message="You have not invited any teachers. Head to the 'Explore Teachers' page to check out teachers profiles and invite them to this job."
        />
      )}
      {applicants.length > 0 && (
        <div className={clsx(classes.root, "my-3")}>
          <div className={classes.container}>
            <div className={clsx(classes.tableHeaderGrid, "py-2")}>
              <div />
              <Typography variant="strongSmallBody">Name</Typography>
              <Typography variant="strongSmallBody">Status</Typography>
              <Typography variant="strongSmallBody">Invite Date</Typography>
            </div>

            {applicants.map((item, index) => {
              const tag = item.status && getTag(item.status, isExpired(item))

              return (
                <div ref={ref} key={index}>
                  <Accordion>
                    <AccordionHeader
                      className={classes.accordionHeader}
                      iconPosition="start"
                    >
                      <div
                        className={clsx(
                          classes.tableContentGrid,
                          classes.tableItem,
                          "py-2"
                        )}
                      >
                        <div className="mr-2 flex items-center justify-end">
                          <Avatar
                            name={item.user.fullName}
                            size="xs"
                            src={item.user.profile.picture ?? undefined}
                          />
                        </div>

                        <div className="flex items-center">
                          <div className="flex items-center gap-0.5">
                            <Button
                              component={Link}
                              to={getTeacherProfileRoute({
                                jobId: job.id,
                                username: item.user.username,
                                schoolId,
                              })}
                              variant="link"
                            >
                              <Typography
                                color="interactive.600"
                                display="inline"
                                variant="smallBody"
                              >
                                {item.user.fullName}
                              </Typography>
                            </Button>
                            {item.user.profile.isVerified && (
                              <img src={VerifiedBadge} alt="" />
                            )}
                          </div>
                        </div>

                        <div className="flex items-center">
                          {item.status && (
                            <Tag color={tag.color} label={tag.text} />
                          )}
                          {/* {!isVerified &&
                        item.status === InvitedUserStatus.NOT_SENT && (
                          <Tooltip title="This invitation will be sent when your profile is verified">
                            <WarningCircle
                              className="ml-1"
                              color={theme.colors.critical[500]}
                            />
                          </Tooltip>
                        )} */}
                        </div>
                        <div className="">
                          {item.invitationSentOn ? (
                            <>
                              <Typography
                                textAlign="left"
                                variant="strongSmallBody"
                              >
                                {format(
                                  new Date(item.invitationSentOn),
                                  "d MMM yyyy"
                                )}
                              </Typography>
                              <Typography textAlign="left" variant="smallBody">
                                {format(
                                  new Date(item.invitationSentOn),
                                  "hh:mm aa"
                                )}
                              </Typography>
                            </>
                          ) : (
                            <Typography textAlign="left" variant="smallBody">
                              -
                            </Typography>
                          )}
                        </div>
                        {!isClosed && (
                          <div>
                            <div className="flex flex-col items-end justify-center">
                              {Boolean(item.applications) && (
                                <Typography
                                  color="onSurface.500"
                                  variant="smallBody"
                                >
                                  Interviewing with <b>{item.applications}</b>{" "}
                                  other{" "}
                                  {pluralize("school", item.applications, {
                                    skipCount: true,
                                  })}
                                </Typography>
                              )}
                            </div>
                          </div>
                        )}
                        {!isClosed && (
                          <div className="mr-2 flex items-center justify-end">
                            {getInvitationAction(item)}
                          </div>
                        )}
                      </div>
                    </AccordionHeader>

                    <AccordionDetails className={classes.accordionDetails}>
                      <div className="ml-5 flex gap-6">
                        <div>
                          {item.user.workExperiences.salaryDrawn ? (
                            <Typography variant="strongSmallBody">
                              {item.user.workExperiences.currency?.symbol}
                              {item.user.workExperiences.salaryDrawn?.toLocaleString()}
                            </Typography>
                          ) : (
                            <Typography variant="strongSmallBody">
                              N/A
                            </Typography>
                          )}

                          <Typography color="onSurface.500" variant="smallBody">
                            Last Drawn Salary
                          </Typography>
                        </div>

                        <div>
                          {item.user.workExperiences.daysOfExperience ? (
                            <Typography variant="strongSmallBody">
                              {formatWorkDuration(
                                item.user.workExperiences.daysOfExperience
                              )}
                            </Typography>
                          ) : (
                            <Typography variant="strongSmallBody">
                              N/A
                            </Typography>
                          )}

                          <Typography color="onSurface.500" variant="smallBody">
                            Experience
                          </Typography>
                        </div>

                        <div>
                          {item.user.workExperiences.noOfJobs ? (
                            <Typography variant="strongSmallBody">
                              {item.user.workExperiences.noOfJobs}
                            </Typography>
                          ) : (
                            <Typography variant="strongSmallBody">
                              N/A
                            </Typography>
                          )}

                          <Typography color="onSurface.500" variant="smallBody">
                            Number of Jobs
                          </Typography>
                        </div>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
              )
            })}
          </div>
        </div>
      )}

      {loading && (
        <div className="mt-5 flex justify-center">
          <CircularProgress />
        </div>
      )}

      {selectedRow && schoolId && (
        <RejectApplicationDialog
          afterSubmit={() => {
            queryClient.invalidateQueries({
              queryKey: api.jobs.overviewCount.key(job.id.toString()),
            })

            refetch()
          }}
          resourceId={selectedRow.id}
          jobPosition={job.position}
          mode="invitation"
          open={Boolean(selectedRow)}
          toggle={() => setSelectedRow(null)}
          userName={selectedRow.user.fullName}
          schoolId={schoolId}
        />
      )}
    </>
  )
}
export default InvitedTab
