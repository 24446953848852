import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogProps,
  DialogTitle,
  InputLabel,
  Select,
  TextField,
  Toggle,
} from "@suraasa/placebo-ui"
import api from "api"
import {
  InterviewDetailsStep,
  JobApplicantStepStatus,
  StepType,
} from "api/resources/jobs/step.types"
import omit from "lodash/omit"
import { useEffect } from "react"
import { Controller, useForm } from "react-hook-form"
import { DURATION_HOURS, DURATION_MINUTES } from "utils/constants"
import { handleErrors, toDateTimeLocal } from "utils/helpers"
import { ToggleValue } from "utils/hooks/useToggle"
import toast from "utils/toast"

type Props = {
  toggle: ToggleValue
  jobApplicant?: string | number
  afterSubmit?: (data: InterviewDetailsStep) => void
  data?: InterviewDetailsStep
  schoolId: string
} & Pick<DialogProps, "open">

type Form = {
  name: string
  scheduledTime: string
  isTelephonic: boolean
  durationMinutes: number
  durationHours: number
  // additionalInterviewers: string[]
}

const EditInterviewDialog = ({
  open,
  data,
  toggle,
  afterSubmit,
  jobApplicant,
  schoolId,
}: Props) => {
  const {
    register,
    handleSubmit,
    reset,
    control,
    setError,
    formState: { isSubmitting, errors },
  } = useForm<Form>()
  const isEditable = Boolean(data)

  const onSubmit = handleSubmit(async formData => {
    if (formData.durationHours === 0 && formData.durationMinutes === 0) {
      setError("durationMinutes", { message: "Duration too short" })
      return
    }

    if (isEditable && data) {
      const res = await api.teacher.interview.update({
        data: {
          ...omit(formData, [
            "durationHours",
            "durationMinutes",
            "scheduledTime",
          ]),
          duration: formData.durationHours + formData.durationMinutes,
          scheduledTime: new Date(formData.scheduledTime).toISOString(),
        },
        urlParams: {
          interviewId: data.step.id,
        },
        headers: {
          "School-Id": schoolId,
        },
      })
      if (res.isSuccessful) {
        toast.success("Interview updated successfully")

        if (afterSubmit) {
          const { dateCreated, ...apiData } = res.data
          afterSubmit({
            dateCreated,
            dueDate: res.data.scheduledTime,
            id: data.id,
            step: { ...data.step, ...apiData },
            stepId: data.step.id,
            stepType: StepType.INTERVIEW,
            status: data.status,
          })
        }
        toggle()
      } else {
        handleErrors(setError, omit(res.errors, "jobApplicant"))
      }
    } else {
      const res = await api.teacher.interview.create({
        data: {
          duration: formData.durationHours + formData.durationMinutes,
          scheduledTime: new Date(formData.scheduledTime).toISOString(),
          ...omit(formData, [
            "durationHours",
            "durationMinutes",
            "scheduledTime",
          ]),
          jobApplicant: Number(jobApplicant),
        },
        headers: {
          "School-Id": schoolId,
        },
      })
      if (res.isSuccessful) {
        if (afterSubmit) {
          const { dateCreated, jobApplicantStep, ...apiData } = res.data
          afterSubmit({
            dateCreated,
            dueDate: res.data.scheduledTime,
            id: jobApplicantStep,
            step: {
              ...apiData,
              url: apiData?.videoMeeting?.meetingUrl ?? null,
            },
            stepId: res.data.id,
            stepType: StepType.INTERVIEW,
            status: JobApplicantStepStatus.NOT_STARTED,
          })
          toast.success("Interview added successfully")
        }
        toggle()
      } else {
        handleErrors(setError, omit(res.errors, "jobApplicant"))
      }
    }
  })

  useEffect(() => {
    if (data?.step) {
      const durationMinutes = data.step.duration % 60
      const durationHours = data.step.duration - durationMinutes
      reset({
        ...data.step,
        durationHours,
        durationMinutes,
        scheduledTime: toDateTimeLocal(data.step.scheduledTime),
      })
    }
  }, [data, reset])

  return (
    <Dialog
      open={open}
      shouldCloseOnOverlayClick={false}
      width="md"
      onAfterClose={() => reset({})}
      onRequestClose={() => toggle()}
    >
      <DialogTitle>{isEditable ? "Update" : "Add"} Interview</DialogTitle>
      <DialogContent>
        <form className="flex flex-col gap-2" onSubmit={onSubmit}>
          <TextField
            {...register("name", {
              required: { value: true, message: "Required" },
            })}
            error={Boolean(errors.name)}
            helperText={errors.name?.message}
            label="name"
            placeholder="Ex: Technical Interview"
            fullWidth
          />
          <TextField
            min={toDateTimeLocal(new Date().toISOString(), {
              omitSeconds: true,
            })}
            type="datetime-local"
            {...register("scheduledTime", {
              required: { value: true, message: "Required" },
            })}
            error={Boolean(errors.scheduledTime)}
            helperText={errors.scheduledTime?.message}
            label="date & time"
            fullWidth
          />

          <div>
            <InputLabel label="duration" />
            <div className="flex flex-wrap gap-2 sm:flex-nowrap">
              <Controller
                control={control}
                name="durationHours"
                render={({ field: { onChange, onBlur, value } }) => (
                  <Select
                    error={Boolean(errors.durationHours)}
                    helperText={errors.durationHours?.message}
                    options={DURATION_HOURS}
                    placeholder="0 hours"
                    value={DURATION_HOURS.find(c => c.value === value)}
                    fullWidth
                    mountOnBody
                    onBlur={onBlur}
                    onChange={val => onChange(val?.value)}
                  />
                )}
                rules={{ required: { value: true, message: "Required" } }}
              />
              <Controller
                control={control}
                name="durationMinutes"
                render={({ field: { onChange, onBlur, value } }) => (
                  <Select
                    error={Boolean(errors.durationMinutes)}
                    helperText={errors.durationMinutes?.message}
                    options={DURATION_MINUTES}
                    placeholder="15 minutes"
                    value={DURATION_MINUTES.find(c => c.value === value)}
                    fullWidth
                    mountOnBody
                    onBlur={onBlur}
                    onChange={val => onChange(val?.value)}
                  />
                )}
                rules={{ required: { value: true, message: "Required" } }}
              />
            </div>
          </div>

          <Toggle
            {...register("isTelephonic")}
            label="This is a telephonic interview"
          />

          {/* <Controller
            control={control}
            name="additionalInterviewers"
            render={({ field: { onChange, onBlur, value } }) => (
              <Select
                error={Boolean(errors.additionalInterviewers)}
                helperText={
                  errors.additionalInterviewers &&
                  errors.additionalInterviewers[0]?.message
                }
                isDisabled={isTelephonic}
                label="Additional Interviewers"
                noOptionsMessage={() => "Type to add email"}
                placeholder="Type to add email"
                value={value}
                createable
                fullWidth
                isMulti
                onBlur={onBlur}
                onChange={v => onChange(v)}
              />
            )}
          /> */}
        </form>
      </DialogContent>
      <DialogFooter
        actions={{
          primary: {
            label: isEditable ? "Update" : "Schedule Interview",
            onClick: onSubmit,
            loading: isSubmitting,
          },
        }}
      />
    </Dialog>
  )
}

export default EditInterviewDialog
