import api from "api"
import Tabs from "components/jobs/Tabs"
import { useEffect, useState } from "react"
import useTabs, { Tabs as TabsType } from "utils/hooks/useTabs"
import useUserActions from "views/auth/useUserActions"
import useUserType from "views/auth/useUserType"

import Table from "./Table"
import { AdminTabs, SchoolTabs } from "./utils"

type TabCount = {
  assigned?: number
  unassigned: number
  unpublished: number
  yourSchools?: number
  otherSchools?: number
}

const School = () => {
  const { isLeadAdmin } = useUserType()

  const {
    schoolTab: {
      canViewAssignedSchool,
      canViewUnassignedSchool,
      canViewUnpublishedSchool,
    },
  } = useUserActions()

  const initTabCount: TabCount = isLeadAdmin
    ? {
        assigned: 0,
        unassigned: 0,
        unpublished: 0,
      }
    : {
        yourSchools: 0,
        otherSchools: 0,
        unassigned: 0,
        unpublished: 0,
      }

  const tabBuilder = ({ count }: { count: TabCount }) => {
    const tabs: TabsType = []

    if (canViewAssignedSchool) {
      if (isLeadAdmin) {
        tabs.push({
          name: AdminTabs.assigned,
          content: Table,
          count: count.assigned,
        })
      } else {
        tabs.push({
          name: SchoolTabs.yourSchools,
          content: Table,
          count: count.yourSchools,
        })
        tabs.push({
          name: SchoolTabs.otherSchools,
          content: Table,
          count: count.otherSchools,
        })
      }
    }
    if (canViewUnassignedSchool) {
      tabs.push({
        name: AdminTabs.unassigned,
        content: Table,
        count: count.unassigned,
      })
    }
    if (canViewUnpublishedSchool) {
      tabs.push({
        name: AdminTabs.unpublished,
        content: Table,
        count: count.unpublished,
      })
    }
    return tabs
  }

  const [tabs, setTabs] = useState<TabsType>(
    tabBuilder({ count: initTabCount })
  )

  const [activeTab, setActiveTab] = useTabs({
    tabs: tabs,
    initialTab: tabs[0].name,
    useSearchParams: false,
  })
  const getSchoolCount = async () => {
    const res = await api.school.schoolCountOverview()
    if (res.isSuccessful) {
      const { assigned, unassigned, unpublished, otherSchools, yourSchools } =
        res.data
      setTabs(() =>
        tabBuilder({
          count: {
            unassigned,
            unpublished,
            assigned,
            otherSchools,
            yourSchools,
          },
        })
      )
    }
  }
  useEffect(() => {
    getSchoolCount()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <div className="flex flex-col justify-between gap-1 sm:flex-row">
        <Tabs activeTab={activeTab} tabs={tabs} onChange={setActiveTab} />

        {/* Portal for search field (gets hydrated from inside the tab) */}
        <div id="school-search-field-portal" />
      </div>
      <div className="mt-[-1.5px] border-b-2 border-onSurface-200" />
      <div className="mt-2 sm:mt-3">
        {activeTab && (
          <activeTab.content
            activeTab={activeTab.name}
            getSchoolCount={getSchoolCount}
            key={activeTab.name}
          />
        )}
      </div>
    </>
  )
}

export default School
