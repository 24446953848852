import { Avatar, Button, Table, Typography } from "@suraasa/placebo-ui"
import { useInfiniteQuery } from "@tanstack/react-query"
import api from "api"
import { ActiveSchoolApplicants } from "api/resources/recruiter/types"
import VerifiedBadge from "assets/home/verifiedBadge.svg"
import NoDataCard from "components/jobs/NoDataCard"
import SearchField from "components/Recruiter/SearchField"
import BackButton from "components/shared/BackButton"
import TableWrapper from "components/TableWrapper"
import { format } from "date-fns"
import React, { useState } from "react"
import { Link, useParams } from "react-router-dom"
import { getTeacherProfileRoute } from "utils/helpers"
import usePaginationScroll from "utils/hooks/usePaginationScroll"
import { routes } from "utils/routes"

import {
  Dialog,
  DialogBody,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/Dialog"
import { Sheet, SheetBody, SheetContent, SheetHeader } from "@/components/Sheet"

type Props = {
  isApplicant: boolean
  onClose: () => void
  open: boolean
  schoolId: string
}

const HiredRecordDialog = ({
  row,
  onClose,
  active,
}: {
  row: ActiveSchoolApplicants
  onClose: () => void
  active: boolean
}) => {
  const { schoolId } = useParams() as { schoolId: string }

  const data = active ? row.activeApplications : row.previousApplications
  const count = active
    ? row.activeApplicationsCount
    : row.previousApplicationsCount
  const title = active ? "Active Applications" : "Previous Applications"

  return (
    <Dialog open={!!row} onOpenChange={onClose}>
      <DialogContent closeWhenInteractOutside>
        <DialogHeader>
          <DialogTitle>
            <Typography variant="strong">{title}</Typography>
          </DialogTitle>
        </DialogHeader>
        <DialogBody>
          {count > 0 ? (
            <div className="flex flex-col gap-1">
              {data.map(el => (
                <Button
                  key={el.jobApplicantId}
                  component={Link}
                  to={routes.school.teacherTimeline
                    .replace(":schoolId", schoolId)
                    .replace(":jobApplicantId", String(el.jobApplicantId))
                    .replace(":username", row.user.username)}
                  variant="link"
                  target="_blank"
                >
                  {el.jobTitle}
                </Button>
              ))}
            </div>
          ) : (
            <Typography variant="body">No applications</Typography>
          )}
        </DialogBody>
      </DialogContent>
    </Dialog>
  )
}

const Applicants = ({ schoolId }: { schoolId: string }) => {
  const [search, setSearch] = useState("")
  const [showHiredRecord, setShowHiredRecord] = useState<{
    row: ActiveSchoolApplicants
    active: boolean
  } | null>(null)

  const {
    isFetching: loading,
    hasNextPage,
    fetchNextPage,
    data,
    // refetch,
  } = useInfiniteQuery({
    queryKey: ["detailsSchool", search, schoolId],
    queryFn: x =>
      api.school.activeSchoolApplicants({
        params: {
          page: x.pageParam,
          ...(search.length > 0 ? { search } : {}),
        },
        headers: {
          "School-Id": schoolId,
        },
      }),
    initialPageParam: 1,
    getNextPageParam: lastPage => {
      return lastPage.isSuccessful ? lastPage.data.nextPage : undefined
    },
  })
  const applicants =
    data?.pages.map(page => (page.isSuccessful ? page.data.data : [])).flat() ||
    []

  const { ref } = usePaginationScroll({
    loading: loading,
    hasNextPage: hasNextPage,
    actionFunc: fetchNextPage,
  })
  return (
    <>
      {showHiredRecord && (
        <HiredRecordDialog
          onClose={() => setShowHiredRecord(null)}
          row={showHiredRecord.row}
          active={showHiredRecord.active}
        />
      )}

      <div className="flex items-center justify-between border-b pb-1">
        <Typography variant="title3">Applicants</Typography>
        <div>
          <SearchField
            placeholder="Search by teacher's name/email"
            onSearch={text => setSearch(text.trim())}
            error={search.length >= 1 && search.length < 3}
            value={search}
          />
          {search.length >= 1 && search.length < 3 && (
            <Typography variant="strongSmallBody" color="critical.400">
              Minimum 3 characters are required for search.
            </Typography>
          )}
        </div>
      </div>
      <div>
        <TableWrapper
          content={className => (
            <Table
              className={className}
              data={applicants}
              loading={loading}
              headers={[
                {
                  title: "Name",
                  id: "name",
                  columnComponent: ({ row }) => (
                    <td className="flex !min-h-12 flex-col justify-center">
                      <div className="flex w-full gap-1" ref={ref}>
                        <div>
                          <Avatar
                            name={row.user.fullName}
                            size="xs"
                            src={row.user.profile.picture ?? undefined}
                          />
                        </div>
                        <div>
                          <div className="flex items-center gap-0.5">
                            <Button
                              className="-ml-0.75"
                              component={Link}
                              to={getTeacherProfileRoute({
                                username: row.user.username,
                                schoolId,
                              })}
                              target="_blank"
                              variant="link"
                            >
                              {row.user.fullName}
                            </Button>
                            {row.user.profile.isVerified && (
                              <img src={VerifiedBadge} alt="" />
                            )}
                          </div>

                          <div className="flex items-center">
                            <Typography
                              className={"text-xs"}
                              color="onSurface.500"
                            >
                              {row.user.email}
                            </Typography>
                          </div>

                          {row.user.profile.phoneNumber && (
                            <Typography
                              className={"text-xs"}
                              color="onSurface.500"
                            >
                              +{row.user.profile.phoneNumber.code}
                              {row.user.profile.phoneNumber.number}
                            </Typography>
                          )}
                        </div>
                      </div>
                    </td>
                  ),
                },
                {
                  title: "Active Applications",
                  id: "active_applications",
                  columnComponent: ({ row }) => (
                    <td>
                      <Button
                        variant="text"
                        color="primary"
                        onClick={() =>
                          setShowHiredRecord({ row, active: true })
                        }
                      >
                        {row.activeApplicationsCount || "0"}
                      </Button>
                    </td>
                  ),
                },
                {
                  title: "Previous Applications",
                  id: "previous_applications",
                  columnComponent: ({ row }) => (
                    <td>
                      <Button
                        variant="text"
                        color="primary"
                        onClick={() =>
                          setShowHiredRecord({ row, active: false })
                        }
                      >
                        {row.previousApplicationsCount || "0"}
                      </Button>
                    </td>
                  ),
                },
              ]}
              multiSort={false}
            />
          )}
        />
        {!loading && applicants && applicants.length === 0 && <NoDataCard />}
      </div>
    </>
  )
}

const HiredApplicants = ({ schoolId }: { schoolId: string }) => {
  const {
    isFetching: loading,
    hasNextPage,
    fetchNextPage,
    data,
    // refetch,
  } = useInfiniteQuery({
    queryKey: ["hiredApplicantsSchool", schoolId],
    queryFn: x =>
      api.school.hiredSchoolApplicants({
        params: {
          page: x.pageParam,
          fields: ["job_offer"],
        },
        headers: {
          "School-Id": schoolId,
        },
      }),
    initialPageParam: 1,
    getNextPageParam: lastPage => {
      return lastPage.isSuccessful ? lastPage.data.nextPage : undefined
    },
  })
  const applicants =
    data?.pages.map(page => (page.isSuccessful ? page.data.data : [])).flat() ||
    []

  const { ref } = usePaginationScroll({
    loading: loading,
    hasNextPage: hasNextPage,
    actionFunc: fetchNextPage,
  })
  return (
    <SheetBody>
      <div className="flex items-center justify-between border-b pb-1">
        <Typography variant="title3">Hired Applicants</Typography>
      </div>
      <div>
        <TableWrapper
          content={className => (
            <Table
              className={className}
              data={applicants}
              loading={loading}
              headers={[
                {
                  title: "Name",
                  id: "name",
                  columnComponent: ({ row }) => (
                    <td className="flex !min-h-12 flex-col justify-center">
                      <div className="flex gap-1" ref={ref}>
                        <div>
                          <Avatar
                            name={row.user.fullName}
                            size="xs"
                            src={row.user.profile.picture ?? undefined}
                          />
                        </div>
                        <div>
                          <div className="flex items-center gap-0.5">
                            <Button
                              className="-ml-0.75"
                              component={Link}
                              to={getTeacherProfileRoute({
                                // jobId: job.id,
                                username: row.user.username,
                                schoolId,
                              })}
                              variant="link"
                            >
                              {row.user.fullName}
                            </Button>
                            {row.user.profile.isVerified && (
                              <img src={VerifiedBadge} alt="" />
                            )}
                          </div>

                          <div className="flex items-center">
                            <Typography
                              className={"text-xs"}
                              color="onSurface.500"
                            >
                              {row.user.email}
                            </Typography>
                          </div>

                          {row.user.profile.phoneNumber && (
                            <Typography
                              className={"text-xs"}
                              color="onSurface.500"
                            >
                              +{row.user.profile.phoneNumber.code}
                              {row.user.profile.phoneNumber.number}
                            </Typography>
                          )}
                        </div>
                      </div>
                    </td>
                  ),
                },
                {
                  title: "Hired on",
                  id: "hired_on",
                  columnComponent: ({ row }) => (
                    <td>
                      <Typography variant="body">
                        {row.jobOffer
                          ? format(
                              new Date(row.jobOffer?.dateCreated),
                              "dd MMMM yyyy"
                            )
                          : "-"}
                      </Typography>
                    </td>
                  ),
                },
                {
                  title: "",
                  id: "",
                  columnComponent: ({ row }) => (
                    <td>
                      <Button
                        component={Link}
                        to={routes.school.teacherTimeline
                          .replace(":jobApplicantId", String(row.id))
                          .replace(":username", row.user.username)}
                        variant="link"
                        className="ml-auto !block"
                      >
                        View Details
                      </Button>
                    </td>
                  ),
                },
              ]}
              multiSort={false}
            />
          )}
        />
        {!loading && applicants && applicants.length === 0 && <NoDataCard />}
      </div>
    </SheetBody>
  )
}

const DetailsSheet = ({ open, onClose, isApplicant, schoolId }: Props) => {
  return (
    <>
      <Sheet open={open} onOpenChange={() => onClose()}>
        <SheetContent
          side="right"
          height={90}
          className="min-w-[1000px] sm:!max-w-5xl"
        >
          <SheetHeader hideClose>
            <BackButton onBack={() => onClose()} />
          </SheetHeader>
          <SheetBody>
            {isApplicant ? (
              <Applicants schoolId={schoolId} />
            ) : (
              <HiredApplicants schoolId={schoolId} />
            )}
          </SheetBody>
        </SheetContent>
      </Sheet>
    </>
  )
}

export default DetailsSheet
