import { useEffect, useState } from "react"

export default function useFirstRender() {
  const [firstRender, setFirstRender] = useState(true)

  useEffect(() => {
    setFirstRender(false)
  }, [])

  return firstRender
}
