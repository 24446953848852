export type AssessmentDetailsType = {
  id: number
  title: string
  duration: number
  dueDate: string | null
  dateCreated: string
  scheduledTime: string
  totalNumberOfQuestions: number
  feedback: any | null
}

export type InterviewDetailsType = {
  scheduledTime: string
  id: number
  duration: number
  name: string
  isTelephonic: boolean
  url: string | null
  videoMeeting: {
    meetingUrl: string
    endTime: string
    startTime: string
  } | null
  feedback: any | null
}

export type LiveDemoDetailsType = {
  id: number
  name: string
  scheduledTime: string
  instructions: string
  isVirtual: boolean
  duration: number
  topic: string | null
  address: string | null
  meetingUrl: string | null
  feedback: any | null
}

export enum JobApplicantStepStatus {
  NOT_STARTED = "not_started",
  IN_PROGRESS = "in_progress",
  CANCELLED = "cancelled",
  COMPLETED = "completed",
  SKIPPED = "skipped",
}

export enum StepType {
  ASSESSMENT = "assessment",
  INTERVIEW = "interview",
  LIVE_DEMO = "livedemo",
}

export type BaseStep = {
  id: number
  stepId: number
  status: JobApplicantStepStatus
  dueDate: string

  dateCreated: string

  step: InterviewDetailsType | LiveDemoDetailsType | AssessmentDetailsType
  stepType: StepType
}

export interface AssessmentDetailsStep extends BaseStep {
  stepType: StepType.ASSESSMENT
  step: AssessmentDetailsType
}
export interface InterviewDetailsStep extends BaseStep {
  stepType: StepType.INTERVIEW
  step: InterviewDetailsType
}
export interface LiveDemoDetailsStep extends BaseStep {
  stepType: StepType.LIVE_DEMO
  step: LiveDemoDetailsType
}

export type Step =
  | AssessmentDetailsStep
  | LiveDemoDetailsStep
  | InterviewDetailsStep

export type CurrentStep = Step & {
  score: number | null
  startTime: string | null // ISODate
  endTime: string | null // ISODate
}

export enum ActiveJobApplicantStepType {
  INTERVIEW = "interview",
  ASSESSMENT = "assessment",
  LIVE_DEMO = "live_demo",
}

export enum ActiveJobApplicantStepStatus {
  NOT_STARTED = "not_started",
  IN_PROGRESS = "in_progress",
  CANCELLED = "cancelled",
  COMPLETED = "completed",
  SKIPPED = "skipped",
}
