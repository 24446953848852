import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogTitle,
  Select,
  TextField,
  theme,
  Typography,
  useMediaQuery,
} from "@suraasa/placebo-ui"
import api from "api"
import { User } from "api/resources/settings/types"
import { PhoneNumber } from "api/resources/users/types"
import React, { useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { createUseStyles } from "react-jss"
import countryCodes from "utils/countryCodes"
import { handleErrors } from "utils/helpers"
import toast from "utils/toast"

const useStyles = createUseStyles({
  countryCode: {
    maxWidth: "120px",
  },
  phoneNumberField: {
    maxWidth: "300px",
  },
})

function ChangePhoneNumberDialog({
  open,
  close,
  currentPhone,
  onUpdate,
}: {
  open: boolean
  close: () => void
  onUpdate: (phone: User["phoneNumber"]) => void
  currentPhone: PhoneNumber | null
}) {
  const [loading, setLoading] = useState(false)
  const classes = useStyles()
  const smDown = useMediaQuery(theme.breakpoints.down("sm"))

  const {
    register,
    handleSubmit,
    reset,
    setError,
    control,
    formState: { errors },
  } = useForm<{
    phoneNumber: number
    countryCode: number
  }>()

  const onSubmit = handleSubmit(async data => {
    setLoading(true)
    const res = await api.settings.changePhoneNumber({
      data: { phoneNumber: `${data.countryCode}${data.phoneNumber}` },
    })
    if (res.isSuccessful) {
      setLoading(false)
      toast.success("Phone number updated")
      onUpdate(res.data.phoneNumber)
      close()
    } else {
      handleErrors(setError, res.errors)
      setLoading(false)
    }
  })

  return (
    <Dialog
      fullScreen={smDown}
      open={open}
      width={518}
      onAfterClose={reset}
      onRequestClose={close}
    >
      <DialogTitle onBack={smDown ? close : undefined}>
        Change Your Phone Number
      </DialogTitle>
      <DialogContent>
        <div className="mb-3.5 mt-2.75 flex items-center gap-2">
          <Typography variant="strongSmallBody">
            Current Phone Number
          </Typography>
          <Typography color="onSurface.500" variant="smallBody">
            + {currentPhone?.code} {currentPhone?.number}
          </Typography>
        </div>

        <form onSubmit={onSubmit}>
          <div className="mb-4.5 flex flex-col  gap-1 md:flex-row md:items-start md:gap-1.75">
            <Controller
              control={control}
              name="countryCode"
              render={({ field: { onChange, onBlur, value } }) => (
                <Select
                  className={classes.countryCode}
                  error={Boolean(errors.countryCode)}
                  getOptionLabel={({ dialCode }) => dialCode}
                  getOptionValue={({ dialCode }) => dialCode}
                  helperText={errors.countryCode?.message}
                  label="Code"
                  options={countryCodes}
                  placeholder="+91"
                  value={
                    value
                      ? countryCodes.find(
                          item => item.dialCode === value.toString()
                        )
                      : null
                  }
                  fullWidth
                  mountOnBody
                  onBlur={onBlur}
                  onChange={val => {
                    onChange(val?.dialCode)
                  }}
                />
              )}
              rules={{
                required: { value: true, message: "Required" },
              }}
            />

            <TextField
              className={classes.phoneNumberField}
              error={Boolean(errors.phoneNumber)}
              helperText={errors.phoneNumber?.message}
              label="Phone Number"
              placeholder="9845XX XXXXX"
              inputMode="numeric"
              fullWidth
              {...register("phoneNumber", {
                required: { value: true, message: "Required" },
                maxLength: {
                  value: 12,
                  message: "Length cannot be greater than 12",
                },
                minLength: {
                  value: 8,
                  message: "Length cannot be less than 8",
                },
              })}
            />
          </div>
        </form>
      </DialogContent>
      <DialogFooter
        actions={{
          primary: {
            label: "Continue",
            type: "submit",
            onClick: onSubmit,
            loading,
          },
          secondary: {
            label: "Cancel",
            onClick: close,
          },
        }}
      />
    </Dialog>
  )
}

export default ChangePhoneNumberDialog
