import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogProps,
  DialogTitle,
  InputLabel,
  TextField,
} from "@suraasa/placebo-ui"
import api from "api"
import { StepType } from "api/resources/jobs/step.types"
import { Feedback } from "api/resources/teacher/types"
import React from "react"
import { Controller, useForm } from "react-hook-form"
import ReactStars from "react-stars"
import { ToggleValue } from "utils/hooks/useToggle"
import toast from "utils/toast"

type Props = {
  toggle: ToggleValue
  afterSubmit?: (data: Feedback) => void
  id: number
  type: Omit<StepType, "ASSESSMENT">
  schoolId: string
} & Pick<DialogProps, "open">

export type FeedbackForm = {
  interpersonalSkills: {
    name: "Interpersonal Skills"
    comment: string
    rating: number
  }
  communicationSkills: {
    name: "Communication Skills"
    comment: string
    rating: number
  }
  attentionToDetail: {
    name: "Attention To Detail"
    comment: string
    rating: number
  }
  pedagogySkills: {
    name: "Pedagogy Skills"
    comment: string
    rating: number
  }
  overallComments: {
    name: "Overall Comments"
    comment: string
    rating: number
  }
}
const defaultValues: FeedbackForm = {
  interpersonalSkills: {
    name: "Interpersonal Skills",
    comment: "",
    rating: 0,
  },
  communicationSkills: {
    name: "Communication Skills",
    comment: "",
    rating: 0,
  },
  attentionToDetail: {
    name: "Attention To Detail",
    comment: "",
    rating: 0,
  },
  pedagogySkills: {
    name: "Pedagogy Skills",
    comment: "",
    rating: 0,
  },
  overallComments: {
    name: "Overall Comments",
    comment: "",
    rating: 0,
  },
}

const AddFeedbackDialog = ({
  open,
  schoolId,
  id,
  toggle,
  afterSubmit,
  type,
}: Props) => {
  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = useForm<FeedbackForm>({
    defaultValues,
  })

  const onSubmit = handleSubmit(async formData => {
    if (type === StepType.INTERVIEW) {
      const res = await api.teacher.interview.feedback.create({
        data: { feedback: Object.values(formData) },
        urlParams: {
          interviewId: id,
        },
        headers: {
          "School-Id": schoolId,
        },
      })
      if (res.isSuccessful) {
        toggle()
        toast.success("Feedback added successfully")
        if (afterSubmit) {
          afterSubmit(res.data)
        }
      } else if (res.errors.fieldErrors?.interview) {
        toast.error(res.errors.fieldErrors.interview)
      } else toast.error(res.errors.message ?? "Feedback couldn't be added")
    }
    if (type === StepType.LIVE_DEMO) {
      const res = await api.teacher.liveDemo.feedback.create({
        data: { feedback: Object.values(formData) },
        urlParams: {
          liveDemoId: id,
        },
        headers: {
          "School-Id": schoolId,
        },
      })
      if (res.isSuccessful) {
        toggle()
        toast.success("Feedback added successfully")
        if (afterSubmit) {
          afterSubmit(res.data)
        }
      } else if (res.errors.fieldErrors?.liveDemo) {
        toast.error(res.errors.fieldErrors.liveDemo)
      } else toast.error(res.errors.message ?? "Feedback couldn't be added")
    }
  })

  return (
    <Dialog
      open={open}
      width="md"
      onAfterClose={() => reset(defaultValues)}
      onRequestClose={() => toggle()}
    >
      <DialogTitle>Add Feedback</DialogTitle>
      <DialogContent>
        <form onSubmit={onSubmit}>
          <div className="-mb-1.5 flex items-center gap-1">
            <InputLabel label="Interpersonal Skills" />
            <Controller
              control={control}
              name="interpersonalSkills.rating"
              render={({ field: { onChange, value } }) => (
                <ReactStars
                  className="mb-1.75"
                  count={5}
                  half={false}
                  size={24}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </div>
          {/* @ts-expect-error placebo-issue */}
          <TextField
            className="mb-1.5"
            rows={2}
            fullWidth
            multiLine
            {...register("interpersonalSkills.comment")}
          />
          <div className="-mb-1.5 flex items-center gap-1">
            <InputLabel label="Communication Skills" />
            <Controller
              control={control}
              name="communicationSkills.rating"
              render={({ field: { onChange, value } }) => (
                <ReactStars
                  className="mb-1.75"
                  count={5}
                  half={false}
                  size={24}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </div>
          {/* @ts-expect-error placebo-issue */}
          <TextField
            className="mb-1.5"
            rows={2}
            fullWidth
            multiLine
            {...register("communicationSkills.comment")}
          />
          <div className="-mb-1.5 flex items-center gap-1">
            <InputLabel label="Attention to Detail" />
            <Controller
              control={control}
              name="attentionToDetail.rating"
              render={({ field: { onChange, value } }) => (
                <ReactStars
                  className="mb-1.75"
                  count={5}
                  half={false}
                  size={24}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </div>
          {/* @ts-expect-error placebo-issue */}
          <TextField
            className="mb-1.5"
            rows={2}
            fullWidth
            multiLine
            {...register("attentionToDetail.comment")}
          />
          <div className="-mb-1.5 flex items-center gap-1">
            <InputLabel label="Pedagogy Skills" />
            <Controller
              control={control}
              name="pedagogySkills.rating"
              render={({ field: { onChange, value } }) => (
                <ReactStars
                  className="mb-1.75"
                  count={5}
                  half={false}
                  size={24}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </div>
          {/* @ts-expect-error placebo-issue */}
          <TextField
            className="mb-1.5"
            rows={2}
            fullWidth
            multiLine
            {...register("pedagogySkills.comment")}
          />
          <div className="-mb-1.5 flex items-center gap-1">
            <InputLabel label="Overall Comments" />
            <Controller
              control={control}
              name="overallComments.rating"
              render={({ field: { onChange, value } }) => (
                <ReactStars
                  className="mb-1.75"
                  count={5}
                  half={false}
                  size={24}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </div>
          {/* @ts-expect-error placebo-issue */}
          <TextField
            className="mb-1.5"
            rows={2}
            fullWidth
            multiLine
            {...register("overallComments.comment")}
          />
        </form>
      </DialogContent>
      <DialogFooter
        actions={{
          secondary: {
            label: "Discard Changes",
            onClick: () => toggle(),
          },
          primary: {
            label: "Submit Feedback",
            onClick: onSubmit,
            loading: isSubmitting,
          },
        }}
      />
    </Dialog>
  )
}

export default AddFeedbackDialog
