export function popupWindow(
  windowUrl: string,
  w = 600,
  h = 500,
  windowName = "Suraasa"
) {
  if (!window.top) {
    return
  }
  const y = window.top.outerHeight / 2 + window.top.screenY - h / 2
  const x = window.top.outerWidth / 2 + window.top.screenX - w / 1.5
  return window.open(
    windowUrl,
    windowName,
    `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`
  )
}

export function isMobileOrTablet() {
  return (
    typeof window !== "undefined" &&
    /(android|iphone|ipad|mobile)/i.test(window.navigator.userAgent)
  )
}
