import api from "api"
import {
  AccessibleSchoolType,
  RecruiterType,
} from "api/resources/recruiter/types"
import { useEffect, useState } from "react"
import { createPortal } from "react-dom"
import useSearchField from "utils/hooks/useSearchField"
import toast from "utils/toast"

import SchoolTable from "../SchoolTable"
import AssignMemberSheet from "./components/AssignMemberSheet"
import PublishDialog from "./components/PublishDialog"
import useSchoolList, { DepartmentType } from "./useSchoolList"
import { AdminTabs, SchoolTabs } from "./utils"

const TabQueryMap = {
  [AdminTabs.assigned]: {
    isAssigned: true,
    getAssignedRecruiter: true,
    getAssignedPartner: true,
  },
  [AdminTabs.unassigned]: {
    isAssigned: false,
    getAssignedRecruiter: true,
    getAssignedPartner: true,
  },
  [AdminTabs.unpublished]: {
    isVerified: false,
    getAssignedPartner: true,
    getAssignedRecruiter: true,
  },
  [SchoolTabs.yourSchools]: {
    getAssignedRecruiter: true,
    getAssignedPartner: true,
    isAssignedToMe: true,
  },
  [SchoolTabs.otherSchools]: {
    getAssignedRecruiter: true,
    getAssignedPartner: true,
    isAssignedToMe: false,
  },
}
type Props = {
  activeTab: string
  getSchoolCount?: () => void
}

const Table = ({ activeTab, getSchoolCount }: Props) => {
  const { SearchField, searchText: search } = useSearchField({
    className: "-mt-2",
  })

  const {
    data: schoolList,
    loading,
    page,
    setPage,
    setSortBy,
    setFilters,
    refetchData,
  } = useSchoolList({
    search,
    ...TabQueryMap[activeTab],
  })
  const [publishSchool, setPublishSchool] = useState<{
    loading?: boolean

    row: AccessibleSchoolType
  } | null>(null)

  const [selectedRow, setSelectedRow] = useState<{
    row: AccessibleSchoolType
    department: DepartmentType
  } | null>(null)

  useEffect(() => {
    const handleEvent = (event: MessageEvent<string>) => {
      if (
        event.origin === window.location.origin &&
        event.data === "new-school-assigned"
      ) {
        refetchData()
      }
    }
    window.addEventListener("message", handleEvent)
    return () => {
      window.removeEventListener("message", handleEvent)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schoolList])

  const onClose = () => {
    setSelectedRow(null)
  }

  const onConfirmChange = async (
    schoolId: number,
    recruiter: RecruiterType
  ) => {
    const isPartner = selectedRow?.department === DepartmentType.PARTNER

    let handler = api.recruiter.assignRecruiter

    if (isPartner && selectedRow?.row.assignedPartner) {
      handler = api.recruiter.reAssignRecruiter
    }

    if (!isPartner && selectedRow?.row.assignedRecruiter) {
      handler = api.recruiter.reAssignRecruiter
    }

    const res = await handler({
      data: {
        user_id: recruiter.uuid,
        school_id: schoolId,
        department: isPartner
          ? DepartmentType.PARTNER
          : DepartmentType.RECRUITER,
      },
    })
    if (res.isSuccessful) {
      toast.success(
        `${isPartner ? "Partner" : "Recruiter"} assigned to ${
          selectedRow?.row.name
        }`
      )
      refetchData()
      getSchoolCount && getSchoolCount()
      onClose()
    } else {
      console.error(res.errors)
      toast.error(`${isPartner ? "Partner" : "Recruiter"} was failed to assign`)
    }
  }
  const onPublishSchool = async (id: string) => {
    setPublishSchool(d => (d ? { ...d, loading: true } : null))

    const res = await api.school.verify({
      headers: {
        "School-Id": id,
      },
    })
    if (res.isSuccessful) {
      toast.success("School published successfully")

      setPublishSchool(null)

      refetchData()
      getSchoolCount && getSchoolCount()
    } else {
      toast.error(res.errors.message || "Something went wrong")
      setPublishSchool(null)
    }
  }
  const portalEl = document.querySelector("#school-search-field-portal")

  return (
    <div>
      {!!portalEl && createPortal(SearchField, portalEl)}
      <AssignMemberSheet
        open={Boolean(selectedRow)}
        change
        handleClose={onClose}
        schoolId={selectedRow?.row.id}
        assignedRecruiter={
          selectedRow?.department === DepartmentType.PARTNER
            ? selectedRow?.row.assignedPartner
            : selectedRow?.row.assignedRecruiter
        }
        onSubmit={(schoolId, recruiterId) => {
          onConfirmChange(schoolId, recruiterId)
        }}
        isPartner={selectedRow?.department === DepartmentType.PARTNER}
      />
      <PublishDialog
        open={Boolean(publishSchool)}
        loading={publishSchool?.loading || false}
        schoolName={publishSchool?.row.name || ""}
        onClose={() => {
          setPublishSchool(null)
        }}
        onSubmit={() => {
          if (publishSchool) onPublishSchool(publishSchool.row.id.toString())
        }}
      />
      <SchoolTable
        loading={loading}
        schoolList={schoolList}
        setFilters={setFilters}
        setSortBy={setSortBy}
        showRecruiter
        showPartner
        page={page}
        setPage={setPage}
        onEditRepresentative={(school, department) =>
          setSelectedRow({ row: school, department })
        }
        onPublishSchool={row => setPublishSchool({ loading: false, row })}
        activeTab={activeTab}
      />
    </div>
  )
}

export default Table
