import axios from "api/axios"
import {
  APIResponse,
  APIResponseOld,
  Config,
  ConfigWithURLParams,
  URLParams,
} from "api/types"
import {
  formatErrorResponse,
  formatSuccessResponse,
  formatSuccessResponseOld,
} from "api/utils"

import { Amenity, GalleryItem, ManagementMessage, Perk, Profile } from "./types"
import { urls } from "./urls"

export default {
  retrieve: async ({
    params,
    urlParams: { schoolSlug },
    headers,
  }: ConfigWithURLParams): Promise<APIResponse<Profile>> => {
    try {
      const res = await axios.get(urls.profile.retrieve(schoolSlug), {
        params,
        headers,
      })
      return formatSuccessResponse(res)
    } catch (e) {
      throw formatErrorResponse(e)
    }
  },

  update: async ({
    data,
    headers,
  }: Config): Promise<APIResponseOld<Profile>> => {
    try {
      const res = await axios.put(urls.profile.update(), data, { headers })
      return formatSuccessResponseOld(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  managementMessage: {
    update: async ({
      data,
      headers,
    }: Config): Promise<APIResponseOld<ManagementMessage>> => {
      try {
        const res = await axios.post(urls.managementMessage.base(), data, {
          headers,
        })
        return formatSuccessResponseOld(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },

    delete: async ({
      headers,
    }: Config): Promise<APIResponseOld<ManagementMessage>> => {
      try {
        const res = await axios.delete(urls.managementMessage.base(), {
          headers,
        })
        return formatSuccessResponseOld(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },

  perks: {
    list: async ({ headers }: Config): Promise<APIResponseOld<Perk[]>> => {
      try {
        const res = await axios.get(urls.perks.list(), { headers })
        return formatSuccessResponseOld(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },

    update: async ({
      data,
      headers,
    }: Config): Promise<APIResponseOld<{ perk: Perk }>> => {
      try {
        const res = await axios.post(urls.perks.update(), data, { headers })
        return formatSuccessResponseOld(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },

    delete: async ({
      urlParams,
      headers,
    }: ConfigWithURLParams): Promise<APIResponseOld<any>> => {
      try {
        const res = await axios.delete(urls.perks.delete(urlParams.id), {
          headers,
        })
        return formatSuccessResponseOld(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },

  amenities: {
    list: async ({ headers }: Config): Promise<APIResponseOld<Amenity[]>> => {
      try {
        const res = await axios.get(urls.amenities.list(), { headers })
        return formatSuccessResponseOld(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },

    update: async ({
      data,
      headers,
    }: Config): Promise<APIResponseOld<{ amenity: Amenity }>> => {
      try {
        const res = await axios.post(urls.amenities.update(), data, { headers })
        return formatSuccessResponseOld(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },

    delete: async ({
      urlParams,
      headers,
    }: ConfigWithURLParams): Promise<APIResponseOld<any>> => {
      try {
        const res = await axios.delete(urls.amenities.delete(urlParams.id), {
          headers,
        })
        return formatSuccessResponseOld(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },

  gallery: {
    list: async (): Promise<APIResponseOld<GalleryItem[]>> => {
      try {
        const res = await axios.get(urls.gallery.list())
        return formatSuccessResponseOld(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },

    delete: async ({
      urlParams,
      headers,
    }: ConfigWithURLParams): Promise<APIResponseOld<any>> => {
      try {
        const res = await axios.delete(urls.gallery.delete(urlParams.id), {
          headers,
        })
        return formatSuccessResponseOld(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },

    update: async ({
      data,
      headers,
    }: Config): Promise<APIResponseOld<GalleryItem[]>> => {
      try {
        const res = await axios.post(urls.gallery.update(), data, {
          headers,
        })
        return formatSuccessResponseOld(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },
}
