import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogTitle,
  InputLabel,
  Select,
  TextField,
} from "@suraasa/placebo-ui"
import api from "api"
import { Currency } from "api/resources/global/types"
import { LearnerType } from "api/resources/recruiter/types"
import format from "date-fns/format"
import { useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import toast from "utils/toast"

type FormData = {
  jobTitle: string
  schoolName: string
  salary: string
  dateOfHiring: string
  currencyId: string
}

const MarkAsPlacedDialog = ({
  learner,
  onClose,
  onMarked,
}: {
  learner: LearnerType
  onClose: () => void
  onMarked: () => void
}) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm<FormData>()

  const [currencyData, setCurrencyData] = useState<Currency[]>([])

  useEffect(() => {
    const getCurrencyData = async () => {
      const res = await api.global.listCurrencies({ params: { page: -1 } })
      if (res.isSuccessful) {
        setCurrencyData(res.data)
      }
    }
    getCurrencyData()
  }, [])

  const onMarkAsHired = handleSubmit(async data => {
    try {
      await api.recruiter.markAsHired({
        data: {
          user: learner.uuid,
          school_name: data.schoolName,
          job_title: data.jobTitle,
          salary: data.salary,
          date_of_hiring: format(new Date(data.dateOfHiring), "yyyy-MM-dd"),
          currency_id: data.currencyId,
        },
      })
    } catch (error) {
      toast.error("Some error occurred")
      onClose()
      return
    }
    onMarked()
    toast.success("Marked as Placed")
    onClose()
  })

  return (
    <Dialog
      open={true}
      width="md"
      onRequestClose={() => (isSubmitting ? () => {} : onClose())}
    >
      <DialogTitle>Mark {learner.fullName} as Placed</DialogTitle>

      <DialogContent>
        <div className="flex flex-col gap-3 p-1">
          <TextField
            error={Boolean(errors.jobTitle)}
            helperText={errors.jobTitle?.message}
            autoFocus
            fullWidth
            inputLabelProps={{
              required: true,
            }}
            label="Job Title"
            {...register("jobTitle", {
              required: { value: true, message: "Required" },
            })}
          />
          <TextField
            error={Boolean(errors.schoolName)}
            helperText={errors.schoolName?.message}
            autoFocus
            fullWidth
            inputLabelProps={{
              required: true,
            }}
            label="School Name"
            {...register("schoolName", {
              required: { value: true, message: "Required" },
            })}
          />
          <div className="flex flex-col">
            <InputLabel label="Salary" required />
            <div className="grid grid-cols-[140px_1fr]">
              <div>
                <Controller
                  control={control}
                  name="currencyId"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Select
                      error={Boolean(errors.currencyId)}
                      getOptionLabel={option =>
                        `${option.symbol} ${option.code}`
                      }
                      getOptionValue={option => option.uuid}
                      helperText={errors.currencyId?.message}
                      options={currencyData}
                      placeholder="₹"
                      mountOnBody
                      fullWidth
                      inputLabelProps={{
                        required: true,
                      }}
                      value={
                        value ? currencyData.find(i => i.uuid === value) : null
                      }
                      isSearchable
                      onBlur={onBlur}
                      onChange={val => onChange(val?.uuid ? val.uuid : null)}
                    />
                  )}
                  rules={{
                    required: { value: true, message: "Required" },
                  }}
                />
              </div>

              <TextField
                error={Boolean(errors.salary)}
                helperText={errors.salary?.message}
                autoFocus
                fullWidth
                inputLabelProps={{
                  required: true,
                }}
                type="number"
                {...register("salary", {
                  required: { value: true, message: "Required" },
                })}
              />
            </div>
          </div>
          <TextField
            error={Boolean(errors.dateOfHiring)}
            helperText={errors.dateOfHiring?.message}
            autoFocus
            fullWidth
            type="date"
            inputLabelProps={{
              required: true,
            }}
            max={format(new Date(), "yyyy-MM-dd")}
            label="Date of Hiring"
            {...register("dateOfHiring", {
              required: { value: true, message: "Required" },
            })}
          />
        </div>
      </DialogContent>
      <DialogFooter
        actions={{
          primary: {
            label: "Mark as Hired",
            color: "success",
            onClick: onMarkAsHired,
            loading: isSubmitting,
          },
          secondary: {
            label: "Cancel",
            onClick: isSubmitting ? () => {} : onClose,
          },
        }}
      />
    </Dialog>
  )
}

export default MarkAsPlacedDialog
