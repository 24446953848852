// import { NotificationsPopup } from "@suraasa/notifications"
import {
  Avatar,
  Button,
  Container,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  theme as globalTheme,
  Typography,
  useMediaQuery,
} from "@suraasa/placebo-ui"
import Hamburger from "assets/hamburger.svg"
import MiniLogo from "assets/logos/suraasa-colored-small.svg"
import Logo from "assets/logos/suraasa-logo-new.svg"
import clsx from "clsx"
import SchoolIsUnverified from "components/auth/SchoolIsUnverified"
import { GlobalContext } from "components/GlobalState"
import TruncatedText from "components/TruncatedText"
import { LogOut, NavArrowDown } from "iconoir-react"
import React, { useContext, useEffect, useState } from "react"
import { createUseStyles } from "react-jss"
import { Link, NavLink, useMatch } from "react-router-dom"
import { clearAuthInfo, getAuthInfo } from "utils/auth"
import { buildUserName, getPlatformURL } from "utils/helpers"
import { routes } from "utils/routes"
import useUserType from "views/auth/useUserType"

import BackButton, { BackButtonProps } from "./BackButton"

const useStyles = createUseStyles(theme => ({
  root: {
    backgroundColor: theme.colors.common.white[500],
    maxHeight: "62px",
    height: "62px",
    borderBottom: `1px solid ${theme.colors.onSurface[200]}`,
  },

  containerRoot: {
    width: "100%",
  },

  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
    },
  },

  mobileNavLink: {
    color: theme.colors.common.black[600],
    textDecoration: "none",
  },

  link: {
    color: "inherit",
    textDecoration: "none",
  },

  menu: {
    width: "100vw",
    height: "100vh",
    background: "white",
  },
}))

export type NavbarProps = {
  slotEnd?: React.ReactElement
  bottomEndSlot?: React.ReactElement
  className?: string
  gutterBottom?: boolean
  hideBackButton?: boolean
  backButtonProps?: BackButtonProps
  hideBanner?: boolean
  startSlot?: React.ReactElement
}

const Navbar = ({
  slotEnd,
  className,
  gutterBottom = true,
  hideBackButton = false,
  backButtonProps,
  hideBanner = false,
  bottomEndSlot,
  startSlot,
}: NavbarProps) => {
  const classes = useStyles()
  const authInfo = getAuthInfo()
  const { profile } = useContext(GlobalContext)

  const { isSchoolAdmin } = useUserType()
  const [showMenu, setShowMenu] = useState(false)
  const isXs = useMediaQuery(globalTheme.breakpoints.only("xs"))
  const matchLoginRoute = useMatch("/")

  const username =
    profile.name || (authInfo ? buildUserName(authInfo.user) : "")

  useEffect(() => {
    if (!isXs && showMenu) {
      setShowMenu(false)
    }
    // No need to add showMenu in dependency array because we need to check only for isXs
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isXs])

  const homeRoute = "/"

  const exploreTeachersRoute = routes.explore

  return (
    <>
      <div
        className={clsx({
          "mb-5": gutterBottom && !showMenu,
        })}
      >
        <nav
          className={clsx(classes.root, "flex items-center py-1", className)}
        >
          <Container className={classes.containerRoot}>
            <div className={clsx(classes.container)}>
              {/* Start */}
              <div className="flex gap-1">
                <div className="sm:hidden">
                  <div className="flex items-center gap-2">
                    {isSchoolAdmin && (
                      <IconButton onClick={() => setShowMenu(!showMenu)}>
                        <img src={Hamburger} alt="" />
                      </IconButton>
                    )}
                    {authInfo && (
                      <Link to={homeRoute}>
                        <img src={MiniLogo} alt="" height="40" width="50" />
                      </Link>
                    )}
                  </div>
                </div>
                {!authInfo && (
                  <Link className="sm:hidden" to={homeRoute}>
                    <img src={Logo} alt="" height="24" width="132" />
                  </Link>
                )}
                <Link className="hidden sm:block" to={homeRoute}>
                  <img src={Logo} alt="" width="150px" />
                </Link>
                {startSlot}
              </div>
              {/* Middle */}
              {isSchoolAdmin && (
                <div className="hidden items-center gap-4 sm:flex">
                  {authInfo ? (
                    <>
                      <NavLink className={classes.link} to={homeRoute}>
                        <Typography variant="strong">Dashboard</Typography>
                      </NavLink>
                      <NavLink
                        className={classes.link}
                        to={`${exploreTeachersRoute}?schoolId=${profile.id}`}
                      >
                        <Typography variant="strong">
                          Explore Teachers
                        </Typography>
                      </NavLink>
                    </>
                  ) : (
                    !matchLoginRoute && (
                      <>
                        <a
                          className={classes.link}
                          href={import.meta.env.VITE_JOBS_PLATFORM_URL}
                        >
                          <Typography variant="strong">For Teachers</Typography>
                        </a>
                      </>
                    )
                  )}
                </div>
              )}
              {/* End */}
              <div className="flex items-center gap-1">
                {slotEnd && <div>{slotEnd}</div>}
                {authInfo ? (
                  <div className="flex items-center gap-2">
                    {/* <NotificationsPopup
                      axiosInstance={axios}
                      handleSeeAll={() => navigate(routes.notifications)}
                      notifications={notifications}
                      onClick={i => notificationHelper(navigate, i.action)}
                    /> */}
                    <Menu
                      align="end"
                      menuButton={
                        <Button color="black" variant="text">
                          <span className="flex items-center gap-1">
                            <Avatar
                              color="two"
                              name={username}
                              src={profile.logo}
                            />

                            <div className="hidden md:block">
                              <TruncatedText maxLength={30} variant="strong">
                                {username}
                              </TruncatedText>
                              {isSchoolAdmin && (
                                <Typography textAlign="left" variant="body">
                                  Admin
                                </Typography>
                              )}
                            </div>

                            <NavArrowDown
                              className={clsx("hidden md:block", {
                                "self-start": isSchoolAdmin,
                              })}
                            />
                          </span>
                        </Button>
                      }
                    >
                      {isSchoolAdmin && (
                        <>
                          <Link
                            className="link-decoration-none"
                            to={routes.school.profile.replace(
                              ":schoolId",
                              profile.id.toString()
                            )}
                          >
                            <MenuItem>Profile</MenuItem>
                          </Link>

                          {/* <Link
                            className="link-decoration-none"
                            to={routes.assessments}
                          >
                            <MenuItem>Assessments</MenuItem>
                          </Link> */}

                          <Link
                            className="link-decoration-none"
                            to={routes.help}
                          >
                            <MenuItem>Reach us for help</MenuItem>
                          </Link>
                        </>
                      )}
                      <Link
                        className="link-decoration-none"
                        to={routes.settings}
                      >
                        <MenuItem>Account Settings</MenuItem>
                      </Link>
                      <MenuItem
                        color="critical"
                        startAdornment={<LogOut />}
                        onClick={() => {
                          clearAuthInfo()
                          const url = new URL(
                            getPlatformURL("sso", "logout?platform=School")
                          )
                          url.searchParams.append(
                            "origin",
                            `${window.location.origin}`
                          )
                          window.location.href = url.href
                        }}
                      >
                        Logout
                      </MenuItem>
                      <Divider
                        color="onSurface.200"
                        weight="light"
                        className="mt-1"
                      />
                      <div className="flex flex-col gap-0.5 pl-1 pr-4 pt-1">
                        <Button
                          component="a"
                          href="https://www.suraasa.com/terms-of-use"
                          target="_blank"
                          variant="link"
                        >
                          <Typography variant="smallBody">
                            Terms and Conditions
                          </Typography>
                        </Button>
                        <Button
                          component="a"
                          href="https://www.suraasa.com/privacy-policy"
                          target="_blank"
                          variant="link"
                        >
                          <Typography variant="smallBody">
                            Privacy Policy
                          </Typography>
                        </Button>
                      </div>
                      <Typography
                        className="px-1.75 pb-0.75 pt-1.75"
                        color="onSurface.500"
                        variant="smallBody"
                      >
                        Les Transformations © {new Date().getFullYear()}
                      </Typography>
                    </Menu>
                  </div>
                ) : (
                  <>
                    {matchLoginRoute && (
                      <div className="hidden sm:flex">
                        <Typography>
                          Don’t have a Suraasa account?&nbsp;
                          <Button
                            component={Link}
                            to={routes.signup}
                            variant="link"
                          >
                            Sign up
                          </Button>
                        </Typography>
                      </div>
                    )}
                    {!matchLoginRoute && (
                      <div className="hidden sm:flex">
                        <Typography>
                          Already have an account?&nbsp;
                          <Button
                            component={Link}
                            to={routes.login + `?next=${window.location.href}`}
                            variant="link"
                          >
                            Sign In
                          </Button>
                        </Typography>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </Container>
        </nav>
        {!hideBanner && Boolean(authInfo) && !profile.isVerified && (
          <>{<SchoolIsUnverified />}</>
        )}
      </div>

      {!hideBackButton && !showMenu && (
        <div className="my-2">
          <Container className={classes.containerRoot}>
            <div className=" flex items-center justify-between">
              <BackButton {...backButtonProps} />
              {bottomEndSlot}
            </div>
          </Container>
        </div>
      )}

      {showMenu && (
        <div className={clsx(classes.menu, "px-2.5 py-4 sm:hidden")}>
          <div className="flex flex-col gap-2 ">
            {authInfo ? (
              <>
                <Link className={classes.mobileNavLink} to={homeRoute}>
                  <Typography variant="strong">Dashboard</Typography>
                </Link>
              </>
            ) : (
              <>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  className={classes.mobileNavLink}
                  href="https://jobs.suraasa.com/"
                >
                  <Typography variant="strong">For Teachers</Typography>
                </a>
              </>
            )}
          </div>

          {!authInfo && (
            <div className="mt-6 flex flex-col items-center gap-3">
              {!matchLoginRoute && (
                <Button
                  color="primary"
                  component={Link}
                  to={routes.login}
                  variant="text"
                  fullWidth
                >
                  Login
                </Button>
              )}
              <Button
                color="primary"
                component={Link}
                to={routes.signup}
                variant="filled"
                fullWidth
              >
                Sign Up
              </Button>
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default Navbar
