import { Button, Menu, MenuItem } from "@suraasa/placebo-ui"
import { UserType } from "api/resources/recruiter/types"
import TeamMemberSheet from "components/Recruiter/Team/TeamMemberSheet"
import { Plus } from "iconoir-react"
import { useState } from "react"
import useUserActions from "views/auth/useUserActions"

const AddTeamMemberButton = () => {
  const {
    addTeamMember: { canAddPartner, canAddRecruiter },
  } = useUserActions()

  const [type, setType] = useState<
    UserType.MEMBER_PARTNER | UserType.MEMBER_RECRUITER | null
  >(null)

  if (canAddPartner && canAddRecruiter) {
    return (
      <>
        {type && (
          <TeamMemberSheet
            open={Boolean(type)}
            handleClose={() => setType(null)}
            type={type}
          />
        )}
        <Menu
          menuButton={
            <Button size="sm" startAdornment={<Plus />} onClick={() => {}}>
              Add Team Member
            </Button>
          }
        >
          <MenuItem
            onClick={() => {
              setType(UserType.MEMBER_RECRUITER)
            }}
          >
            Recruiter
          </MenuItem>
          <MenuItem
            onClick={() => {
              setType(UserType.MEMBER_PARTNER)
            }}
          >
            Partner
          </MenuItem>
        </Menu>
      </>
    )
  }

  if (canAddRecruiter)
    return (
      <>
        {type && (
          <TeamMemberSheet
            open={Boolean(type)}
            handleClose={() => setType(null)}
            type={type}
          />
        )}
        <Button
          size="sm"
          startAdornment={<Plus />}
          onClick={() => setType(UserType.MEMBER_RECRUITER)}
        >
          Add Team Member
        </Button>
      </>
    )

  if (canAddPartner)
    return (
      <>
        {type && (
          <TeamMemberSheet
            open={Boolean(type)}
            handleClose={() => setType(null)}
            type={type}
          />
        )}
        <Button
          size="sm"
          startAdornment={<Plus />}
          onClick={() => setType(UserType.MEMBER_PARTNER)}
        >
          Add Team Member
        </Button>
      </>
    )

  return null
}

export default AddTeamMemberButton
