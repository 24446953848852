import { validateUrls } from "api/types"
import { getServiceURL } from "api/utils"
import { ServicePrefix } from "utils/constants"

const getNebulaURL = getServiceURL(ServicePrefix.nebula)
const getSolisURL = getServiceURL(ServicePrefix.solis)

export const urls = validateUrls({
  actions: () => getNebulaURL(`/v1/users/types-actions/`),
  accessibleSchoolList: () => `/v1/jobs/representatives/schools`,
  recruiterList: () => `/v1/schools/representatives/`,
  assignRecruiter: () =>
    getNebulaURL("/v1/representatives/school-representatives/"),
  createSchool: () => getNebulaURL("/v2/schools/"),
  addRecruiter: () => getNebulaURL(`/v1/representatives/`),
  checkRecruiter: () => getSolisURL(`/v1/school-users/fetch`),
  learnerList: () => "/v1/placements/users/",
  markAsHired: () => getNebulaURL("/v1/placements/trackers/"),
})
