import { CircularProgress, IconButton } from "@suraasa/placebo-ui"
import api from "api"
import clsx from "clsx"
import FileInput from "components/shared/FileInput"
import { Plus } from "iconoir-react"
import React, { useContext, useRef, useState } from "react"
import { createUseStyles } from "react-jss"
import { useParams } from "react-router-dom"
import { acceptedImageTypes } from "utils/constants"
import { validateImageUpload } from "utils/helpers"
import toast from "utils/toast"
import ProfileContext from "views/Profile/context"

const useStyles = createUseStyles(theme => ({
  addButtonContainer: ({
    width,
    height,
  }: {
    width: string
    height: string
  }) => ({
    background: theme.colors.surface[100],
    width: `${width}`,
    height: `${height}`,

    "& > button": {
      width: "100%",
      height: "100%",
    },
  }),
}))

function AddGalleryImage({
  width = "100%",
  height = "100%",
  iconSize = "54px",
}: {
  width?: string
  height?: string
  iconSize?: string
}) {
  const fileInputRef = useRef<HTMLInputElement>(null)
  const classes = useStyles({ width, height })
  const { gallery, updateProfile } = useContext(ProfileContext)
  const [loading, setLoading] = useState(false)

  const { schoolId } = useParams() as { schoolId: string }

  const uploadImages = async (fileArray: File[]) => {
    setLoading(true)

    // Check if gallery has/will have more than 20 images
    if (fileArray.length > 20 - gallery.data.length) {
      toast.error("Upto 20 files allowed")
      setLoading(false)
      return
    }

    // Check if all the images uploaded pass validation check
    for (let i = 0; i < fileArray.length; i += 1) {
      if (!validateImageUpload(fileArray[i], 5)) {
        setLoading(false)
        return
      }
    }

    // Uploads Files in case all of them pass validation
    const files = new FormData()
    fileArray.forEach(item => files.append("photos", item))

    const res = await api.profile.gallery.update({
      data: files,
      headers: {
        "School-Id": schoolId,
        "Content-Type": "multipart/form-data",
      },
    })
    if (res.isSuccessful) {
      gallery.set([...res.data.slice(0).reverse(), ...gallery.data])
      updateProfile({ galleryCount: res.data.length + gallery.data.length })
    } else {
      toast.error(res.errors.message)
    }
    setLoading(false)
  }

  return (
    <div
      className={clsx(
        "flex items-center justify-center",
        classes.addButtonContainer
      )}
    >
      <FileInput
        accept={acceptedImageTypes.toString()}
        limit={20}
        name="gallery-images"
        ref={fileInputRef}
        onChange={files => uploadImages(files)}
      />
      <IconButton
        disabled={loading}
        variant="plain"
        onClick={() => fileInputRef.current?.click()}
      >
        {loading ? (
          <CircularProgress />
        ) : (
          <Plus height={iconSize} width={iconSize} />
        )}
      </IconButton>
    </div>
  )
}

export default AddGalleryImage
