import { Button, Typography } from "@suraasa/placebo-ui"
import Illustration from "assets/illustrations/broke-connection.svg"
import Navbar from "components/shared/Navbar"
import React from "react"
import { createUseStyles } from "react-jss"
import { Link } from "react-router-dom"
import { routes } from "utils/routes"

const useStyles = createUseStyles(theme => ({
  root: {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      "& svg": {
        display: "none",
      },
    },
  },
}))

const InvalidMeeting = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Navbar gutterBottom={false} />
      <div className="flex flex-1 items-center justify-evenly">
        <div className="flex flex-col gap-2">
          <Typography color="onSurface.800" variant="largeTitle">
            Ooooops!
          </Typography>
          <Typography color="onSurface.500" variant="largeBody">
            The meeting link is invalid
          </Typography>
          <Typography color="onSurface.800" variant="largeBody">
            If the issue persists, please reach out to us.
          </Typography>
          <div className="mt-3 flex flex-col gap-2">
            <Button component={Link} size="sm" to={routes.home}>
              Return to Dashboard
            </Button>
            <Button variant="text">Contact us</Button>
          </div>
        </div>
        <img src={Illustration} alt="" />
      </div>
    </div>
  )
}

export default InvalidMeeting
