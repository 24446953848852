import { CallableJSX } from "components/TabBar/hooks/useTabs"
import { Tab } from "utils/hooks/useTabs"

import { AllTabs } from ".."
import AddSchoolButton from "./AddSchoolButton"
import AddTeamMemberButton from "./AddTeamMemberButton"

type Props = {
  activeTab: Tab<CallableJSX>
}

const TitleBarActions = ({ activeTab }: Props) => {
  return (
    <div className="flex items-center gap-1">
      {activeTab && activeTab.name === AllTabs.team && <AddTeamMemberButton />}
      {activeTab && activeTab.name === AllTabs.school && <AddSchoolButton />}
    </div>
  )
}

export default TitleBarActions
