"use client"

import * as DialogPrimitive from "@radix-ui/react-dialog"
import { theme, useMediaQuery } from "@suraasa/placebo-ui"
import clsx from "clsx"
import { ArrowLeft, Xmark } from "iconoir-react"
import * as React from "react"

import { cn } from "@/lib/utils"

const Dialog = DialogPrimitive.Root

export type DialogProps = DialogPrimitive.DialogProps

const DialogTrigger = DialogPrimitive.Trigger

const PADDING = "px-2 py-1.5"

const DialogPortal = ({ ...props }: DialogPrimitive.DialogPortalProps) => (
  <DialogPrimitive.Portal {...props} />
)
const DialogClose = ({
  asChild = true,
  ...props
}: DialogPrimitive.DialogCloseProps) => (
  <DialogPrimitive.Close asChild={asChild} {...props} />
)
DialogClose.displayName = DialogPrimitive.Close.displayName

const DialogOverlay = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay>
>(({ className, ...props }, ref) => {
  return (
    <DialogPrimitive.Overlay
      ref={ref}
      className={clsx(
        "fixed inset-0 z-dialog bg-black/40 backdrop-blur-sm data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0",
        className
      )}
      {...props}
    />
  )
})
DialogOverlay.displayName = DialogPrimitive.Overlay.displayName

/**
 * Max width of dialog is 36rem, but on mobile it should be 95% of the screen.
 * To override this behavior you can pass any custom width class to the [DialogContent] component.
 */
const DialogContent = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content> & {
    closeWhenInteractOutside?: boolean
    fullScreen?: boolean
  }
>(
  (
    {
      className,
      children,
      closeWhenInteractOutside = false,
      fullScreen = false,
      ...props
    },
    ref
  ) => {
    const dialogContent = React.useRef<HTMLDivElement>(null)

    const calculatedHeight = React.useRef("")

    const setHeight = (value: number) => {
      const height = Math.max(0, Math.min(100, value))
      calculatedHeight.current = `${height * window.innerHeight * 0.01}px`
    }

    const smDown = useMediaQuery(theme.breakpoints.down("sm"))
    /**
     * Height of dialog can never be 100% of the screen by default. If you need that behaviour, pass fullScreen={true},
     */
    const MAXIMUM_HEIGHT = fullScreen ? 100 : smDown ? 98 : 90

    setHeight(MAXIMUM_HEIGHT)

    return (
      <DialogPortal>
        <DialogOverlay />
        <DialogPrimitive.Content
          ref={dialogContent}
          /**
           * Width classes
           */
          className={cn(
            "fixed left-[50%] top-[50%] z-dialog !flex w-full translate-x-[-50%] translate-y-[-50%] !flex-col rounded-lg bg-white shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] md:w-full",
            { "max-w-[min(95%,36rem)]": !fullScreen },
            className
          )}
          onInteractOutside={
            closeWhenInteractOutside
              ? undefined
              : e => {
                  e.preventDefault()
                }
          }
          style={{ maxHeight: calculatedHeight.current }}
          {...props}
        >
          {children}
        </DialogPrimitive.Content>
      </DialogPortal>
    )
  }
)
DialogContent.displayName = DialogPrimitive.Content.displayName

const DialogHeader = ({
  className,
  hideClose = false,
  ...props
}: React.HTMLAttributes<HTMLHeadElement> & { hideClose?: boolean }) => {
  return (
    <header
      className={cn(PADDING, className, {
        "border-b border-onSurface-200": true,
        "flex justify-between items-center": !hideClose,
      })}
      {...props}
    >
      {props.children}
      {!hideClose && (
        <DialogPrimitive.Close className="rounded-sm opacity-70 transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:pointer-events-none">
          <Xmark className="size-3" />
          <span className="sr-only">Close</span>
        </DialogPrimitive.Close>
      )}
    </header>
  )
}

DialogHeader.displayName = "DialogHeader"

const DialogFooter = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      "border-t border-onSurface-200 flex justify-end space-x-1",
      PADDING,
      className
    )}
    {...props}
  />
)
DialogFooter.displayName = "DialogFooter"

/**
 * This is an accessibility helper. Use this when a dialog has a clear title.
 * It announces the title to screen readers and helps make the dialog more accessible.
 *
 * For more info: https://www.radix-ui.com/primitives/docs/components/dialog#title
 */
const DialogTitle = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Title> & {
    onBack?: () => void
  }
>(({ className, children, asChild = true, onBack, ...props }, ref) => {
  return (
    <DialogPrimitive.Title
      ref={ref}
      asChild={asChild}
      className={clsx(className)}
      {...props}
    >
      <div className="flex items-center">
        {onBack && (
          <button onClick={onBack} className="me-1.25">
            <ArrowLeft width={24} height={24} />
          </button>
        )}
        <div>{children}</div>
      </div>
    </DialogPrimitive.Title>
  )
})
DialogTitle.displayName = DialogPrimitive.Title.displayName

/**
 * This is an accessibility helper. Use this when a dialog has a clear description.
 * It announces the description to screen readers and helps make the dialog more accessible.
 *
 * For more info: https://www.radix-ui.com/primitives/docs/components/dialog#description
 */
const DialogDescription = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Description>
>(({ className, asChild = true, ...props }, ref) => (
  <DialogPrimitive.Description
    ref={ref}
    className={cn(className)}
    asChild={asChild}
    {...props}
  />
))
DialogDescription.displayName = DialogPrimitive.Description.displayName

const DialogBody = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content>
>(({ className, ...props }, ref) => (
  <main
    ref={ref}
    className={cn("overflow-auto", PADDING, className)}
    {...props}
  />
))
DialogBody.displayName = DialogPrimitive.Content.displayName

export {
  Dialog,
  DialogBody,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
}
