"use client"

/**
 * Sheet body should have border-radius?
 */
import * as SheetPrimitive from "@radix-ui/react-dialog"
import { cva, VariantProps } from "class-variance-authority"
import clsx from "clsx"
import { Xmark } from "iconoir-react"
// import { Xmark } from "iconoir-react"
import * as React from "react"

import { cn } from "@/lib/utils"

const PADDING = "px-2 py-1.5"

const Sheet = SheetPrimitive.Root

/**
 * Be sure to pass `asChild=true` if you're passing a `<button>` yourself. Otherwise you'll end up with a DOM structure that looks like:
 * `<button><button>Trigger</button></button>` which is obviously not what you want.
 */
const SheetTrigger = SheetPrimitive.Trigger

const SheetClose = SheetPrimitive.Close

const SheetPortal = ({ ...props }: SheetPrimitive.DialogPortalProps) => (
  <SheetPrimitive.Portal {...props} />
)
SheetPortal.displayName = SheetPrimitive.Portal.displayName

const SheetOverlay = React.forwardRef<
  React.ElementRef<typeof SheetPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof SheetPrimitive.Overlay>
>(({ className, ...props }, ref) => (
  <SheetPrimitive.Overlay
    className={clsx(
      "fixed inset-0 z-dialog backdrop-blur-sm data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0",
      className
    )}
    {...props}
    ref={ref}
  />
))
SheetOverlay.displayName = SheetPrimitive.Overlay.displayName

const sheetVariants = cva(
  "fixed z-dialog mx-auto !flex max-w-screen-lg !flex-col bg-white shadow-lg transition ease-in-out data-[state=closed]:duration-300 data-[state=open]:duration-500 data-[state=open]:animate-in data-[state=closed]:animate-out",
  {
    variants: {
      side: {
        top: "inset-x-0 top-0 border-b data-[state=closed]:slide-out-to-top data-[state=open]:slide-in-from-top",
        bottom:
          "inset-x-0 bottom-0 data-[state=closed]:slide-out-to-bottom data-[state=open]:slide-in-from-bottom",
        left: "inset-y-0 left-0 h-full w-3/4 border-r data-[state=closed]:slide-out-to-left data-[state=open]:slide-in-from-left sm:max-w-sm",
        right:
          "inset-y-0 right-0 h-full w-3/4  border-l data-[state=closed]:slide-out-to-right data-[state=open]:slide-in-from-right sm:max-w-sm",
      },
      fullScreen: {
        true: "rounded-t-none",
        false: "rounded-t-lg",
      },
    },
    defaultVariants: {
      side: "bottom",
      fullScreen: false,
    },
  }
)

interface SheetContentProps
  extends React.ComponentPropsWithoutRef<typeof SheetPrimitive.Content>,
    VariantProps<typeof sheetVariants> {
  height: number
  closeWhenInteractOutside?: boolean
}

/**
 * This is where all the magic happens.
 */
const SheetContent = React.forwardRef<
  React.ElementRef<typeof SheetPrimitive.Content>,
  SheetContentProps
>(
  (
    {
      side = "bottom",
      className,
      children,
      height,
      closeWhenInteractOutside = false,
      ...props
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ref
  ) => {
    const sheetContent = React.useRef<HTMLDivElement>(null)

    const calculatedHeight = React.useRef("")

    const setSheetHeight = (value: number) => {
      const sheetHeight = Math.max(0, Math.min(100, value))
      calculatedHeight.current = `${sheetHeight * window.innerHeight * 0.01}px`
    }

    setSheetHeight(height)

    return (
      <SheetPortal>
        <SheetOverlay className="bg-black/40" />
        <SheetPrimitive.Content
          forceMount
          ref={sheetContent}
          className={clsx(
            sheetVariants({ side, fullScreen: height === 100 }),
            className
          )}
          onInteractOutside={
            closeWhenInteractOutside
              ? undefined
              : e => {
                  e.preventDefault()
                }
          }
          style={{
            height:
              side === "bottom" || side === "top"
                ? calculatedHeight.current
                : "100%",
          }}
          {...props}
        >
          {children}
        </SheetPrimitive.Content>
      </SheetPortal>
    )
  }
)
SheetContent.displayName = SheetPrimitive.Content.displayName

/**
 * Comes with a close button by default. Pass `hideClose=false` to get rid of it.
 * Uses a `display: flex` by default.
 *
 * `SheetHeader` should always be inside a `<SheetContent>` component.
 */
const SheetHeader = ({
  className,
  hideClose = false,
  ...props
}: React.HTMLAttributes<HTMLHeadElement> & { hideClose?: boolean }) => (
  <header
    className={cn(PADDING, "flex justify-between items-center", className)}
    {...props}
  >
    {props.children}
    {!hideClose && (
      <SheetPrimitive.Close className="rounded-sm opacity-70 transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:pointer-events-none">
        <Xmark className="size-3" />
        <span className="sr-only">Close</span>
      </SheetPrimitive.Close>
    )}
  </header>
)
SheetHeader.displayName = "SheetHeader"

// const SheetFooter = ({
//   className,
//   ...props
// }: React.HTMLAttributes<HTMLDivElement>) => (
//   <div
//     className={clsx(
//       "flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2",
//       className
//     )}
//     {...props}
//   />
// )
// SheetFooter.displayName = "SheetFooter"

/**
 * This is an accessibility helper. Use this when a sheet has a clear title.
 * It announces the title to screen readers and helps make the sheet more accessible.
 *
 * For more info: https://www.radix-ui.com/primitives/docs/components/dialog#title
 */
const SheetTitle = React.forwardRef<
  React.ElementRef<typeof SheetPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof SheetPrimitive.Title>
>(({ className, ...props }, ref) => (
  <SheetPrimitive.Title
    ref={ref}
    className={clsx("text-lg font-semibold", className)}
    {...props}
  />
))
SheetTitle.displayName = SheetPrimitive.Title.displayName

/**
 * This is an accessibility helper. Use this when a sheet has a clear description.
 * It announces the description to screen readers and helps make the sheet more accessible.
 *
 * For more info: https://www.radix-ui.com/primitives/docs/components/dialog#description
 */
const SheetDescription = React.forwardRef<
  React.ElementRef<typeof SheetPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof SheetPrimitive.Description>
>(({ className, asChild = true, ...props }, ref) => (
  <SheetPrimitive.Description
    ref={ref}
    className={cn(className)}
    asChild={asChild}
    {...props}
  />
))
SheetDescription.displayName = SheetPrimitive.Description.displayName

const SheetBody = React.forwardRef<
  React.ElementRef<typeof SheetPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof SheetPrimitive.Content>
>(({ className, ...props }, ref) => (
  <main
    ref={ref}
    className={cn(PADDING, "overflow-auto", className)}
    {...props}
  />
))
SheetBody.displayName = SheetPrimitive.Content.displayName

export {
  Sheet,
  SheetBody,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
}
