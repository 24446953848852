import { Button } from "@suraasa/placebo-ui"
import { Plus } from "iconoir-react"
import { useState } from "react"
import useUserActions from "views/auth/useUserActions"
import CreateNewSchool from "views/common/SchoolTab/components/CreateNewSchool"

export const AddSchoolButton = () => {
  const {
    schoolTab: { canAddSchool },
  } = useUserActions()

  const [open, setOpen] = useState(false)

  if (canAddSchool)
    return (
      <>
        <CreateNewSchool open={open} handleClose={() => setOpen(false)} />
        <Button
          size="sm"
          startAdornment={<Plus />}
          onClick={() => setOpen(true)}
        >
          Add School
        </Button>
      </>
    )

  return null
}

export default AddSchoolButton
