import axios from "api/axios"
import {
  APIResponseOld,
  Config,
  ConfigWithURLParams,
  NoContentType,
  PaginatedAPIResponseOld,
} from "api/types"
import {
  formatErrorResponse,
  formatPaginatedResponse,
  formatSuccessResponseOld,
} from "api/utils"

import { Assessment, BackendQuestion, Solutions } from "./types"
import { urls } from "./urls"

export default {
  create: async ({ data }: Config): Promise<APIResponseOld<Assessment>> => {
    try {
      const res = await axios.post(urls.assessment.base(), data)
      return formatSuccessResponseOld(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  list: async ({
    params,
    headers,
  }: Config): Promise<PaginatedAPIResponseOld<Assessment[]>> => {
    try {
      const res = await axios.get(urls.assessment.base(), { params, headers })
      return formatPaginatedResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  retrieve: async ({
    urlParams,
    headers,
  }: ConfigWithURLParams): Promise<
    APIResponseOld<Assessment & { questions: BackendQuestion[] }>
  > => {
    try {
      const res = await axios.get(
        urls.assessment.detail(urlParams.assessmentId),
        { headers }
      )
      return formatSuccessResponseOld(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  delete: async ({
    urlParams,
  }: ConfigWithURLParams): Promise<APIResponseOld<NoContentType>> => {
    try {
      const res = await axios.delete(
        urls.assessment.detail(urlParams.assessmentId)
      )
      return formatSuccessResponseOld(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  update: async ({
    data,
    urlParams,
  }: ConfigWithURLParams): Promise<APIResponseOld<Assessment>> => {
    try {
      const res = await axios.put(
        urls.assessment.detail(urlParams.assessmentId),
        data
      )
      return formatSuccessResponseOld(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  bulkUpdate: async ({
    data,
    urlParams,
  }: ConfigWithURLParams): Promise<APIResponseOld<Assessment>> => {
    try {
      const res = await axios.post(
        urls.assessment.bulkUpdate(urlParams.assessmentId),
        data
      )
      return formatSuccessResponseOld(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  questions: {
    create: async ({
      data,
    }: Config): Promise<APIResponseOld<BackendQuestion>> => {
      try {
        const res = await axios.post(urls.questions.base(), data)
        return formatSuccessResponseOld(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    update: async ({
      data,
      urlParams,
    }: ConfigWithURLParams): Promise<APIResponseOld<BackendQuestion>> => {
      try {
        const res = await axios.put(
          urls.questions.update(urlParams.questionId),
          data
        )
        return formatSuccessResponseOld(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    delete: async ({
      urlParams,
    }: ConfigWithURLParams): Promise<APIResponseOld<NoContentType>> => {
      try {
        const res = await axios.delete(
          urls.questions.update(urlParams.questionId)
        )
        return formatSuccessResponseOld(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },
  listSolutions: async ({
    urlParams,
  }: ConfigWithURLParams): Promise<APIResponseOld<Solutions>> => {
    try {
      const res = await axios.get(
        urls.assessment.listSolutions(urlParams.jobApplicantStepId)
      )
      return formatSuccessResponseOld(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
}
