import { CircularProgress, Theme, useMediaQuery } from "@suraasa/placebo-ui"
import api from "api"
import { Profile, SkillEvidence } from "api/resources/teacher/types"
import BackButton from "components/shared/BackButton"
import Navbar from "components/shared/Navbar"
import ReactHelmet from "components/shared/ReactHelmet"
import SlidingSheet from "components/SlidingSheet"
import metadata from "metadata.json"
import { useEffect, useState } from "react"
import { useTheme } from "react-jss"
import { useNavigate, useParams } from "react-router-dom"
import { routes } from "utils/routes"

import EvidenceDetails from "./SlidingSheetPages/EvidenceDetails"
import Evidences from "./SlidingSheetPages/Evidences"
import SkillOverview from "./SlidingSheetPages/SkillOverview"
import VerificationProcess from "./SlidingSheetPages/VerificationProcess"

const getInitialView = (params: any) => {
  const username = params.username

  if (
    window.location.pathname.includes(
      routes.publicSkillProfile.verificationProcess.replace(
        ":username",
        username
      )
    )
  )
    return "verificationProcess"

  if (params.submissionId) return "evidence"
  if (params.slug) return "evidenceList"

  return "overview"
}

const SkillProfile = () => {
  const params = useParams<{ username?: string }>()
  const username = params.username
  const theme = useTheme<Theme>()
  const isXsDown = useMediaQuery(theme.breakpoints.down("xs"))

  const [profile, setProfile] = useState<Profile>()

  useEffect(() => {
    api.teacher.profile
      .retrieve({
        urlParams: {
          username: username ?? "",
        },
      })
      .then(res => {
        if (res.isSuccessful) {
          setProfile(res.data)
        }
      })
  }, [username])

  const [skillEvidenceList, setSkillEvidenceList] = useState<{
    skills: SkillEvidence[]
    user: { fullName: string; photo: string | null } | null
  }>()

  useEffect(() => {
    api.teacher.skillEvidences
      .list({
        urlParams: {
          username: username ?? "",
        },
      })
      .then(res => {
        if (res.isSuccessful) {
          setSkillEvidenceList(res.data)
        }
      })
  }, [username])

  const [currentlyViewing, setCurrentlyViewing] = useState<
    "overview" | "evidenceList" | "evidence" | "verificationProcess"
  >(getInitialView(params))

  useEffect(() => {
    setCurrentlyViewing(getInitialView(params))
  }, [params])

  const navigate = useNavigate()

  if (!profile || !skillEvidenceList)
    return (
      <div className="flex items-center justify-center p-5">
        <CircularProgress />
      </div>
    )

  const isOverview = currentlyViewing === "overview"
  const isEvidence = currentlyViewing === "evidenceList"
  const isEvidenceDetails = currentlyViewing === "evidence"
  const isVerificationProcess = currentlyViewing === "verificationProcess"

  const pages = (
    <>
      {isEvidence && <Evidences skillEvidences={skillEvidenceList.skills} />}

      {isEvidenceDetails && (
        <EvidenceDetails firstName={profile.user.firstName} />
      )}

      {isVerificationProcess && <VerificationProcess />}
    </>
  )

  const noRouteHandler = () => {
    navigate(
      routes.publicSkillProfile.overview.replace(":username", username ?? ""),
      { replace: true }
    )
  }
  const getBackButton = () => <BackButton noRouteHandler={noRouteHandler} />

  if (isXsDown) {
    return (
      <div>
        {isOverview && (
          <SkillOverview
            profile={profile}
            skillEvidences={skillEvidenceList.skills}
          />
        )}
        {!isOverview && <div className="mt-3 px-3">{getBackButton()}</div>}
        {pages}
      </div>
    )
  }

  return (
    <>
      <Navbar hideBackButton gutterBottom={false} />
      <ReactHelmet data={metadata.home} />
      <SkillOverview
        profile={profile}
        skillEvidences={skillEvidenceList.skills}
      />
      <SlidingSheet
        className="flex h-screen flex-col rounded-l-2xl sm:w-3/4 md:w-3/6"
        from={isXsDown ? "bottom" : "end"}
        open={!isOverview}
        isDismissible={false}
        onClose={() => setCurrentlyViewing("overview")}
      >
        <div className="mt-3 px-3 pt-1">{getBackButton()}</div>
        {pages}
      </SlidingSheet>
    </>
  )
}

export default SkillProfile
