import { createUseStyles } from "react-jss"

const useStyles = createUseStyles(theme => ({
  table: {
    background: "#FFFFFF",
    border: `1px solid ${theme.colors.surface[200]}`,
    borderRadius: " 8px 8px 0px 0px",
    marginTop: theme.spacing(1),
    "& thead": {
      borderBottom: `1px solid ${theme.colors.surface[200]}`,
      color: theme.colors.onSurface[700],
      fontSize: "16px",
      height: "48px",
    },
    "& td, th": {
      padding: theme.spacing(1),
      paddingRight: theme.spacing(6),
    },
    "& th:first-child": {
      paddingLeft: theme.spacing(4),
    },
    "& td:first-child": {
      paddingLeft: theme.spacing(4),
    },
    "& tr": {
      height: "48px",
      borderBottom: `1px solid ${theme.colors.surface[200]}`,
    },
    "& td": {
      height: "48px",
    },
  },
}))

const TableWrapper = ({
  content,
}: {
  content: (className: string) => JSX.Element
}) => {
  const classes = useStyles()
  return content(classes.table)
}

export default TableWrapper
