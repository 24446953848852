import { Chip, Divider, Typography } from "@suraasa/placebo-ui"
import Section from "components/teacher/profile/Section"
import SectionContent from "components/teacher/profile/Section/SectionContent"
import SectionHeading from "components/teacher/profile/Section/SectionHeading"
import SectionTitle from "components/teacher/profile/Section/SectionTitle"
import TruncatedText from "components/TruncatedText"
import { format } from "date-fns"
import { useContext } from "react"
import ProfileContext from "views/teacher/profileContext"

import { getEmploymentTypeDisplay } from "./utils"

const WorkExperience = () => {
  const { workExperiences } = useContext(ProfileContext)

  return (
    <>
      <Section>
        <SectionHeading
          heading={<SectionTitle title="Work Experience" />}
          xPadding={2}
        />

        <SectionContent className="flex flex-col" xPadding={2}>
          {workExperiences.data.map((experience, index) => (
            <div key={experience.id || index}>
              <div>
                <span className="mb-0.5 flex items-center gap-1">
                  <Typography variant="strongSmallBody">
                    {experience.title}
                  </Typography>
                  {experience.curriculum?.name ? (
                    <Chip
                      color="secondary"
                      label={experience.curriculum?.name}
                      size="sm"
                      variant="outlined"
                    />
                  ) : (
                    experience.otherCurriculum && (
                      <Chip
                        color="secondary"
                        label={experience.otherCurriculum}
                        size="sm"
                        variant="outlined"
                      />
                    )
                  )}
                </span>

                {experience.employmentType && (
                  <Typography
                    className="mb-0.25"
                    color="onSurface.600"
                    variant="smallBody"
                  >
                    {[
                      getEmploymentTypeDisplay(experience.employmentType),
                      experience.teachingLevel?.name,
                    ]
                      .filter(Boolean)
                      .join(", ")}
                  </Typography>
                )}

                <Typography
                  className="mb-0.25"
                  color="onSurface.600"
                  variant="smallBody"
                >
                  {[
                    experience.organisationName,
                    [experience.state?.name, experience.country?.name]
                      .filter(Boolean)
                      .join(", "),
                  ]
                    .filter(Boolean)
                    .join(" • ")}
                </Typography>

                {experience.startDate && (
                  <Typography color="aonSurface.600" variant="smallBody">
                    {format(new Date(experience.startDate), "MMMM yyyy")} -{" "}
                    {experience.currentlyWorking
                      ? "Present"
                      : experience.endDate &&
                        format(new Date(experience.endDate), "MMMM yyyy")}
                  </Typography>
                )}

                {experience.description && (
                  <TruncatedText
                    className="mt-1"
                    maxLength={300}
                    style={{
                      whiteSpace: "pre-wrap",
                    }}
                    variant="smallBody"
                  >
                    {experience.description}
                  </TruncatedText>
                )}
              </div>

              {index < workExperiences.data.length - 1 && (
                <Divider
                  className="my-2.25"
                  color="onSurface.200"
                  weight="light"
                />
              )}
            </div>
          ))}

          {workExperiences.data.length === 0 && (
            <Typography color="onSurface.400" variant="smallBody">
              Looks like there's nothing here.
            </Typography>
          )}
        </SectionContent>
      </Section>
    </>
  )
}

export default WorkExperience
