import { Container, Typography } from "@suraasa/placebo-ui"
import clsx from "clsx"
import { createUseStyles } from "react-jss"
import useUserType from "views/auth/useUserType"

const useStyles = createUseStyles(theme => ({
  root: {
    background: theme.colors.warning[100],
    padding: theme.spacing(2, 0),
  },

  bannerText: { maxWidth: "735px" },

  "@keyframes loading1": {
    "0%": {
      height: 18,
      background: theme.colors.warning[700],
    },
    "33%": {
      height: 16,
      background: theme.colors.warning[500],
    },
  },

  "@keyframes loading2": {
    "33%": {
      height: 18,
      background: theme.colors.warning[700],
    },
    "66%": {
      height: 16,
      background: theme.colors.warning[500],
    },
  },

  "@keyframes loading3": {
    "66%": {
      height: 18,
      background: theme.colors.warning[700],
    },
    "100%": {
      height: 16,
      background: theme.colors.warning[500],
    },
  },
  waveLoader: {
    display: "flex",
    alignItems: "center",
    "& span": {
      height: 16,
      width: 3,
      marginRight: 4,
      background: theme.colors.warning[500],
      borderRadius: 100,
    },
    "& span:nth-child(1)": {
      animation: "$loading1 2.5s linear infinite",
    },
    "& span:nth-child(2)": {
      animation: "$loading2 2.5s linear infinite",
    },
    "& span:nth-child(3)": {
      animation: "$loading3 2.5s linear infinite",
    },
  },
}))

const WaveLoader = () => {
  const classes = useStyles()
  return (
    <div className={classes.waveLoader}>
      <span />
      <span />
      <span />
    </div>
  )
}

const SchoolIsUnverified = () => {
  const classes = useStyles()
  const { isSchoolAdmin, userType } = useUserType()

  if (!isSchoolAdmin || userType == null) return null

  return (
    <div className={classes.root}>
      <Container>
        <div className="flex items-center justify-between gap-2">
          <div>
            <Typography color="warning.700" variant="strongSmallBody">
              Profile is under verification.
            </Typography>
            <Typography
              className={clsx("mt-0.5", classes.bannerText)}
              color="warning.700"
              variant="smallBody"
            >
              Your school profile is being verified. Your job listings will not
              be made public and any invites sent to teachers won’t be received
              till the verification process is complete. It might take up to 48
              hours.
            </Typography>
          </div>

          <WaveLoader />
        </div>
      </Container>
    </div>
  )
}

export default SchoolIsUnverified
