export const AdminTabs = {
  assigned: "Assigned",
  unassigned: "Unassigned",
  unpublished: "Unpublished",
}

export const SchoolTabs = {
  yourSchools: "Your Schools",
  otherSchools: "Other Schools",
  unassigned: "Unassigned",
  unpublished: "Unpublished",
}
