import {
  CircularProgress,
  Container,
  theme,
  useMediaQuery,
} from "@suraasa/placebo-ui"
import api from "api"
import {
  Award,
  Certification,
  CoverStyle,
  Interest,
  LanguageResponse,
  Profile as ProfileType,
  Project,
  Publication,
  Qualification,
  Skill,
  SkillEvidencesOverview,
  SubjectResponse,
  TestScore,
  WorkExperienceType,
  WorkLocationInterest,
} from "api/resources/teacher/types"
import ReactHelmet from "components/shared/ReactHelmet"
import About from "components/teacher/profile/About"
import Academics from "components/teacher/profile/Academics"
import Achievements from "components/teacher/profile/Achievements"
import Interests from "components/teacher/profile/Interests"
import Languages from "components/teacher/profile/Languages"
import Overview from "components/teacher/profile/Overview"
import PersonalInfo from "components/teacher/profile/PersonalInfo"
import Subjects from "components/teacher/profile/Subjects"
import WorkExperience from "components/teacher/profile/WorkExperience"
import WorkLocationInterests from "components/teacher/profile/WorkLocationInterests"
import { useEffect, useState } from "react"
import { useParams, useSearchParams } from "react-router-dom"
import useArray from "utils/hooks/useArray"
import ProfileContext from "views/teacher/profileContext"

import EducationWithSuraasa from "./EducationWithSuraasa"
import Resume from "./Resume"
import SkillProfileOverview from "./SkillProfileOverview"
import Skills from "./Skills"
import TopSkillEvidences from "./TopSkillEvidences"

/**
 * TODOs:
 * TODOs: jobId from searchParams
 * Displaying relevant actions
 * skipOverview thingy (for timeline)
 */

const Profile = ({ skipOverview = false, skipContainer = false }) => {
  const [searchParams] = useSearchParams()
  const jobId = searchParams.get("jobId")
  const { username } = useParams()

  const isXsDown = useMediaQuery(theme.breakpoints.down("xs"))

  const [loading, setLoading] = useState(true)

  const [profile, setProfile] = useState<ProfileType>({
    id: "",
    totalWorkingExperience: 0,
    careerAspiration: null,
    countriesWorkedIn: [],
    country: null,
    currentWorkingExperience: null,
    dateOfBirth: null,
    coverStyle: CoverStyle.DUSK,
    gender: null,
    isVerified: false,
    lookingForJobs: false,
    nationality: null,
    phoneNumber: null,
    picture: null,
    state: null,
    user: {
      uuid: "",
      email: "",
      firstName: "",
      lastName: null,
      middleName: "",
      username: "",
    },
  })

  const workExperiences = useArray<Partial<WorkExperienceType>>([])
  const languages = useArray<Partial<LanguageResponse>>([])
  const awards = useArray<Partial<Award>>([])
  const projects = useArray<Partial<Project>>([])
  const publications = useArray<Partial<Publication>>([])
  const testScores = useArray<Partial<TestScore>>([])
  const subjects = useArray<Partial<SubjectResponse>>([])
  const qualifications = useArray<Partial<Qualification>>([])
  const certifications = useArray<Partial<Certification>>([])
  const workLocationInterest = useArray<Partial<WorkLocationInterest>>([])
  const interests = useArray<Partial<Interest>>([])
  const skills = useArray<Skill>([])

  const [skillEvidences, setSkillEvidences] = useState<SkillEvidencesOverview>({
    advancedSkillCount: 0,
    beginnerSkillCount: 0,
    intermediateSkillCount: 0,
    professional: 0,
  })

  // const [inviteToJobDialogOpen, setInviteToJobDialogOpen] = useState(false)

  useEffect(() => {
    const getData = async () => {
      setLoading(true)

      api.teacher.profile
        .retrieve({
          urlParams: {
            username: username ?? null,
          },
        })
        .then(res => {
          if (res.isSuccessful) {
            setProfile(res.data)
          }
        })

      api.teacher.skillEvidences
        .overview({
          urlParams: { username: username ?? null },
        })
        .then(res => {
          if (res.isSuccessful) {
            setSkillEvidences(res.data)
          }
        })

      const promises = [
        api.teacher.listAcademics({
          urlParams: { username: username ?? null },
        }),
        api.teacher.workExperiences.list({
          urlParams: { username: username ?? null },
        }),
        api.teacher.listAchievements({
          urlParams: { username: username ?? null },
        }),
        api.teacher.languages.list({
          urlParams: { username: username ?? null },
        }),
        api.teacher.interests.list({
          urlParams: { username: username ?? null },
        }),
        api.teacher.skills.list({
          urlParams: { username: username ?? "" },
        }),
      ] as const

      const [
        academicsRes,
        workExperiencesRes,
        achievementsRes,
        languagesRes,
        interestsRes,
        skillsRes,
      ] = await Promise.all(promises)

      if (workExperiencesRes.isSuccessful) {
        workExperiences.set(workExperiencesRes.data)
      }

      if (languagesRes.isSuccessful) languages.set(languagesRes.data)

      if (achievementsRes.isSuccessful) {
        awards.set(achievementsRes.data.awards)
        publications.set(achievementsRes.data.publications)
        projects.set(achievementsRes.data.projects)
        testScores.set(achievementsRes.data.testScores)
      }

      if (academicsRes.isSuccessful) {
        qualifications.set(academicsRes.data.qualifications)
        certifications.set(academicsRes.data.certifications)
      }

      if (interestsRes.isSuccessful) {
        workLocationInterest.set(interestsRes.data.preferredWorkLocations)
        subjects.set(interestsRes.data.subjects)
        interests.set(interestsRes.data.interests)
      }

      if (skillsRes.isSuccessful) {
        skills.set(skillsRes.data)
      }

      setLoading(false)
    }
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fullName = [profile.user.firstName, profile.user.lastName]
    .filter(Boolean)
    .join(" ")

  const ProfileContainer = skipContainer ? "div" : Container
  const containerProps = skipContainer ? {} : { fullWidth: isXsDown }

  return (
    <>
      <ReactHelmet
        data={{
          title: fullName ?? "Teacher Profile",
          description: "Teacher Profile",
          url: window.location.href,
          coverImage: "https://hire.suraasa.com/assets/suraasa-meta-img.jpg",
        }}
      />

      <ProfileContext.Provider
        value={{
          profile,
          languages: {
            data: languages.array,
          },
          achievements: {
            awards: {
              data: awards.array,
            },
            projects: {
              data: projects.array,
            },
            publications: {
              data: publications.array,
            },
            testScores: {
              data: testScores.array,
            },
          },
          workExperiences: {
            data: workExperiences.array,
          },
          academics: {
            qualifications: {
              data: qualifications.array,
            },
            certifications: {
              data: certifications.array,
            },
          },
          subjects: {
            data: subjects.array,
          },
          workLocationInterest: {
            data: workLocationInterest.array,
          },
          interests: {
            data: interests.array,
          },
          skillEvidences,
          skills: {
            data: skills.array,
          },
        }}
      >
        {loading ? (
          <div className="my-3 flex justify-center">
            <CircularProgress />
          </div>
        ) : (
          <>
            {!skipOverview && (
              <Overview
                profile={profile}
                workExperiences={workExperiences.array}
                showQuickAccessTabs
              />
            )}
            <ProfileContainer {...containerProps} className="pb-5">
              <div className="flex flex-col gap-2 md:gap-3">
                <About />
                <Resume />
                <EducationWithSuraasa />
                <SkillProfileOverview />
                <TopSkillEvidences />
                <Academics />
                <WorkExperience />
                <Achievements />
                <Languages />
                <Subjects />
                <WorkLocationInterests />
                <Interests />
                <Skills />
                <PersonalInfo />
              </div>
            </ProfileContainer>
          </>
        )}
      </ProfileContext.Provider>
    </>
  )
}

export default Profile
