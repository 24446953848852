import { Button, IconButton, Typography } from "@suraasa/placebo-ui"
import api from "api"
import {
  InterviewDetailsStep,
  JobApplicantStepStatus,
  StepType,
} from "api/resources/jobs/step.types"
import RemoveDialog from "components/RemoveDialog"
import Section from "components/Section"
import SectionContent from "components/Section/SectionContent"
import ListItem from "components/teacher/ListItem"
import { format } from "date-fns"
import add from "date-fns/add"
import { Calendar, Clock, EditPencil, Trash } from "iconoir-react"
import React, { useContext, useState } from "react"
import { createUseStyles } from "react-jss"
import { Link, useParams } from "react-router-dom"
import { getTag, isMissed } from "utils/getStepStatusTag"
import { generateHuddleURL } from "utils/helpers"
import useToggle from "utils/hooks/useToggle"
import toast from "utils/toast"
import TeacherTimelineContext from "views/teacher/context"

import AddFeedbackDialog from "../Dialogs/AddFeedbackDialog"

const useStyles = createUseStyles(theme => ({
  scheduledFor: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
}))

const Card = ({
  handleInterviewEdit,
  manageHiringProcess,
  ...props
}: InterviewDetailsStep & {
  handleInterviewEdit: () => void
  manageHiringProcess: boolean
}) => {
  const {
    id,
    dateCreated,
    status,
    step: {
      name,
      scheduledTime,
      feedback,
      url,
      isTelephonic,
      duration,
      ...step
    },
  } = props
  const params = useParams()
  const schoolId = params.schoolId || ""
  const classes = useStyles()

  const [feedbackDialog, toggleFeedbackDialog] = useToggle(false)

  const {
    isRejected,
    interviews,
    dialogs: { openFeedback, markAsComplete },
  } = useContext(TeacherTimelineContext)

  const [openRemoveDialog, toggleRemoveDialog] = useToggle(false)
  const [removeLoading, setRemoveLoading] = useState(false)

  const handleMarkAsComplete = () => {
    toggleFeedbackDialog()
    interviews.update(id, {
      ...props,
      status: JobApplicantStepStatus.COMPLETED,
    })
  }

  const PostedDate = () =>
    dateCreated ? (
      <Typography className="mr-auto" color="onSurface.500" variant="smallBody">
        Posted on {format(new Date(dateCreated), "do MMM yy")}
      </Typography>
    ) : null

  const getAction = () => {
    switch (status) {
      case JobApplicantStepStatus.COMPLETED:
        return feedback ? (
          <Button
            size="sm"
            onClick={() => openFeedback.toggle(step.id, StepType.INTERVIEW)}
          >
            Open Feedback
          </Button>
        ) : (
          <Button
            disabled={!manageHiringProcess || isRejected}
            size="sm"
            onClick={() => toggleFeedbackDialog()}
          >
            Add Feedback
          </Button>
        )

      case JobApplicantStepStatus.NOT_STARTED:
        return url ? (
          <>
            <Button
              color="success"
              disabled={!manageHiringProcess || isRejected}
              variant="text"
              onClick={() =>
                markAsComplete.toggle({
                  id,
                  name,
                  afterSubmit: handleMarkAsComplete,
                  schoolId,
                })
              }
            >
              Mark As Complete
            </Button>
            <Button
              color="primary"
              component={url ? Link : undefined}
              disabled={isRejected || !url}
              rel="opener"
              size="sm"
              target="_blank"
              to={generateHuddleURL({
                meetingUrl: url,
                interviewId: step.id,
              })}
            >
              Join Interview
            </Button>
          </>
        ) : (
          <>
            {feedback ? (
              <Button
                size="sm"
                onClick={() => openFeedback.toggle(step.id, StepType.INTERVIEW)}
              >
                Open Feedback
              </Button>
            ) : (
              <Button
                disabled={!manageHiringProcess || isRejected}
                variant="text"
                onClick={() => toggleFeedbackDialog()}
              >
                Add Feedback
              </Button>
            )}
            {!isMissed(props) && (
              <Button
                color="success"
                disabled={!manageHiringProcess || isRejected}
                size="sm"
                onClick={() =>
                  markAsComplete.toggle({
                    id,
                    name,
                    afterSubmit: handleMarkAsComplete,
                    schoolId,
                  })
                }
              >
                Mark as complete
              </Button>
            )}
          </>
        )
      case JobApplicantStepStatus.SKIPPED:
        return (
          <>
            {feedback ? (
              <Button
                variant="text"
                onClick={() => openFeedback.toggle(step.id, StepType.INTERVIEW)}
              >
                Open Feedback
              </Button>
            ) : (
              <Button
                disabled={!manageHiringProcess || isRejected}
                variant="text"
                onClick={() => toggleFeedbackDialog()}
              >
                Add Feedback
              </Button>
            )}
            <Button
              color="success"
              disabled={!manageHiringProcess || isRejected}
              size="sm"
              onClick={() =>
                markAsComplete.toggle({
                  id,
                  name,
                  afterSubmit: handleMarkAsComplete,
                  schoolId,
                })
              }
            >
              Mark as complete
            </Button>
          </>
        )

      case JobApplicantStepStatus.IN_PROGRESS:
        return (
          <>
            <Button
              color="success"
              disabled={!manageHiringProcess || isRejected}
              variant="text"
              onClick={() =>
                markAsComplete.toggle({
                  id,
                  name,
                  afterSubmit: handleMarkAsComplete,
                  schoolId,
                })
              }
            >
              Mark As Complete
            </Button>
            {url && (
              <Button
                color="primary"
                component={url ? Link : undefined}
                disabled={isRejected || !url}
                rel="opener"
                size="sm"
                target="_blank"
                to={generateHuddleURL({
                  meetingUrl: url,
                  interviewId: step.id,
                })}
              >
                Join Interview
              </Button>
            )}
          </>
        )

      default:
        break
    }
  }

  const handleRemove = async () => {
    setRemoveLoading(true)
    const res = await api.teacher.jobApplicantStep.delete({
      urlParams: {
        stepId: id,
      },
      headers: {
        "School-Id": schoolId,
      },
    })

    if (res.isSuccessful) {
      interviews.remove(id)
      toast.success("Interview removed successfully")
    } else {
      toast.error("Interview couldn't be removed")
    }

    setRemoveLoading(false)
  }

  return (
    <>
      <AddFeedbackDialog
        afterSubmit={feedbackData => {
          interviews.update(id, {
            ...props,
            step: { ...props.step, feedback: feedbackData },
          })
        }}
        schoolId={schoolId}
        id={step.id}
        open={feedbackDialog}
        toggle={toggleFeedbackDialog}
        type={StepType.INTERVIEW}
      />

      <RemoveDialog
        handleClose={toggleRemoveDialog}
        loading={removeLoading}
        open={openRemoveDialog}
        title="Delete Interview"
        onRemove={handleRemove}
      >
        Are you sure you want to delete this Interview? This action can’t be
        reversed.
      </RemoveDialog>

      <Section>
        <SectionContent>
          <div className="flex flex-wrap gap-1">
            <Typography color="onSurface.800" variant="strong">
              {name}
            </Typography>
            <span>•</span>
            <Typography color="onSurface.500" variant="body">
              {isTelephonic ? "Telephonic Interview" : "Online Interview"}
            </Typography>

            {getTag(props)}

            {[
              JobApplicantStepStatus.NOT_STARTED,
              JobApplicantStepStatus.SKIPPED,
            ].includes(status) && (
              <div className="ml-auto flex gap-1.25">
                <IconButton disabled={isRejected} onClick={handleInterviewEdit}>
                  <EditPencil />
                </IconButton>
                <IconButton
                  color="critical"
                  disabled={isRejected}
                  onClick={() => toggleRemoveDialog()}
                >
                  <Trash />
                </IconButton>
              </div>
            )}
          </div>

          <ListItem icon={<Calendar />}>
            <Typography
              className={classes.scheduledFor}
              color="onSurface.400"
              display="inline"
              variant="smallBody"
            >
              Scheduled for{" "}
            </Typography>
            <Typography display="inline" variant="strongSmallBody">
              {format(new Date(scheduledTime), "dd MMMM yyyy hh:mm aa")}
            </Typography>
          </ListItem>

          <ListItem icon={<Clock />}>
            <Typography variant="smallBody">
              {[
                format(new Date(scheduledTime), "hh:mm aaa"),
                format(
                  add(new Date(scheduledTime), { minutes: duration }),
                  "hh:mm aaa"
                ),
              ].join(" - ")}
            </Typography>
          </ListItem>

          <div className="mt-2 flex flex-wrap items-center justify-end gap-1">
            <PostedDate />
            {getAction()}
          </div>
        </SectionContent>
      </Section>
    </>
  )
}

export default Card
