import { Button, Divider, Radio, Typography } from "@suraasa/placebo-ui"
import api from "api"
import { RepresentativeType } from "api/resources/partner/types"
import {
  AccessibleSchoolType,
  RecruiterType,
  UserType,
} from "api/resources/recruiter/types"
import SlidingSheet from "components/SlidingSheet"
import { Xmark } from "iconoir-react"
import { useCallback, useEffect, useState } from "react"
import { getAuthInfo } from "utils/auth"

import { DepartmentType } from "../useSchoolList"

type Props = {
  open: boolean
  handleClose: () => void
  schoolId?: number | null
  onSubmit: (schoolId: number, recruiter: RecruiterType) => void
  change?: boolean
  assignedRecruiter?: AccessibleSchoolType["assignedRecruiter"]
  isPartner?: boolean
}

const AssignMemberSheet = ({
  open,
  handleClose,
  schoolId,
  onSubmit,
  assignedRecruiter,
  change,
  isPartner,
}: Props) => {
  //   const { dispatch: callEventListener } = useCustomEvent("teamList")
  // const { recruiterList, partnerList } = useContext(GlobalContext)
  const [recruiterList, setRecruiterList] = useState<RecruiterType[]>([])
  const [partnerList, setPartnerList] = useState<RepresentativeType[]>([])

  const [recruiter, setRecruiter] = useState<RecruiterType | null>(null)

  const onClose = () => {
    handleClose()
    setRecruiter(null)
  }
  const list = isPartner
    ? partnerList.filter(
        r =>
          r.roles.includes(UserType.MEMBER_PARTNER) ||
          r.roles.includes(UserType.LEAD_PARTNER)
      )
    : recruiterList.filter(
        r =>
          r.roles.includes(UserType.MEMBER_RECRUITER) ||
          r.roles.includes(UserType.MEMBER_PARTNER)
      )
  const authInfo = getAuthInfo()

  const updatedList =
    (authInfo &&
      authInfo.user.uuid && [
        {
          email: authInfo.user.email,
          fullName: `${authInfo.user.firstName} ${authInfo.user.lastName}`,
          uuid: authInfo.user.uuid,
          product: "",
          schoolCount: 0,
          representativeId: -1,
          roles: [],
        },
        ...list.filter(v => v.uuid !== authInfo?.user.uuid),
      ]) ||
    []

  const fetchRecruiters = useCallback(async () => {
    const res = await api.recruiter.recruiterList({
      params: {
        related_department: DepartmentType.RECRUITER,
      },
    })

    if (res.isSuccessful) {
      setRecruiterList(res.data.data)
    }
  }, [])

  const fetchPartners = useCallback(async () => {
    const res = await api.partner.representativeList({
      params: {
        related_department: DepartmentType.PARTNER,
      },
    })

    if (res.isSuccessful) {
      setPartnerList(res.data.data)
    }
  }, [])

  useEffect(() => {
    if (schoolId) {
      if (isPartner) {
        fetchPartners()
      } else {
        fetchRecruiters()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schoolId, isPartner])

  return (
    <SlidingSheet
      from="end"
      open={open}
      onClose={onClose}
      className="flex h-screen w-full flex-col px-3 pb-3 pt-4 sm:max-w-[420px]"
    >
      <Button
        variant="text"
        color="black"
        onClick={onClose}
        startAdornment={<Xmark />}
        className="-ml-1 mb-2.5"
      >
        Close
      </Button>
      <Typography variant="title3" className="mb-3">
        {change ? "Change" : "Assign"} {isPartner ? "Partner" : "Recruiter"}
      </Typography>
      <div className="flex h-full flex-col justify-between">
        <div className="flex flex-col">
          {updatedList.length > 0 &&
            updatedList.map((item, index) => {
              if (!item) {
                return null
              }
              return (
                <div key={index}>
                  <button
                    onClick={() => {
                      setRecruiter(item)
                    }}
                    className="flex items-center gap-1.5"
                  >
                    <Radio
                      color="primary"
                      checked={
                        recruiter?.uuid === item.uuid ||
                        (!recruiter && assignedRecruiter?.uuid === item.uuid)
                      }
                    />
                    <div className="flex flex-col items-start gap-0.5">
                      <Typography variant="strong">
                        {authInfo && authInfo.user.uuid === item.uuid
                          ? "Myself"
                          : item.fullName}
                      </Typography>
                      <Typography
                        variant="smallBody"
                        className="break-all"
                        textAlign="left"
                      >
                        {item.email}
                      </Typography>
                    </div>
                  </button>
                  <Divider className="my-2" weight="light" />
                </div>
              )
            })}
        </div>

        <Button
          onClick={() => {
            if (schoolId && recruiter) onSubmit(schoolId, recruiter)
          }}
          type="submit"
          className="!rounded-full"
          fullWidth
          disabled={
            recruiter ? assignedRecruiter?.uuid === recruiter.uuid : true
          }
        >
          {change ? "Change" : "Assign"}{" "}
          {recruiter && `to ${recruiter.fullName}`}
        </Button>
      </div>
    </SlidingSheet>
  )
}

export default AssignMemberSheet
