import axios from "api/axios"
import {
  APIResponseOld,
  Config,
  ConfigWithURLParams,
  NoContentType,
  PaginatedAPIResponse,
} from "api/types"
import {
  formatErrorResponse,
  formatSuccessResponse,
  formatSuccessResponseOld,
} from "api/utils"

import {
  InterviewDetailsType,
  LiveDemoDetailsType,
  Step,
} from "../jobs/step.types"
import { ExploreTeacher } from "../jobs/types"
import {
  Award,
  Certification,
  Feedback,
  Interest,
  LanguageResponse,
  Note,
  Profile,
  Project,
  Publication,
  Qualification,
  Skill,
  SkillEvidence,
  SkillEvidencesOverview,
  SubjectResponse,
  TestScore,
  WorkExperienceType,
  WorkLocationInterest,
} from "./types"
import { urls } from "./urls"

export default {
  profile: {
    retrieve: async ({
      urlParams,
      headers,
    }: ConfigWithURLParams<"username">): Promise<APIResponseOld<Profile>> => {
      try {
        const res = await axios.get(urls.profile.public(urlParams.username), {
          headers,
        })
        return formatSuccessResponseOld(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    downloadResume: async ({
      urlParams,
    }: ConfigWithURLParams): Promise<APIResponseOld<any>> => {
      try {
        const res = await axios.get(urls.profile.resume(urlParams.userId), {
          responseType: "blob",
        })
        return formatSuccessResponseOld(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },

  videoPortfolio: {
    retrieve: async ({
      urlParams,
    }: ConfigWithURLParams<"username">): Promise<
      APIResponseOld<{ videoPortfolio: string | null }>
    > => {
      try {
        const res = await axios.get(
          urls.videoPortfolio.list(urlParams.username),
          {}
        )
        return formatSuccessResponseOld(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },

  listAchievements: async ({
    urlParams,
  }: ConfigWithURLParams<"username">): Promise<
    APIResponseOld<{
      publications: Publication[]
      awards: Award[]
      testScores: TestScore[]
      projects: Project[]
    }>
  > => {
    try {
      const res = await axios.get(urls.achievements.list(urlParams.username))
      return formatSuccessResponseOld(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  languages: {
    list: async ({
      urlParams,
    }: ConfigWithURLParams<"username">): Promise<
      APIResponseOld<LanguageResponse[]>
    > => {
      try {
        const res = await axios.get(urls.languages.list(urlParams.username))
        return formatSuccessResponseOld(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },

  interests: {
    list: async ({
      urlParams,
    }: ConfigWithURLParams<"username">): Promise<
      APIResponseOld<{
        preferredWorkLocations: WorkLocationInterest[]
        interests: Interest[]
        subjects: SubjectResponse[]
      }>
    > => {
      try {
        const res = await axios.get(urls.interests.list(urlParams.username))
        return formatSuccessResponseOld(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },

  skills: {
    list: async ({
      urlParams,
    }: ConfigWithURLParams): Promise<APIResponseOld<Skill[]>> => {
      try {
        const res = await axios.get(urls.skills.list(urlParams.username), {})
        return formatSuccessResponseOld(res)
      } catch (e) {
        throw formatErrorResponse(e)
      }
    },
  },

  listAcademics: async ({
    urlParams,
  }: ConfigWithURLParams<"username">): Promise<
    APIResponseOld<{
      certifications: Certification[]
      qualifications: Qualification[]
    }>
  > => {
    try {
      const res = await axios.get(urls.academics.list(urlParams.username), {})
      return formatSuccessResponseOld(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  workExperiences: {
    list: async ({
      urlParams,
    }: ConfigWithURLParams<"username">): Promise<
      APIResponseOld<WorkExperienceType[]>
    > => {
      try {
        const res = await axios.get(
          urls.workExperiences.list(urlParams.username),
          {}
        )
        return formatSuccessResponseOld(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },

  interview: {
    create: async ({
      data,
      headers,
    }: Config): Promise<
      APIResponseOld<
        InterviewDetailsType & { dateCreated: string; jobApplicantStep: number }
      >
    > => {
      try {
        const res = await axios.post(urls.interview.list(), data, { headers })
        return formatSuccessResponseOld(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    update: async ({
      urlParams,
      data,
      headers,
    }: ConfigWithURLParams<"interviewId">): Promise<
      APIResponseOld<
        InterviewDetailsType & { dateCreated: string; jobApplicantStep: number }
      >
    > => {
      try {
        const res = await axios.put(
          urls.interview.detail(urlParams.interviewId),
          data,
          { headers }
        )
        return formatSuccessResponseOld(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    feedback: {
      create: async ({
        urlParams,
        data,
        headers,
      }: ConfigWithURLParams<"interviewId">): Promise<
        APIResponseOld<Feedback>
      > => {
        try {
          const res = await axios.post(
            urls.interview.feedback(urlParams.interviewId),
            data,
            { headers }
          )
          return formatSuccessResponseOld(res)
        } catch (e) {
          return formatErrorResponse(e)
        }
      },
      retrieve: async ({
        urlParams,
        headers,
      }: ConfigWithURLParams<"interviewId">): Promise<
        APIResponseOld<{ id: number; interview: number; feedback: Feedback }>
      > => {
        try {
          const res = await axios.get(
            urls.interview.feedback(urlParams.interviewId),
            { headers }
          )
          return formatSuccessResponseOld(res)
        } catch (e) {
          return formatErrorResponse(e)
        }
      },
    },
  },

  liveDemo: {
    feedback: {
      create: async ({
        urlParams,
        data,
        headers,
      }: ConfigWithURLParams<"liveDemoId">): Promise<
        APIResponseOld<Feedback>
      > => {
        try {
          const res = await axios.post(
            urls.liveDemo.feedback(urlParams.liveDemoId),
            data,
            {
              headers,
            }
          )
          return formatSuccessResponseOld(res)
        } catch (e) {
          return formatErrorResponse(e)
        }
      },
      retrieve: async ({
        urlParams,
      }: ConfigWithURLParams<"liveDemoId">): Promise<
        APIResponseOld<{
          feedback: Feedback
          liveDemo: Pick<
            LiveDemoDetailsType,
            "id" | "name" | "scheduledTime" | "isVirtual"
          >
        }>
      > => {
        try {
          const res = await axios.get(
            urls.liveDemo.feedback(urlParams.liveDemoId)
          )
          return formatSuccessResponseOld(res)
        } catch (e) {
          return formatErrorResponse(e)
        }
      },
    },

    create: async ({
      data,
      headers,
    }: Config): Promise<
      APIResponseOld<
        LiveDemoDetailsType & { jobApplicantStep: number; dateCreated: string }
      >
    > => {
      try {
        const res = await axios.post(urls.liveDemo.create(), data, { headers })
        return formatSuccessResponseOld(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },

    update: async ({
      urlParams,
      data,
      headers,
    }: ConfigWithURLParams<"liveDemoId">): Promise<
      APIResponseOld<
        LiveDemoDetailsType & { jobApplicantStep: number; dateCreated: string }
      >
    > => {
      try {
        const res = await axios.put(
          urls.liveDemo.detail(urlParams.liveDemoId),
          data,
          { headers }
        )
        return formatSuccessResponseOld(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },

    retrieve: async ({
      urlParams,
    }: ConfigWithURLParams<"liveDemoId">): Promise<
      APIResponseOld<LiveDemoDetailsType>
    > => {
      try {
        const res = await axios.get(urls.liveDemo.detail(urlParams.liveDemoId))
        return formatSuccessResponseOld(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },

  jobApplicantStep: {
    list: async ({
      urlParams,
      params,
      headers,
    }: ConfigWithURLParams<"jobApplicantId">): Promise<
      APIResponseOld<Step[]>
    > => {
      try {
        const res = await axios.get(
          urls.jobApplicantStep.list(urlParams.jobApplicantId),
          { params, headers }
        )
        return formatSuccessResponseOld(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    delete: async ({
      urlParams,
      headers,
    }: ConfigWithURLParams<"stepId">): Promise<
      APIResponseOld<NoContentType>
    > => {
      try {
        const res = await axios.delete(
          urls.jobApplicantStep.detail(urlParams.stepId),
          { headers }
        )
        return formatSuccessResponseOld(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },

    update: async ({
      data,
      urlParams,
    }: ConfigWithURLParams<"stepId">): Promise<APIResponseOld<Step>> => {
      try {
        const res = await axios.put(
          urls.jobApplicantStep.detail(urlParams.stepId),
          data
        )
        return formatSuccessResponseOld(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },

  explore: {
    list: async ({
      params,
      headers,
    }: Config): Promise<PaginatedAPIResponse<ExploreTeacher[]>> => {
      try {
        const res = await axios.get(urls.explore.list(), { params, headers })
        return formatSuccessResponse(res, { paginatedResponse: true })
      } catch (e) {
        throw formatErrorResponse(e)
      }
    },
  },

  notes: {
    list: async ({
      urlParams,
      headers,
    }: ConfigWithURLParams): Promise<APIResponseOld<Note[]>> => {
      try {
        const res = await axios.get(urls.notes.base(urlParams.jobApplicantId), {
          headers,
        })
        return formatSuccessResponseOld(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    create: async ({
      urlParams,
      data,
      headers,
    }: ConfigWithURLParams): Promise<APIResponseOld<Note>> => {
      try {
        const res = await axios.post(
          urls.notes.base(urlParams.jobApplicantId),
          data,
          { headers }
        )
        return formatSuccessResponseOld(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },
  skillEvidences: {
    overview: async ({
      urlParams,
    }: ConfigWithURLParams<"username">): Promise<
      APIResponseOld<SkillEvidencesOverview>
    > => {
      try {
        const res = await axios.get(
          urls.skillEvidences.overview(urlParams.username),
          {}
        )
        return formatSuccessResponseOld(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },

    list: async ({
      urlParams,
    }: ConfigWithURLParams): Promise<
      APIResponseOld<{
        skills: SkillEvidence[]
        user: { fullName: string; photo: string | null } | null
      }>
    > => {
      try {
        const res = await axios.get(
          urls.skillEvidences.list(urlParams.username),
          {}
        )
        return formatSuccessResponseOld(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },
}
