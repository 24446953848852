import axios from "api/axios"
import { APIResponseOld, Config, NoContentType } from "api/types"
import { formatErrorResponse, formatSuccessResponseOld } from "api/utils"

import { urls } from "./urls"

export default {
  create: async ({ data }: Config): Promise<APIResponseOld<NoContentType>> => {
    try {
      const res = await axios.post(urls.help(), data)
      return formatSuccessResponseOld(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
}
