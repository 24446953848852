import {
  Avatar,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@suraasa/placebo-ui"
import { useInfiniteQuery } from "@tanstack/react-query"
import api from "api"
import { LearnerType } from "api/resources/recruiter/types"
import VerifiedBadge from "assets/home/verifiedBadge.svg"
import NoDataCard from "components/jobs/NoDataCard"
import SearchField from "components/Recruiter/SearchField"
import format from "date-fns/format"
import { InfoCircleSolid, MoreVert } from "iconoir-react"
import { useState } from "react"
import { Link } from "react-router-dom"
import { getTeacherProfileRoute, pluralize } from "utils/helpers"
import usePaginationScroll from "utils/hooks/usePaginationScroll"
import useUserActions from "views/auth/useUserActions"

import MarkAsPlacedDialog from "./MarkAsPlacedDialog"

const Profile = ({ item }: { item: LearnerType }) => {
  return (
    <div className="flex gap-1">
      <div>
        <Avatar
          name={item.fullName}
          size="xs"
          src={item.profile.picture ?? undefined}
        />
      </div>
      <div>
        <div className="flex items-center gap-0.5">
          <Button
            className="-ml-0.75"
            component={Link}
            to={getTeacherProfileRoute({
              // jobId: job.id,
              username: item.username,
              // schoolId,
            })}
            variant="link"
          >
            {item.fullName}
          </Button>
          {item.profile.isVerified && <img src={VerifiedBadge} alt="" />}
        </div>

        <div className="flex items-center">
          <Typography className={"text-xs"} color="onSurface.500">
            {item.email}
          </Typography>
        </div>

        {item.profile.phoneNumber && (
          <Typography className={"text-xs"} color="onSurface.500">
            +{item.profile.phoneNumber.code}
            {item.profile.phoneNumber.number}
          </Typography>
        )}
      </div>
    </div>
  )
}

const LearnerCard = ({
  learner,
  showPlacementRecord,
  onMarkAsPlaced,
}: {
  learner: LearnerType
  showPlacementRecord: (learner: LearnerType) => void
  onMarkAsPlaced: (learner: LearnerType) => void
}) => {
  const {
    learnersTab: { canMarkAsPlaced },
  } = useUserActions()

  return (
    <div className="grid w-full grid-cols-3 items-center justify-between p-2">
      <div>
        <Profile item={learner} />
      </div>
      <div className="flex items-center justify-center">
        {learner.placementRecords.length > 0 && (
          <>
            <Typography variant="smallBody">
              Placed in&nbsp;
              {pluralize("School", learner.placementRecords.length)}
            </Typography>
            <IconButton onClick={() => showPlacementRecord(learner)}>
              <InfoCircleSolid className="size-2.5 text-onSurface-900" />
            </IconButton>
          </>
        )}
      </div>
      <div className="flex justify-end gap-4">
        <Button
          variant="text"
          to={getTeacherProfileRoute({
            username: learner.username,
          })}
          component={Link}
        >
          View Profile
        </Button>
        {canMarkAsPlaced && (
          <Menu
            menuButton={
              <IconButton>
                <MoreVert />
              </IconButton>
            }
            portal
          >
            <MenuItem color="default" onClick={() => onMarkAsPlaced(learner)}>
              Mark as Placed
            </MenuItem>
          </Menu>
        )}
      </div>
    </div>
  )
}

const PlacementRecordDialog = ({
  learner,
  onClose,
}: {
  learner: LearnerType
  onClose: () => void
}) => {
  return (
    <Dialog open={true} width="md" onRequestClose={() => onClose()}>
      <DialogContent>
        <div className="grid grid-cols-3">
          <Typography variant="strongSmallBody">School Name</Typography>
          <Typography variant="strongSmallBody">Job Title</Typography>
          <Typography variant="strongSmallBody">Date</Typography>
        </div>

        <div className="mt-1 flex flex-col gap-1">
          {learner.placementRecords.map((item, key) => (
            <div className="grid grid-cols-3" key={key}>
              <Typography variant="smallBody">{item.schoolName}</Typography>
              <Typography variant="smallBody">{item.jobTitle}</Typography>
              <Typography variant="smallBody">
                {format(new Date(item.dateOfHiring), "dd MMM yyyy")}
              </Typography>
            </div>
          ))}
        </div>
      </DialogContent>
    </Dialog>
  )
}

const Learners = () => {
  const [search, setSearch] = useState("")
  const [showMarkAsPlaced, setShowMarkAsPlaced] = useState<LearnerType | null>(
    null
  )
  const [showPlacementRecord, setShowPlacementRecord] =
    useState<LearnerType | null>(null)

  const {
    isFetching: loading,
    hasNextPage,
    fetchNextPage,
    data,
    refetch,
  } = useInfiniteQuery({
    queryKey: ["learners", search],
    queryFn: x =>
      api.recruiter.learnerList({
        params: {
          size: 25,
          ...(search && { search: encodeURI(search) }),
          page: x.pageParam,
        },
      }),
    initialPageParam: 1,
    getNextPageParam: lastPage => {
      return lastPage.nextPage ?? undefined
    },
  })

  const { ref } = usePaginationScroll({
    loading: loading,
    hasNextPage: hasNextPage,
    actionFunc: fetchNextPage,
  })
  const learners = data?.pages.map(page => page.data).flat() || []

  return (
    <div className="h-full bg-white">
      {showPlacementRecord && (
        <PlacementRecordDialog
          learner={showPlacementRecord}
          onClose={() => setShowPlacementRecord(null)}
        />
      )}
      {showMarkAsPlaced && (
        <MarkAsPlacedDialog
          learner={showMarkAsPlaced}
          onClose={() => setShowMarkAsPlaced(null)}
          onMarked={refetch}
        />
      )}
      <div className="flex w-full pb-1">
        <SearchField
          placeholder="Search by name/email"
          value={search}
          onSearch={setSearch}
          fullWidth
          error={search.length === 2}
        />
      </div>

      <div className="mt-2">
        {!loading && learners.length === 0 ? (
          <NoDataCard />
        ) : (
          <div className="flex w-full flex-col divide-y rounded-lg border">
            {learners.map((item, i) => (
              <div ref={i === learners.length - 4 ? ref : null} key={i}>
                <LearnerCard
                  learner={item}
                  showPlacementRecord={item => setShowPlacementRecord(item)}
                  onMarkAsPlaced={item => setShowMarkAsPlaced(item)}
                />
              </div>
            ))}
          </div>
        )}
      </div>
      {loading && (
        <div className="flex items-center justify-center p-5">
          <CircularProgress />
        </div>
      )}
    </div>
  )
}

export default Learners
