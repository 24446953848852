import { Button, InputLabel, TextField, Typography } from "@suraasa/placebo-ui"
import api from "api"
import clsx from "clsx"
import InvalidMeeting from "components/InvalidMeeting"
import LoadingOverlay from "components/shared/LoadingOverlay"
import Navbar from "components/shared/Navbar"
import { FeedbackForm as FeedbackFormType } from "components/teacher/Dialogs/AddFeedbackDialog"
import camelCase from "lodash/camelCase"
import React, { useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { createUseStyles } from "react-jss"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import ReactStars from "react-stars"
import useScript from "utils/hooks/useScript"
import toast from "utils/toast"

const feedbackFormStyles = createUseStyles(theme => ({
  stars: {
    marginBottom: theme.spacing(1.75),
    display: "flex",
    overflow: "auto",
  },
}))

const convertArrayToObject = (array: any[], key: string) => {
  const initialValue = {}
  return array.reduce(
    (obj, item) => ({
      ...obj,
      [camelCase(item[key])]: item,
    }),
    initialValue
  )
}

const FeedbackForm = ({
  interviewId,
  handleFormSubmit,
  feedbackFormSubmitted,
}: {
  feedbackFormSubmitted: boolean
  interviewId: string
  handleFormSubmit: () => void
}) => {
  const classes = feedbackFormStyles()

  const params = useParams()
  const [searchParams] = useSearchParams()
  const schoolId = params.schoolId || searchParams.get("schoolId")

  const {
    register,
    reset,
    control,
    handleSubmit,
    getValues,
    formState: { isSubmitting },
  } = useForm<FeedbackFormType>({
    defaultValues: {
      interpersonalSkills: {
        name: "Interpersonal Skills",
        comment: "",
        rating: 0,
      },
      communicationSkills: {
        name: "Communication Skills",
        comment: "",
        rating: 0,
      },
      attentionToDetail: {
        name: "Attention To Detail",
        comment: "",
        rating: 0,
      },
      pedagogySkills: {
        name: "Pedagogy Skills",
        comment: "",
        rating: 0,
      },
      overallComments: {
        name: "Overall Comments",
        comment: "",
        rating: 0,
      },
    },
  })

  useEffect(() => {
    const fetchFeedback = async () => {
      const res = await api.teacher.interview.feedback.retrieve({
        urlParams: { interviewId },
        headers: {
          "School-Id": schoolId,
        },
      })

      if (res.isSuccessful) {
        reset(convertArrayToObject(res.data.feedback, "name"))
      }
    }
    if (interviewId) fetchFeedback()
  }, [interviewId, reset])

  const onSubmit = handleSubmit(async formData => {
    const res = await api.teacher.interview.feedback.create({
      data: { feedback: Object.values(formData) },
      urlParams: {
        interviewId,
      },
      headers: {
        "School-Id": schoolId,
      },
    })
    if (res.isSuccessful) {
      toast.success("Feedback added successfully")
      handleFormSubmit()
    } else if (res.errors.fieldErrors?.interview) {
      toast.error(res.errors.fieldErrors.interview)
    } else toast.error(res.errors.message ?? "Feedback couldn't be added")
  })

  const autoSave = () => {
    api.teacher.interview.feedback.create({
      data: { feedback: Object.values(getValues()) },
      urlParams: {
        interviewId,
      },
      headers: {
        "School-Id": schoolId,
      },
    })
  }

  if (feedbackFormSubmitted) {
    return (
      <div className="my-3 flex flex-col gap-3 px-1">
        {Object.values(getValues()).map(({ comment, name, rating }) => (
          <div key={name}>
            <Typography variant="strong">{name}</Typography>
            <ReactStars edit={false} size={24} value={rating} />
            <Typography
              style={{
                whiteSpace: "pre-line",
              }}
              variant="smallBody"
            >
              {comment || "No comments"}
            </Typography>
          </div>
        ))}
      </div>
    )
  }

  return (
    <form
      className="flex flex-col"
      style={{ height: "100%" }}
      onSubmit={onSubmit}
    >
      <div className="-mb-1.5 flex items-center gap-1">
        <InputLabel label="Interpersonal Skills" />
        <Controller
          control={control}
          name="interpersonalSkills.rating"
          render={({ field: { onChange, value } }) => (
            <ReactStars
              className={classes.stars}
              count={5}
              half={false}
              size={24}
              value={value}
              onChange={v => {
                onChange(v)
                autoSave()
              }}
            />
          )}
        />
      </div>
      {/* @ts-expect-error placebo-issue */}
      <TextField
        className="mb-2.5"
        rows={2}
        fullWidth
        multiLine
        {...register("interpersonalSkills.comment", {
          onBlur: autoSave,
        })}
      />
      <div className="-mb-1.5 flex items-center gap-1">
        <InputLabel label="Communication Skills" />
        <Controller
          control={control}
          name="communicationSkills.rating"
          render={({ field: { onChange, value } }) => (
            <ReactStars
              className={classes.stars}
              count={5}
              half={false}
              size={24}
              value={value}
              onChange={v => {
                onChange(v)
                autoSave()
              }}
            />
          )}
        />
      </div>
      {/* @ts-expect-error placebo-issue */}
      <TextField
        className="mb-2.5"
        rows={2}
        fullWidth
        multiLine
        {...register("communicationSkills.comment", {
          onBlur: autoSave,
        })}
      />
      <div className="-mb-1.5 flex items-center gap-1">
        <InputLabel label="Attention to Detail" />
        <Controller
          control={control}
          name="attentionToDetail.rating"
          render={({ field: { onChange, value } }) => (
            <ReactStars
              className={classes.stars}
              count={5}
              half={false}
              size={24}
              value={value}
              onChange={v => {
                onChange(v)
                autoSave()
              }}
            />
          )}
        />
      </div>
      {/* @ts-expect-error placebo-issue */}
      <TextField
        className="mb-2.5"
        rows={2}
        fullWidth
        multiLine
        {...register("attentionToDetail.comment", {
          onBlur: autoSave,
        })}
      />
      <div className="-mb-1.5 flex items-center gap-1">
        <InputLabel label="Pedagogy Skills" />
        <Controller
          control={control}
          name="pedagogySkills.rating"
          render={({ field: { onChange, value } }) => (
            <ReactStars
              className={classes.stars}
              count={5}
              half={false}
              size={24}
              value={value}
              onChange={v => {
                onChange(v)
                autoSave()
              }}
            />
          )}
        />
      </div>
      {/* @ts-expect-error placebo-issue */}
      <TextField
        className="mb-2.5"
        rows={2}
        fullWidth
        multiLine
        {...register("pedagogySkills.comment", {
          onBlur: autoSave,
        })}
      />
      <div className="-mb-1.5 flex items-center gap-1">
        <InputLabel label="Overall Comments" />
        <Controller
          control={control}
          name="overallComments.rating"
          render={({ field: { onChange, value } }) => (
            <ReactStars
              className={classes.stars}
              count={5}
              half={false}
              size={24}
              value={value}
              onChange={v => {
                onChange(v)
                autoSave()
              }}
            />
          )}
        />
      </div>
      {/* @ts-expect-error placebo-issue */}
      <TextField
        className="mb-2.5"
        rows={2}
        fullWidth
        multiLine
        {...register("overallComments.comment", {
          onBlur: autoSave,
        })}
      />
      <Button
        className="mb-3 mt-auto"
        color="primary"
        disabled={isSubmitting}
        type="submit"
        fullWidth
      >
        Submit Feedback
      </Button>
    </form>
  )
}
const useStyles = createUseStyles(theme => ({
  feedbackForm: {
    background: "#fff",
    height: "100%",
    maxWidth: "100%",
    width: 380,
  },
  huddleFrame: {
    width: "100%",
    height: "100%",
    "& > iframe": {
      border: "none",
    },
  },
  soloForm: {
    width: 500,
    padding: theme.spacing(2),
  },
}))

// NOTE: TLD stands for top level domain (like .com, .in)
const isSecure = (url: URL) => url.protocol === "https:"
const isHuddleUrl = (url: URL) => {
  const [TLD, domain] = url.hostname.split(".").reverse()
  return domain === "huddle01" && TLD === "com"
}

const isURLValid = (link: string) => {
  try {
    return isSecure(new URL(link)) && isHuddleUrl(new URL(link))
  } catch (error) {
    console.error(error)
    return false
  }
}

const Interview = () => {
  const [searchParams] = useSearchParams()
  const classes = useStyles()
  const [meLeft, setMeLeft] = useState(false)
  const [feedbackFormSubmitted, setFeedbackFormSubmitted] = useState(false)
  const navigate = useNavigate()
  const [closingOverlay, setClosingOverlay] = useState(false)
  const link = decodeURIComponent(searchParams.get("meeting-link") || "")
  const interviewId = searchParams.get("interview-id")

  const closeTab = () => {
    setClosingOverlay(true)
    setTimeout(() => {
      window.close()
    }, 3000)
  }
  const status = useScript("https://huddll01-iframe-api.netlify.app/api.min.js")

  const handleFormSubmit = () => {
    setFeedbackFormSubmitted(true)
    if (meLeft) {
      closeTab()
    }
  }

  useEffect(() => {
    if (meLeft && feedbackFormSubmitted) {
      window.close()
    }
    // We just wanna know if user has submitted the form while leaving
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meLeft])

  useEffect(() => {
    const huddleEl = document.getElementById("huddle01")
    if (status === "ready" && huddleEl) {
      // @ts-expect-error typescript doesn't know that HuddleApp is defined by the injected script
      // eslint-disable-next-line no-undef
      const huddleApp = new HuddleApp({
        config: {
          parentEl: huddleEl,
          iframeConfig: {
            src: link,
            width: "100%",
            height: "100%",
          },
        },
      })

      const handleEvent = (event: MessageEvent<any>) => {
        if (event.data.event === "me-left") {
          setMeLeft(true)
        }
      }

      window.addEventListener("message", handleEvent)

      return () => {
        window.removeEventListener("message", handleEvent)
        huddleApp.destroy()
      }
    }
  }, [link, status, navigate])

  if (!link || !isURLValid(link) || !interviewId) return <InvalidMeeting />

  if (closingOverlay)
    return (
      <LoadingOverlay>
        <Typography className="mt-2" variant="largeBody">
          You are being redirected
        </Typography>
      </LoadingOverlay>
    )

  return (
    <div className="flex flex-col" style={{ height: "100%" }}>
      <Navbar gutterBottom={false} hideBackButton />
      <div className="flex justify-center" style={{ height: "100%" }}>
        {!meLeft && (
          <div
            className={clsx(classes.huddleFrame, "grow")}
            id="huddle01"
            title="Interview"
          />
        )}

        <div
          className={clsx(
            { [classes.soloForm]: meLeft },
            classes.feedbackForm,
            "p-1 md:p-3"
          )}
        >
          <FeedbackForm
            feedbackFormSubmitted={feedbackFormSubmitted}
            handleFormSubmit={handleFormSubmit}
            interviewId={interviewId}
          />
        </div>
      </div>
    </div>
  )
}

export default Interview
