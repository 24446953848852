import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogProps,
  DialogTitle,
  Typography,
} from "@suraasa/placebo-ui"
import api from "api"
import { ContactDetails } from "api/resources/jobs/types"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"

type Props = {
  handleClose: () => void
  loading?: boolean
  teacher: {
    uuid: string
    name: string
  }
  canViewContactDetails?: boolean
} & DialogProps

const ContactDetailsDialog = ({
  open,
  handleClose,
  teacher,
  canViewContactDetails = false,
  ...props
}: Props) => {
  const [details, setDetails] = useState<ContactDetails>()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string>()

  const { schoolId } = useParams()

  useEffect(() => {
    const fetchDetails = async () => {
      setLoading(true)
      const res = await api.jobs.jobApplicant.retrieveContactDetails({
        urlParams: { userId: teacher.uuid },
        headers: {
          "School-Id": schoolId,
        },
      })
      if (res.isSuccessful) {
        setDetails(res.data)
      } else {
        setError(
          res.errors.message ||
            res.errors.fieldErrors?.user ||
            "Something went wrong"
        )
      }
      setLoading(false)
    }

    if (canViewContactDetails) fetchDetails()
  }, [teacher, canViewContactDetails])

  const mountData = () => {
    if (!canViewContactDetails) {
      return (
        <Typography variant="smallBody">
          You can only view <b>{teacher.name}’s</b> contact details once they
          accept your invite.
        </Typography>
      )
    }

    if (error) {
      return <Typography variant="smallBody">{error}</Typography>
    }

    if (loading)
      return (
        <div className="my-3 flex justify-center">
          <CircularProgress />
        </div>
      )

    if (details)
      return (
        <>
          <Typography className="mb-1 mt-2" variant="smallBody">
            E-Mail Address: <b>{details.email}</b>
          </Typography>
          {details.profile.phoneNumber && (
            <Typography className="mb-2" variant="smallBody">
              Phone Number:{" "}
              <b style={{ whiteSpace: "nowrap" }}>
                {" "}
                +{details.profile.phoneNumber.code}{" "}
                {details.profile.phoneNumber.number}
              </b>
            </Typography>
          )}
        </>
      )
  }

  return (
    <Dialog open={open} width="sm" onRequestClose={handleClose} {...props}>
      <DialogTitle>Contact Details</DialogTitle>
      <DialogContent>{mountData()}</DialogContent>
      {!details && !loading && (
        <DialogFooter
          actions={{
            primary: null,
            secondary: {
              label: "Okay",
              type: "button",
              onClick: handleClose,
            },
          }}
        />
      )}
    </Dialog>
  )
}

export default ContactDetailsDialog
