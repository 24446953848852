import { Profile } from "api/resources/profile/types"
import React from "react"

type CurrentSchool = {
  school: Profile | null
}
export const CurrentSchoolContext = React.createContext<CurrentSchool>({
  school: null,
})
export const useCurrentSchool = () => React.useContext(CurrentSchoolContext)
