import { Button, Container, IconButton, Typography } from "@suraasa/placebo-ui"
import { Job } from "api/resources/jobs/types"
import clsx from "clsx"
import { Xmark } from "iconoir-react"
import React from "react"
import { createUseStyles } from "react-jss"
import { Link } from "react-router-dom"
import { routes } from "utils/routes"

const useStyles = createUseStyles(({ colors }) => ({
  topMessageBar: {
    background: colors.interactive[50],
    position: "relative",
  },
  closeTopBarButton: {
    position: "absolute",
    right: 24,
    top: "25%",
  },
}))

const getDisplay = (teachers: string[]) => {
  switch (teachers.length) {
    case 0:
      return ``
    case 1:
      return `${teachers[0]} has`
    case 2:
      return `${teachers[0]} and 1 other teacher has`
    default:
      return `${teachers[0]} and ${teachers.length - 1} other teachers have`
  }
}

const InvitedTeachersBanner = ({
  onCancel,
  teachers,
  job,
  schoolId,
}: {
  onCancel: () => void
  teachers: string[]
  job: Pick<Job, "id" | "position">
  schoolId: string
}) => {
  const classes = useStyles()

  if (teachers.length === 0) return null

  return (
    <div className={clsx("mb-3 py-1.5", classes.topMessageBar)}>
      <Container>
        <div className="flex items-center justify-between">
          <Typography variant="smallBody">
            {getDisplay(teachers)} been invited for the position of{" "}
            {job.position}.
          </Typography>
          {schoolId && (
            <Button
              color="primary"
              component={Link}
              to={`${routes.school.job.details
                .replace(":schoolId", schoolId.toString())
                .replace(":jobId", job.id.toString())}?tab=Active+Applicants`}
              variant="link"
            >
              View Job
            </Button>
          )}
        </div>
      </Container>
      <IconButton
        className={classes.closeTopBarButton}
        color="black"
        onClick={onCancel}
      >
        <Xmark />
      </IconButton>
    </div>
  )
}

export default InvitedTeachersBanner
