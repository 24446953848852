import {
  Button,
  SplitTextField,
  TextField,
  Typography,
} from "@suraasa/placebo-ui"
import { useQueryClient } from "@tanstack/react-query"
import api from "api"
import { UserType } from "api/resources/recruiter/types"
import SlidingSheet from "components/SlidingSheet"
import { Xmark } from "iconoir-react"
import { useEffect, useMemo, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import countryCodes from "utils/countryCodes"
import { mapErrors } from "utils/helpers"
import useDetectCountry from "utils/hooks/useDetectCountry"
import toast from "utils/toast"
import { joinRoles } from "views/common/utils"

type Props = {
  open: boolean
  handleClose: () => void
  type: UserType.MEMBER_PARTNER | UserType.MEMBER_RECRUITER
}

export type TeamMemberForm = {
  email: string
  firstName: string
  lastName: string
  phoneNumber: { code: string; number: string } | null
}

const Loading = () => (
  <div className="flex items-center justify-center">
    <div className="m-0.25 inline-block size-0.5 animate-dot-animation rounded-full bg-surface-300"></div>
    <div
      style={{
        animationDelay: "200ms",
      }}
      className="m-0.25 inline-block size-0.5 animate-dot-animation rounded-full bg-surface-300 delay-200"
    ></div>
    <div
      style={{
        animationDelay: "300ms",
      }}
      className="m-0.25 inline-block size-0.5 animate-dot-animation rounded-full bg-surface-300 delay-300"
    ></div>
  </div>
)

const getInitialValues = (): TeamMemberForm => ({
  email: "",
  firstName: "",
  lastName: "",
  phoneNumber: null,
})

const TeamMemberSheet = ({ open, handleClose, type }: Props) => {
  const country = useDetectCountry()
  const [fetchingDetails, setFetchingDetails] = useState(false)

  const queryClient = useQueryClient()

  const {
    register,
    handleSubmit,
    reset,
    clearErrors,
    setError,
    control,
    formState: { errors, isSubmitting },
  } = useForm<TeamMemberForm>({ defaultValues: getInitialValues() })

  const options = useMemo(
    () =>
      countryCodes.map(({ dialCode }) => ({
        value: dialCode,
        label: dialCode,
      })),
    []
  )

  useEffect(() => {
    if (open)
      reset({
        email: "",
        firstName: "",
        lastName: "",
        phoneNumber: {
          code:
            countryCodes.find(item => item.code === country?.isoCode)
              ?.dialCode || "",
          number: "",
        },
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, country])

  const onClose = () => {
    reset(getInitialValues())
    handleClose()
  }

  const checkRecruiterAvailability = async (
    e: React.FocusEvent<HTMLInputElement, Element>
  ) => {
    setFetchingDetails(true)
    const res = await api.recruiter.checkRecruiter({
      data: {
        email: e.target.value,
      },
    })
    if (res.isSuccessful) {
      reset({
        firstName: res.data.firstName,
        lastName: res.data.lastName,
        phoneNumber:
          res.data.phoneNumber.code && res.data.phoneNumber.number
            ? {
                code: `+${res.data.phoneNumber.code}`,
                number: res.data.phoneNumber.number.toString(),
              }
            : null,
      })
      setFetchingDetails(false)
    } else {
      setFetchingDetails(false)
    }
  }

  const onHandleSubmit = handleSubmit(async formData => {
    clearErrors()

    let hasError = false

    if (
      formData.phoneNumber &&
      formData.phoneNumber.number &&
      !formData.phoneNumber.code
    ) {
      setError("phoneNumber.code", {
        message: "Please select country code",
      })

      hasError = true
    }

    if (hasError) {
      return
    }

    const userProfile = {
      firstName: formData.firstName,
      lastName: formData.lastName || null,
      email: formData.email,

      phoneNumber:
        formData.phoneNumber &&
        formData.phoneNumber.code &&
        formData.phoneNumber.number
          ? `${formData.phoneNumber.code}${formData.phoneNumber.number}`
          : undefined,
    }
    const res = await api.recruiter.addRecruiter({
      data: {
        ...userProfile,
        department: type === UserType.MEMBER_PARTNER ? "partner" : "recruiter",
      },
    })
    if (res.isSuccessful) {
      toast.success("Details updated successfully.")
      queryClient.invalidateQueries({ queryKey: ["team"] })
      onClose()
    } else {
      toast.error(res.errors.message || "Error adding Team Member")
      mapErrors(setError, res.errors, [
        ["phoneNumber", "phoneNumber.number"],
        ["email"],
        ["firstName"],
        ["user", "email"],
        ["lastName"],
      ])
    }
  })

  return (
    <SlidingSheet
      from="end"
      open={open}
      onClose={onClose}
      className="flex h-screen w-full flex-col px-3 pb-3 pt-4 sm:max-w-[420px]"
    >
      <Button
        variant="text"
        color="black"
        onClick={onClose}
        startAdornment={<Xmark />}
        className="-ml-1 mb-2.5"
      >
        Close
      </Button>
      <Typography variant="title3" className="mb-3">
        Add {joinRoles([type])}
      </Typography>

      <form
        onSubmit={onHandleSubmit}
        className="flex h-full flex-col justify-between"
      >
        <div className="flex flex-col gap-3">
          <div>
            <TextField
              label="Email ID"
              type="email"
              className="mb-0.5"
              inputLabelProps={{ required: true }}
              error={Boolean(errors.email)}
              helperText={errors.email?.message}
              placeholder="Ex. someone@suraasa.com"
              fullWidth
              {...register("email", {
                required: { value: true, message: "Required" },
                pattern: {
                  value: /^[^\s@]+@suraasa\.com$/,
                  message: "Only Suraasa emails are allowed.",
                },
              })}
              endAdornment={fetchingDetails ? <Loading /> : undefined}
              onBlur={checkRecruiterAvailability}
            />
          </div>
          <TextField
            label="First Name"
            inputLabelProps={{ required: true }}
            error={Boolean(errors.firstName)}
            helperText={errors.firstName?.message}
            placeholder="Ex. John"
            fullWidth
            disabled={fetchingDetails}
            {...register("firstName", {
              required: { value: true, message: "Required" },
            })}
          />
          <TextField
            label="Last Name"
            inputLabelProps={{ required: true }}
            error={Boolean(errors.lastName)}
            helperText={errors.lastName?.message}
            placeholder="Ex. Doe"
            fullWidth
            disabled={fetchingDetails}
            {...register("lastName", {
              required: { value: true, message: "Required" },
            })}
          />
          <Controller
            control={control}
            name="phoneNumber.code"
            render={({ field: { value, onChange, onBlur } }) => (
              <SplitTextField
                className="mb-1"
                disabled={fetchingDetails}
                inputLabelProps={{ required: true }}
                label="Phone Number"
                error={Boolean(errors.phoneNumber)}
                helperText={
                  errors.phoneNumber?.number?.message ||
                  errors.phoneNumber?.code?.message ||
                  errors.phoneNumber?.message
                }
                selectProps={{
                  onBlur: onBlur,
                  placeholder: "+XX",
                  options,
                  value: value ? options.find(c => c.value === value) : null,
                  onChange: val => {
                    clearErrors()
                    if (val) onChange(val.value)
                  },
                }}
                textFieldProps={{
                  type: "number",
                  placeholder: "Enter phone number",
                  ...register("phoneNumber.number", {
                    required: { value: true, message: "Required" },
                  }),
                }}
                fullWidth
              />
            )}
            rules={{
              required: { value: true, message: "Required" },
            }}
          />
        </div>
        <Button
          type="submit"
          className="!rounded-full"
          fullWidth
          loading={isSubmitting}
        >
          Add
        </Button>
      </form>
    </SlidingSheet>
  )
}

export default TeamMemberSheet
