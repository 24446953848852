import Navbar from "components/shared/Navbar"
import TeacherProfile from "components/teacher/profile"

const Profile = () => {
  return (
    <>
      <Navbar gutterBottom={false} hideBackButton />
      <TeacherProfile />
    </>
  )
}

export default Profile
