import { validateUrls } from "api/types"
import { getServiceURL } from "api/utils"
import { ServicePrefix } from "utils/constants"

const getSolisURL = getServiceURL(ServicePrefix.solis)

export const urls = validateUrls({
  user: () => getSolisURL("/v1/users/"),
  changePassword: () => getSolisURL("/v1/auth/change-password/"),
  generateOTP: () => getSolisURL("/v1/school-users/otp/"),
  verifyOTP: () => getSolisURL("/v1/school-users/otp/verify/"),
})
