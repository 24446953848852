const isSymbol = (word: string) =>
  Boolean(/[-!$%^&*()_+|~=`{}[\]:";'<>?,./]/.exec(word))

export const countWords = (text: string) => {
  if (!text) return 0

  const chunks = text
    // Replace all html tags with empty space
    .replace(/<[^>]+>/g, " ")
    // Replace characters like &nbsp; (html entities)
    .replace(/&.{2,8};/g, " ")
    // Collapse all spaces, line breaks into one space.
    .replace(/\s+/g, " ")
    // Remove spaces on the ends
    .trim()
    // Split on space. Voila, we now have all the words
    .split(" ")

  // Without symbols
  const cleanedChunks = chunks.filter(word => {
    /**
     * Allow any combination to pass if length > 0
     */
    if (word.length > 1) return true

    /**
     * Only return words which are not symbols
     * An example of 'words' that won't pass through this check are:
     * '*', '.', '#' etc... (without quotes and commas)
     */
    return !isSymbol(word)
  })

  // Helpful for debugging. Don't remove
  // console.log(`Text typed ${text}`)
  // console.log("cleanedChunks", cleanedChunks)

  // Prevent [''] from setting word count = 1
  if (cleanedChunks.length === 1 && !cleanedChunks[0]) {
    return 0
  }
  return cleanedChunks.length
}
