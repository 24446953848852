import { Button, Divider, Typography } from "@suraasa/placebo-ui"
import api from "api"
import { SkillEvidence } from "api/resources/teacher/types"
import { ArrowRight } from "iconoir-react"
import React from "react"
import { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import { routes } from "utils/routes"

import Section from "../Section"
import SectionContent from "../Section/SectionContent"
import SectionHeading from "../Section/SectionHeading"
import SectionTitle from "../Section/SectionTitle"
import { ProfileQuickAccessTabs } from "../utils"
import ItemRow from "./ItemRow"

const TopSkillEvidences = () => {
  const { username } = useParams<{ username?: string }>()

  const [data, setData] = useState<SkillEvidence[]>([])

  useEffect(() => {
    api.teacher.skillEvidences
      .list({
        urlParams: {
          username: username ?? "",
        },
      })
      .then(res => {
        if (res.isSuccessful) {
          setData((res.data.skills || []).slice(0, 3))
        }
      })
  }, [])

  return (
    <>
      <Section id={ProfileQuickAccessTabs.topSkillEvidences}>
        <SectionHeading
          heading={
            <SectionTitle
              title="Skill Evidences"
              actionIcon={
                <Button
                  disabled={!data.length}
                  component={Link}
                  to={routes.publicSkillProfile.overview.replace(
                    ":username",
                    username ?? ""
                  )}
                  variant="text"
                  endAdornment={<ArrowRight />}
                >
                  View All
                </Button>
              }
            />
          }
          xPadding={2}
        />
        <SectionContent xPadding={2}>
          {data.length === 0 && (
            <Typography variant="smallBody" color="onSurface.400">
              No skill evidences available.
            </Typography>
          )}
          <div className="flex flex-col gap-1.5">
            {data.map((item, index) => (
              <React.Fragment key={item.slug}>
                <ItemRow data={item} />
                {index < data.length - 1 && (
                  <Divider color="onSurface.200" weight="light" />
                )}
              </React.Fragment>
            ))}
          </div>
        </SectionContent>
      </Section>
    </>
  )
}

export default TopSkillEvidences
