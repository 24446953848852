import { Tag, Typography } from "@suraasa/placebo-ui"
import Section from "components/teacher/profile/Section"
import SectionContent from "components/teacher/profile/Section/SectionContent"
import SectionHeading from "components/teacher/profile/Section/SectionHeading"
import SectionTitle from "components/teacher/profile/Section/SectionTitle"
import React, { useContext } from "react"
import { createUseStyles } from "react-jss"
import { pluralize } from "utils/helpers"
import ProfileContext from "views/teacher/profileContext"

const useStyles = createUseStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(1.5),
  },
}))

const Subjects = () => {
  const classes = useStyles()

  const {
    subjects: { data },
  } = useContext(ProfileContext)

  return (
    <>
      <Section>
        <SectionHeading
          heading={
            <SectionTitle
              itemCount={data.length}
              title={pluralize("Subject", data.length, { skipCount: true })}
            />
          }
        />

        <SectionContent className={classes.root} xPadding={2}>
          {data.length > 0 ? (
            data.map(
              (item, index) =>
                item.subject && (
                  <Tag
                    key={item.id || index}
                    label={item.subject.name}
                    size="sm"
                  />
                )
            )
          ) : (
            <Typography color="onSurface.400" variant="smallBody">
              Looks like there's nothing here.
            </Typography>
          )}
        </SectionContent>
      </Section>
    </>
  )
}
export default Subjects
