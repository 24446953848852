/* eslint-disable no-console */
import {
  getAnalytics,
  isSupported as analyticsIsSupported,
} from "firebase/analytics"
import { initializeApp } from "firebase/app"
import { getFirestore } from "firebase/firestore"
import { getToken } from "firebase/messaging"

const config = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  databaseURL: import.meta.env.VITE_FIREBASE_DATABASE_URL,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
  measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
}

const app = initializeApp(config)

const enableAnalytics = async () => {
  const result = await analyticsIsSupported()
  if (result) {
    getAnalytics()
  }
}

enableAnalytics()

const messaging: any | undefined | null = undefined

// const setupMessaging = async () => {
//   messaging = (await messagingIsSupported()) ? getMessaging() : null

//   if (messaging) {
//     onMessage(messaging, payload => {
//       // eslint-disable-next-line no-console
//       console.log("> In-App notification recieved", payload)
//       if (payload.notification && payload.notification.body)
//         toast.info(payload.notification.body)
//     })
//   }
// }

// setupMessaging()

// navigator.serviceWorker
//   .register("./firebase-messaging-sw.js")
//   .then(registration => {
//     messaging.useServiceWorker(registration)

//     // Request permission and get token.....
//   })

// messaging
//   .requestPermission()
//   .then(function () {
//     console.log("Got Perms")
//   })
//   .catch(function (err) {
//     console.log("Perms Denied", err)
//   })

// Initialize Cloud Firestore and get a reference to the service
export const db = getFirestore(app)

export const requestFirebaseNotificationPermission = () => {
  if (!("Notification" in window)) {
    return new Promise((resolve, reject) =>
      reject("Firebase is not supported on this browser")
    )
  }

  return Promise.resolve(Notification.requestPermission()).then(permission => {
    if (permission === "granted") {
      console.log("Notification permission granted.")
      // TODO(developer): Retrieve a registration token for use with FCM.
      // In many cases once an app has been granted notification permission,
      // it should update its UI reflecting this.
      return getFirebaseToken()
    } else {
      console.log("Unable to get permission to notify.")
    }

    // return new Promise((resolve, reject) => {
    //   if (messaging) {
    //     messaging
    //       .requestPermission()
    //       .then(getFirebaseToken)
    //       .then(firebaseToken => {
    //         resolve(firebaseToken)
    //       })
    //       .catch(err => {
    //         reject(err)
    //       })
    //   } else {
    //     reject("Firebase is not supported in this browser")
    //   }
    // })
  })
}

export function getFirebaseToken() {
  if (messaging)
    return getToken(messaging, {
      vapidKey: import.meta.env.VITE_FIREBASE_VAPID_KEY,
    })
  return new Promise((resolve, reject) => {
    // reject("Firebase is not supported in this browser")
    reject(null)
  })
}
